import React from 'react'
import moment from 'moment'



export const Activity = ({date,activities}) => {
    
  return (
   
     <div>
     <p className='acvtivity_header mt-3'>{moment(date).format('DD MMM YY')}</p>
     
     {activities.map(({ type,like,pay_request,pay_someone,post,paid_by_someone,given_to_post},i) => <p className='border-bottom py-2 text-dark' key={i}>
       
      {
        type === 'someone_like' ? `${like?.user?.full_name? like?.user?.full_name : like?.user?.email ? like?.user?.email : 'Someone' }  liked your "${like?.post?.ACTIVITY}" post `
        :
        type === 'like' ? `You liked ${like?.user?.full_name? like?.user?.full_name :  like?.user?.email ? like?.user?.email : 'someone' } post "${like?.post?.ACTIVITY}"`
        :
        type === 'pay_request' ? `You generated code "${pay_request?.code}" for a payment request of ${pay_request?.amount < 0.01 ? '<$0.01' : '$'+pay_request?.amount } USD`
        :
        type === 'pay_someone' ? `You paid ${pay_someone?.amount < 0.01 ? '<$0.01' : '$'+pay_someone?.amount} USD to ${pay_someone?.payme_request?.user?.full_name? pay_someone?.payme_request?.user?.full_name :  pay_someone?.payme_request?.user?.email ? pay_someone?.payme_request?.user?.email : 'someone' }`
        :
        type === 'post' ? `You posted  "${post?.ACTIVITY}" post`
        :      
        type === 'paid_by_someone' ? `You received ${pay_someone?.amount < 0.01 ? '<$0.01' : '$'+pay_someone?.amount} USD from ${pay_someone?.user?.full_name? pay_someone?.user?.full_name :  pay_someone?.user?.email ? pay_someone?.user?.email : 'someone' }`
        :
        type === 'given_to_post' ? `You gave $${given_to_post?.value_in_doller} USD to "${given_to_post?.post?.ACTIVITY}" post `
        :
        null
    }
   
      
      </p>)}
     </div>
    
  )
}
