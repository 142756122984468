import React, { useState} from 'react'
import Base from '../Layouts/Base'
import { Link } from 'react-router-dom'
import axios from 'axios'

const Terms = () => {
    
    const [email,setEmail] = useState('')

    const handleUpdateSubmit = async (e) =>{
        e.preventDefault()
        let payload = JSON.stringify({'email':email})
        let res = await axios.post(`/user/update-sign-up-user/`,payload,
          {
            headers: {
              "Content-Type": "application/json",
            }
          }
        );
        if(res.statusText === 'OK'){
            setEmail('')
        }
    }

    return (
        <Base>
            <div className='container termsWrapper my-3'>
                <h3 className='text-center mb-4'>Terms and Conditions</h3>
                <div>
                    <h4 className='mb-3'>Hello and welcome to The Beach Collective.</h4>
                    <p>
                        We have developed the Beach Collective (“BC”) so that together we can all help make the world a better place.
                        We have since the start prided ourselves on being light on the legalese.  We have always collaborated with
                        people that understood that what we are trying to achieve comes from a good place and that what we are
                        trying to achieve is worth supporting without the need for masses of defensive legal mumbo-jumbo.  For
                        the most part we have been right, in that 99.999% of people just get it.  Sadly, however and perhaps
                        reflective of some naivety on our behalf we also found out (the hard way) that this is not always the case,
                        and that sometimes it is necessary to protect ourselves and clearly define and set out the legal relationship
                        between us and the people we interact with
                    </p>
                    <p>
                        With all this in mind, these terms and conditions set out and govern the legal relationship between you and us.
                        These terms are to the greatest extent possible still intended to reflect the values that we at BC strive to
                        uphold nevertheless we recognise that it is necessary to protect ourselves at the same time. We will review and
                        revise these terms from time to time.  Please read these terms and conditions carefully they form a legal
                        contract between you and us.
                    </p>
                    <p>
                        In order to use BC or any of our other products or services that link to these Terms (the “Services”), you must
                        have accepted our Terms and Privacy Policy, which are presented to you (i) when you first open the website,
                        dApp or other digital mediums and (ii) when we make any material changes to the Terms or the Privacy Policy.
                        If you don’t accept our Terms and Privacy Policy, then don’t use the Services.
                    </p>
                    <h6 className='my-3'>
                        ARBITRATION NOTICE: IF YOU’RE USING THE SERVICES ON BEHALF OF A BUSINESS OR A NOT-FOR-PROFIT ORGANISATION
                        (“ORGANISATION”), THEN YOUR ORGANISATION WILL BE BOUND BY THE ARBITRATION CLAUSE THAT APPEARS LATER IN
                        THESE TERMS.
                    </h6>
                </div>
                <div className='my-3'>
                    <h4>1.&nbsp;&nbsp;&nbsp;Who can use the Services</h4><br />
                    <p>No one under 13 years of age is allowed to create an account or use the Services.</p><br />
                    <p>By using the Services, you make the following statements</p><br />
                    <ul className='terms-list'>
                        <li> you can form a binding contract with The Beach Collective Foundation (“BCF”);</li>
                        <li>you are not prohibited from receiving our products, services or software under applicable laws;</li>
                        <li>you are not a convicted sex offender;</li>
                        <li>you are not an employee, agent, contractor, developer or owner of an online social network or social media platform (that is not BC) or, without limitation, any platform that is a competitor of BC, other than to the extent you access and use the Services entirely in a personal capacity;  </li>
                        <li>you will comply with these Terms and all applicable local, state, national, and international laws, rules, regulations;</li>
                        <li>you have not previously had an account disabled or removed by us for breaches of our Terms or Policies;</li>
                        <li>you will not create an account other than using your real name;</li>
                        <li>you will not create more than one account, provided that you may create both a personal account and, if relevant a bona fide organisation account in respect of an organisation which you are legally authorised to create an account for and which is otherwise entitled to create an account on BC in its own right and in accordance with our policies and terms and conditions with respect to organisation accounts;</li>
                        <li>you will not provide inaccurate information (or information that is otherwise intended to mislead) about yourself; and</li>
                        <li>you will not permit or facilitate the access of your account by other people, entities or by any software, bot or program.</li>
                    </ul>
                    <p>You cannot use BC if you are unable to truthfully or accurately make any of the statements listed above, if you breach any of these statements or if at any point any of these statements cease to be accurate.</p>
                    <p><u>Additional terms if you are an Organisation</u></p>
                    <p>If you are using the Services on behalf of an Organisation or some other entity, you state that you are legally authorised to grant all licences set out in these Terms and to agree to these Terms on behalf of the Organisation or entity.</p>
                    <p>You confirm that you have accurately declared the nature of your business including as to whether it and the nature of goods and/or services you provide is appropriate for people under the age of 18.</p>
                    <p>Certain types of businesses and the goods and/or services they provide can not use our Services and or certain aspects of our services. We do this because it may not be appropriate nor legal for others on the platform to be exposed to certain things. In addition there are certain categories of businesses, goods, services and interest groups which are not, consistent with our values.</p>
                    <p>The Organisations that are restricted (“<strong>Restricted Categories</strong>”) are those that fall into the following categories.</p>
                    <ul className='terms-list'>
                        <li>Organisations whose ideologies, practices or conduct are based on promoting discrimination, hate, bigotry or inflicting suffering on others (this may for example include terrorist or far-right organisations).</li>
                        <li>Organisations that sell weapons, ammunition, explosives or instruments designed to inflect suffering on others. </li>
                        <li>Organisations involved in the adult industry including those that sell adult products, services or content</li>
                        <li>Organisations involved in the tobacco or marijuana industry, including vape companies</li>
                        <li>Organisations that sell or promote unsafe supplements</li>
                        <li>Organisations that produce or distribute fake, controversial, discriminatory or divisive political content</li>
                        <li>Organisations that sell surveillance equipment or software</li>
                        <li>Organisations that offer payday or cash advance loans</li>
                        <li>Organisations that promote or are structured as multi-level marketing or pyramid schemes</li>
                        <li>Organisations that are involved in the scrapping, harvesting, mining or collation of big data</li>
                        <li>Penny Auction Companies</li>
                        <li>Organisations that sell counterfeit documents</li>
                        <li>Organisations that sell counterfeit goods </li>
                        <li>Organisations that develop, distribute or otherwise apply spyware or malware or hacking software or activities</li>
                        <li>Organisations that sell unauthorised streaming devices </li>
                        <li>Organisations that offer prohibited financial products or services </li>
                        <li>Organisations that offer or otherwise promote an online based social network or social media platform </li>

                    </ul>
                    <p>Each Organisation that joins BC states that it is not an Organisation that falls into any of the categories listed above or
                        that otherwise conducts itself or participates in activities that would fall into any such categories and acknowledges that in
                        the event it breaches these Terms (and any policies relating thereto) and/or if it does in fact fall into these categories that
                        we may, at our own discretion, take any (or any combination) of the following actions:</p>
                    <ul className='terms-list'>
                        <li>Suspend and/or terminate your account and access to our Services</li>
                        <li>Suspend and/or terminate the accounts of any persons affiliated with such account.</li>
                        <li>Prohibit any persons or Organisations referred to above from using our Services in future.</li>
                        <li>Confiscate all BC balances that may be affiliated with such accounts without any obligation to compensate or refund such Organisation in respect of the same</li>

                    </ul>
                    <p>
                        Any Organisation or person who’s account is suspended or terminated as contemplated above agrees that they shall in no way be entitled to claim a
                        refund for any amounts paid by them (whether in advance or otherwise) in respect of our Services.
                    </p>
                    <p>
                        False or misleading actions<br />
                        We have built BC in a manner that trust and integrity are at the heart of how our Services work. We want BC to be a safe and friendly place
                        free, (to the maximum extent possible), from the anti-social practices that plague social media including bullying, trolling, cat-fishing
                        and other undesirable practices or behaviour. We want people to be kind and considerate and respectful toward each other. To help promote
                        this BC relies on the concept of social accountability. This is reflected in a number of tools we have implemented (and will implement).
                        We take anti-social activity and accusations of the same seriously.
                    </p>
                </div>
                <div className='my-3'>
                    <h4>2.&nbsp;&nbsp;&nbsp;Rights we grant you</h4><br />
                    <p>BCF grants you a personal, worldwide, royalty-free, non-assignable, non-exclusive, revocable, and non-sublicensable licence to access
                        and use the Services. This licence is for the sole purpose of letting you use and enjoy the Services’ benefits in a way that these Terms
                        and our usage policies, and any guidelines that we may issue from time to time, permit.</p>
                    <p>Any software that we provide you may automatically download and install upgrades, updates, or other new features.
                        You may or may not be able to adjust these automatic downloads through your device’s settings</p>
                    <p>
                        You may not copy, modify, distribute, sell, or lease any part of our Services. Nor may you reverse engineer or attempt to extract the
                        source code of that software, unless any applicable laws prohibit these restrictions or you have our express prior awritten permission to
                        do so.  You may not use our Services or platform for the purposes of taking, harvesting or extracting our intellectual property or features,
                        ideas and concepts or any parts of our Services and applying them or derivatives of them in the furtherance of another platform, software or service.
                    </p>
                    <p>
                        These terms do not transfer any right, title or interest in any intellectual property right to you. We maintain all rights, title and interest in and
                        to all its patents, inventions, copyrights, trademarks, domain names, trade secrets, know-how and any other intellectual property and/or proprietary rights
                        (collectively, “<strong>Intellectual Property Rights</strong>”). The limited rights granted to you to access and use the Services do not convey any
                        additional rights in the Services, or in or to any Intellectual Property Rights associated therewith. Subject only to the limited rights to access and
                        use the Services as expressly provided herein, all rights, title and interest in and to the Services and all, software and other components of or used to
                        provide the Services, including all related Intellectual Property Rights, will remain with and belong exclusively to us.
                    </p>
                </div>
                <div className='my-3'>
                    <h4>3.&nbsp;&nbsp;&nbsp;Rights you grant us</h4><br />
                    <p>
                        Many of our Services let you create, upload, post, send, receive, and store content. When you do that, you retain whatever ownership rights in that content
                        you had to begin with. But you grant us a licence to use that content. How broad that licence is depends on which Services you use and the settings you have selected.
                    </p>
                    <p>
                        Any content you create, upload, post, send, receive, generate and store may be publicly viewable.  For all content you submit to the Services, you grant BCF and its
                        affiliates a worldwide, royalty-free, sublicensable, and transferable licence to host, store, use, display, reproduce, modify, adapt, edit, publish, and distribute
                        that content for as long as you use the Services. This licence is for the limited purpose of operating, developing, providing, promoting, developing and improving
                        the Services and developing new services.
                    </p>
                    <p>
                        Because Public Content is public by nature and records matters of public interest, the licence you grant us for this content is broader. In addition to granting us
                        the rights mentioned in the previous paragraph, you also grant us a licence to create derivative works from, promote, exhibit, broadcast, syndicate, sublicence,
                        publicly perform and publicly display Public Content in any form and in any and all media or distribution methods (now known or later developed). To the extent
                        it’s necessary, when you appear in, create, upload, post or send Public Content, you also grant BCF and our affiliates and business partners the unrestricted,
                        worldwide right and licence to use your name, likeness, and voice. This means, among other things, that you will not be entitled to any compensation from BCF or
                        our affiliates and business partners if your name, likeness, or voice is conveyed through the Services, either on the website, dApp, app or any other medium or
                        on one of our business partners’ platforms
                    </p>
                    <p>
                        For more information about how to tailor who can view your content, please take a look at our FAQ and tutorial guidance content and Privacy Policy.
                    </p>
                    <p>
                        We reserve the right to delete any content (i) which we think violates these Terms, any community guidelines we may institute from time to time or the social,
                        environmental or morals, values or objectives of BC, or (ii) if necessary to comply with our legal obligations. However, you alone, remain responsible for the
                        content you create, upload, post, send, generate or store through the Services.
                    </p>
                    <p>
                        Because the Services contain content that you and other users provide us, advertising may sometimes appear near, between, over, or in your content.
                    </p>
                    <p>We always love to hear from our users. But if you provide feedback or suggestions, just know that we can use this without compensating you, and without any restriction or obligation to you.</p>
                </div>
                <div className='my-3'>
                    <h4>4.&nbsp;&nbsp;&nbsp;The content of others</h4><br />
                    <p>
                        Much of the content on our Services is produced by users, publishers, and other third parties. Whether that content is posted
                        publicly or sent privately, the content is the sole responsibility of the person or organisation that submitted it or generated it.
                        Although BC reserves the right to review all content that appears on the Services and to remove any content that violates these
                        Terms, any community guidelines we may institute from time to time or the social, environmental or morals, values or objectives of
                        BC or the law, we do not necessarily review all of it.
                    </p>
                    <p>
                        Through these Terms, any community guidelines we may institute from time to time or the social, environmental or morals, values or
                        objectives of BC, we make clear that we don't want the Services to be put to improper uses. But because we don't review all content,
                        we cannot guarantee that content on the Services will always conform to our Terms, any community guidelines we may institute from
                        time to time or the social, environmental or morals, values or objectives of BC.
                    </p>
                </div>
                <div className='my-3'>
                    <h4>5.&nbsp;&nbsp;&nbsp;Privacy</h4><br />
                    <p>
                        Your privacy matters to us. You can learn how your information is handled when you use our Services by reading the Privacy Policy.
                    </p>
                </div>
                <div className='my-3'>
                    <h4>6.&nbsp;&nbsp;&nbsp;Respecting other people’s rights</h4><br />
                    <p>
                        BCF and its affiliates respects the rights of others. And so should you. You therefore may not use the Services, or enable anyone else to use the Services, in a manner that:
                    </p>
                    <ul className='terms-list'>
                        <li>violates or infringes someone else’s rights of publicity, privacy, copyright, trademark, or other intellectual-property right;</li>
                        <li>bullies, harasses, or intimidates;</li>
                        <li>defames;</li>
                        <li>spams or solicits our users;</li>
                        <li>is unkind to others, the planet or yourself.</li>
                        <li>In short: You may not use the Services or the content on the Services in ways that are not authorised by these Terms. Nor may you help anyone or anything else in doing so.</li>
                        <li>You must also respect BCF’s and its affiliates’ rights and adhere to any guidelines we may published from time to time. You may not do any of the following (or enable anyone else to do so):</li>
                        <li>use branding, logos, designs, photographs, videos, or any other materials used in our Services, except as expressly contemplated in any brand guidelines we may publish from time to time.</li>
                        <li>copy, archive, download, upload, distribute, syndicate, broadcast, perform, display, make available, or otherwise use any portion of the Services or the content on the Services except as set forth in these Terms.</li>
                        <li>use the Services, any tools provided by the Services, or any content on the Services for any commercial purposes without our express written consent.</li>

                    </ul>
                </div>
                <div className='my-3'>
                    <h4>7.&nbsp;&nbsp;&nbsp;Copyright</h4><br />
                    <p>
                        BCF honours copyright laws. We therefore take reasonable steps to expeditiously remove from our Services any infringing material that we become aware of. And if BCF becomes aware that one of its account holders has repeatedly
                        infringed copyright, we will take reasonable steps within our power to terminate such account.
                    </p>
                    <p>
                        We make it easy for you to report suspected copyright infringement. If you believe that anything on the Services infringes a copyright that you own or control, please report it using the reporting function that is available
                        on every post in the platform.
                    </p>
                    <p>
                        If you notify us of a breach of copyright you will be required to provide a notice:
                    </p>
                    <ul className='terms-list'>
                        <li>identifying the copyrighted work claimed to have been infringed;</li>
                        <li>identifying the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed, or access to which is to be disabled, and information reasonably sufficient to let us locate the material;</li>
                        <li>setting out your contact information, including your address, telephone number, and an email address;</li>
                        <li>setting out a personal statement that you have a good-faith belief that the use of the material in the manner complained of is not authorised by the copyright owner, its agent, or the law;</li>
                        <li>setting out a statement that the information in the notification is accurate and, under penalty of perjury, that you are authorised to act on behalf of the copyright owner; and</li>
                        <li>setting out the physical or electronic signature of a person authorised to act on behalf of the copyright owner.</li>

                    </ul>
                </div>
                <div className='my-3'>
                    <h4>8.&nbsp;&nbsp;&nbsp;Safety</h4><br />
                    <p>
                        We want BC to be a safe and welcoming place where people feel comfortable and secure. While we have implemented a number of tools designed to help make BC a safe place we can’t guarantee it.  The best chance we have to make
                        sure BC is safe for all is if everyone does their little bit.  With this in mind, by using the Services, you agree that:
                    </p>
                    <ul className='terms-list'>
                        <li>You will always use the Services and conduct yourself in a manner that is kind to others, kind to the planet and kind to yourself </li>
                        <li>You will not use the Services for any purpose that is illegal or prohibited in these Terms or in any guidelines or policies that may be issued from time to time.</li>
                        <li>You will not use any robot, spider, crawler, scraper, or other means or interface to access the Services or extract the information or data of others.</li>
                        <li>You will not use or develop any third-party applications that interact with the Services or the content of others or information or data.</li>
                        <li>You will not use the Services in a way that could interfere with, disrupt, affect negatively, or inhibit others from fully enjoying the Services, or that could damage, disable, overburden, or impair the functioning of the Services.</li>
                        <li>You will not use or attempt to use another person account, username, or password without their permission.</li>
                        <li>You will not solicit nor share login credentials from another person.</li>
                        <li>You will not post or share content that contains pornography, graphic violence, threats, hate speech, or incitements to violence.</li>
                        <li>You will not upload viruses or other malicious code or otherwise compromise the security of the Services.</li>
                        <li>You will not attempt to circumvent any content-filtering techniques we employ, or attempt to access areas or features of the Services that you are not authorised to access.</li>
                        <li>You will not probe, scan, or test the vulnerability of our Services or any system or network.</li>
                        <li>You will not encourage or promote any activity that violates these Terms.</li>

                    </ul>
                    <p>
                        We also care about your safety while using our Services. So do not use our Services in a way that would distract you from obeying any laws including traffic and safety laws.
                    </p>
                    <p>
                        Certain aspects of our Services will from time to time contemplate a call to action. This may involve a physical activity (for example picking up litter or partaking in physical
                        exercise) or a social activity (for example meeting friends or strangers). You acknowledge that these are only ideas and are in no way an instruction or recommendation by
                        us to do or not do anything. You need to exercise your own care, judgement and discretion always with your safety and the safety of others in mind as well as being kind,
                        courteous, considerate and respectful of others. This extends to your physical and mental wellbeing and making sure you do not undertake any activity that may be contrary
                        to any medical condition you may have or medical guidance you may have been given. While we want to encourage positive actions, your health and safety and that of others,
                        is paramount so do not do anything that risks this.
                    </p>
                    <p>
                        Make sure you always take proper precautions such as wearing gloves, footwear and appropriate safety or activity specific equipment and being careful about who, where and how you meet strangers both online and in real life (to give two examples!)
                    </p>
                    <p>
                        Always be aware of your surroundings and do not use the Services (including, without limitation any augmented reality aspects of the Services) in a manner that may put you or others in harm’s way or otherwise be contrary to law or any duty of care that may apply.
                    </p>
                </div>
                <div className='my-3'>
                    <h4>9.&nbsp;&nbsp;&nbsp;Your account</h4><br />
                    <p>
                        You are responsible for any activity that occurs in your BC account. So it’s important that you keep your account secure. One way to do that is to select a strong password that you don’t use for any other account.
                    </p>
                    <p>By using the Services, you agree that, in addition to exercising common sense:</p>
                    <ul className='terms-list'>
                        <li>You will not create more than 1 account for yourself.</li>
                        <li>You will not create another account if we have already disabled your account, unless you have our written permission to do so.</li>
                        <li>You will not buy, sell, rent or lease access to your BC account, any content, or an BC username without our written permission.</li>
                        <li>You will not share your password.</li>
                        <li>You will not log in or attempt to access the Services through unauthorised third-party applications, bots, other software or clients.</li>
                        <li>You will not use or seek to use the Services or the Platform as the basis for developing a competitive solution (or contract with a third party to do so).</li>

                    </ul>
                    <p>If you think that someone has gained access to your account, please reach out immediately by reporting the account via the in-app function or write to BC support.</p>
                </div>
                <div className='my-3'>
                    <h4>10.&nbsp;&nbsp;DAO and governance</h4><br />
                    <p>BCF is established (or will be established) as a not-for-profit DAO foundation. We may change the corporate structure of BC from time to time. We may, but have no obligation
                        to, tell you about this not to seek your consent.</p>
                    <p>As a DAO, BC will in due course, provide a mechanism through which holders of $BEACH will, subject to certain guidelines, rules and requirements, have the opportunity from
                        time to time to participate in the governance of BCF and decision making with respect to BC.</p>
                    <p>BCF will also establish a Stewardship Board (the “SB”) whose functions will include oversight, vetting and implementation of DAO proposals and decisions. BCF and the members
                        of the SB shall have no liability to you, and you indemnify them to the extent you may attempt to assert any claim against them with regards to any proposal, voting, governance
                        related aspects, the loss or change in value of tokens nor the performance of their duties relating thereto.</p>
                    <p>Your participation in the DAO will be entirely governed by and be subject to the operating procedures of the DAO but may include: (1) making proposals for the SB to consider
                        putting up for a community vote and (2) voting with respect to proposals which are put forward for community vote.</p>
                    <p>The ways in which voting will occur and be counted may change from time to time based on either a community vote and/or at the reasonable discretion of the SB.</p>
                    <p>Voting may take into account your wallet balance, your bag balance and/or a combination of both. Your right to vote may be subject to other restrictions including (1)
                        a minimum threshold on the number of tokens you hold (2) a minimum threshold on the amount of time you have held your voting tokens.</p>
                    <p>The SB and BCF may change the voting mechanism from time to time including, without limitation, in order to satisfy or otherwise provide for applicable legal and regulatory requirements.</p>
                    <p>In the event of a hack, exploit or otherwise where the integrity of the DAO and voting mechanism may be under threat or attack, the SB and/or BCF may take such emergency action
                        (“Emergency Action”) as they reasonably deem necessary to maintain the integrity of BC, the Services and/or the voting mechanism.</p>
                </div>
                <div className='my-3'>
                    <h4>11.&nbsp;&nbsp;Beach Token and other currencies</h4><br />
                    <p>BC uses Beach Token ($BEACH) as its native token in the offering and performance of the Services and the various activities relating there to.</p>
                    <p>At present $BEACH exists on the Binance Smart Chain (“BSC”) blockchain, however BCF and the SB may propose (1) migrating to another blockchain (2) migrating to a new smart contract provided
                        that they receive community approval via the appropriate DAO voting mechanism in place at such time, provided that if no such mechanism is in place at such time then BCF and/or the SB may propose a mechanism.</p>
                    <p>We may from time to facilitate the use of other tokens on BC (together with $BEACH, the “Tokens”) and in the provision of our services. If we do so then you agree that we may at our discretion elect to
                        keep those other tokens in their native form or to exchange them at any time for a, then equivalent, amount of $BEACH. </p>
                    <p>You agree that transactions on BC will be subject to the payment of platform fees which will be deducted from any transaction amounts and, if necessary, converted into $BEACH.</p>
                    <p>Other than to the extent of BCF’s own fraud, you agree that none of BCF, its Affiliates, employees or agents shall bear any liability for:</p>
                    <div className='my-3'>
                        <p>-&nbsp;&nbsp;&nbsp;&nbsp;the loss, theft of any of your tokens (including $BEACH or any other token);</p>
                        <p>-&nbsp;&nbsp;&nbsp;&nbsp;the change in value of such tokens;</p>
                        <p>-&nbsp;&nbsp;&nbsp;&nbsp;the exploitation of or any error relating to any smart contract (including without pmitation the token smart contracts, the core custody contract); or</p>
                        <p>-&nbsp;&nbsp;&nbsp;&nbsp;any software or transaction error in BC, the Services, the ledger</p>
                        <p>-&nbsp;&nbsp;&nbsp;&nbsp;and you agree to indemnify BCF in respect of any claim you may make against it in contravention of the above.</p>

                    </div>
                    <p>You agree that any tokens may receive, accrue or which you otherwise may have standing to the balance of your wallet or bag or
                        account at any time: are not exchangeable, refundable or redeemable for cash or any other credit by BCF and you must look to the
                        appropriate decentralised or centralised exchange to exchange such tokens into another form;</p>
                    <p>To the extent any Token has (in our reasonable opinion) been transferred, accrued as a result of, or used or intended to be
                        used for, any purpose or activity that is illegal, fraudulent or involves the unauthorised accessing of our platform, use of our
                        Services or any account on it, we shall at our discretion be entitled (without being obliged) to: </p>
                    <ul className='terms-list'>
                        <li>suspend the use or transfer of such Tokens;</li>
                        <li>cause the transfer or return or confiscate any such Tokens and redistribute, burn or reinstate them as we (acting reasonably) deem appropriate (or with a community vote) and without any obligation to compensate you or any other person; and/or</li>
                        <li>suspend or terminate your account.</li>

                    </ul>
                </div>
                <div className='my-3'>
                    <h4>12.&nbsp;&nbsp;Crypto related risks</h4><br />
                    <p>Cryptocurrencies are risky and volatile and their value may be subject to frequent and extreme fluctuations both up
                        and down. It is possible that the value of your portfolio may be reduced to zero as a result.</p>
                    <p>Given the “ownerless” and relative “anonymity” of holding and ease of dealing with crypto assets, cryptocurrency and
                        blockchain related platforms, including BC, are frequently the subject of attacks by cyber-criminals and various scams.
                        Often these scams result in the theft, lock-up and/or loss of tokens.  It is possible that the value of your portfolio
                        may be reduced to zero as a result.</p>
                    <p>We do not accept any responsibility for such dynamics and if you are not prepared to accept this risk then you might
                        wish to reconsider whether crypto related initiatives, including BC are appropriate for you.</p>
                    <p>By joining BC or otherwise using the Services, you acknowledge and agree that BCF, its Affiliates, employees and agents
                        shall have no liability to you in respect of any such losses and you indemnify each of BCF, its Affiliates, employees and
                        agents to the extent of any claim that you may make in contradiction of the above. </p>

                </div>
                <div className='my-3'>
                    <h4>13.&nbsp;&nbsp;Wallet and Bag</h4><br />
                    <p>To help further reduce the cost and energy footprint of our activities, transactions in BC occur off-chain within a smart
                        contract (or contracts) commonly referred to as the “Core Custody Contract” (the “CCC”).  The CCC holds these tokens ($BEACH
                        and other tokens) and is informed by what we refer to as the “Master Ledger” which keeps a record of all transactions each
                        user has made (tokens received, spent, deposited and withdrawn) and what each users token balance is within the BC is at any given point in time.</p>
                    <p>Your balance within the BC is reflected in what we call your “Bag”.  Hence you can think of your bag as your off-chain wallet on the BC.  Because your
                        Bag only refers to your share of the tokens in the CCC, it does not include any $BEACH (or other tokens) that you may hold on-chain in your crypto-wallet nor any $BEACH you may hold on a CEX.  </p>
                    <p>Despite its name, tokens in the CCC are not held in our custody, they are held in the custody of the CCC smart contract itself and we have no ability to control
                        or access those tokens other than in limited circumstances being (i) to cause the migration of the token to another contract (and/or chain) or (ii) to take emergency
                        action in the event of an exploit or manifest error.  In either case these steps are subject to a community vote (either prior to the event, or in the case of emergency
                        action, to ratify the emergency action taken after the fact). </p>
                    <p>Other than where the limited interventions referred to above apply, you are always free to withdraw your $BEACH (and other tokens)
                        from your bag to your wallet (or indeed to deposit $BEACH from your wallet to your bag). </p>
                    <p>You acknowledge and agree that BCF does not have custody of your $BEACH (or other tokens), that these are instead held in the custody
                        of the CCC itself and that we shall bear no liability to you in this respect. </p>
                </div>
                <div className='my-3'>
                    <h4>14.&nbsp;&nbsp;Web 2.0 and 3.0 Account</h4><br />
                    <p>At present we provide for two ways for users to create an account on BC:<br />
                        (i) using a crypto wallet (e.g. metamask or trust wallet); and/order<br />
                        (ii) using a verified email address.</p>
                    <p>We may in future and from time to time require that additional account details be provided (including, without limitation, for AML and KYC purposes) and you agree
                        to provide such details as may be required in a timely manner in accordance with relevant regulatory and legal requirements.</p>
                    <p>Failure to do so may result in your account being suspended and/or terminated and potentially in the forfeiture of tokens held in your account.</p>
                </div>
                <div className='my-3'>
                    <h4>15.&nbsp;&nbsp;Action</h4><br />
                    <p>Beach Action is a social platform where you are able to record (via a social media post mechanism) various real world activities you undertake. </p>
                    <p>These activities may be rewarded by us with an amount of $BEACH and/or a points system which is informed by a mechanism that determines the applicable $BEACH and/or points reward. </p>
                    <p>We reserve the right to adjust, suspend or terminate the reward mechanism at any point in time.</p>
                    <p>We may also exclude and potentially terminate user accounts which are used to improperly earn rewards (including without limitation via the fraudulent logging of activities or the use of software/bots to log actions).</p>
                    <p>You agree that a range of activities you undertake on BC may appear publicly on Action.</p>
                    <p>You agree not to use Action other than as it is intended to be used and you agree that wrongful/improper use of the platform may result in your account being suspended and/or terminated and potentially in the forfeiture of tokens held in your account.</p>
                </div>
                <div className='my-3'>
                    <h4>16.&nbsp;&nbsp;Pay</h4><br />
                    <p>Beach Pay is a payments platform that facilitates the making of payments between users in a way that funds ocean conservation and climate action (via a 1.5% platform fee), is cheaper than traditional card payments while also having a lower energy footprint.</p>
                    <p>You agree not to use our Services (including without limitation Beach Pay) to facilitate any criminal or unlawful enterprise including without limitation, money laundering or tax evasion and you agree to comply with all applicable laws with respect to the making and receipt of payments.</p>
                    <p>You agree that payments once made are irretrievable by BCF and that we shall bear no responsibility in the event you make a payment sending $BEACH (or other tokens) to the wrong person and/or where you send the wrong amount.</p>
                    <p>You acknowledge that any transactions between you and another party are between you and them only and we are not a party to such transactions nor do we have any practical ability to intervene in any such payments, provided that you agree not to use Pay other than as it is intended to be used and you agree that wrongful/improper use of the platform may result in your account being suspended and/or terminated and potentially in the forfeiture of tokens held in your account.</p>

                </div>
                <div className='my-3'>
                    <h4>17.&nbsp;&nbsp;Shop</h4><br />
                    <p>Beach Shop is an online marketplace that facilitates trade between online retailers and users in a way that funds ocean conservation and climate action (via a 3% platform fee), is cheaper than traditional online marketplaces while also having a lower energy footprint.</p>
                    <p>You agree not to use our Services (including without limitation Beach Shop) to facilitate any criminal or unlawful enterprise including without limitation, money laundering or tax evasion and you agree to comply with all applicable laws with respect to the making and receipt of payments.</p>
                    <p>You agree that payments once made are irretrievable by BCF and that we shall bear no responsibility in the event you make a payment sending $BEACH (or other tokens) to the wrong person and/or where you send the wrong amount.</p>
                    <p>You agree that your account details (eg username, email and location) may be shared with the any vendor from whom you purchase goods/services on Beach Shop.</p>
                    <p>You acknowledge that any Beach Shop transactions between you and another party are between you and them only and we are not a party to such transactions nor do we have any practical ability to intervene in any such payments, provided that you agree not to use Beach Shop other than as it is intended to be used and you agree that wrongful/improper use of the platform may result in your account being suspended and/or terminated and potentially in the forfeiture of tokens held in your account.</p>
                    <p>BCF shall have no responsibility nor liability in relation to any issues relating to Beach Shop transactions (eg returns, defects, delivery issues). </p>
                </div>
                <div className='my-3'>
                    <h4>18.&nbsp;&nbsp;CSR</h4><br />
                    <p>CSR is an initiative whereby BC provides CSR packages which brands (and individuals) may purchase from BC (in exchange for a platform fee of 1%).
                        While each CSR package shall contain a description of the CSR initiative or activity being undertaken, you acknowledge that it may be necessary for
                        us to change, delay, substitute or otherwise cancel a CSR activity and that we shall have the discretion to take such steps.  In the event we cancel
                        an activity (instead of changing, delaying or substituting it) we shall refund you the full amount of the payment.
                    </p>
                </div>
                <div className='my-3'>
                    <h4>19.&nbsp;&nbsp;Carbon</h4><br />
                    <p>Carbon is an initiative whereby BC facilitates the development and trading of carbon products which brands (and individuals) may purchase from BC
                        (in exchange for a platform fee of 5% on primary market sales and 3% on secondary market trades).</p>
                    <p>While each Carbon package shall contain a description of the Carbon product being offered, you acknowledge that it may be necessary for us to
                        change, delay, substitute or otherwise cancel that particular carbon product and that we shall have the discretion to take such steps.  In the
                        event we cancel the issue of such carbon product (instead of changing, delaying or substituting it) we shall refund you the full amount of the payment.</p>
                </div>
                <div className='my-3'>
                    <h4>20.&nbsp;&nbsp;Ocean and Carbon Funds</h4><br />
                    <p>The Carbon fund and the Ocean fund are where funds earmarked for each of these causes are held. </p>
                    <p>We may use these to fund both not-for-profit and commercial activities as we may reasonably determine.</p>
                </div>
                <div className='my-3'>
                    <h4>21.&nbsp;&nbsp;Information</h4><br />
                </div>
                <div className='my-3'>
                    <h4>22.&nbsp;&nbsp;Your data</h4><br />
                    <p>We want you to be in control of how your data is and isn’t used. </p>
                    <p>
                        <strong>Data that is shared - </strong> You acknowledge that certain personal data of yours will inevitably be shared with third parties through the ordinary operation of
                        the platform and the Services. For example, if you create a public post that tags your location, includes photos and other people then third parties will obviously be able to see this information.
                    </p>
                    <p>
                        <strong>Our commitment -  </strong>
                        We will never intentionally scrape or harvest and sell or otherwise share any personal data that expressly identifies you for financial gain provided that the ordinary operation of the platform,
                        including as contemplated in this Article [x] and the Services do not fall into this.
                    </p>
                    <p><strong>Our intention on the use of Data</strong></p>
                    <p>
                        You agree that (1) we may gather, aggregate and utilise entirely anonymised personal data provided it is used solely for purposes which are entirely consistent with the core values and
                        principles of BC including for example, the raising of funds for the conservation and preservation of our natural environment, the protection and promotion of biodiversity, the sustainable
                        and equitable distribution and use of resources, access to education, health and happiness for all and (2) you may be a targeted recipient of business related advertising.
                    </p>
                    <p>
                        <strong>Your location - </strong>
                        if you post an activity or tag your location in a tag then you understand and agree that this information will be revealed in relation to that post, message or action. This may mean that others on the platform are able to see this information. If you do not wish this to occur then do not use the location function or the action module.
                    </p>

                </div>
                <div className='my-3'>
                    <h4>23.&nbsp;&nbsp;Not Used</h4><br />
                </div>
                <div className='my-3'>
                    <h4>24.&nbsp;&nbsp;Data charges and mobile phones</h4><br />
                    <p>
                        You are responsible for any mobile charges that you may incur for using our Services, including text-messaging, roaming and data charges. If you’re unsure what those charges may be, you should ask your service provider before using the Services.
                    </p>
                    <p>
                        If in future you change or deactivate any mobile phone number that you have added to your BC account, you must update your account information through Settings within 72 hours to prevent us from sending to someone else messages intended for you.
                    </p>
                </div>
                <div className='my-3'>
                    <h4>25.&nbsp;&nbsp;Third-party services</h4><br />
                    <p>
                        If you use a service, feature or functionality that is operated by a third party and made available through our Services (including Services we offer jointly with the third party), each party’s terms will govern the respective party’s relationship
                        with you. Neither BCF nor any of its affiliates, employees or agents is responsible or liable for a third party’s terms or actions taken under the third party’s terms or such other service, feature or functionality.
                    </p>
                </div>
                <div className='my-3'>
                    <h4>26.&nbsp;&nbsp;Modifying the Services and termination</h4><br />
                    <p>We’re relentlessly improving our Services and developing new services. This means that we may add or remove features, products, or functionalities, and we may also suspend or stop the Services altogether. We may take any of these actions at any
                        time, and when we do, we will try to notify you beforehand - but this won’t always be possible. In any event we will have no liability to you in this regard.</p>
                    <p>You may terminate these Terms at any time and for any reason by deleting your account.</p>
                    <p>BCF may also terminate these Terms with you at any time for any or no reason including without limitation if you fail to comply with these Terms, our policies, values, any guidelines we may implement from time to time or the law, or for any reason
                        outside of our control. And while we’ll try to give you advance notice, we can’t guarantee it. Our right to terminate these Terms means that we may stop providing you with any Services, or impose new or additional limits on your ability to use the
                        Services. For example, we may deactivate your account due to prolonged inactivity, and we may reclaim your username at any time for any reason.</p>
                    <p>Regardless of who terminates these Terms, both you and BCF continue to be bound by Sections 3, 6, 9, 10 and 13-25 of the Terms.</p>
                </div>
                <div className='my-3'>
                    <h4>27.&nbsp;&nbsp;Indemnity</h4><br />
                    <p>You agree, to the extent permitted by law, to indemnify, defend and hold harmless BCF and our affiliates, directors, officers, stockholders, employees, licensors, suppliers, and agents from and against any complaints, charges, claims, damages,
                        losses, costs, liabilities and expenses (including attorneys’ fees) due to, arising out of, or relating in any way to: (a) your access to or use of the Services; (b) your content; and (c) your breach of these Terms or acting or failing to act other than in accordance with these Terms.</p>
                </div>
                <div className='my-3'>
                    <h4>28.&nbsp;&nbsp;Disclaimers</h4><br />
                    <p>
                        We try to keep the Services up and running and free of issues. But we cannot promise that we will always succeed.
                    </p>
                    <p>
                        <strong>
                            The Services are provided “as is” and “as available” and to the extent permitted by law without warranties of any kind, either express or implied, including, in particular implied warranties, conditions, or other terms relating to (i) merchantability, satisfactory quality,
                            fitness for a particular purpose, title, quiet enjoyment, non-infringement, or (ii) arising from a course of dealing. In addition, while BCF attempts to provide a good user experience, we do not represent or warrant that: (a) the Services will always be secure, error-free
                            or timely; (b) the Services will always function without delays, disruption or imperfections; or (c) that any content or information you obtain through the Services will be timely or accurate.
                        </strong>
                    </p>
                    <p>
                        <strong>
                            IF THE LAW OF THE COUNTRY WHERE YOU LIVE DOES NOT ALLOW THE EXCLUSIONS OF LIABILITY PROVIDED FOR IN THIS CLAUSE, THOSE EXCLUSIONS SHALL NOT APPLY.
                        </strong>
                    </p>
                    <p>
                        <strong>
                            BCF and its affiliates take no responsibility and assume no liability for any content that you, another user, or a third party creates, uploads, posts, sends, receives, or stores on or through our Services. You understand and agree that you may be exposed to content that
                            might be offensive, illegal, misleading, or otherwise inappropriate, none of which BCF nor its affiliates will be responsible for.
                        </strong>
                    </p>
                    <p>
                        <strong>
                            Nothing in these Terms will exclude or limit any responsibility we may have to remove content if so required by the law of the country where you live.
                        </strong>
                    </p>

                </div>
                <div className='my-3'>
                    <h4>29.&nbsp;&nbsp;Limitation of liability</h4><br />
                    <p>
                        <strong>
                            BCF and its affiliates, directors, officers, stockholders, employees, licensors, suppliers, and agents will not be liable for any indirect, incidental, special, consequential, punitive, or multiple damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill or other intangible losses, resulting from: (a) your use of the Services or inability to use the Services; (b) your access to or inability to access the Services; (c) the conduct or content of other users or third parties on or through the Services; or (d) unauthorised access, use or alteration of your content. In no event will BCF or its affiliates’ aggregate liability for all claims relating to the Services exceed €100 EUR.
                        </strong>
                    </p>
                    <p>
                        <strong>IF THE LAW OF THE COUNTRY WHERE YOU LIVE DOES NOT ALLOW ANY LIMITATION OF LIABILITY PROVIDED FOR IN THIS CLAUSE, THAT LIMITATION WILL NOT APPLY.</strong>
                    </p>
                </div>
                <div className='my-3'>
                    <h4>30.&nbsp;&nbsp;Dispute resolution, arbitration</h4><br />
                    <p>
                        If you have a concern, let’s talk. Go ahead and contact us first and we’ll do our best to resolve the issue.
                    </p>
                    <p>
                        Some of our Services may have additional terms that contain dispute-resolution provisions unique to that Service or your residency.
                    </p>
                    <p>
                        <strong>
                            If you are using the Services on behalf of a business (rather than for your personal use), you and BCF agree that to the extent permitted
                            by law, all claims and disputes between us arising out of or relating to these Terms or the use of the Services will be finally settled under
                            the LCIA Arbitration Rules, which are incorporated by reference into this clause. There will be one arbitrator (to be appointed by the LCIA),
                            the arbitration will take place in London, and the arbitration will be conducted in English. If you do not wish to agree to this clause, you must not use the Services.
                        </strong>
                    </p>
                </div>
                <div className='my-3'>
                    <h4>31.&nbsp;&nbsp;Exclusive venue</h4><br />
                    <p>
                        To the extent the parties are permitted under these Terms to initiate litigation in a court,
                        both you and BCF agree that all claims and disputes (whether contractual or otherwise) arising
                        out of or relating to the Terms or the use of the Services will be litigated exclusively in
                        the courts of England in the United Kingdom, unless this is prohibited by the laws of the country
                        where you reside. You and BCF consent to the exclusive jurisdiction of those courts.
                    </p>
                </div>
                <div className='my-3'>
                    <h4>32.&nbsp;&nbsp;Choice of law</h4><br />
                    <p>
                        The laws of England and Wales govern these Terms and any claims and disputes (whether contractual or otherwise)
                        arising out of or relating to these Terms or their subject matter. The courts in some countries may not apply
                        the laws of England and Wales to some disputes related to these Terms. If you reside in one of those countries
                        , the laws of your home country may apply to those disputes.
                    </p>
                </div>
                <div className='my-3'>
                    <h4>33.&nbsp;&nbsp;Severability</h4><br />
                    <p>
                        If any provision of these Terms is found unenforceable, then that provision will be severed from these
                        Terms and not affect the validity and enforceability of any remaining provisions.
                    </p>
                </div>
                <div className='my-3'>
                    <h4>34.&nbsp;&nbsp;Additional terms for specific Services</h4><br />
                    <p>
                        Given the breadth of our Services, we sometimes need to develop additional terms and
                        conditions for specific Services. Those additional terms and conditions, which we will
                        try to present to you before you access the relevant Services, then become part of your
                        agreement with us when you accept them. If any part of those additional terms and
                        conditions conflicts with these Terms, the additional terms and conditions will prevail.
                    </p>
                </div>
                <div className='my-3'>
                    <h4>35.&nbsp;&nbsp;Final terms</h4><br />
                    <ul className='terms-list'>
                        <li>These Terms make up the entire agreement between you and BCF and supersede any prior agreements.</li>
                        <li>These Terms do not create or confer any third-party beneficiary rights.</li>
                        <li>If we do not enforce a provision in these Terms, it will not be considered a waiver.</li>
                        <li>We reserve all rights not expressly granted to you.</li>
                        <li>You may not transfer any of your rights or obligations under these Terms without our express prior written consent.</li>

                    </ul>
                </div>
                <div className='my-3'>
                    <h4>Contact us</h4><br />
                    <p>BCF welcomes comments, questions, concerns, or suggestions. You can contact us or get support at <a className='text-dark' href='mailto:hello@beachcollective.io'>hello@beachcollective.io</a> </p>
                </div>
            </div>
            <section className="footer-shape">
            <img src="/images/footer-top-shape.png" alt=""/>
            </section>
            <footer>
            <div className="container-fluid">
              <div className="footer-logo mb-3 text-center"><img src="/images/footer-logo.png" width={350} alt=""/></div>
              <div className="row flex-column-reverse flex-md-row">
                <div className="col-md-4 d-none d-md-block">
                  <div className="footer-nav">
                    <Link to="/action" >Action</Link>
                    <Link to="/pay">Pay</Link>
                    <a   data-bs-toggle="modal" href="#beach-shop">Shop</a>
                    <a   data-bs-toggle="modal" href="#beach-nomad">Nomad</a>
                    <a   data-bs-toggle="modal" href="#beach-farm">Farm</a>
                    <a   data-bs-toggle="modal" href="#beach-nft">NFT</a> 
                  
          </div>
                </div>
                <div className="col-md-8">
                  <div className="row justify-content-end">
                    <div className="col-md-4 col-lg-3">
                      <div className="widget widget_links text-center text-md-start clearfix my-4 my-md-0 my-xl-0">
                        <h4>CONTACT US</h4>
                        <a href="mailto:hello@beachtoken.io" target="_blank" className="emailus" rel="noreferrer">hello@beachtoken.io</a>
                      </div>
                      <div className="foot-signup mt-3 d-md-none ">
                          <h3>Sign up for updates</h3>
                          <form onSubmit={handleUpdateSubmit}>
                          <div className='signup_Input'>
                          <input type="text" value={email} onChange={(e)=>setEmail(e.target.value)} name="email" placeholder="Type email address here..."/>
                          <button type={'submit'} className="signup_Btn">SIGN UP</button>
                          </div>
                          </form>
                          
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 mt-3 mt-md-0">
    
                      <div className="d-flex justify-content-between d-md-block align-items-center">
                      <div className="footer-nav d-md-none">
                      <Link to="/action" >Action</Link>
                      <Link to="/pay" >Pay</Link>
                      <a   data-bs-toggle="modal" href="#beach-shop">Shop</a>
                      <a   data-bs-toggle="modal" href="#beach-nomad">Nomad</a>
                      <a   data-bs-toggle="modal" href="#beach-farm">Farm</a>
                      <a   data-bs-toggle="modal" href="#beach-nft">NFT</a> 
                    
            </div>
                      <div className="widget widget_links clearfix">
                        <ul>
                          <li><Link to="/">Home</Link></li>
                          <li><a href="https://beachtoken.io/static/images/%24BEACH_White_Paper_May_2022.pdf" target="_blank" rel="noreferrer">White Paper</a></li>
                          <li><a href="https://pancakeswap.finance/swap?outputCurrency=0x4f4543Fa5F7FE28a7758ef43FeA71d11fCCDAD5c&inputCurrency=BNB" target="_blank" rel="noreferrer">Buy on Pancake Swap</a></li>
              {/*<li><a href="https://app.uniswap.org/#/swap?outputCurrency=0xbd15c4c8cd28a08e43846e3155c01a1f648d8d42&use=V2" target="_blank">Buy on Uniswap</a></li>*/}
                          <li><Link to="/">Blog</Link></li>
                          <li><a href="https://beachcollective.io/" target="_blank" rel="noreferrer">Beach Collective</a></li>
                          <li><Link to="/risk-disclosure" target="_blank" rel="noreferrer">Risk Disclosure</Link></li>
                        </ul>
                      </div>
    
                      </div>
    
                    </div>
                  </div>
    
                  <div className="row justify-content-end">
                    <div className="col-md-7 col-xl-6 d-none d-md-block">
                        <div className="foot-signup py-4">
                          <h3>Sign up for updates</h3>
                          <form onSubmit={handleUpdateSubmit}>
                            <div style={{position:'relative',background:'#fff',borderRadius:'5px'}}>
                            <input type="text" value={email} onChange={(e)=>setEmail(e.target.value)} style={{width:'80%'}} name="email"/>
                            <button type={'submit'} className='btn btn-outline-blue' style={{position:'absolute',right:'10px',top:'50%',transform:'translateY(-50%)'}} >SIGN UP</button>
                            </div>
                          </form>
                        </div>
                    </div>
                    <div className="col-md-5 col-lg-4 col-xl-3 mt-3 mt-md-0">
                        <div className="widget clearfix">
                          <div className="footer-social  mt-3">
                            <div className="row m-0">
                              <div className="col-3 mb-2 responsive-0 d-flex justify-content-center align-items-center"> 
                                  <a class="responsive-icon" href="https://t.me/BeachToken" target="_blank" rel="noreferrer">
                                    <img className="img-fluid" src="/images/ic-telegram.png" alt=""/></a>
                              </div>
                              <div className="col-3 mb-2 responsive-0 d-flex justify-content-center align-items-center">
                                <a className="responsive-icon" href="https://twitter.com/beach_token" target="_blank" rel="noreferrer">
                                  <img className="img-fluid" src="/images/ic-twitter2.png" alt=""/></a>
                              </div>
                              <div className="col-3 responsive-0  mb-2 d-flex justify-content-center align-items-center"> 
                                <a className="responsive-icon " href="https://m.facebook.com/beachtoken" target="_blank" rel="noreferrer"><img class="img-fluid" src="/images/ic-fb-f.png" alt=""/></a>
                              </div>
                              <div className="col-3 responsive-0 mb-2  d-flex justify-content-center align-items-center"> 
                                    <a className="responsive-icon" href="https://discord.gg/beachtoken" target="_blank" rel="noreferrer"><img className="img-fluid" src="/images/ic-discord.png" alt=""/></a>
                              </div>
                              <div className="col-3 mb-2 responsive-0  d-flex justify-content-center align-items-center">
                                <a className="responsive-icon" href="https://www.instagram.com/beach_token/" target="_blank" rel="noreferrer">
                                  <img className="img-fluid" src="/images/ico-instagram.svg" alt=""/></a>
                              </div>
                              
                              
                              <div className="col-3 mb-2 responsive-0  d-flex justify-content-center align-items-center"> 
                                  <a className="responsive-icon" href="https://www.youtube.com/channel/UCWe9w4b1asXIAxHatN_FFhw" target="_blank" rel="noreferrer"><img className="img-fluid" src="/images/ic-YouTube2.png" alt=""/></a>
                              </div>
                              <div className="col-3 mb-2 responsive-0  d-flex justify-content-center align-items-center"> 
                                <a className="responsive-icon" href="https://www.linkedin.com/company/80447914" target="_blank" rel="noreferrer"><img className="img-fluid" src="/images/ic-linkedin2.png" alt=""/></a>
                              </div>
                              <div className="col-3 mb-2 responsive-0  d-flex justify-content-center align-items-center"> 
                                <a className="responsive-icon" href="https://www.tiktok.com/@beach_token?lang=en" target="_blank" rel="noreferrer"><img className="img-fluid" height="30" src="/images/ic-TikTok.png" alt=""/></a>
                            </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
    
                </div>
              </div>
            </div>
          </footer>
        </Base>
    )
}

export default Terms