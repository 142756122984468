import axios from "axios";



axios.defaults.baseURL = 'https://api.beachcollective.io'

axios.defaults.validateStatus = (status) => {

  return status < 500;
};

const axiosInstance = axios.create({
    baseURL : 'https://api.beachcollective.io',
    validateStatus: function (status)
    {
        return status < 500;
    }
})


    // Add a request interceptor
    axiosInstance.interceptors.request.use(async function (config) {
        if(config.method === 'GET') { 
          config.headers = { 
            'Accept': 'application/json',
          }
        }
        return config;
      }, function (error) {
        
        return Promise.reject(error);
      });

    axiosInstance.interceptors.response.use(async function (response){
      const originalRequest = response.config;  
      if(response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        const access_token = await axios.get(`/token/refresh/`,
        {
          withCredentials:true
        }
        );       

        if(access_token.status === 200){
          
        axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + access_token.data['access'];
        originalRequest.headers.Authorization = 'Bearer ' + access_token.data['access'];

        return axiosInstance(originalRequest);
        }else{
          window.localStorage.clear()
          window.location.href = '/'
        }
      }


        return response 

    }, async function (error) {
       
        return Promise.reject(error);
      });

export default axiosInstance;