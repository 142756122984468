import React, { useState, useEffect, useContext, useRef } from "react";
import UpComing from "./UpComing";
// import ProfileModel from "./ProfileModel";
import Loader from "../Loader";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { MdContentCopy } from "react-icons/md";
import { FaEthereum } from "react-icons/fa";
import { AiOutlineTeam } from "react-icons/ai";
import { BiLogOut, BiWallet, BiInfoCircle, BiLogIn } from "react-icons/bi";
import { disconnectWallet } from "../../Web3Client";
import SignupModal from "./SignupModal";
import LoginModal from "./LoginModal";


import GlobalContext from "../../Contexts/GlobalContext";
import Model from "../ConnectWallet/Model";
import { isMetamask } from "../../connectors";
import ReferencePopup from "../ReferencePopup";
import HomePopup from "../WelcomePopups/HomePopup";
import useLocalStorage from "../../hooks/useLocalStroage";
import WelcomePopup from '../WelcomePopups/WelcomePopup'
import { useWeb3React } from '@web3-react/core'
import axiosInstance from "../../Axios/axios.config";
import axios from "axios";

import { HiOutlineArrowDown } from 'react-icons/hi'
import Marquee from "react-fast-marquee";
import {
  Popover,
  OverlayTrigger
} from "react-bootstrap";


const BeachClean = (
  <Popover id="popover-positioned-scrolling-top" title="Beach Cleanup">
    <div className="p-1">
      <h6 className="border-bottom pb-1 mb-1">Beach Cleanup</h6>
      <p> We've funded hundreds of beach cleans in over 25 countries, working with grassroots climate groups and refugees.</p>
    </div>
  </Popover>
);
const SocialMedia = (
  <Popover id="popover-positioned-scrolling-top" title="Social Platform">
    <div className="p-1">
      <h6 className="border-bottom pb-1 mb-1">Social Platform</h6>
      <p> Beach Action allows you to track your impact while rewarding anyone, anywhere who takes action for our planet.</p>
    </div>
  </Popover>
);
const Tree = (
  <Popover id="popover-positioned-scrolling-top" title="Mangrove Restoration">
    <div className="p-1">
      <h6 className="border-bottom pb-1 mb-1">Mangrove Restoration</h6>
      <p> We are actively restoring mangroves in the Philippines, Tanzania and Kenya using a holistic community-based approach.</p>
    </div>
  </Popover>
);
const Commerce = (
  <Popover id="popover-positioned-scrolling-top" title="Sustainable Commerc">
    <div className="p-1">
      <h6 className="border-bottom pb-1 mb-1">Sustainable Commerce</h6>
      <p> Our shop features planet-friendly brands and products, funds direct climate action and charges lower fees than competitors.</p>
    </div>
  </Popover>
);
const Blue = (
  <Popover id="popover-positioned-scrolling-top" title="Regenerative Finance">
    <div className="p-1">
      <h6 className="border-bottom pb-1 mb-1">Regenerative Finance</h6>
      <p> Our currency $BEACH has been adopted in Cebu, Philippines - every transaction funds Clean Oceans and Blue Carbon initiatives.</p>
    </div>
  </Popover>
);
const Plastic = (
  <Popover id="popover-positioned-scrolling-top" title="Plastic Credits">
    <div className="p-1">
      <h6 className="border-bottom pb-1 mb-1">Plastic Credits</h6>
      <p>For $1 we will clear 1kg of plastic from the oceans, while brands and companies earn recognition and exposure</p>
    </div>
  </Popover>
);
const Recycle = (
  <Popover id="popover-positioned-scrolling-top" title="Circular Economy">
    <div className="p-1">
      <h6 className="border-bottom pb-1 mb-1">Circular Economy</h6>
      <p>We make products from waste and have built a shop out of recycled plastic in Kakuma Refugee Camp.</p>
    </div>
  </Popover>
);
const Eco = (
  <Popover id="popover-positioned-scrolling-top" title="Planet-friendly Brands">
    <div className="p-1">
      <h6 className="border-bottom pb-1 mb-1">Planet-friendly Brands</h6>
      <p> Partners benefit from enhanced reputation and visibility with our 200,000+ followers and community.</p>
    </div>
  </Popover>
);


async function getSummaryData() {
  const api = await fetch('https://api.beachcollective.io/map/beach-summary/')

  const res = await api.json()

  return res
}

export default function Home() {
  const {
    user,
    walletAddress,
    loggedIn,
    setLoggedIn,
    loginWithEmail,
    welcomePop,
    openConnector,
    setOpenConnector,
    setDefaultConnector,
    setBalance,
    setProfileModal
  } = useContext(GlobalContext);

  const { deactivate, library } = useWeb3React()

  const navigate = useNavigate()

  const [summaryData, setSummaryData] = useState({
    total_actions: 0,
    total_no_country: 0,
    west_collected: 0
  })

  const [loader, setLoader] = useState(true);


  const menuDrop = useRef(null)

  const [email, setEmail] = useState("");

  const [isOpen, setOpen] = useState(false);

  const [openedMenu, setOpenedMenu] = useState(false);

  const [loginPopup, setLoginPopup] = useState(false);

  const [sharingLink, setSharingLink] = useState(false);


  const [embrOpen, setEmbrOpen] = useState(false)


  const [homePop, setHomePop] = useLocalStorage('homePop', !welcomePop);


  const connect = async () => {
    if (isMetamask) {
      console.log(typeof isMetamask);
      console.log("insde connectwallet.metamask");
      setDefaultConnector(true);
    } else {
      setOpenConnector(!openConnector);
    }
  };




  useEffect(() => {

    if (!document.getElementById("embr-script")) {
      let a = document.createElement("script");
      a.id = "embr-script"
      a.type = "text/javascript"
      a.async = !0
      a.src = "https://cdn.embr.org/embeddables.es.js"
      document.head.append(a)
    }
    if (!window.EMBR) { const t = []; window.EMBR = { _init: t, mount: e => t.push(e) } }
    window.EMBR.mount({
      type: 'CheckoutEmbed',
      options: {
        checkoutId: '01GBFE2W5S14FT8YAYAX4G57YJ',
        selector: '#embr-widget'
      }
    })
  }, [])


  useEffect(() => {

    getSummaryData().then(data => {
      setSummaryData(data)
    })
    setLoader(false);
    // eslint-disable-next-line
  }, []);

  const copytexturl = () => {
    setTimeout(async () => {
      await navigator.clipboard.writeText(walletAddress);
      alert("copied to clipboard");
    }, 2000);
  };


  const handelLogout = async () => {

    if (loggedIn) {

      let logout = await axiosInstance.post(`/user/user-logout/`, {}, {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true
      });

      if (logout.statusText === 'OK') {
        setLoggedIn(false);
        disconnectWallet(deactivate, library, user.name);
        toast.success("Successfully logout", { containerId: 'toast' });
        navigate('/')
      } else {
        toast.warn("Something went wrong", { containerId: 'toast' });
      }
    }
  };

  const updateBalance = async () => {
    if (loggedIn) {
      setProfileModal(true)

      let updatedDetails = await axiosInstance.get(`/user/wallet-details/`);

      let details = updatedDetails.data

      if (updatedDetails.statusText === 'OK') {

        setBalance(details.data.total_balance_usd)
      }

    }
  }

  const removeOverLay = () => {
    if (openedMenu) {
      setOpenedMenu(false)
    }
  }

  const handleMenu = () => {
    if (menuDrop.current.classList.contains('show')) {
      menuDrop.current.classList.remove('show')
    }
    setOpenedMenu(!openedMenu)
  }

  const handleUpdateSubmit = async (e) => {
    e.preventDefault()

    let payload = JSON.stringify({ 'email': email })

    let updatedDetails = await axios.post(`/user/update-sign-up-user/`, payload,
      {
        headers: {
          "Content-Type": "application/json",
        }
      }
    );
    if (updatedDetails.statusText === 'OK') {
      setEmail('')
    }
  }

  const handleImportToken = async () => {
    const tokenAddress = '0x4f4543Fa5F7FE28a7758ef43FeA71d11fCCDAD5c';
    const tokenSymbol = 'BEACH';
    const tokenDecimals = 9;
    const tokenImage = 'https://beachtoken.io/images/default-icon.webp';

    if (window.ethereum) {
      try {
        // wasAdded is a boolean. Like any RPC method, an error may be thrown.
        const wasAdded = await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20', // Initially only supports ERC20, but eventually more!
            options: {
              address: tokenAddress, // The address that the token is at.
              symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
              decimals: tokenDecimals, // The number of decimals in the token
              image: tokenImage, // A string url of the token logo
            },
          },
        });

        if (wasAdded) {
          console.log('Thanks for your interest!');
        } else {
          console.log('Your loss!');
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      window.location.replace('https://metamask.app.link/dapp/beachcollective.io/')
    }

  }

  return loader ? (
    <Loader />
  ) : (
    <React.Fragment>
      <Model
        show={openConnector}
        hide={setOpenConnector}
        link={!walletAddress && loggedIn}
      />
      <button onClick={() => setOpen(true)} className="d-block w-100 notification-bar">
        <small>Sign up now and we will clear 1kg of trash from the oceans!</small>
      </button>

      <nav className="navbar navbar-expand-lg bg-light primary-menu">
        <div className="container-fluid">
          <Link className="navbar-brand d-lg-none" to="/"><img src={`${process.env.PUBLIC_URL}/images/bc-logo.svg`} height={60} alt="" /></Link>

          <div className='d-flex d-lg-none align-items-end justify-content-center'>

            <Link className='userNameLink me-2 mb-1' to={'/account'}>{user.name}</Link>
            <div className="dropdown">

              {!user.image ?
                <span onClick={handleMenu} id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {
                    openedMenu ? <img src={`${process.env.PUBLIC_URL}/images/user_unselect.svg`} alt='user' width={30} />
                      :
                      <img src={`${process.env.PUBLIC_URL}/images/user_select.svg`} alt='user' width={30} />
                  }

                </span>
                :
                <span onClick={handleMenu} id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">

                  <img className="rounded-circle" src={user.image} alt='user' width={30} height={30} />

                </span>

              }


              <ul
                className="dropdown-menu p-0"
                id="dropdownMenuButtonHome"
                aria-labelledby="dropdownMenuButtonHome"
                onClick={() => setOpenedMenu(false)}
              >
                {(walletAddress || loginWithEmail) && (
                  <li className="dropdown-item border-bottom">
                    <div className="user-img d-flex justify-content-between align-items-center">
                      <span className=" me-2">
                        {user.name ? user.name : ""}
                      </span>
                      <img
                        src={
                          user.image
                            ?
                            user.image
                            : "https://api.beachcollective.io/media/default-user.png"
                        }
                        id="user_image-1"
                        style={{ height: "40px", width: "40px" }}
                        className="rounded-circle ml-3"
                        onClick={updateBalance}
                        alt="img"
                      />
                    </div>
                  </li>
                )}


                {loggedIn && (
                  <>
                    <li
                      className="dropdown-item"
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                      style={{ cursor: "pointer" }}
                    >
                      <BiWallet />
                      <Link to={'/account'} className="ms-2 userLink">My Account</Link>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => setSharingLink(!sharingLink)}
                      style={{ cursor: "pointer" }}
                    >
                      <AiOutlineTeam />
                      <span className=" ms-2">Generate Referral</span>
                    </li>
                  </>
                )}

                {!loggedIn && (
                  <li
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={() => setLoginPopup(!loginPopup)}
                  >
                    <BiLogIn />
                    <span className="ms-2">Sign in</span>
                  </li>
                )}

                {(loginWithEmail || !walletAddress) && (
                  <li
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={connect}
                  >
                    {/* eslint-disable-next-line */}
                    <span className="text-dark">
                      <BiWallet />
                      <span className=" ms-2">Connect wallet</span>
                    </span>
                  </li>
                )}
                {walletAddress && !loginWithEmail && (
                  <>
                    <li
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                    >
                      {/* eslint-disable-next-line */}
                      <a href="#" className="text-dark">
                        <FaEthereum />
                        <span className=" ms-2">View</span>
                      </a>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={copytexturl}
                      style={{ cursor: "pointer" }}
                    >
                      <MdContentCopy />
                      <span className=" ms-2">Copy</span>
                    </li>
                  </>
                )}

                {loggedIn && (
                  <li
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={handelLogout}
                  >
                    <BiLogOut />
                    <span className="ms-2">Sign out</span>
                  </li>
                )}

                <li
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  onClick={() => setHomePop(true)}
                >
                  <BiInfoCircle />
                  <span className="ms-2">Help</span>
                </li>
              </ul>
            </div>
            <button className="navbar-toggler" onClick={() => setOpenedMenu(false)} type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon">
                <img src={`${process.env.PUBLIC_URL}/images/menuBar.svg`} className="img-fluid" alt="bar" />
              </span>
            </button>
          </div>
          <div className="collapse navbar-collapse" ref={menuDrop} id="navbarTogglerDemo02">
            <ul className="navbar-nav me-auto mb-lg-2 mb-lg-0">
              <li className="nav-item">
                <Link className="nav-link" to="/">HOME</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about">ABOUT</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/action">ACTION</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/pay">PAY</Link>
              </li>
              {/*<li className="nav-item">
                <Link className="nav-link" to="/shop">SHOP</Link>
                </li>*/}
              <li className="nav-item">
                <a className="nav-link" target='_blank' href="https://token.beachcollective.io">TOKEN</a>
              </li>
            </ul>
            <ul className="navbar-nav ms-auto mb-lg-2 mb-lg-0">
              {!user.email && <li class="nav-item" onClick={() => setOpen(true)}>
                <span className="nav-link d-lg-none" >
                  JOIN US
                </span>
                <button className="btn blue-btn d-none d-lg-inline me-3 text-white">JOIN US</button>
              </li>}
              <li className='nav-item d-inline-flex align-items-center'>
                <Link className='userNameLink me-2 ' to={'/account'}>{user.name}</Link>
              </li>
              <li className="nav-item dropdown  d-none d-lg-inline ">
                {!user.image ?
                  <span onClick={() => setOpenedMenu(!openedMenu)} id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {
                      openedMenu ? <img src={`${process.env.PUBLIC_URL}/images/user_select.svg`} alt='user' width={30} />
                        :
                        <img src={`${process.env.PUBLIC_URL}/images/user_unselect.svg`} alt='user' width={30} />
                    }

                  </span>
                  :
                  <span onClick={() => setOpenedMenu(!openedMenu)} id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">

                    <img className="rounded-circle" src={user.image} alt='user' width={30} height={30} />

                  </span>

                }
                <ul
                  className="dropdown-menu p-0"
                  id="dropdownMenuButtonHome"
                  aria-labelledby="dropdownMenuButtonHome"
                  onClick={() => setOpenedMenu(false)}
                >
                  {(walletAddress || loginWithEmail) && (
                    <li className="dropdown-item border-bottom">
                      <div className="user-img d-flex justify-content-between align-items-center">
                        <span className=" me-2">
                          {user.name ? user.name : ""}
                        </span>
                        <img
                          src={
                            user.image
                              ?
                              user.image
                              : "https://api.beachcollective.io/media/default-user.png"
                          }
                          id="user_image-1"
                          data-bs-toggle="modal"
                          data-bs-target="#staticBackdrop"
                          style={{ height: "40px", width: "40px" }}
                          className="rounded-circle ml-3"
                          onClick={updateBalance}
                          alt="img"
                        />
                      </div>
                    </li>
                  )}


                  {loggedIn && (
                    <>
                      <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                        style={{ cursor: "pointer" }}
                      >
                        <BiWallet />
                        <Link to={'/account'} className="ms-2 userLink">My Account</Link>
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => setSharingLink(!sharingLink)}
                        style={{ cursor: "pointer" }}
                      >
                        <AiOutlineTeam />
                        <span className=" ms-2">Generate Referral</span>
                      </li>
                    </>
                  )}
                  {!loggedIn && (
                    <li
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => setLoginPopup(!loginPopup)}
                    >
                      <BiLogIn />
                      <span className="ms-2">Sign in</span>
                    </li>
                  )}

                  {(loginWithEmail || !walletAddress) && (
                    <li
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={connect}
                    >
                      {/* eslint-disable-next-line */}
                      <span className="text-dark">
                        <BiWallet />
                        <span className=" ms-2">Connect wallet</span>
                      </span>
                    </li>
                  )}


                  {walletAddress && !loginWithEmail && (
                    <>
                      <li
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                      >
                        {/* eslint-disable-next-line */}
                        <a href="#" className="text-dark">
                          <FaEthereum />
                          <span className=" ms-2">View</span>
                        </a>
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={copytexturl}
                        style={{ cursor: "pointer" }}
                      >
                        <MdContentCopy />
                        <span className=" ms-2">Copy</span>
                      </li>
                    </>
                  )}

                  {loggedIn && (
                    <li
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={handelLogout}
                    >
                      <BiLogOut />
                      <span className="ms-2">Sign out</span>
                    </li>
                  )}

                  <li
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={() => setHomePop(true)}
                  >
                    <BiInfoCircle />
                    <span className="ms-2">Help</span>
                  </li>
                </ul>
              </li>

            </ul>
          </div>
        </div>
      </nav>
      <div className={`bg-white ${openedMenu ? 'overlay' : ''} `} onClick={removeOverLay} >

        <section className="hero-banner-wrapper topHeroBanner">
          {/* <img className="img-fluid w-100 d-none d-lg-block" src={`${process.env.PUBLIC_URL}/images/home-hero-banner.webp`} alt="banner" />
                  <img className="img-fluid w-100 d-lg-none" src={`${process.env.PUBLIC_URL}/images/home-hero-xs-banner.webp`} alt="banner" /> */}
          <div className="hero-banner-content">
            <h1 className="font-regular mt-3 mt-md-0 mb-5 pb-md-4 text-light">We believe you have the power to make a difference. Do you?</h1>

            {!loggedIn && <button className="btn btn-light rounded-pill p-2 fs-4 px-3  d-inline-flex gap-2 align-items-center" onClick={() => setOpen(true)}>
              <span>Get Started</span>
              <img src={`${process.env.PUBLIC_URL}/images/bc-logo.svg`} width={30} />
            </button>}
            {loggedIn && <Link to={'/action'} className="btn btn-light rounded-pill p-2 fs-4 px-3 noUnderline  d-inline-flex gap-2 align-items-center">
              <span>Take Action</span>
              <img src={`${process.env.PUBLIC_URL}/images/bc-logo.svg`} width={30} />
            </Link>}
          </div>
        </section>

        <section className="py-md-5 my-5  position-relative">
          <div className="container">
            <div className="hide-scrollbar overflow-hidden d-none d-xl-block">
              <div className="row g-2 g-md-3 flex-nowrap text-center">
                <div className="col-4 mb-3 mb-lg-0 col-lg-3 col-xl-2">
                  <div className="card process-sec ">
                    <div className="card-body">
                      <div className="icon mb-2 mb-md-3"><img src={`${process.env.PUBLIC_URL}/images/Cleaning.svg`} alt="" /></div>
                      <h4 className="text-white">{Math.floor(summaryData.west_collected)}kg</h4>
                      <p className="mb-2 text-white">of waste collected</p>
                    </div>
                  </div>
                </div>
                <div className="col-4 mb-3 mb-lg-0 col-lg-3 col-xl-2">
                  <div className="card process-sec ">
                    <div className="card-body">
                      <div className="icon mb-2 mb-md-3"><img src={`${process.env.PUBLIC_URL}/images/Conservation.svg`} alt="" /></div>
                      <h4 className="text-white">1000 +</h4>
                      <p className="mb-2 text-white">school meals</p>
                    </div>
                  </div>
                </div>
                <div className="col-4 mb-3 mb-lg-0 col-lg-3 col-xl-2">
                  <div className="card process-sec line">
                    <div className="card-body">
                      <div className="icon mb-2 mb-md-3"><img src={`${process.env.PUBLIC_URL}/images/Planting.svg`} alt="" /></div>
                      <h4 className="text-white">2500 +</h4>
                      <p className="mb-2 text-white">mangroves planted</p>
                    </div>
                  </div>
                </div>
                <div className="col-4 mb-3 mb-lg-0 col-lg-3 col-xl-2">
                  <div className="card process-sec ">
                    <div className="card-body">
                      <div className="icon mb-2 mb-md-3"><img src={`${process.env.PUBLIC_URL}/images/Kindness.svg`} alt="" /></div>
                      <h4 className="text-white">4000 +</h4>
                      <p className="mb-2 text-white">days of employment</p>
                    </div>
                  </div>
                </div>


                <div className="col-4 mb-3 mb-lg-0 col-lg-3 col-xl-2">
                  <div className="card process-sec ">
                    <div className="card-body">
                      <div className="icon mb-2 mb-md-3"><img src={`${process.env.PUBLIC_URL}/images/ic-beachAction.png`} alt="" /></div>
                      <h4 className="text-white">{summaryData.total_actions}</h4>
                      <p className="mb-2 text-white">actions taken</p>
                    </div>
                  </div>
                </div>
                <div className="col-4 mb-3 mb-lg-0 col-lg-3 col-xl-2">
                  <div className="card process-sec line">
                    <div className="card-body">
                      <div className="icon mb-2 mb-md-3"><img src={`${process.env.PUBLIC_URL}/images/globe.png`} alt="" /></div>
                      <h4 className="text-white">{summaryData.total_no_country}</h4>
                      <p className="mb-2 text-white">countries</p>
                    </div>
                  </div>
                </div>

              </div>


            </div>
            <div className="d-xl-none blue-boxes">
              <Marquee autoFill={true} >
                <div className="box-col">
                  <div className="card process-sec ">
                    <div className="card-body">
                      <div className="icon mb-2 mb-md-3"><img src={`${process.env.PUBLIC_URL}/images/Cleaning.svg`} alt="" /></div>
                      <h4 className="text-white">{Math.floor(summaryData.west_collected)}kg</h4>
                      <p className="mb-2 text-white">of waste collected</p>
                    </div>
                  </div>
                </div>
                <div className="box-col">
                  <div className="card process-sec ">
                    <div className="card-body">
                      <div className="icon mb-2 mb-md-3"><img src={`${process.env.PUBLIC_URL}/images/Conservation.svg`} alt="" /></div>
                      <h4 className="text-white">1000 +</h4>
                      <p className="mb-2 text-white">school meals</p>
                    </div>
                  </div>
                </div>
                <div className="box-col">
                  <div className="card process-sec line">
                    <div className="card-body">
                      <div className="icon mb-2 mb-md-3"><img src={`${process.env.PUBLIC_URL}/images/Planting.svg`} alt="" /></div>
                      <h4 className="text-white">2500 +</h4>
                      <p className="mb-2 text-white">mangroves planted</p>
                    </div>
                  </div>
                </div>
                <div className="box-col">
                  <div className="card process-sec ">
                    <div className="card-body">
                      <div className="icon mb-2 mb-md-3"><img src={`${process.env.PUBLIC_URL}/images/Kindness.svg`} alt="" /></div>
                      <h4 className="text-white">4000 +</h4>
                      <p className="mb-2 text-white">days of employment</p>
                    </div>
                  </div>
                </div>


                <div className="box-col">
                  <div className="card process-sec ">
                    <div className="card-body">
                      <div className="icon mb-2 mb-md-3"><img src={`${process.env.PUBLIC_URL}/images/ic-beachAction.png`} alt="" /></div>
                      <h4 className="text-white">{summaryData.total_actions}</h4>
                      <p className="mb-2 text-white">actions taken</p>
                    </div>
                  </div>
                </div>
                <div className="box-col">
                  <div className="card process-sec line">
                    <div className="card-body">
                      <div className="icon mb-2 mb-md-3"><img src={`${process.env.PUBLIC_URL}/images/globe.png`} alt="" /></div>
                      <h4 className="text-white">{summaryData.total_no_country}</h4>
                      <p className="mb-2 text-white">countries</p>
                    </div>
                  </div>
                </div>
              </Marquee>
            </div>
          </div>
        </section>


        <section >
          <div className="container my-5">
            <p className="font-regular text-center text-blue visionText">
              Our vision is to unite the climate community in a blue circular <br class="d-md-none" /> economy platform where every <br className="d-none d-md-inline" /> transaction funds Clean <br class="d-md-none" /> Oceans and Blue Carbon initiatives around the world.
              {!loggedIn && <React.Fragment> <br /> <strong> <span onClick={() => setOpen(true)} style={{ cursor: 'pointer' }}>Sign up</span> now to clear 1kg of trash from the oceans</strong></React.Fragment>}
            </p>
          </div>
        </section>

        <section className="py-5">
          <div className="container">
            <div className="row g-3">
              <div className="col-lg-3 col-6">
                <OverlayTrigger
                  container={this}
                  trigger={['hover', 'focus']}
                  placement="top"
                  overlay={BeachClean}
                >
                  <div className="home-card">
                    <img src={`${process.env.PUBLIC_URL}/images/home-card-1-img.webp`} className="img-fluid" alt="card-img" />
                    <p>Beach Cleanups</p>
                  </div>
                </OverlayTrigger>

              </div>
              <div className="col-lg-3 col-6">
                <OverlayTrigger
                  container={this}
                  trigger={['hover', 'focus']}
                  placement="top"
                  overlay={SocialMedia}
                >
                  <div className="home-card">
                    <img src={`${process.env.PUBLIC_URL}/images/home-card-2-img.webp`} className="img-fluid" alt="card-img" />
                    <p>Social Platform</p>
                  </div>
                </OverlayTrigger>
              </div>
              <div className="col-lg-3 col-6">
                <OverlayTrigger
                  container={this}
                  trigger={['hover', 'focus']}
                  placement="top"
                  overlay={Tree}
                >
                  <div className="home-card">
                    <img src={`${process.env.PUBLIC_URL}/images/home-card-3-img.webp`} className="img-fluid" alt="card-img" />
                    <p>Mangrove Restoration</p>
                  </div>
                </OverlayTrigger>
              </div>
              <div className="col-lg-3 col-6">
                <OverlayTrigger
                  container={this}
                  trigger={['hover', 'focus']}
                  placement="top"
                  overlay={Commerce}
                >
                  <div className="home-card">
                    <img src={`${process.env.PUBLIC_URL}/images/home-card-5-img.webp`} className="img-fluid" alt="card-img" />
                    <p>Sustainable Commerce</p>
                  </div>
                </OverlayTrigger>
              </div>
              <div className="col-lg-3 col-6">
                <OverlayTrigger
                  container={this}
                  trigger={['hover', 'focus']}
                  placement="top"
                  overlay={Blue}
                >
                  <div className="home-card">
                    <img src={`${process.env.PUBLIC_URL}/images/home-card-4-img.webp`} className="img-fluid" alt="card-img" />
                    <p>Regenerative Finance</p>
                  </div>
                </OverlayTrigger>
              </div>
              <div className="col-lg-3 col-6">
                <OverlayTrigger
                  container={this}
                  trigger={['hover', 'focus']}
                  placement="top"
                  overlay={Plastic}
                >

                  <div className="home-card">
                    <img src={`${process.env.PUBLIC_URL}/images/home-card-6-img.webp`} className="img-fluid" alt="card-img" />
                    <p>Plastic Credits</p>
                  </div>
                </OverlayTrigger>
              </div>
              <div className="col-lg-3 col-6">
                <OverlayTrigger
                  container={this}
                  trigger={['hover', 'focus']}
                  placement="top"
                  overlay={Recycle}
                >
                  <div className="home-card">
                    <img src={`${process.env.PUBLIC_URL}/images/home-card-7-img.webp`} className="img-fluid" alt="card-img" />
                    <p>Circular Economy</p>
                  </div>
                </OverlayTrigger>
              </div>
              <div className="col-lg-3 col-6">
                <OverlayTrigger
                  container={this}
                  trigger={['hover', 'focus']}
                  placement="top"
                  overlay={Eco}
                >
                  <div className="home-card">
                    <img src={`${process.env.PUBLIC_URL}/images/home-card-8-img.webp`} className="img-fluid" alt="card-img" />
                    <p>Planet-friendly Brands</p>
                  </div>
                </OverlayTrigger>
              </div>
            </div>
          </div>
        </section>


        <section className="mt-5">
          <div className="hero-banner-wrapper">
            <img className="img-fluid w-100 d-none d-lg-block" src={`${process.env.PUBLIC_URL}/images/home-banner-1.webp`} alt="banner" />
            <img className="img-fluid w-100 d-lg-none" src={`${process.env.PUBLIC_URL}/images/home-banner-1-xs.webp`} alt="banner" />
            <div className="hero-banner-content-center">
              <p className="mb-3 mb-md-5 text-white font-regular">Sign up now and we will clear 1kg of trash from the oceans!</p>
              {!loggedIn && <button className="btn btn-blue text-white" onClick={() => setOpen(true)}>SIGN UP</button>}
            </div>

          </div>

        </section>

        <section className="my-5">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10">
                <div className="iconWithTextWrapper d-flex align-items-center justify-content-center ">
                  <div className="flex-shrink-0">
                    <Link to="/action" class="btn btn-transparent ">
                      <img src={`${process.env.PUBLIC_URL}/images/ic-beachAction.png`} height={190} alt="icon" />
                    </Link>
                  </div>
                  <div>
                    <p className="text-blue mb-3">
                      Take action: earn $BEACH. Beach Action is  a <br class="d-md-none" />social platform which rewards <br className="d-none d-lg-inline" /> direct climate<br class="d-md-none" /> action. <span className="d-none d-md-inline">- join today and take action!</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="position-relative bg-bottom-blue">
          <img className="d-none d-lg-block  w-100" src={`${process.env.PUBLIC_URL}/images/home-banner-2.webp`} alt="banner" />
          <img className="d-block d-lg-none  w-100" src={`${process.env.PUBLIC_URL}/images/home-banner-2-xs.webp`} alt="banner" />
          <div className="px-4 clientsWrapper">
            <div className="card client-block">
              <div className="row g-0 h-100">
                <div className="col-4">
                  <img src={`${process.env.PUBLIC_URL}/images/client1.webp`} className="img-fluid client-img" alt="..." />
                </div>
                <div className="col-8">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-baseline">
                      <h5 className="card-title">Jihad Hendawi, Lebanon</h5>
                      {/*<Link to='' className="text-blue font-regular"><small>View Profile</small></Link>*/}
                    </div>
                    <p className="card-text">Knowing there's someone who cares about our beaches, which is a big part of our culture and community, gives us hope that tomorrow will be better.</p>

                  </div>
                </div>
              </div>
            </div>

            <div className="card client-block">
              <div className="row g-0 h-100">
                <div className="col-4">
                  <img src={`${process.env.PUBLIC_URL}/images/client2.webp`} className="img-fluid client-img" alt="..." />
                </div>
                <div className="col-8">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-baseline">
                      <h5 className="card-title">Togbe Ghana, Ghana</h5>
                      {/*<Link to='' className="text-blue font-regular"><small>View Profile</small></Link>*/}
                    </div>
                    <p className="card-text">Beach Token through their support has enabled "Lets Go Clean The Beach" to not only keep the beaches clean in Ghana but also to pay workers, feed them and attract volunteers.</p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="text-center enterWrapper">
          <Link to="/action" className="btn btn-lg btn-blue text-white">ACTION</Link>
        </div>


        <section className="position-relative">
          <div className="beachPay-top  position-ralative" style={{ backgroundColor: '#E0FAFD' }}>
            <img className="d-none d-md-block w-100" src={`${process.env.PUBLIC_URL}/images/top-desktop.webp`} />
            <img className="d-block d-md-none w-100" src={`${process.env.PUBLIC_URL}/images/top-mobile.webp`} />
            <div className="d-flex container block-content-top">
              <Link to="/pay" class="btn btn-transparent">
                <img src={`${process.env.PUBLIC_URL}/images/ic-beachPay.png`} className="mobile-small-icon" height={150} alt="" />
              </Link>
              <div className="desktop-pos">
                <div className="container">
                  <div className="row g-md-5">
                    <div className="col-md-4">
                      <img src={`${process.env.PUBLIC_URL}/images/mob-img.webp`} className="img-fluid mobile-small" alt="" />
                    </div>
                    <div className="col-md-8">
                      <div className="friction-txt">
                        <h2 >Beach Pay is an easy way to pay anyone in the world</h2>
                        <h4 className="py-4">1% of each transaction goes towards the planet</h4>
                        <Link to="/pay" class="btn blue-btn mt-sm-5 mt-md-0 btn-lg rounded-4 text-white">PAY</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="beachPay-middle  position-ralative"> </div> {/* tokenomics */}
          <div className="beachPay-bottom  position-ralative">
            <img className="d-none d-md-block w-100" src={`${process.env.PUBLIC_URL}/images/desktop-bottom.webp`} />
            <img className="d-block d-md-none w-100" src={`${process.env.PUBLIC_URL}/images/mobile-bottom.webp`} />
          </div>
        </section>

        <section className="py-md-5 my-5">
          <div className="container">
            <div className="row">
              <div className="my-3 py-md-5 my-md-5 text-center">
                <h2 className="text-blue">Our Partners:</h2>
              </div>
            </div>
            <div className="my-md-0  position-relative">
              <div className="container">
                <div className="hide-scrollbar overflow-hidden">

                  <Marquee className="partners-marquee" autoFill={true}>

                    <div>
                      <a target="_blank" rel="noreferrer" href={'https://www.soalliance.org/'}><img src={`${process.env.PUBLIC_URL}/images/partners/soa.png`} className="img-fluid mb-3" /></a>
                    </div>
                    <div>

                      <a target="_blank" rel="noreferrer" href={'https://future.quest/'}><img src={`${process.env.PUBLIC_URL}/images/partners/Future-Quest-logo.png`} className="img-fluid mb-3" /></a>
                    </div>

                    <div>

                      <a target="_blank" rel="noreferrer" href={'https://oceansoleonline.com'}><img src={`${process.env.PUBLIC_URL}/images/partners/ocean-solo.png`} className="img-fluid mb-3" /></a>
                    </div>
                    <div>

                      <a target="_blank" rel="noreferrer" href={'https://www.gvi.co.uk/'}><img src={`${process.env.PUBLIC_URL}/images/partners/Planet-Dark-Logo.png`} className="img-fluid mb-3" /></a>
                    </div>

                    <div>

                      <a target="_blank" rel="noreferrer" href={'https://www.facebook.com/Lets-Go-Clean-the-Beach-Project-104520747611864'}><img src={`${process.env.PUBLIC_URL}/images/partners/letsgo.png`} className="img-fluid mb-3" /></a>
                    </div>
                    <div>

                      <a target="_blank" rel="noreferrer" href={'https://anantasustainables.com/'}><img src={`${process.env.PUBLIC_URL}/images/partners/ananta.png`} className="img-fluid mb-3" /></a>
                    </div>

                    <div>

                      <a target="_blank" rel="noreferrer" href={'https://www.instagram.com/cleansurfproject/?utm_medium=copy_link'}><img src={`${process.env.PUBLIC_URL}/images/partners/cleansurf.png`} className="img-fluid mb-3" /></a>
                    </div>
                    <div>

                      <a target="_blank" rel="noreferrer" href={'https://ambakofi.org/'}><img src={`${process.env.PUBLIC_URL}/images/partners/ambakifo.png`} className="img-fluid mb-3" /></a>
                    </div>

                    <div>

                      <a target="_blank" rel="noreferrer" href={'https://oceansole.com/'}><img src={`${process.env.PUBLIC_URL}/images/partners/associacao.png`} className="img-fluid mb-3" /></a>
                    </div>
                    <div>

                      <a target="_blank" rel="noreferrer" href={'https://www.letsmakecyprusgreen.com/'}><img src={`${process.env.PUBLIC_URL}/images/partners/cyprus.png`} className="img-fluid mb-3" /></a>
                    </div>

                    <div>

                      <a target="_blank" rel="noreferrer" href={'https://mangroveactionproject.org/'}><img src={`${process.env.PUBLIC_URL}/images/partners/map-logo.png`} className="img-fluid mb-3" /></a>
                    </div>
                    <div>

                      <a target="_blank" rel="noreferrer" href={'http://www.cici.org.au/'}><img src={`${process.env.PUBLIC_URL}/images/partners/cicis.png`} className="img-fluid mb-3" /></a>
                    </div>

                    <div>

                      <a target="_blank" rel="noreferrer" href={'https://therubbishproject.com/'}><img src={`${process.env.PUBLIC_URL}/images/partners/rubbish.png`} className="img-fluid mb-3" /></a>
                    </div>
                    <div>

                      <a target="_blank" rel="noreferrer" href={'https://www.effektfootwear.com/'}><img src={`${process.env.PUBLIC_URL}/images/partners/effektfootwear.png`} className="img-fluid mb-3" /></a>
                    </div>

                    <div>
                      <a target="_blank" rel="noreferrer" href={'https://www.seeturtles.org/'}><img src={`${process.env.PUBLIC_URL}/images/partners/see.png`} className="img-fluid mb-3" /></a>
                    </div>
                    <div>
                      <a target="_blank" rel="noreferrer" href={'https://gngrbees.com/'}><img src={`${process.env.PUBLIC_URL}/images/partners/gngr.png`} className="img-fluid mb-3" /></a>
                    </div>

                    <div>
                      <a target="_blank" rel="noreferrer" href={'https://roguetokens.com'}><img src={`${process.env.PUBLIC_URL}/images/partners/rogue.png`} className="img-fluid mb-3" /></a>
                    </div>
                    <div>
                      <a target="_blank" rel="noreferrer" href={'https://everywhere.us/'}><img src={`${process.env.PUBLIC_URL}/images/partners/everywhere.png`} className="img-fluid mb-3" /></a>
                    </div>

                    <div>

                      <a target="_blank" rel="noreferrer" href={'https://www.facebook.com/FAULU-production-2158535267732567'}><img src={`${process.env.PUBLIC_URL}/images/partners/faulu.png`} className="img-fluid mb-3" /></a>
                    </div>
                    <div>
                      <a target="_blank" rel="noreferrer" href={'https://www.instagram.com/greenkeepers.lb/'}><img src={`${process.env.PUBLIC_URL}/images/partners/green-keepers.png`} className="img-fluid mb-3" /></a>
                    </div>

                    <div>
                      <a href={'https://12tides.com/'}><img src={`${process.env.PUBLIC_URL}/images/partners/12%20Tides%20Logo%20(1).png`} className="img-fluid mb-3" /></a>
                    </div>
                    <div>
                      <a target="_blank" rel="noreferrer" href={'https://www.instagram.com/boxmallcompany/'}><img src={`${process.env.PUBLIC_URL}/images/partners/boxmall.png`} className="img-fluid mb-3" /></a>
                    </div>

                    <div>

                      <a target="_blank" rel="noreferrer" href={'https://nutshellcoolers.com/'}><img src={`${process.env.PUBLIC_URL}/images/partners/Nutshell%20Logo.png`} className="img-fluid mb-3" /></a>
                    </div>
                    <div>

                      <a target="_blank" rel="noreferrer" href={'https://www.commvault.com/'}><img src={`${process.env.PUBLIC_URL}/images/partners/commvault.png`} className="img-fluid mb-3" /></a>
                    </div>

                    <div>
                      <a target="_blank" rel="noreferrer" href={'https://pristinecollective.com/'}><img src={`${process.env.PUBLIC_URL}/images/partners/PEC-Logo-White-Back.png`} className="img-fluid mb-3" /></a>
                    </div>
                    <div>
                      <a target="_blank" rel="noreferrer" href={'https://what3words.com/about'}><img src={`${process.env.PUBLIC_URL}/images/partners/what3word.jpg`} className="img-fluid mb-3" /></a>
                    </div>
                    <div>
                      <a target="_blank" rel="noreferrer" href={'https://peaceboat-us.org/'}><img src={`${process.env.PUBLIC_URL}/images/partners/PBus-logoUS.jpg`} className="img-fluid mb-3" /></a>
                    </div>
                    <div>
                      <a target="_blank" rel="noreferrer" href={'https://www.theashleylashleyfoundation.org/'}><img src={`${process.env.PUBLIC_URL}/images/partners/talf.png`} className="img-fluid mb-3" /></a>
                    </div>

                  </Marquee>


                </div>
              </div>
            </div>

          </div>
        </section>

        <div className="bule-line" />

        <section className="py-md-5 my-5">
          <div className="container">

            <div className="my-3 my-md-5 text-center">
              <h2 className="text-blue">Media</h2>
            </div>

            <div className="row">
              <div className="col-md-10 col-xl-8 mx-auto">
                <div className="d-flex gap-2 justify-content-between">

                  <div className="text-center partner-block">
                    <img src={`${process.env.PUBLIC_URL}/images/kucoin.png`} />
                    <h4 className="mt-2 mt-md-3"><a href="https://www.kucoin.com/kucoins/bbs/post/217768" target="_blank">KuCoin</a></h4>
                  </div>


                  <div className="text-center partner-block">
                    <img src={`${process.env.PUBLIC_URL}/images/what3words.png`} />
                    <h4 className="mt-2 mt-md-3"><a href="https://what3words.com/news/blog/meet-businesses-around-the-world-using-what3words-2" target="_blank">What3Words</a></h4>
                  </div>


                  <div className="text-center partner-block">
                    <img src={`${process.env.PUBLIC_URL}/images/speech.png`} />
                    <h4 className="mt-2 mt-md-3"><a href="https://www.youtube.com/watch?v=5P80nffwKV0" target="_blank">Speech at COP27</a></h4>
                  </div>


                  <div className="text-center partner-block">
                    <img src={`${process.env.PUBLIC_URL}/images/duetshe.png`} />
                    <h4 className="mt-2 mt-md-3"><a href="https://www.dw.com/en/artists-turn-the-spotlight-on-ghanas-environment/video-61932003" target="_blank">Deutsche Welle</a></h4>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </section>

        <section>
          <img className="d-none d-md-block img-fluid" src={`${process.env.PUBLIC_URL}/images/media-boootm-img.png`} />
          <img className="d-block d-md-none img-fluid" src={`${process.env.PUBLIC_URL}/images/media-boootm-mobile-img.png`} />
        </section>

        <section className="py-5">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-5">
                <img className="img-fluid mb-3" src={`${process.env.PUBLIC_URL}/images/Team_Mangrove2.png`} />
                <img className="img-fluid mb-3" src={`${process.env.PUBLIC_URL}/images/form-mg-1.png`} />
                <img className="img-fluid mb-3" src={`${process.env.PUBLIC_URL}/images/form-mg-2.png`} />
              </div>
              <div className="col-7">
                <div className='form-section contact-form'>
                  <h4>Are you an individual, brand or cause that wants to work with us?</h4>
                  <h2 className="my-md-5">Contact us!</h2>
                  <form>
                    <div className="mb-3">
                      <input type="text" className="form-control" id="formGroupExampleInput" placeholder="Email address" />
                    </div>
                    <div className="mb-3">
                      <textarea class="form-control" id="exampleFormControlTextarea1" rows="6" placeholder="What would you like to say?"></textarea>
                    </div>

                    <button type="button" class="btn btn-primary mt-md-4 w-100">SEND</button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>




        <footer>
          <div className="container-fluid">
            <div className="footer-logo mb-3 text-center"><img src={`${process.env.PUBLIC_URL}/images/footer-logo.png`} width={350} alt="" /></div>
            <div className="row flex-column-reverse flex-md-row">
              <div className="col-md-4 d-none d-md-block">
                <div className="footer-nav">
                  <Link to="/action" >Action</Link>
                  <Link to="/pay">Pay</Link>
                  {/*<Link to="/shop">Shop</Link>*/}
                  <a data-bs-toggle="modal" href="#beach-nomad">Nomad</a>
                  <a data-bs-toggle="modal" href="#beach-farm">Farm</a>
                  <a data-bs-toggle="modal" href="#beach-nft">NFT</a>

                </div>
              </div>
              <div className="col-md-8">
                <div className="row justify-content-end">
                  <div className="col-md-4 col-lg-3">
                    <div className="widget widget_links text-center text-md-start clearfix my-4 my-md-0 my-xl-0">
                      <h4>CONTACT US</h4>
                      <a href="mailto:hello@beachtoken.io" target="_blank" className="emailus">hello@beachtoken.io</a>
                    </div>
                    <div className="foot-signup mt-3 d-md-none ">
                      <h3>Sign up for updates</h3>
                      <form onSubmit={handleUpdateSubmit}>
                        <div className='signup_Input'>
                          <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} name="email" placeholder="Type email address here..." />
                          <button type={'submit'} className="signup_Btn">SIGN UP</button>
                        </div>
                      </form>

                    </div>
                  </div>
                  <div className="col-md-4 col-lg-3 mt-3 mt-md-0">

                    <div className="d-flex justify-content-between d-md-block align-items-center">
                      <div className="footer-nav d-md-none">
                        <Link to="/action" >Action</Link>
                        <Link to="/pay" >Pay</Link>
                        <Link to="/shop" >Shop</Link>
                        <a data-bs-toggle="modal" href="#beach-nomad">Nomad</a>
                        <a data-bs-toggle="modal" href="#beach-farm">Farm</a>
                        <a data-bs-toggle="modal" href="#beach-nft">NFT</a>

                      </div>
                      <div className="widget widget_links clearfix">
                        <ul>
                          <li><Link to="/">Home</Link></li>
                          <li><a href="/whitepaper/White-Paper.pdf" target="_blank" rel="noreferrer">White Paper</a></li>
                          <li><Link to="/tokenomics" target="_blank" rel="noreferrer">Tokenomics</Link></li>
                          <li><a href="https://pancakeswap.finance/swap?outputCurrency=0x4f4543Fa5F7FE28a7758ef43FeA71d11fCCDAD5c&inputCurrency=BNB" target="_blank" rel="noreferrer">Buy on Pancake Swap</a></li>
                          <li><Link to="/blog">Blog</Link></li>
                          <li><a to="https://beachcollective.io/" target="_blank" rel="noreferrer">Beach Collective</a></li>
                          <li><Link to="/risk-disclosure" target="_blank" rel="noreferrer">Risk Disclosure</Link></li>
                        </ul>
                      </div>

                    </div>

                  </div>
                </div>

                <div className="row justify-content-end">
                  <div className="col-md-7 col-xl-6 d-none d-md-block">
                    <div className="foot-signup py-4">
                      <h3>Sign up for updates</h3>
                      <form onSubmit={handleUpdateSubmit}>
                        <div style={{ position: 'relative', background: '#fff', borderRadius: '5px' }}>
                          <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} style={{ width: '80%' }} name="email" />
                          <button type={'submit'} className='btn btn-outline-blue' style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }} >SIGN UP</button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="col-md-5 col-lg-4 col-xl-3 mt-3 mt-md-0">
                    <div className="widget clearfix">
                      <div className="footer-social  mt-3">
                        <div className="row m-0">
                          <div className="col-3 mb-2 responsive-0 d-flex justify-content-center align-items-center">
                            <a class="responsive-icon" href="https://t.me/BeachToken" target="_blank">
                              <img className="img-fluid" src={`${process.env.PUBLIC_URL}/images/ic-telegram.png`} alt="" /></a>
                          </div>
                          <div className="col-3 mb-2 responsive-0 d-flex justify-content-center align-items-center">
                            <a className="responsive-icon" href="https://twitter.com/beach_token" target="_blank">
                              <img className="img-fluid" src={`${process.env.PUBLIC_URL}/images/ic-twitter2.png`} alt="" /></a>
                          </div>
                          <div className="col-3 responsive-0  mb-2 d-flex justify-content-center align-items-center">
                            <a className="responsive-icon " href="https://www.facebook.com/beachcollective.io" target="_blank"><img class="img-fluid" src={`${process.env.PUBLIC_URL}/images/ic-fb-f.png`} alt="" /></a>
                          </div>
                          <div className="col-3 responsive-0 mb-2  d-flex justify-content-center align-items-center">
                            <a className="responsive-icon" href="https://discord.gg/beachtoken" target="_blank"><img className="img-fluid" src={`${process.env.PUBLIC_URL}/images/ic-discord.png`} alt="" /></a>
                          </div>
                          <div className="col-3 mb-2 responsive-0  d-flex justify-content-center align-items-center">
                            <a className="responsive-icon" href="https://www.instagram.com/beachcollective.io/" target="_blank">
                              <img className="img-fluid" src={`${process.env.PUBLIC_URL}/images/ico-instagram.svg`} alt="" /></a>
                          </div>


                          <div className="col-3 mb-2 responsive-0  d-flex justify-content-center align-items-center">
                            <a className="responsive-icon" href="https://www.youtube.com/channel/UCWe9w4b1asXIAxHatN_FFhw" target="_blank"><img className="img-fluid" src={`${process.env.PUBLIC_URL}/images/ic-YouTube2.png`} alt="" /></a>
                          </div>
                          <div className="col-3 mb-2 responsive-0  d-flex justify-content-center align-items-center">
                            <a className="responsive-icon" href="https://www.linkedin.com/company/80447914" target="_blank"><img className="img-fluid" src={`${process.env.PUBLIC_URL}/images/ic-linkedin2.png`} alt="" /></a>
                          </div>
                          <div className="col-3 mb-2 responsive-0  d-flex justify-content-center align-items-center">
                            <a className="responsive-icon" href="https://www.tiktok.com/@beachcollective.io?lang=en" target="_blank"><img className="img-fluid" height="30" src={`${process.env.PUBLIC_URL}/images/ic-TikTok.png`} alt="" /></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </footer>

      </div>






      <UpComing div_id="beach-shop" title="SHOP" />
      <UpComing div_id="beach-nomad" title="NOMAD" />
      <UpComing div_id="beach-swap" title="SWAP" />
      <UpComing div_id="beach-verse" title="VERSE" />
      <UpComing div_id="beach-farm" title="FARM" />
      <UpComing div_id="beach-nomad" title="NOMAD" />
      {/*<ProfileModel wallet_address={walletAddress} />*/}

      <SignupModal show={isOpen} onClose={setOpen} openLogin={setLoginPopup} />
      <LoginModal show={loginPopup} hide={setLoginPopup} openSignup={setOpen} />
      {loggedIn && <ReferencePopup show={sharingLink} hide={setSharingLink} />}
      <HomePopup show={homePop} close={setHomePop} signin={setLoginPopup} signup={setOpen} />
      {loggedIn && <WelcomePopup openHomePopup={setHomePop} />}

      <div id="embr-widget" className={embrOpen ? 'show' : ''}></div>

      <div className="embr-button" onClick={() => setEmbrOpen(prev => !prev)}>
        <div className="embr-button-icon-wrapper pointer-event-none">

          {
            embrOpen ?
              <div className="embr-button-icon pointer-event-none">
                <HiOutlineArrowDown size={20} />
              </div>
              :
              <img src={`${process.env.PUBLIC_URL}/images/bc-logo.svg`} className="embr-button-icon pointer-event-none" />
          }

        </div>
        <p className="m-0 embr-button-text">Buy Beach Token</p>
      </div>

    </React.Fragment>
  );
}
