import {
  api_url,
  TokenAddress,
  Providers,
  ContractABI,
  Networks,
  ContractByteCode,
} from "../Constant";
import Web3 from "web3";
import fromExponential from "from-exponential";
import { ethers } from "ethers";
import axiosInstance from "../Axios/axios.config";

function putDotToken(value) {
  value = String(value).replace(".", "");
  value = value.slice(0, -9) + "." + value.slice(-9);
  // return parseInt(value);
  value = parseInt(value);
  value = value ? value : 0;

  return parseInt(value);
}

export const getTokensTotalSupply = async () => {
  let response_return = {};
  let url = `https://api.coingecko.com/api/v3/coins/beach-token`;
  let response = await fetch(url);
  let data = await response.json();
  let total_supply = data.market_data.total_supply;
  response_return.beach_total_supply = total_supply;

  url = `https://api.coingecko.com/api/v3/coins/beach-token-bsc`;
  response = await fetch(url);
  data = await response.json();
  total_supply = data.market_data.total_supply;
  response_return.beach_bsc_total_supply = total_supply;
  return response_return;
};

export const getUserOldReflectionBalance = async (address) => {
  let token_balance = {};
  //   const accounts = await web3.eth.getAccounts();
  // let address = address;
  // address = '0xd58de028bD05f5CA0b74272F692d4e6c4C1c7f75'
  const url = `${api_url}/get-user-old-reflection-data/${address}/`;
  const response = await fetch(url);
  const data = await response.json();
  token_balance.beach_erc_old_reflection = data.beach_erc_old_reflection;
  token_balance.beach_bsc_old_reflection = data.beach_bsc_old_reflection;
  // debugger;
  return token_balance;
};

export const getUserTokenBalance = async (address,provider_obj) => {
  let token_balance = {};

  const web3_bsc = new Web3(provider_obj);
  console.log('inside getUserTokenBalance')

  const beach_bsc_token = new web3_bsc.eth.Contract(
    JSON.parse(ContractABI.BeachBSCMainnet),
    TokenAddress.BeachBSCMainnet
  );

  let beach_bsc_token_balance = await beach_bsc_token.methods
    .balanceOf(address)
    .call();
  beach_bsc_token_balance = putDotToken(beach_bsc_token_balance);
  token_balance.beach_bsc_token_balance = beach_bsc_token_balance;

  return token_balance;
};


const getC3Tansaction = async (hash) =>{

  const api_response =  await axiosInstance.get(`/on-chain/get-ccc-transaction/${hash}`,{
    'Content-Type': 'application/json'
  })

  if(api_response.status === 200){
      console.log(api_response.data)
  }
 
}
const getTransactionStatus = async (hash) =>{

  const api_response =  await axiosInstance.get(`/user/user-tstatus/`,{
    'Content-Type': 'application/json'
  })

  if(api_response.status === 200){
      console.log(api_response.data)
  }
 
}

export const BeachTokenPrice = async (type) => {
  let url = `${api_url}/on-chain/request-price/0x4f4543Fa5F7FE28a7758ef43FeA71d11fCCDAD5c/`;
  const response = await fetch(url);
  const data = await response.json();
  return parseFloat(data.usd_price);
};

const AllReflection = async (address, type) => {
  let response_obj = {};
  let weight_of_wallet = 0;

  const url = `${api_url}/get-all-volume-data/${type}/`;
  const response = await fetch(url);
  const data = await response.json();
  // // console.log(data);

  if (response.ok) {
    //   get the token total supply
    let token_supply = await getTokensTotalSupply();
    // console.log("token_supply", token_supply);
    let beach_token_supply = token_supply.beach_total_supply;
    let beach_bsc_token_supply = token_supply["beach_bsc_total_supply"];
    // console.log(beach_token_supply, beach_bsc_token_supply);

    //##################################################

    // get the user token balance
    let token_balance = await getUserTokenBalance(address);
    let beach_token_balance = parseFloat(token_balance["beach_token_balance"]);
    let beach_bsc_token_balance = parseFloat(
      token_balance["beach_bsc_token_balance"]
    );
    // // console.log(beach_token_balance, beach_bsc_token_balance);

    // ##################################################

    if (data.data_beach.length > 0 && data.data_beach_bsc.length > 0) {
      let reflection_beach_token = parseFloat(
        data.data_beach[0].reflections_in_tokens
      );
      let reflection_beach_bsc_token = parseFloat(
        data.data_beach_bsc[0].reflections_in_tokens
      );

      // // console.log("reflection_beach", reflection_beach_token);
      // // console.log("reflection_beach_bsc", reflection_beach_bsc_token);

      // ##################### beach token #################################

      //  wallet weight wallet calculation
      weight_of_wallet =
        parseFloat(beach_token_balance) / parseFloat(beach_token_supply);
      // // console.log("weight_of_wallet-->", weight_of_wallet);

      // get live beach token price
      let beach_token_price = await BeachTokenPrice("erc");
      // // console.log("beach_token_price-->", beach_token_price);

      //  old reflection
      // ######################################################
      let old_reflection = await getUserOldReflectionBalance(address);
      // ###################################################
      // let beach_tokens = reflection_beach_token * 0.03

      let beach_tokens =
        parseInt(reflection_beach_token) * parseFloat(weight_of_wallet);
      let beach_tokens_usd =
        parseFloat(beach_tokens) * parseFloat(beach_token_price);
      if (type === "all") {
        // debugger;
        beach_tokens_usd +=
          parseFloat(old_reflection.beach_erc_old_reflection) *
          parseFloat(beach_token_price);
        beach_tokens += parseFloat(old_reflection.beach_erc_old_reflection);
      }

      // set beach response
      response_obj.beach_token_reflection = parseInt(beach_tokens);
      response_obj.beach_token_reflection_usd =  parseFloat(beach_tokens_usd).toFixed(2);
      // // console.log("---------beach_tokens---------->", beach_tokens);
      // // console.log("---------beach_tokens_usd---------->", beach_tokens_usd);

      // #########################Beach token Bsc ##############################

      //   wallet weight calcuation
      weight_of_wallet =  parseFloat(beach_bsc_token_balance) / parseFloat(beach_bsc_token_supply);
      // // console.log("weight_of_wallet-->", weight_of_wallet);

      //  get live beach bsc price
      let beach_token_bsc_price = await BeachTokenPrice("bsc");
      // // console.log("beach_token_bsc_price-->", beach_token_bsc_price);

      let beach_bsc_tokens =
        parseFloat(reflection_beach_bsc_token) * parseFloat(weight_of_wallet);
      let beach_bsc_tokens_usd =
        parseFloat(beach_bsc_tokens) * parseFloat(beach_token_bsc_price);
      if (type === "all") {
        // debugger;
        beach_bsc_tokens_usd +=
          parseFloat(old_reflection.beach_bsc_old_reflection) *
          parseFloat(beach_token_bsc_price);
        // // console.log("beach_bsc_tokens------->", beach_bsc_tokens);
        beach_bsc_tokens += parseFloat(old_reflection.beach_bsc_old_reflection);
        // // console.log("beach_bsc_tokens after------->", beach_bsc_tokens);
      }

      response_obj.beach_token_bsc_reflection = parseInt(beach_bsc_tokens);
      response_obj.beach_token_bsc_reflection_usd =
        parseFloat(beach_bsc_tokens_usd).toFixed(2);
      // // console.log("---------beach_bsc_tokens---------->", beach_tokens);
      // // console.log("---------beach_bsc_tokens_usd---------->", beach_tokens_usd);
      return response_obj;
    } else {
      response_obj.beach_token_reflection = 0;
      response_obj.beach_token_reflection_usd = 0;
      response_obj.beach_token_bsc_reflection = 0;
      response_obj.beach_token_bsc_reflection_usd = 0;
      return response_obj;
    }
  }
};

export default AllReflection;

export const getUserWalletBalance = async (address,provider_obj) => {
  // get the user token balance
  let return_response = {};
  let token_balance = await getUserTokenBalance(address,provider_obj);
  
  let beach_bsc_token_balance = parseFloat(
    token_balance["beach_bsc_token_balance"]
  );
  // // console.log(beach_token_balance, beach_bsc_token_balance);

  // get live beach token price
  let beach_token_bsc_price = await BeachTokenPrice("bsc");

  let beach_token_bsc_usd_balance =
    parseFloat(beach_bsc_token_balance) * parseFloat(beach_token_bsc_price);

  return_response.beach_token_bsc_balance = parseInt(beach_bsc_token_balance);
  return_response.beach_token_bsc_usd_balance = parseFloat(
    beach_token_bsc_usd_balance
  ).toFixed(3);
  console.log("return_response==",return_response)

  return return_response;
};

export const getUserName = async (address) => {
  let url = `${api_url}/get-user-name/${address}/`;
  const response = await fetch(url);
  const data = await response.json();
  // console.log("data --->------------------", data);
  return data.user[0].full_name;
};

// #################################################################################
//  test Network

// export const getTestUserTokenBalance = async (address) => {
//   let token_balance = {};

//   const web3_erc = new Web3(Providers.RopstenInfura);

//   const beach_token = new web3_erc.eth.Contract(
//     JSON.parse(ContractABI.BeachRopsten),
//     TokenAddress.BeachRopsten
//   );

//   let beach_token_balance = await beach_token.methods.balanceOf(address).call();
//   beach_token_balance = putDotToken(beach_token_balance);
//   token_balance.beach_token_balance = beach_token_balance;
//   // console.log("beach_token_balance Test-->", beach_token_balance);

//   const web3_bsc = new Web3(Providers.BSCTestnet);

//   const beach_bsc_token = new web3_bsc.eth.Contract(
//     JSON.parse(ContractABI.BeachBSCTestnet),
//     TokenAddress.BeachBSCTestnet
//   );

//   let beach_bsc_token_balance = await beach_bsc_token.methods
//     .balanceOf(address)
//     .call();
//   beach_bsc_token_balance = putDotToken(beach_bsc_token_balance);
//   token_balance.beach_bsc_token_balance = beach_bsc_token_balance;
//   // debugger;
//   return token_balance;
// };

// core custody ropsten
// 0x8B2bD3De73ad1360dE7FdCF93ae0aEc0553d8711

// core custody bsc test network
// 0x5b44fc4af39b9ae338a553ea80b5e69dd032e0bf;

// export const getTestUserBagTokenBalance = async (address) => {
//   let return_response = {};

//   const web3_erc = new Web3(Providers.RopstenInfura);

//   const core_custody = new web3_erc.eth.Contract(
//     JSON.parse(ContractABI.TestCoreCustodyERC),
//     TokenAddress.TestCoreCustodyERC
//   );

//   let beach_token_balance = await core_custody.methods
//     .UserTokens(address, TokenAddress.BeachRopsten)
//     .call();

//   // console.log("beach_token_balance----", beach_token_balance);
//   beach_token_balance = putDotToken(beach_token_balance);

//   const web3_bsc = new Web3(Providers.BSCTestnet);

//   const core_custody_bsc = new web3_bsc.eth.Contract(
//     JSON.parse(ContractABI.TestCoreCustodyBSC),
//     TokenAddress.TestCoreCustodyBSC
//   );

//   let beach_bsc_token_balance = await core_custody_bsc.methods
//     .UserTokens(address, TokenAddress.BeachBSCTestnet)
//     .call();

//   beach_bsc_token_balance = putDotToken(beach_bsc_token_balance);

//   // get live beach token price
//   let beach_token_price = await BeachTokenPrice("erc");
//   // get live beach token price
//   let beach_token_bsc_price = await BeachTokenPrice("bsc");

//   let beach_token_bsc_usd_balance =
//     parseFloat(beach_bsc_token_balance) * parseFloat(beach_token_bsc_price);

//   let beach_tokens_usd_balance =
//     parseFloat(beach_token_balance) * parseFloat(beach_token_price);

//   // console.log("<--->", beach_tokens_usd_balance, beach_token_bsc_usd_balance);
//   return_response.beach_token_balance = parseInt(beach_token_balance);
//   return_response.beach_tokens_usd_balance = parseFloat(
//     beach_tokens_usd_balance
//   ).toFixed(2);
//   return_response.beach_token_bsc_balance = parseInt(beach_bsc_token_balance);
//   return_response.beach_token_bsc_usd_balance = parseFloat(
//     beach_token_bsc_usd_balance
//   ).toFixed(2);

//   return return_response;
// };

// ############ deposit #################################

export const depositTokens = async (amount, wallet_address,provider_obj,networkId,update) => {
  console.log("amount-->", amount, wallet_address);
  debugger;

  amount = String(amount) + "000000000";
  amount = fromExponential(amount);
  debugger;
  // const networkId = await window.web3.eth.net.getId();

  console.log("networkId", networkId);

if (networkId === Networks.BSCMainnet) {

    console.log("inside network smart chain test network ");
    const web3_bsc = new Web3(provider_obj);

        const CoreCustody = new web3_bsc.eth.Contract(
      JSON.parse(ContractABI.MainnetCoreCustodyBSC),
      TokenAddress.MainnetCoreCustodyBSC
    );
    console.log('CoreCustody',CoreCustody)

    const beach_token = new web3_bsc.eth.Contract(
      JSON.parse(ContractABI.BeachBSCMainnet),
      TokenAddress.BeachBSCMainnet
    );

    console.log('CoreCustody',beach_token)

    debugger;

    let tokenAllownce = await beach_token.methods
      .allowance(wallet_address, TokenAddress.MainnetCoreCustodyBSC)
      .call();
    debugger;

    // console.warn("token allownce-->", tokenAllownce);
    tokenAllownce = String(fromExponential(tokenAllownce));
    amount = String(fromExponential(amount));
    // console.warn("token allownce-->", tokenAllownce, amount);
    console.log(amount, tokenAllownce);
    debugger;

    if (amount > tokenAllownce) {
      debugger;

      await beach_token.methods
        .approve(TokenAddress.MainnetCoreCustodyBSC, amount)
        .send({ from: wallet_address })
        .on("receipt", async function (confirmationNumber, receipt) {
          // console.warn("receip event ");
          window.setTimeout(async () => {
            await CoreCustody.methods
              .depositToken(TokenAddress.BeachBSCMainnet, amount)
              .send({ from: wallet_address })
              .on("receipt", async (hash) => {
                // console.log(hash);
                await getC3Tansaction(hash.transactionHash)
                update()
                return true;
              })
          }, 2000);
          return true;
        });
    } else {
      debugger;

      amount = String(fromExponential(amount));
      // console.log("amount=>", amount);
      await  CoreCustody.methods
        .depositToken(TokenAddress.BeachBSCMainnet, amount)
        .send({ from: wallet_address })
        .on("receipt", async (hash) =>{
          // console.warn("receip event ");
          await getC3Tansaction(hash.transactionHash)
          update()
          return true;
        })
    }
  } else {
    window.alert("this network is not supported");
  }
  debugger;
};

export const withdrawTokens = async (amount, wallet_address,provider_obj,networkId,update) => {
  // console.log("amount-->", amount);
  amount = String(amount) + "000000000";
  console.log("amount -1", amount);
  amount = parseInt(amount);
  amount = String(fromExponential(amount));
  console.log("amount-2", amount);

  // const networkId = await window.web3.eth.net.getId();
  // console.log("networkId-->", networkId);
  // const provider = new ethers.providers.JsonRpcProvider(Providers.BSCTestnet);
  // const networkId = await provider.getNetwork();
  console.log("networkId", networkId);

  if (networkId === Networks.BSCMainnet) {
    // console.log("inside network smart chain test network ");
    const web3_bsc = new Web3(provider_obj);

    const CoreCustody = new web3_bsc.eth.Contract(
      JSON.parse(ContractABI.MainnetCoreCustodyBSC),
      TokenAddress.MainnetCoreCustodyBSC
    );

    await CoreCustody.methods
      .withdrawToken(TokenAddress.BeachBSCMainnet, amount)
      .send({ from: wallet_address })
      .on("receipt", async (hash) => {
        // console.log(hash);
        await getC3Tansaction(hash.transactionHash)
        update()
      }).on("error",async err=>{
        console.log(err)
        await getTransactionStatus()
      });
  } else {
    window.alert("this network is not supported");
  }
};

export const getUserAllowanceBalance = async (address,provider_obj,networkId) => {
  console.log("networkId", networkId);

  debugger;

  if (networkId === Networks.BSCMainnet) {
    // console.log("inside network smart chain test network ");

    const web3_bsc = new Web3(provider_obj);

    const beach_bsc = new web3_bsc.eth.Contract(
      JSON.parse(ContractABI.BeachBSCMainnet),
      TokenAddress.BeachBSCMainnet
    );
    const allowance = await beach_bsc.methods
      .allowance(address, TokenAddress.MainnetCoreCustodyBSC)
      .call();
    return putDotToken(allowance);
  } else {
    window.alert("this network is not supported");
  }
};

export const TokenAllowance = async (address, amount,provider_obj,networkId) => {
  console.log("amount-->", amount, address);
  amount = String(amount) + "000000000";
  // console.warn("token allowance amount -- >", amount);
  console.log("networkId", networkId);
  // debugger;
  // console.log("networkId-->", networkId);

  if (networkId === Networks.BSCMainnet) {
    // console.log("inside network smart chain test network ");
    // debugger;

    const web3_bsc = new Web3(provider_obj);
    // debugger;

    const beach_bsc = new web3_bsc.eth.Contract(
      JSON.parse(ContractABI.BeachBSCMainnet),
      TokenAddress.BeachBSCMainnet
    );
    // debugger;

    await beach_bsc.methods
      .approve(TokenAddress.MainnetCoreCustodyBSC, amount)
      .send({ from: address })
      .on("error", function (confirmationNumber, receipt) {
        // console.warn("confirmed event ");
        // debugger;

        return false;
      })
      .on("receipt", function (confirmationNumber, receipt) {
        // console.warn("receip event ");
        // debugger;

        return true;
      });
    // debugger;

    return true;
  } else {
    window.alert("this network is not supported");
    return false;
  }
};


