import React,{useEffect} from 'react'
import Styles from "./SignupStyles.module.css"
import { Link, useParams } from 'react-router-dom'

const ActivationWithLink = () => {
    const {uid} = useParams()

    const [success,setSuccess] = React.useState(false)
    const [isExpired,setIsExpired] = React.useState(false)

    useEffect(()=>{
       async function verify(){
         let response = await fetch('https://api.beachcollective.io/user/user-link-verification/',{
            method:'POST',
            body:JSON.stringify({'key':uid}),
            headers:{
                'Content-Type': 'application/json',
            }
        })
        if(response.ok){
            setSuccess(true)
        }else{
            setIsExpired(true)
        }
    }
    verify()
    },[uid])

  return (
    <div className={Styles.background}>
    <div className="logo d-flex justify-content-center mb-3 px-3 mt-5">
    <Link to="/">
      <img
        src="/images/logo-small-device.svg"
        className="d-block img-fluid"
        alt=""
      />
    </Link>
  </div>
    <div className='content pt-5 d-flex flex-column align-items-center'>

        {
            isExpired ?
            <h3 className='text-blue text-center'>Activation link is Expired</h3>
            :

            !success ?
            <>
                <h3 className='text-blue text-center'>Your Email Address is getting Verified</h3>
            </>
            :
            <>
            <h3 className='text-blue text-center'>Thank you! Your Email Address is verified and your account is activated!</h3>
            <h4 className='my-4 text-center'>Please login To BeachCollective and get started! </h4>
            <Link to={'/'}>
            <button className='btn btn-creme text-white' >Go To Home</button>
            </Link> 
            </>
        }

    </div>

    </div>
  )
}

export default ActivationWithLink