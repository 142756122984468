import React from 'react'
import Modal from "react-bootstrap/Modal";
import { HiArrowLeft } from 'react-icons/hi';
// import {IoCloseSharp} from 'react-icons/io5'
import {VscClose} from 'react-icons/vsc'


const CustomPopup = ({show,onClose,children,title,tag,type,goBack,onBack,isSmall}) => {
  return (
      <Modal size={isSmall ? 'sm' : 'lg'} show={show} onHide={onClose} className={`newModal ${type === 'welcome' ? 'imageTop' : 'withImage' } `} >
          <Modal.Header>
            <div className={` flex-grow-1 mt-4 mt-md-0`}>
            {
              goBack && 
              
                <button className={`modal-close border-0 btn-outline-blue btn btn-transparent`} style={{left:'10px',right:'unset'}} onClick={onBack} >
                   <HiArrowLeft/>
                </button>
               
            }
              
            <h4 className='text-center text-blue'>{title}</h4>
            {tag && <p className='text-center'>{tag}</p>}
            </div>
            <button
              className="modal-close border-0 btn-outline-blue btn btn-transparent p-0 me-md-3"
              onClick={() => onClose()}
            >
            <VscClose  size={30} fontFamily='qanelas_softregular' color={type === 'welcome' ? '#fff' : '#007A97'}  />
            </button>
  
          </Modal.Header>
          <Modal.Body>
            {children}
          </Modal.Body>

      </Modal>
  )
}

export default CustomPopup