import React, { useContext, useRef, useCallback } from 'react'
import { Link } from 'react-router-dom';
import CustomPopup from './Pop/CustomPopup';
import GlobalContext from "../Contexts/GlobalContext";
import { TwitterShareButton, LinkedinShareButton, FacebookShareButton } from "react-share";
import { useState } from 'react';
import styles from "./BeachPay/BeachPayStyles.module.css";
import * as htmlToImage from 'html-to-image';

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
  bstr = window.atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
  while(n--){
  u8arr[n] = bstr.charCodeAt(n);
  }
return new File([u8arr], filename, {type:mime});
}

const ReferencePopup = ({ show, hide }) => {

  const { user } = useContext(GlobalContext)

  const inputRefer = useRef(null)

  const[showQr,setShowQr] = useState(false)

  const referLink = window.location.origin.toString()+'/sign-up/'+user.slug;


  const handleCopy = () =>{
    inputRefer.current.select()
    navigator.clipboard.writeText(inputRefer.current.value);

  }

  const downloadMyQrcode = async () => {

    htmlToImage.toPng(document.getElementById('self_Qrcode'), { quality: 0.95, canvasWidth: 400, canvasHeight: 400 })
      .then(function (dataUrl) {
        var link = document.createElement('a');
        if (user.name) {
          link.download = user.name + "'s Referral Qrcode.png";
        }
        link.href = dataUrl;
        link.click();
      }).catch(er => {
        console.log('something went wrong', er)
      });

    }

    const handleClose = () =>{
      hide(false)
      setShowQr(false)
    }
    
    const handleImageShare = useCallback(async () =>{

      let files = []
  
        if (!navigator.canShare) {
          console.log(`Your browser doesn't support the Web Share API.`);
          return;
        }
  
        htmlToImage.toPng(document.getElementById('self_Qrcode'), { quality: 0.95, canvasWidth: 400, canvasHeight: 400 })
        .then(async function (dataUrl) {
          console.log(dataUrl)
          var fileData = dataURLtoFile(dataUrl, user.name + "'s Referral Qrcode.png");
          files.push(fileData)
          if (navigator.canShare({ files })) {
            try {
              await navigator.share({
                files
              });
            } catch (error) {
              console.log(`Error: ${error.message}`)
            }
          } else {
            console.log(`Your system doesn't support sharing these files.`);
          }
        })
      
        
    },[user])




  return (
    <CustomPopup show={show} onClose={handleClose} type={'welcome'} >

   { !showQr ?
      (<React.Fragment>
      <img src='/images/reference-bg.png' className='w-100 welcomePopupImage' />

      {!user?.name ?
        <div className='text-center my-4'>
          <h5 className='text-blue text-center mb-4'>Please update your username</h5>
          <div className='text-center'>
            <Link to={'/profileEdit'} className='btn btn-lg btn-creme  text-white px-3'>
              Go to profile page
            </Link>
          </div>
        </div>
        :

        <div className='text-center mb-4'>
          <div className='text-uppercase text-white welcomePopup-heading'>
            <h3 className='mt-3'>
              Invite Others
            </h3>
          </div>

          <div className='row justify-content-center'>
            <div className='col-md-8 col-10'>
              <h4 className='text-dark fs-4'>
                Invite others to join the Beach Collective using your unique referral link
              </h4>
              <div className='signupLink my-4' style={{maxWidth:'400px'}}>
                        <input ref={inputRefer} readOnly={true} value={referLink} />
                        <button className='linkCopyBtn'>
                            <img src='/images/copyIcon.png' alt='copy' onClick={handleCopy} />
                        </button>
              </div>
              <div className='text-center my-4'>
              <button className='btn btn-transparent text-uppercase text-dark' onClick={()=>setShowQr(true)}>
                OR Show your  Qr code
              </button>
            </div>
              <div className='mb-4'>
              <LinkedinShareButton className='shareBtn'
              url={referLink}
              >
                  <img src='/images/LinkedinSharelogoSquare.png' height={41} alt='linkedin' />
              </LinkedinShareButton>
              <FacebookShareButton url={referLink}
              className='shareBtn'>
                  <img src='/images/facebookSharelogo.png'  height={41} alt='facebook' />
              </FacebookShareButton>

              <TwitterShareButton className='shareBtn'
                  url={referLink}               
              >
                  <img src='/images/twitterSharelogo.png' height={41} alt='twitter' />

              </TwitterShareButton>
              
              </div>
            </div>
          </div>
          
        </div>
      }
      </React.Fragment>)
      :
      ( <div className='mx-auto my-5' style={{maxWidth:'400px'}}>
      <div className={`${styles.qrCodeBox} px-0`} id='self_Qrcode' style={{borderRadius:'30px'}}>
      <h4 className={`${styles.qrCodeBoxHeading}`}>Beach<span>Collective</span></h4>
      <img className={`${styles.userQrcode}`} src={user?.refferal_qr_code} />
      <p className={`${styles.userNameAt}`}>@{user.name}</p>
    </div>
    <div className={styles.qrBoxBtnsWrapper}>
      <button className={`${styles.qrBoxBtn} ${styles.qrBoxDwnBtn}`} onClick={downloadMyQrcode}>
        <span>Download</span>
        <span className={styles.qrBoxBtnIcon}>
          <img className="img-fluid" src="/images/beachpay_download_icon.png" />
        </span>
      </button>
      <button className={`${styles.qrBoxBtn} ${styles.qrBoxSndBtn}`} onClick={handleImageShare}>
        <span>Send</span>
        <span className={styles.qrBoxBtnIcon}>
          <img className="img-fluid" src="/images/beachpay_send_icon.png" />
        </span>
      </button>
    </div>
      </div>)
    }
    </CustomPopup>
  )
}

export default ReferencePopup

