// import * as ABI from "./ContractAbi";
import BeachTokenAbi from "./ContractAbi/BeachToken";
import BeachBSCTokenAbi from "./ContractAbi/BeachTokenBsc";
import TestCoreCustodyAbi from "./ContractAbi/TestCoreCustodyErc";
import TestCoreCustodyBscAbi from "./ContractAbi/TestCoreCustodyBsc";
import TestBeachTokenAbi from "./ContractAbi/TestBeachToken";
import TestBeachBSCTokenAbi from "./ContractAbi/TestBeachTokenBsc";
import beachByteCode from "./ContractAbi/ByteCode/TestBeachBsc";
import CCCAbi from "./ContractAbi/CoreCentralContract";
import { TOKEN_ABI } from "./ContractAbi/TokenAbi";
import CfrscAbi from "./ContractAbi/Cfrsc";
import BCCAbi from "./ContractAbi/BCCarbonCredit";

const base_url = process.env.PUBLIC_URL;

export default base_url;

export const api_url = "https://api.beachcollective.io";

// supported networks

export const infuraID = "3b8dadfe5b7f48288c222d99db889691";

export const Networks = {
  Mainnet: 1,
  Ropsten: 3,
  Rinkeby: 4,
  Goerli: 5,
  Kovan: 42,
  BSCMainnet: 56,
  BSCTestnet: 56,
};

export const Providers = {
  RopstenInfura:
    "https://ropsten.infura.io/v3/6ccd9a721a844a779eea17482a62cd9e",
  MainnetInfura:
    "https://mainnet.infura.io/v3/6ccd9a721a844a779eea17482a62cd9e",
  BSCMainnet: "https://bsc-dataseed.binance.org/",
  // BSCTestnet: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  BSCTestnet: "https://bsc-dataseed.binance.org/",
};

// export const TokenAddress = {
//   BeachMainnet: "0xbd15c4c8cd28a08e43846e3155c01a1f648d8d42",
//   BeachRopsten: "0x2800d8bd24315dd1e9af5c903ec589a1e5136412",
//   BeachBSCMainnet: "0x1a57dc4e3bc63b06c2b263774859f227b99ab031",
//   BeachBSCTestnet: "0xb161faa53B6815DaDE188Ed58f931CEC1C3E7355",
//   TestCoreCustodyERC: "0x02a8fbc62157b27b6c85d2dfb24497d07c7fed76",
//   TestCoreCustodyBSC: "0x37660c11b9a02c47c30a355b84dbcbccc691e766",
// };
export const TokenAddress = {
  BeachMainnet: "0xbd15c4c8cd28a08e43846e3155c01a1f648d8d42",
  BeachRopsten: "0x2800d8bd24315dd1e9af5c903ec589a1e5136412",
  // BeachBSCMainnet: "0x1a57dc4e3bc63b06c2b263774859f227b99ab031",
  BeachBSCMainnet: "0x4f4543Fa5F7FE28a7758ef43FeA71d11fCCDAD5c",
  BeachBSCTestnet: "0x4f4543Fa5F7FE28a7758ef43FeA71d11fCCDAD5c",
  // BeachBSCTestnet: "0x664950615EC01daf22F9fB76a900C6Dd2F8698Ac",
  // TestCoreCustodyERC: "0x02a8fbc62157b27b6c85d2dfb24497d07c7fed76",
  TestCoreCustodyERC: "0x8Be7Ef22271C99C9969A6fc836CacffC8f24E331",
  // TestCoreCustodyBSC: "0x37660c11b9a02c47c30a355b84dbcbccc691e766",
  // TestCoreCustodyBSC: "0xC3b94B8CE2A66feA23823a25657a3218907d622F",
  // TestCoreCustodyBSC: "0x4A3C3650F17E2C3DD7321f7C1fA8d6290182913D",
  TestCoreCustodyBSC: "0x53529871C53cA8FE6EdE11CD7474B495C91398aB",
  // MainnetCoreCustodyBSC: "0x6bD13d62FA2C8b1d2B655C446f064c2E43065e24",
  // MainnetCoreCustodyBSC: "0x53529871C53cA8FE6EdE11CD7474B495C91398aB",
  MainnetCoreCustodyBSC: "0xfdaB8Cd8332760B248B93f608F98E8452f043438",

  Cfrsc : "0xa958F64eDe3C9979BC0377F61C78178EA7BBd55e",
  Ccc : "0xfdaB8Cd8332760B248B93f608F98E8452f043438",
  BCCarbonCredit : '0xf023006f10Dee761564f1Dd52A53786CB95FF334',
};

// old one
// bscnettest - 0xcac8e66e16780031958900549fcfa83c0a63d247;
// TestCoreCustodyERC: "0x548238112b3be4a9d0996864b201333262cc87db","0x8B2bD3De73ad1360dE7FdCF93ae0aEc0553d8711","0x7B558f2603843A7e1dA41f76356a28770DabADBd ","0xBe756bb1B3545D17C3317601B1629c90C2BA6de3"
// TestCoreCustodyBSC: "0x4cb2ada880bd64a3f8a17aaa32a41e69701ed47f","0x5b44fc4af39b9ae338a553ea80b5e69dd032e0bf",

// old ropsten beach
// "0xC6B11aBd15508313d0fc25003068fd0C6F100653"

export const ContractABI = {
  BeachMainnet: BeachTokenAbi,
  BeachRopsten: TestBeachTokenAbi,
  BeachBSCMainnet: BeachBSCTokenAbi,
  BeachBSCTestnet: TestBeachBSCTokenAbi,
  TestCoreCustodyERC: TestCoreCustodyAbi,
  TestCoreCustodyBSC: TestCoreCustodyBscAbi,
  MainnetCoreCustodyBSC:CCCAbi,
  BEP20ABI : TOKEN_ABI,
  Cfrsc : CfrscAbi,
  Ccc :CCCAbi,
  BCCarbonCredit : BCCAbi
};

export const ContractByteCode = {
  BeachBSCTestnet: beachByteCode,
};
