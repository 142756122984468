import React from 'react'

const Tokenomics = () => {
    return (
        <div className="beachPay-middle  position-ralative">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center tocup position-relative zIndex-2">
                        <h2 className="mb-4">Beach Token ($BEACH) is an innovative currency which funds Clean Oceans and Blue Carbon initiatives with every transaction</h2>
                        <img className="img-fluid" src={`${process.env.PUBLIC_URL}/images/beach-token-dark.svg`} height={100} alt="banner" />
                    </div>
                </div>
                <div className="row position-relative zIndex-2">
                    <div className="mt-5">
                        <div className="row align-items-center ">
                            <div class="section-head text-center">
                                <h2 class="text-dark">Tokenomics</h2>
                            </div>
                            <div className="col-lg-8 offset-lg-2 mt-4">
                                <div className="row g-3 mb-3">
                                    <div className="col-md-4 col-6">
                                        <div className="toko-block h-100 shadow bg-white rounded p-2 p-md-3 mb-4 py-md-4  text-center">
                                            <img src={`${process.env.PUBLIC_URL}/images/ico-oceanfund.svg`} width={80} alt="" />
                                            <h5 class="my-3 fw-bold">Clean Ocean Fund: 1</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-6">
                                        <div className="toko-block h-100 shadow bg-white rounded p-2 p-md-3 mb-4 py-md-4 text-center">
                                            <img src={`${process.env.PUBLIC_URL}/images/ico-carbon.svg`} width={80} alt="" />
                                            <h5 class="my-3 fw-bold">Blue Carbon Fund: 1</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-6">
                                        <div className="toko-block h-100 shadow bg-white rounded p-2 p-md-3 mb-4 py-md-4 text-center">
                                            <img src={`${process.env.PUBLIC_URL}/images/ico-burn.svg`} width={80} alt="" />
                                            <h5 class="my-3 fw-bold">True Burn: 3</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-6">
                                        <div className="toko-block h-100 shadow bg-white rounded p-2 p-md-3 py-md-4 text-center">
                                            <img src={`${process.env.PUBLIC_URL}/images/ico-reflection.svg`} width={80} alt="" />
                                            <h5 class="my-3 my-md-4 fw-bold">BEACH Reserve: 3</h5>
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-6">
                                        <div className="toko-block h-100 shadow bg-white rounded p-2 p-md-3 py-md-4 text-center">
                                            <img src={`${process.env.PUBLIC_URL}/images/ico-boost.svg`} width={80} alt="" />
                                            <h5 class="my-3 fw-bold pb-md-0">BNB Reserve: 3</h5>
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-6">
                                        <div className="toko-block h-100 shadow bg-white rounded p-2 p-md-3  py-md-4 text-center">
                                            <img src={`${process.env.PUBLIC_URL}/images/ico-boost.svg`} width={80} alt="" />
                                            <h5 class="my-3 fw-bold pb-md-0">DAI Reserve: 4 </h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h5 class="text-center py-3 boostText text-dark">BOOST FUND FIGURE IS INCLUSIVE OF 1% MANAGEMENT INCENTIVES and 0.5% DEVELOPMENT FEE</h5>
                        </div>
                        <div className="text-center">
                            <a href="https://pancakeswap.finance/swap?outputCurrency=0x4f4543Fa5F7FE28a7758ef43FeA71d11fCCDAD5c&inputCurrency=BNB" target="_blank" class="btn blue-btn py-2 px-4 my-4 btn-lg rounded-pill text-white">BUY BEACH</a>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Tokenomics