import React from "react";
import CustomPopup from "./Pop/CustomPopup";

const ErrorAddress = ({show,close}) => {
 

  return (
    <CustomPopup show={show} onClose={()=>close(false)}  >
    <div className='row my-4 justify-content-center'>
        <div className='col-10 '>
            <p className='text-dark fs-5 text-center'>
            Sorry the connected wallet with your logged in account on BC  is different , please unlink your old wallet from Profile page and then try connecting new. OR Connect to your linked wallet address of logged in account            </p>
            {/* <p className='text-dark fs-5 text-center mt-3'>
           
            </p> */}
            
        </div>
    </div>
    
</CustomPopup>
  )
}

export default ErrorAddress