import { WalletLinkConnector } from "@web3-react/walletlink-connector";
// import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { InjectedConnector } from "@web3-react/injected-connector";
import { BscConnector } from "@binance-chain/bsc-connector";
// import { ethers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import _ from "lodash";

// old metamask config
export const injected = new InjectedConnector({
  supportedChainIds: [56],
});

// // walletConnect provider config
// export const walletconnectProvider = new WalletConnectConnector({
//   rpc: { 1: "https://mainnet.infura.io/v3/6ccd9a721a844a779eea17482a62cd9e",
//   56: "https://bsc-dataseed.binance.org/", },
//   bridge: "https://bridge.walletconnect.org",
//   qrcode: true,
//   pollingInterval: 10000,
// });

// wallet link provider config
export const walletlinkProvider = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/6ccd9a721a844a779eea17482a62cd9e`,
  appName: "beachcollective.io",
  supportedChainIds: [56],
});


// binance wallet provider config
export const binanceWallectProvider = new BscConnector({
  supportedChainIds: [56], 
});

// walletconnect config
export const provider = new WalletConnectProvider({
  rpc: { 56: "https://bsc-dataseed.binance.org/" },
  // rpcUrl :"https://bsc-dataseed.binance.org/",
  infuraId: "6ccd9a721a844a779eea17482a62cd9e",
});


// coinbase wallet config
export const coinbaseWallet = new CoinbaseWalletSDK({
  appName: "beachcollective.io",
  appLogoUrl: "",
  darkMode: false,
});

export const coinbaseWalletProvider = coinbaseWallet.makeWeb3Provider(
  "https://mainnet.infura.io/v3/6ccd9a721a844a779eea17482a62cd9e",
  1
);





export const connectors = {
  injected: injected,
  // walletConnectProvider: walletconnectProvider,
  coinbaseWalletProvider: walletlinkProvider,
  metamaskProvider: window.ethereum,
  binanceWalletProvider: window.BinanceChain,
  walletConnectProvider: provider,
  coinbaseProvider: coinbaseWalletProvider,
};


// return provider details 
export function getProvider(provider_name){

  // debugger;
  console.log('provider_name',provider_name)
  // debugger;
  if (provider_name ==='metamask'){
    // const provider = new ethers.providers.Web3Provider(connectors.injected);
    const provider = connectors.metamaskProvider
    console.log('inside metamask')
    return provider;

  }else if (provider_name ==='binanceWallet'){
    const provider = connectors.binanceWalletProvider;
    console.log('inside binance wallet ')
    return provider;
  }else if (provider_name ==='coinbaseWallet'){

    const provider = connectors.coinbaseProvider;
    console.log('inside coinbaseWallet')
    return provider;

  }else if (provider_name ==='walletConnect'){
    const provider =connectors.walletConnectProvider;
    console.log('inside walletConnect')
    return provider;


  }else{
    const provider =  connectors.metamaskProvider;
    console.log('inside metamask default')
    return provider;
  }


}


// deeplink redirection 
export const isBrowser = typeof window !== "undefined";
export const isNode = !isBrowser;
export const userAgent = isBrowser ? _.get(window, "navigator.userAgent") : "";
export const hasEthereum = isBrowser && _.has(window, "ethereum");
export const isAndroid = /(Android)/i.test(userAgent);
export const isIphone = /(iPhone|iPod)/i.test(userAgent);
export const isIpad = /(iPad)/i.test(userAgent);
export const isMobile = isIphone || isAndroid;
// A mobile browser with ethereum we assume it's Metamask Browser
export const isMetamask = isMobile && hasEthereum;
