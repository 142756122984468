import React, { useContext } from "react";
import { Modal } from "react-bootstrap";
import axiosInstance from "../../Axios/axios.config";
import GlobalContext from "../../Contexts/GlobalContext";


const ReportModel = ({
  show,
  setShow,
  clearReportPost,
  reportPost
}) => {
  const [reportSuccess, setReportSuccess] = React.useState(false);

  const {loggedIn} = useContext(GlobalContext)

  const handleReportPost = async () => {
    if(loggedIn){
      
      let Report = await axiosInstance.get(`/map/report-issue/${reportPost}`);
      if (Report.statusText === 'OK') {
        let data =  Report.data;
  
        setReportSuccess(data.flag);
  
        clearReportPost("");
  
  
        setTimeout(() => {
          setShow(false);
          setTimeout(() => {
            setReportSuccess(false);
          }, 1000);
        }, 3000);
      }
    }
  };

  return (
    <Modal show={show} className="GivePop">
      <Modal.Header className="d-flex justify-content-end">
        <button className="btn btn-transparent" onClick={() => setShow(false)}>
          <img src="/images/map-close.svg" height={"25"} alt="close" />
        </button>
      </Modal.Header>
      <Modal.Body className="text-center">
      {loggedIn ? ( <div className="content pb-3">
          {reportSuccess ? (
            <h3 className="mb-5">This post has been reported</h3>
          ) : (
            <>
              <h3 className="mb-5">
                Are you sure you want to report this post
              </h3>

              <button
                type="button"
                className="btn btn-creme py-2 px-3 text-white me-2 "
                onClick={handleReportPost}
              >
                Yes
              </button>

              <button
                type="button"
                className="btn btn-creme py-2 px-3 text-white ms-2"
                onClick={() => setShow(false)}
              >
                No
              </button>
            </>
          )}
        </div>)
      :
      <div className="content pb-3">
      <h3 className="mb-5">Please login to report</h3>
      </div>
      }

      </Modal.Body>
    </Modal>
  );
};

export default ReportModel;
