import React from "react";
import {IoCloseSharp} from 'react-icons/io5'

export default function UpComing(props) {
  return (
    <>
      <div
        className="modal fade newModal"
        id={props.div_id}
        data-bs-dismiss="modal"
      >
        <div className="modal-dialog modal-lg modal-main"  >
          <div className="modal-content">
          <div className="coming-soon-popup">
          <div className=" popup-inside">
              <button
              className="modal-close border-0 btn-outline-blue btn btn-transparent p-0 me-md-3" style={{top:'15px'}}
              data-bs-dismiss="modal"
            >
            <IoCloseSharp />
            </button>
              
              <h1 style={{color: '#EFCCA4'}}>BEACH<span className="text-blue">{props.title}</span> </h1>
                
              <div className="cs-content">
                  <h2>Coming soon</h2>
                  <button className="mt-3" type="button">SIGN UP</button>
              </div>
              <div className="cs-bottom">
                  BEACHTOKEN.IO
              </div>
          </div>
      </div>
          </div>
        </div>
      </div>
    </>
  );
}
