import React,{ useContext } from 'react'
import GlobalContext from '../../Contexts/GlobalContext';
import CustomPopup from "../Pop/CustomPopup";

const PayPopup = ({show,close,signup,signin}) => {

  const {loggedIn} = useContext(GlobalContext)

  const openSignup = () =>{
    close(false)
    signup(true)
}
const openSignin = () =>{
    close(false)
    signin(true)
}


  return (

    
    <CustomPopup show={show} onClose={()=>close(false)}  type='welcome'>
   
    <img src='/images/welcome-pay-bg.webp' className='w-100 welcomePopupImage' />

        <div className='text-center mb-4'>
        <div className='text-uppercase text-white welcomePopup-heading'>
              <h3 className='mt-3'>
                    Beach Pay
              </h3>
            </div>
        <div className='row justify-content-center'>
        <div className='col-10'>
        <p className='mb-5 text-dark'>
        On Beach Pay you can easily make or request instant payments using $BEACH.
         Whether paying a vendor at your local farmers market, paying for a meal 
         at a sea side restaurant or sending money to family overseas, Beach Pay 
         helps you make or request payments while helping the planet via a flat 
         1.5% fee on each transaction. To get started select “Pay Me” to request 
         a payment or “Pay Someone” to make a payment.
        </p>
      </div>
        </div>
         
         {
          !loggedIn && <div className='row justify-content-center g-5 mb-5' >
              <div className='col-5 col-md-3 d-flex justify-content-center'>
              <button
              className={`btn text-white btn-blue w-100 popupBtn`} 
              type="button"
              onClick={openSignin}
              
            >
              Sign in
            </button>
                
              </div>
              <div className='col-5 col-md-3 d-flex justify-content-center'>
                <button
              className={`btn text-white  blue-btn w-100 popupBtn`} 
              type="button"
              
              onClick={openSignup}
            >
              Sign up
            </button>
              </div>
           
         </div>
        }
        </div> 
</CustomPopup>
  )
}

export default PayPopup