import React from 'react'
import { Link } from 'react-router-dom'

const NotFound = () => {

  return (
    <div className='notFoundWrapper'>
        <div className='text-center'>
            <h2 className='text-blue mb-4'>404 <br/> Not found</h2>
            <Link to={'/'} className='btn backToHomeBtn'>Back to home</Link>
        </div>
    </div>
  )
}

export default NotFound