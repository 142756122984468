import axios from 'axios'
import React from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import UpComing from '../Components/index/UpComing'
import Base from '../Layouts/Base'

const Disclouser = () => {

    const [email,setEmail] = useState('')

    const handleUpdateSubmit = async (e) =>{
        e.preventDefault()
        let payload = JSON.stringify({'email':email})
        let res = await axios.post(`/user/update-sign-up-user/`,payload,
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        );
        if(res.statusText === 'OK'){
            setEmail('')
        }
    }

    return (
        <Base>
            <div className='container termsWrapper my-3'>
                <h3 className='text-center mb-4'>Cryptocurrency Risk Disclosure</h3>
                
                    <p>
                        Cryptocurrency is a digital representation of value that functions as a medium of exchange,
                        a unit of account, or a store of value, but it does not have legal tender status.
                        Cryptocurrencies are sometimes exchanged for U.S. dollars or other currencies around
                        the world, but they are not generally backed or supported by any government or central bank.
                        Their value is completely derived by market forces of supply and demand, and they are more
                        volatile than traditional currencies. The value of cryptocurrency may be derived from the
                        continued willingness of market participants to exchange fiat currency for cryptocurrency,
                        which may result in the potential for permanent and total loss of value of a particular
                        cryptocurrency should the market for that cryptocurrency disappear. Cryptocurrencies are
                        not covered by either FDIC or SIPC insurance. Legislative and regulatory changes or actions at
                        the state, federal, or international level may adversely affect the use, transfer, exchange, and
                        value of cryptocurrency.
                    </p>

                    <p>Purchasing cryptocurrencies comes with a
                        number of risks, including volatile market price swings or flash crashes,
                        fraud, market manipulation, and cybersecurity risks. In addition, cryptocurrency
                        markets and exchanges are not regulated with the same controls or customer protections
                        available in equity, option, futures, or foreign exchange investing. There is no
                        assurance that a person who accepts a cryptocurrency as payment today will continue
                        to do so in the future.</p>
                    <p>
                        Investors should conduct extensive research into the legitimacy of each individual cryptocurrency,
                        including its platform, before investing. The features, functions, characteristics, operation,
                        use and other properties of the specific cryptocurrency may be complex, technical, or difficult
                        to understand or evaluate. The cryptocurrency may be vulnerable to attacks on the security,
                        integrity or operation, including attacks using computing power sufficient to overwhelm the
                        normal operation of the cryptocurrency's blockchain or other underlying technology. Some
                        cryptocurrency transactions will be deemed to be made when recorded on a public ledger, which
                        is not necessarily the date or time that a transaction may have been initiated.</p>
                    <p>
                        Cryptocurrency trading requires knowledge of cryptocurrency markets. In attempting to profit
                        through cryptocurrency trading you must compete with traders worldwide. You should have
                        appropriate knowledge and experience before engaging in substantial cryptocurrency trading.<br />
                        Any individual cryptocurrency may change or otherwise cease to operate as expected due to changes
                        made to its underlying technology, changes made using its underlying technology, or changes
                        resulting from an attack. These changes may include, without limitation, a "fork," a "rollback,"
                        an "airdrop," or a "bootstrap." Such changes may dilute the value of an existing cryptocurrency
                        position and/or distribute the value of an existing cryptocurrency position to another cryptocurrency.
                        Beach Collective retains the right to support or not support any of these changes. Any cryptocurrency may be
                        cancelled, lost or double spent, or otherwise lose all or most of their value, due to forks, rollbacks,
                        attacks, or failures to operate as intended. The nature of cryptocurrency means that any technological
                        difficulties experienced by Beach Collective may prevent the access of your cryptocurrency.
                    </p>

                    <p>
                        Cryptocurrency trading can be extremely risky. Cryptocurrency trading may not generally be appropriate, 
                        particularly with funds drawn from retirement savings, student loans, mortgages, emergency funds, or 
                        funds set aside for other purposes. Cryptocurrency trading can lead to large and immediate financial 
                        losses. The volatility and unpredictability of the price of cryptocurrency relative to fiat currency 
                        may result in significant loss over a short period of time. Transactions in cryptocurrency may be 
                        irreversible, and, accordingly, losses due to fraudulent or accidental transactions may not be recoverable.<br />
                    </p>
                    <p>
                    Under certain market conditions, you may find it difficult or impossible to
                     liquidate a position quickly at a reasonable price. This can occur, for example, when the market for a 
                     particular cryptocurrency suddenly drops, or if trading is halted due to recent news events, unusual 
                     trading activity, or changes in the underlying cryptocurrency system.
                     </p>
                    <p>
                    The greater the volatility of a particular cryptocurrency, the greater the likelihood that problems may be 
                    encountered in executing a transaction. In addition to normal market risks, you may experience losses due 
                    to one or more of the following: system failures, hardware failures, software failures, network connectivity
                     disruptions, and data corruption.
                     </p>
                    <p>
                    Several federal agencies have also published advisory documents surrounding the risks of virtual currency.
                     For more information see, the CFPB's Consumer Advisory, the CFTC's Customer Advisory, the SEC's Investor Alert, and FINRA's Investor Alert.</p>
                    <p>
                    Trading in Digital Tokens entails certain risks. This risk disclosure statement cannot and does not disclose
                     all risks and other aspects involved in holding, trading, or engaging in financing or financed transactions 
                     in Digital Tokens. Risks include, but are not limited to, the following:</p>
                    <p>
                    <em>1. Market Risk: </em>The market for Digital Tokens is still new and uncertain. No-one should have funds
                     invested in Digital Tokens or speculate in Digital Tokens that she is not prepared to lose entirely. Whether 
                     the market for one or more Digital Tokens will move up or down, or whether a particular Digital Token will 
                     lose all or substantially all of its value, is unknown. This applies both to traders that are going long and
                      to traders that are shorting the market. Participants should be cautious about holding Digital Tokens.</p>
                    <p>
                    <em>2. Liquidity and Listing Risk: Markets for Digital Tokens have varying degrees of liquidity.</em> Some
                     are quite liquid while others may be thinner. Thin markets can amplify volatility. There is never a guarantee 
                     that there will be an active market for one to sell, buy, or trade Digital Tokens or products derived from or
                      ancillary to them. Furthermore, any market for tokens may abruptly appear and vanish. Beach Collective makes no 
                      representations or warranties about whether a Digital Token that may be traded on exchanges may be traded
                       on exchanges any point in the future, if at all. Any Digital Token is subject to delisting without notice or consent.</p>
                    <p>
                    <em>3. Legal Risk: The legal status of certain Digital Tokens may be uncertain. </em>This can mean that 
                    the legality of holding or trading them is not always clear. Whether and how one or more Digital Tokens constitute
                     property, or assets, or rights of any kind may also seem unclear. Participants are responsible for knowing and
                      understanding how Digital Tokens will be addressed, regulated, and taxed under applicable law.</p>
                    <p>
                    <em>4. Exchange Risk (Counterparty Risk): Having Digital Tokens on deposit or with any third party in a 
                    custodial relationship has attendant risks.</em> These risks include security breaches, risk of contractual breach, and risk of loss.</p>
                    <p>
                    <em>5. Trading Risk: In addition to liquidity risks, values in any digital token marketplace are volatile
                     and can shift quickly. </em>Participants in any Digital Tokens market are warned that they should pay close 
                     attention to their position and holdings, and how they may be impacted by sudden and adverse shifts in trading and other market activities.</p>
                    <h4 className='mt-3'>Questions</h4>

                    <p>If you have any questions on the cryptocurrency risk disclosure, please contact us at:<br /><br />
                       <p>Beach Collective</p>
                        <a className='text-dark' href="mailto:hello@beachcollective.io?subject=Support%20request">hello@beachcollective.io</a><br />
                        <span class="text-span-5">LAST AMENDED DATE:&nbsp;Dec. 1, 2022</span></p>
            </div>
            <section className="footer-shape">
            <img src="/images/footer-top-shape.png" alt=""/>
            </section>
            <footer>
            <div className="container-fluid">
              <div className="footer-logo mb-3 text-center"><img src="/images/footer-logo.png" width={350} alt=""/></div>
              <div className="row flex-column-reverse flex-md-row">
                <div className="col-md-4 d-none d-md-block">
                  <div className="footer-nav">
                    <Link to="/action" >Action</Link>
                    <Link to="/pay">Pay</Link>
                    <a   data-bs-toggle="modal" href="#beach-shop">Shop</a>
                    <a   data-bs-toggle="modal" href="#beach-nomad">Nomad</a>
                    <a   data-bs-toggle="modal" href="#beach-farm">Farm</a>
                    <a   data-bs-toggle="modal" href="#beach-nft">NFT</a> 
                  
          </div>
                </div>
                <div className="col-md-8">
                  <div className="row justify-content-end">
                    <div className="col-md-4 col-lg-3">
                      <div className="widget widget_links text-center text-md-start clearfix my-4 my-md-0 my-xl-0">
                        <h4>CONTACT US</h4>
                        <a href="mailto:hello@beachtoken.io" target="_blank" rel="noreferrer" className="emailus">hello@beachtoken.io</a>
                      </div>
                      <div className="foot-signup mt-3 d-md-none ">
                          <h3>Sign up for updates</h3>
                          <form onSubmit={handleUpdateSubmit}>
                          <div className='signup_Input'>
                          <input type="text" value={email} onChange={(e)=>setEmail(e.target.value)} name="email" placeholder="Type email address here..."/>
                          <button type={'submit'} className="signup_Btn">SIGN UP</button>
                          </div>
                          </form>
                          
                        </div>
                    </div>
                    <div className="col-md-4 col-lg-3 mt-3 mt-md-0">
    
                      <div className="d-flex justify-content-between d-md-block align-items-center">
                      <div className="footer-nav d-md-none">
                      <Link to="/action" >Action</Link>
                      <Link to="/pay" >Pay</Link>
                      <a   data-bs-toggle="modal" href="#beach-shop">Shop</a>
                      <a   data-bs-toggle="modal" href="#beach-nomad">Nomad</a>
                      <a   data-bs-toggle="modal" href="#beach-farm">Farm</a>
                      <a   data-bs-toggle="modal" href="#beach-nft">NFT</a> 
                    
            </div>
                      <div className="widget widget_links clearfix">
                        <ul>
                          <li><Link to="/">Home</Link></li>
                          <li><a href="https://beachtoken.io/static/images/%24BEACH_White_Paper_May_2022.pdf" target="_blank" rel="noreferrer">White Paper</a></li>
                          <li><a href="https://pancakeswap.finance/swap?outputCurrency=0x4f4543Fa5F7FE28a7758ef43FeA71d11fCCDAD5c&inputCurrency=BNB" target="_blank" rel="noreferrer">Buy on Pancake Swap</a></li>
              {/*<li><a href="https://app.uniswap.org/#/swap?outputCurrency=0xbd15c4c8cd28a08e43846e3155c01a1f648d8d42&use=V2" target="_blank">Buy on Uniswap</a></li>*/}
                          <li><Link to="/">Blog</Link></li>
                          <li><a href="https://beachcollective.io/" target="_blank" rel="noreferrer">Beach Collective</a></li>
                          <li><Link to="/risk-disclosure" target="_blank" rel="noreferrer">Risk Disclosure</Link></li>
                        </ul>
                      </div>
    
                      </div>
    
                    </div>
                  </div>
    
                  <div className="row justify-content-end">
                    <div className="col-md-7 col-xl-6 d-none d-md-block">
                        <div className="foot-signup py-4">
                          <h3>Sign up for updates</h3>
                          <form onSubmit={handleUpdateSubmit} >
                            <div style={{position:'relative',background:'#fff',borderRadius:'5px'}}>
                            <input type="text" value={email} onChange={(e)=>setEmail(e.target.value)} style={{width:'80%'}} name="email"/>
                            <button type={'submit'} className='btn btn-outline-blue' style={{position:'absolute',right:'10px',top:'50%',transform:'translateY(-50%)'}} >SIGN UP</button>
                            </div>
                          </form>
                        </div>
                    </div>
                    <div className="col-md-5 col-lg-4 col-xl-3 mt-3 mt-md-0">
                        <div className="widget clearfix">
                          <div className="footer-social  mt-3">
                            <div className="row m-0">
                              <div className="col-3 mb-2 responsive-0 d-flex justify-content-center align-items-center"> 
                                  <a class="responsive-icon" href="https://t.me/BeachToken" target="_blank" rel="noreferrer">
                                    <img className="img-fluid" src="/images/ic-telegram.png" alt=""/></a>
                              </div>
                              <div className="col-3 mb-2 responsive-0 d-flex justify-content-center align-items-center">
                                <a className="responsive-icon" href="https://twitter.com/beach_token" target="_blank" rel="noreferrer">
                                  <img className="img-fluid" src="/images/ic-twitter2.png" alt=""/></a>
                              </div>
                              <div className="col-3 responsive-0  mb-2 d-flex justify-content-center align-items-center"> 
                                <a className="responsive-icon " href="https://m.facebook.com/beachtoken" target="_blank" rel="noreferrer"><img class="img-fluid" src="/images/ic-fb-f.png" alt=""/></a>
                              </div>
                              <div className="col-3 responsive-0 mb-2  d-flex justify-content-center align-items-center"> 
                                    <a className="responsive-icon" href="https://discord.gg/beachtoken" target="_blank" rel="noreferrer"><img className="img-fluid" src="/images/ic-discord.png" alt=""/></a>
                              </div>
                              <div className="col-3 mb-2 responsive-0  d-flex justify-content-center align-items-center">
                                <a className="responsive-icon" href="https://www.instagram.com/beach_token/" target="_blank" rel="noreferrer">
                                  <img className="img-fluid" src="/images/ico-instagram.svg" alt=""/></a>
                              </div>
                              
                              
                              <div className="col-3 mb-2 responsive-0  d-flex justify-content-center align-items-center"> 
                                  <a className="responsive-icon" href="https://www.youtube.com/channel/UCWe9w4b1asXIAxHatN_FFhw" target="_blank" rel="noreferrer"><img className="img-fluid" src="/images/ic-YouTube2.png" alt=""/></a>
                              </div>
                              <div className="col-3 mb-2 responsive-0  d-flex justify-content-center align-items-center"> 
                                <a className="responsive-icon" href="https://www.linkedin.com/company/80447914" target="_blank" rel="noreferrer"><img className="img-fluid" src="/images/ic-linkedin2.png" alt=""/></a>
                              </div>
                              <div className="col-3 mb-2 responsive-0  d-flex justify-content-center align-items-center"> 
                                <a className="responsive-icon" href="https://www.tiktok.com/@beach_token?lang=en" target="_blank" rel="noreferrer"><img className="img-fluid" height="30" src="/images/ic-TikTok.png" alt=""/></a>
                            </div>
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
    
                </div>
              </div>
            </div>
          </footer>
          <UpComing div_id="beach-shop" title="SHOP" />
      <UpComing div_id="beach-nomad" title="NOMAD" />
      <UpComing div_id="beach-swap" title="SWAP" />
      <UpComing div_id="beach-verse" title="VERSE" />
      <UpComing div_id="beach-farm" title="FARM" />
      <UpComing div_id="beach-nomad" title="NOMAD" />
        </Base>
    )
}

export default Disclouser