import React, { useEffect, useState } from 'react'
import Base from '../Layouts/Base'
import styles from '../Styles/Balance.module.css'
import {
    CircularProgressbar,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Order from '../Components/shop/Order';
import axiosInstance from '../Axios/axios.config';
import { useContext } from 'react';
import GlobalContext from '../Contexts/GlobalContext';

const staticStyles = {
    textColor: "#fff",
    fontSize: '2rem',
    pathColor: "#fff",
    strokeLinecap: "butt",
    trailColor: "transparent"
}

const initial = {
    name : '',
    carbon_type: '',
    project_area : {
        LOCATION : ''
    },
    project_area_total_meters : null,
    carbon_density_square_meters_per_tonne : null,
    no_of_tonnes : null,
    available_tonnes : null,
    additionality_score : 0,
    biodiversity_score : 0, 
    community_score : 0,
    additionality_q1_10_years : null,
    additionality_q1_20_years : null,
    additionality_q1_30_years : null,
    biodiversity_q1 : null,
    biodiversity_q2 : null,
    biodiversity_q3 : null,
    biodiversity_q4 : null,
    biodiversity_q5 : null,
    biodiversity_q6 : null,
    biodiversity_q7 : false,
    biodiversity_q8 : null,
    community_q1_1 : null,
    community_q1_2 : null,
    community_q1_3 : null,
    community_q1_4 : null,
    community_q2_1 : null,
    community_q2_2 : null,
    community_q2_3 : null,
    community_q2_4 : null,
    community_q2_5 : null,
    community_q2_6 : null,
    community_q2_7 : null,
    community_q2_8 : null,
    community_q2_9 : null,
    community_q2_10 : null,
    community_q3 : null
}


const CarbonDetails = () => {

    const {id} = useParams()

    const { loggedIn } = useContext(GlobalContext)

    const[details,setDetails] = useState(initial)

    const[purchases,setPurchases] = useState([])

    useEffect(()=>{
        async function getDetails(){
            const res = await axiosInstance.get(`shop/enrollment/user-carbon-project-detail-view/${id}`)
            const data = res.data.data
            const carbons = res.data.carbon_purachases
            setDetails(()=>({...data}))
            setPurchases(()=>[...carbons])
        }
        if(loggedIn){
            getDetails()
        }
    },[loggedIn])

    return (
        <Base>
            <div className={`${styles.tabContent} ${styles.bgDarkBlue} p-0`}>
                <div className={styles.accountWrapper}>
                    <div className='bg-light-blue py-2'>
                        <h4 className='text-center'>Carbon Details</h4>
                    </div>
                    <div className={styles.container}>
                        <div className='my-4'>
                            <div className={`${styles.bottomContentBox} mb-4`}>
                                <h4 className='mb-3'>Project Details</h4>
                                <div className='row flex-column-reverse flex-md-row'>
                                    <div className='col-md-8'>
                                        <p className='font-regular mb-2'>Name : <strong>{details.name}</strong></p>
                                        <p className='font-regular mb-2'>Project Type : <strong>{details.carbon_type}</strong></p>
                                        <p className='font-regular mb-2'>Location : <strong>{details.project_area.LOCATION}</strong></p>
                                        <p className='font-regular mb-2'>Project Area : <strong>{details.project_area_total_meters} sqm</strong></p>
                                        <p className='font-regular mb-2'>Density : <strong>{details.carbon_density_square_meters_per_tonne}</strong></p>
                                        <p className='font-regular mb-2'>No of tonnes : <strong>{details.no_of_tonnes}</strong></p>
                                        <p className='font-regular mb-2'>Available tonnes : <strong>{details.available_tonnes}</strong></p>
                                    </div>
                                    <div className='col-md-4 mb-3 mb-md-0'>
                                       <div className='h-100'>
                                        <img className='h-100 w-100' style={{borderRadius:'30px'}} src='/images/carbon-img-1.png' alt='igg' />
                                       </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.bottomContentBox} mb-4`}>
                                <h4 className='mb-3'>Additionality</h4>
                                <div className='row flex-column-reverse flex-md-row'>
                                    <div className='col-md-8'>
                                        <p className='mb-2'>In the absence of this project, what percentage of deforestation or degradation would you reasonably expect (with a degree of certainty greater than 50%) will occur in the project area over the next</p>
                                        <div className='row mb-2'>
                                            <div className='col-10'>
                                                <p className='font-regular'>- 10 years</p>
                                            </div>
                                            <div className='col-2 text-end'>
                                                <p>{details.additionality_q1_10_years}</p>
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-10'>
                                                <p className='font-regular'>- 20 years</p>
                                            </div>
                                            <div className='col-2 text-end'>
                                                <p>{details.additionality_q1_20_years}</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-10'>
                                                <p className='font-regular'>- 30 years</p>
                                            </div>
                                            <div className='col-2 text-end'>
                                                <p>{details.additionality_q1_30_years}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-4 mb-3 mb-md-0'>
                                        <div className='mx-auto' style={{ height: '150px', width: '150px' }}>
                                            <CircularProgressbar
                                                value={details.additionality_score}
                                                text={`${details.additionality_score}`}
                                                background
                                                counterClockwise
                                                backgroundPadding={6}
                                                styles={buildStyles({
                                                    ...staticStyles,
                                                    backgroundColor: "#5BC46B"
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.bottomContentBox} mb-4`}>
                                <h4 className='mb-3'>Biodiversity</h4>
                                <div className='row flex-column-reverse flex-md-row'>
                                    <div className='col-md-8'>
                                        <div className='row mb-2'>
                                            <div className='col-10'>
                                                <p className='font-regular'>
                                                - How intact is the Project Area based on our methodology for intactness and Biodiversity
                                                </p>
                                            </div>
                                            <div className='col-2 text-end'>
                                                <p>{details.biodiversity_q1}</p>
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-10'>
                                                <p className='font-regular'>
                                                - How many native species of flora can be found in the wild within the Project Area
                                                </p>
                                            </div>
                                            <div className='col-2 text-end'>
                                                <p>{details.biodiversity_q2}</p>
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-10'>
                                                <p className='font-regular'>
                                                - What percentage of these are endemic species of flora?
                                                </p>
                                            </div>
                                            <div className='col-2 text-end'>
                                                <p>{details.biodiversity_q3}</p>
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-10'>
                                                <p className='font-regular'>
                                                - How many native species of fauna can be found in the wild within the Project Area?
                                                </p>
                                            </div>
                                            <div className='col-2 text-end'>
                                                <p>{details.biodiversity_q4}</p>
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-10'>
                                                <p className='font-regular'>
                                                - What percentage of these are endemic species of fauna?
                                                </p>
                                            </div>
                                            <div className='col-2 text-end'>
                                                <p>{details.biodiversity_q5}</p>
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-10'>
                                                <p className='font-regular'>
                                                - In the absence of this project, what percentage of biodiversity loss would you reasonably expect (with a degree of certainty greater than 50%) will occur in the project area over the next 30 years.
                                                </p>
                                            </div>
                                            <div className='col-2 text-end'>
                                                <p>{details.biodiversity_q6}</p>
                                            </div>
                                        </div>
                                        <div className='row mb-2'>
                                            <div className='col-10'>
                                                <p className='font-regular'>
                                                - Is all or part of the Project Area a recognised KBA?
                                                </p>
                                            </div>
                                            <div className='col-2 text-end'>
                                                <p className='text-capitalize'>{`${details.biodiversity_q7}`}</p>
                                            </div>
                                        </div>
                                        <div>
                                        <p className='my-3'>
                                        How this is consistent with our methodology as it relates to Biodiversity. Include references to KBAs, Biodiversity hotspots and Endemism where relevant.
                                        </p>
                                        <p className='font-regular'>{details.biodiversity_q8}</p>
                                        </div>
                                  
                                    </div>
                                    <div className='col-md-4 mb-3 mb-md-0'>
                                        <div className='mx-auto' style={{ height: '150px', width: '150px' }}>
                                            <CircularProgressbar
                                                value={details.biodiversity_score}
                                                text={`${details.biodiversity_score}`}
                                                background
                                                counterClockwise
                                                backgroundPadding={6}
                                                styles={buildStyles({
                                                    ...staticStyles,
                                                    backgroundColor: "#2CC9E6"
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.bottomContentBox} mb-4`}>
                                <h4 className='mb-3'>Community</h4>
                                <div className='row flex-column-reverse flex-md-row'>
                                    <div className='col-md-8'>
                                        <p className='mb-2'>Distributed between stakeholders</p>
                                        <div className='row'>
                                            <div className='col-10'>

                                                <p className='font-regular'>- Indigenous and
                                                    Community Stewards:</p>
                                            </div>
                                            <div className='col-2 text-end'>

                                                <p>{details.community_q2_1}%</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-10'>

                                                <p className='font-regular'>- Local and Regional
                                                    Government:</p>
                                            </div>
                                            <div className='col-2 text-end'>

                                                <p>{details.community_q2_2}%</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-10'>

                                                <p className='font-regular'>- National Government:</p>
                                            </div>
                                            <div className='col-2 text-end'>

                                                <p>{details.community_q2_3}%</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-10'>

                                                <p className='font-regular'>- Intermediaries:</p>
                                            </div>
                                            <div className='col-2 text-end'>

                                                <p>{details.community_q2_4}%</p>
                                            </div>
                                        </div>
                                        <p className='my-3'>How will proceeds be spent</p>
                                        <div className='row'>
                                            <div className='col-10'>

                                                <p className='font-regular'>- Community Livelihood:</p>
                                            </div>
                                            <div className='col-2 text-end'>

                                                <p>{details.community_q2_5}%</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-10'>

                                                <p className='font-regular'>- Education:</p>
                                            </div>
                                            <div className='col-2 text-end'>

                                                <p>{details.community_q2_6}%</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-10'>

                                                <p className='font-regular'>- Health:</p>
                                            </div>
                                            <div className='col-2 text-end'>
                                                <p>{details.community_q2_7}%</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-10'>

                                                <p className='font-regular'>- Employment:</p>
                                            </div>
                                            <div className='col-2 text-end'>

                                                <p>{details.community_q2_8}%</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-10'>

                                                <p className='font-regular'>- Sustainable
                                                    Development:</p>
                                            </div>
                                            <div className='col-2 text-end'>

                                                <p>{details.community_q2_9}%</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-10'>

                                                <p className='font-regular'>- Cultural Preservation:</p>
                                            </div>
                                            <div className='col-2 text-end'>

                                                <p>{details.community_q2_10}%</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-10'>
                                                <p className='font-regular text-capitalize'>- Future Generations/ Intergenerational Equity:</p>
                                            </div>
                                            <div className='col-2 text-end'>

                                                <p>{details.community_q2_10}%</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-10'>

                                                <p className='font-regular'>- Justice/Equality/
                                                    Diversity/Inclusion:</p>
                                            </div>
                                            <div className='col-2 text-end'>

                                                <p>{details.community_q2_10}%</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-10'>

                                                <p className='font-regular'>- Adaptation and
                                                    Resilience:</p>
                                            </div>
                                            <div className='col-2 text-end'>

                                                <p>{details.community_q2_10}%</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-10'>

                                                <p className='font-regular'>- Other:</p>
                                            </div>
                                            <div className='col-2 text-end'>

                                                <p>{details.community_q2_10}%</p>
                                            </div>
                                        </div>
                                        <p className='my-3'>How this is consistent with our methodology as it relates to Community</p>
                                        <p className='font-regular'>{details.community_q3}</p>
                                    </div>
                                    <div className='col-md-4 mb-3 mb-md-0'>
                                        <div className='mx-auto' style={{ height: '150px', width: '150px' }}>
                                            <CircularProgressbar
                                                value={details.community_score}
                                                text={`${details.community_score}`}
                                                background
                                                counterClockwise
                                                backgroundPadding={6}
                                                styles={buildStyles({
                                                    ...staticStyles,
                                                    backgroundColor: "#EFCCA4"
                                                })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            { purchases.length > 0 && <div>
                            <h4 className='mb-3'>Carbon Purchases</h4>
                                <div className='overflow-x'>
                                    <table className='carbon_purchase_table'>
                                    <thead>
                                        <tr className='bg-light-blue'>
                                        <th>Sr</th>
                                        <th>Username</th>
                                        <th className='text-center'>Token Id</th>
                                        <th className='text-center'>Status</th>
                                        <th className='text-center'>No.of Tonnes</th>
                                        <th>Created</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        purchases.map((order,i)=>(
                                            <tr key={order.id}>
                                                <td>{i+1}</td>
                                                <td className='text-capitalize'>{order.user.full_name}</td>
                                                <td className='text-center'>
                                                <a href={order.transaction_link} target='_blank' rel='noreferrer'>
                                                {order.nft_token_id}
                                                </a>
                                                </td>
                                                <td className='text-center'>
                                                <span className={`${order.status} badge text-capitalize`}>{order.status}</span>
                                                </td>
                                                <td className='text-center'>{order.no_of_tonnes}</td>
                                                <td>{order.created}</td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                    </table>
                                </div>
                               {/*
                                purchases.map( order =>)
                                            */} 
                            </div>}
                        </div>
                    </div>

                </div>
            </div>
        </Base>
    )
}

export default CarbonDetails