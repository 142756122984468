
export const  ADD_TO_CART_WALLET = 'ADD_TO_CART_WALLET'
export const  REMOVE_FROM_CART_WALLET = 'REMOVE_FROM_CART_WALLET'
export const  EMPTY_CART_WALLET = 'EMPTY_CART_WALLET'

export const  ADD_TO_CART_BAG = 'ADD_TO_CART_BAG'
export const  REMOVE_FROM_CART_BAG = 'REMOVE_FROM_CART_BAG'
export const  EMPTY_CART_BAG = 'EMPTY_CART_BAG'


export const  ADD_TO_CART_BAG_CARBON = 'ADD_TO_CART_BAG_CARBON'
export const  REMOVE_FROM_CART_BAG_CARBON = 'ADD_TO_CART_BAG_CARBON'
export const  EMPTY_CART_BAG_CARBON = 'EMPTY_CART_BAG_CARBON'


export const  ADD_TO_CART_WALLET_CARBON = 'ADD_TO_CART_WALLET_CARBON'
export const  REMOVE_FROM_CART_WALLET_CARBON = 'ADD_TO_CART_WALLET_CARBON'
export const  EMPTY_CART_WALLET_CARBON = 'EMPTY_CART_WALLET_CARBON'

