import React, { useState, useEffect, useContext } from "react";
// import ProfileModel from "../Components/index/ProfileModel";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { MdContentCopy, MdHomeFilled ,MdLeaderboard } from "react-icons/md";
import { FaEthereum } from "react-icons/fa";
import { AiOutlineTeam } from "react-icons/ai";
import { BiLogOut, BiWallet, BiInfoCircle ,BiLogIn} from "react-icons/bi";
import { disconnectWallet } from "../Web3Client";
import SignupModal from "../Components/index/SignupModal";
import LoginModal from "../Components/index/LoginModal";
import { isMetamask } from "../connectors";

import {Provider} from "../Contexts/BaseContext";


import GlobalContext from "../Contexts/GlobalContext";
import Model from "../Components/ConnectWallet/Model";

import ReferencePopup from "../Components/ReferencePopup";

import { useWeb3React } from '@web3-react/core'
import axiosInstance from "../Axios/axios.config";



const Base = ({children,active}) => {

    const {
        user,
        walletAddress,
        loggedIn,
        setLoggedIn,
        loginWithEmail,
        openConnector,
        setOpenConnector,
        setDefaultConnector,
        setBalance,
        setProfileModal,
        loginPopup, 
        setLoginPopup,
        sharingLink, 
        setSharingLink
      } = useContext(GlobalContext);

    const [openedMenu, setOpenedMenu] = useState(false);

    const [signUpModal,setSignUpModal] = useState(false);

    const [currentPath,setCurrentPath] = useState('')
 
    const { deactivate,library } = useWeb3React()


    useEffect(()=>{
        let path = window.location 
        setCurrentPath(path.pathname)
    },[])
 

    const connect = async () => {
    if (isMetamask) {
      console.log(typeof isMetamask);
      console.log("insde connectwallet.metamask");
      setDefaultConnector(true);
    } else {
      setOpenConnector(!openConnector);
    }
  };
    
    const copytexturl = () => {
        setTimeout(async () => {
          await navigator.clipboard.writeText(walletAddress);
          alert("copied to clipboard");
        }, 2000);
      };
    

    
const handelLogout = async () => {   
        let logout = await axiosInstance.post(`/user/user-logout/`,{}, {
            headers: {
              "Content-Type": "application/json",
            },
            withCredentials : true
          });
      
          if (logout.statusText === 'OK') {
            setLoggedIn(false);
            disconnectWallet(deactivate,library,user.name);
            toast.success("Successfully logout");
          } else {
            toast.warn("Something went wrong");
          }
    };
    
    const updateBalance = async () => {
        
        setProfileModal(true)
        let updatedDetails = await axiosInstance.get( `/user/wallet-details/`);
    
        let details =  updatedDetails.data
     
        setBalance(details.data.total_balance_usd)
    }


    
    const removeOverLay = ()=>{
        if(openedMenu){
          setOpenedMenu(false)
        }
    }

  return (
    <div>
        <nav className="navbar navbar-expand-lg bg-light primary-menu">
            <div className="container-fluid">
            <Link className="navbar-brand" to="/"><img src="/images/bc-logo.svg" height={60} alt="" /></Link>
            
            <div className='d-flex d-lg-none align-items-end justify-content-center'>
            <button className="navbar-toggler me-3" onClick={()=>setOpenedMenu(false)} type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon">
            <img src="/images/menuBar.svg" className="img-fluid" alt="bar" />
    </span> 
        </button>
        <Link className='userNameLink me-2 mb-1' to={'/account'}>{user.name}</Link>
        <div className="dropdown">
        
        { !user.image ?
            <span  onClick={()=>setOpenedMenu(!openedMenu)}  id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            {
            openedMenu ? <img src="/images/user_unselect.svg" alt='user' width={30} />
            :
            <img src="/images/user_select.svg" alt='user' width={30} />
    }
            
            </span>
            :
            <span  onClick={()=>setOpenedMenu(!openedMenu)}  id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
        
            <img className="rounded-circle" src={user.image} alt='user' width={30} height={30}/>
            
            </span>
        
        }
        
            
        <ul
        className="dropdown-menu p-0"
        id="dropdownMenuButtonHome"
        aria-labelledby="dropdownMenuButtonHome"
        onClick={()=>setOpenedMenu(false)}
        >
        {(walletAddress || loginWithEmail) && (
            <li className="dropdown-item border-bottom">
            <div className="user-img d-flex justify-content-between align-items-center">
                <span className=" me-2">
                {user.name ? user.name : ""}
                </span>
                <img
                src={
                    user.image
                    ? 
                        user.image
                    : "https://api.beachcollective.io/media/default-user.png"
                }
                id="user_image-1"
                onClick={updateBalance}
                style={{ height: "40px", width: "40px" }}
                className="rounded-circle ml-3"
                alt="img"
                />
            </div>
            </li>
        )}

        <li
            className="dropdown-item"
            style={{ cursor: "pointer" }}
        >
            <Link to="/" className="text-dark">
            <MdHomeFilled />
            <span className=" ms-2">Home</span>
            </Link>
        </li>
        {loggedIn && (
            <>
            {
               currentPath === '/action' && <li className="dropdown-item" style={{ cursor: 'pointer' }}>
  
                    <MdLeaderboard />
                    <span className=" ms-2">Leaderboard</span>
    
                </li>
            }
            <li
                className="dropdown-item"
                style={{ cursor: "pointer" }}
            >
                <BiWallet />
                <Link to={'/account'} className="ms-2 userLink">My Account</Link>
            </li>
            <li
                className="dropdown-item"
                onClick={() => setSharingLink(!sharingLink)}
                style={{ cursor: "pointer" }}
            >
                <AiOutlineTeam />
                <span className=" ms-2">Generate Referral</span>
            </li>
            </>
        )}

        {(loginWithEmail || !walletAddress)  && (
            <li
            className="dropdown-item"
            style={{ cursor: "pointer" }}
            onClick={connect}
            >
            {/* eslint-disable-next-line */}
            <span className="text-dark">
                <BiWallet />
                <span className=" ms-2">Connect wallet</span>
            </span>
            </li>
        )}
        {walletAddress && !loginWithEmail && (
            <>
            <li
                className="dropdown-item"
                style={{ cursor: "pointer" }}
            >
                {/* eslint-disable-next-line */}
                <a href="#" className="text-dark">
                <FaEthereum />
                <span className=" ms-2">View</span>
                </a>
            </li>
            <li
                className="dropdown-item"
                onClick={copytexturl}
                style={{ cursor: "pointer" }}
            >
                <MdContentCopy />
                <span className=" ms-2">Copy</span>
            </li>
            </>
        )}

        {loggedIn && (
            <li
            className="dropdown-item"
            style={{ cursor: "pointer" }}
            onClick={handelLogout}
            >
            <BiLogOut />
            <span className="ms-2">Sign out</span>
            </li>
        )}
        {!loggedIn && (
            <li
            className="dropdown-item"
            style={{ cursor: "pointer" }}
            onClick={() => setLoginPopup(true)}
            >
            <BiLogIn />
            <span className="ms-2">Sign in</span>
            </li>
        )}
        <li
            className="dropdown-item"
            style={{ cursor: "pointer" }}
            
        >
            <BiInfoCircle />
            <span className="ms-2">Help</span>
        </li>
        </ul>
        </div>
        </div>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
                <ul className="navbar-nav me-auto mb-lg-2 mb-lg-0">
                <li className={`nav-item `}>
                        <Link className="nav-link " aria-current="page"  to="/about">ABOUT</Link>
                </li>
                <li className={`nav-item`}>
                        <Link className={`nav-link ${active === 'action' ? 'active' : ''}`} aria-current="page"  to="/action">ACTION</Link>
                    </li>
                    <li className={`nav-item`}>
                        <Link className={`nav-link ${active === 'pay' ? 'active' : ''}`} to="/pay">PAY</Link>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" target='_blank' rel="noreferrer" href="https://token.beachcollective.io">TOKEN</a>
                    </li>
                 </ul>
                <ul className="navbar-nav ms-auto mb-lg-2 mb-lg-0">
            {!user.email && <li class="nav-item" onClick={() => setSignUpModal(true)}>
            <span className="nav-link d-lg-none" >
            SIGN UP
            </span>
                <button className="btn blue-btn d-none d-lg-inline me-3 text-white"> SIGN UP </button>
    </li>}
    <li className='nav-item d-inline-flex align-items-center'>
<Link className='userNameLink me-2 ' to={'/account'}>{user.name}</Link>
</li>
                <li className="nav-item dropdown  d-none d-lg-inline ">
                    { !user.image ?
                    <span  onClick={()=>setOpenedMenu(!openedMenu)}  id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {
                    openedMenu ? <img src="/images/user_select.svg" alt='user' width={30} />
                    :
                    <img src="/images/user_unselect.svg" alt='user' width={30} />
                }               
                    </span>
                    :
                    <span  onClick={()=>setOpenedMenu(!openedMenu)}  id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                
                    <img className="rounded-circle" src={user.image} alt='user' width={30} height={30}/>
                    
                    </span>
                
                }
                    <ul
                    className="dropdown-menu p-0"
                    id="dropdownMenuButtonHome"
                    aria-labelledby="dropdownMenuButtonHome"
                    onClick={()=>setOpenedMenu(false)}
                >
                    {(walletAddress || loginWithEmail) && (
                    <li className="dropdown-item border-bottom">
                        <div className="user-img d-flex justify-content-between align-items-center">
                        <span className=" me-2">
                            {user.name ? user.name : ""}
                        </span>
                        <img
                            src={
                            user.image
                                ? 
                                user.image
                                : "https://api.beachcollective.io/media/default-user.png"
                            }
                            id="user_image-1"
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                            style={{ height: "40px", width: "40px" }}
                            className="rounded-circle ml-3"
                            onClick={updateBalance}
                            alt="img"
                        />
                        </div>
                    </li>
                    )}

                    <li
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    >
                    <Link to="/" className="text-dark">
                        <MdHomeFilled />
                        <span className=" ms-2">Home</span>
                    </Link>
                    </li>
                    {loggedIn && (
                    <>
                        <li
                        className="dropdown-item"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                        style={{ cursor: "pointer" }}
                        >
                        <BiWallet />
                        <Link to={'/account'} className="ms-2 userLink">My Account</Link>
                        </li>
                        <li
                        className="dropdown-item"
                        onClick={() => setSharingLink(!sharingLink)}
                        style={{ cursor: "pointer" }}
                        >
                        <AiOutlineTeam />
                        <span className=" ms-2">Generate Referral</span>
                        </li>
                    </>
                    )}
                    {!loggedIn && (
                    <li
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={() => setLoginPopup(true)}
                    >
                        <BiLogIn />
                        <span className="ms-2">Sign in</span>
                    </li>
                    )}

                    {(loginWithEmail || !walletAddress ) && (
                    <li
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={connect}
                    >
                        {/* eslint-disable-next-line */}
                        <span className="text-dark">
                        <BiWallet />
                        <span className=" ms-2">Connect wallet</span>
                        </span>
                    </li>
                    )}

                    
                    {walletAddress && !loginWithEmail && (
                    <>
                        <li
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        >
                        {/* eslint-disable-next-line */}
                        <a href="#" className="text-dark">
                            <FaEthereum />
                            <span className=" ms-2">View</span>
                        </a>
                        </li>
                        <li
                        className="dropdown-item"
                        onClick={copytexturl}
                        style={{ cursor: "pointer" }}
                        >
                        <MdContentCopy />
                        <span className=" ms-2">Copy</span>
                        </li>
                    </>
                    )}

                    
                    {loggedIn && (
                    <li
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                        onClick={handelLogout}
                    >
                        <BiLogOut />
                        <span className="ms-2">Sign out</span>
                    </li>
                    )}
                
                    <li
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    
                    >
                    <BiInfoCircle />
                    <span className="ms-2">Help</span>
                    </li>
                </ul>
                </li>
                
                </ul>
            </div>
            </div>
        </nav>
        <div className={`${openedMenu ? 'overlay' : ''} `}  onClick={removeOverLay} >
            <Provider value={{setSignUpModal}}>
            {children}
            </Provider>
        </div>

        <Model show={openConnector} hide={setOpenConnector} link={user.name ? true : false} />
        {loggedIn && <ReferencePopup show={sharingLink} hide={setSharingLink} />}
        {/*<ProfileModel wallet_address={walletAddress}  />  */}    
        <SignupModal show={signUpModal} onClose={()=>setSignUpModal(false)} openLogin={setLoginPopup}  />
        <LoginModal show={loginPopup} hide={setLoginPopup} openSignup={setSignUpModal} />


    </div>
  )
}

export default Base