import Web3 from "web3";

export const disconnectWallet = async (deactivate, library,user) => {

  if (library) {
    deactivate(library);
  }

  window.ethereum = false;
  window.web3 = false;
  localStorage.setItem("connect_to_wallet", false);
  sessionStorage.clear()
  localStorage.clear();
  localStorage.setItem('user_autofill',user)
  window.location.href = '/'
  window.location.reload()
};


export const DontHaveAccount = () => {
  var web3 = new Web3(
    "https://mainnet.infura.io/v3/6ccd9a721a844a779eea17482a62cd9e"
  );
  var account = web3.eth.accounts.create();
  return account;
};

