import React, { useContext , useRef } from "react";
import { Modal } from "react-bootstrap";
import styles from "./modal.module.css";
import { useWeb3React } from "@web3-react/core";
import { connectors } from "../../connectors";
import GlobalContext from "../../Contexts/GlobalContext";
import detectEthereumProvider from "@metamask/detect-provider";
import { ethers } from "ethers";
import { toast } from "react-toastify";
import validator from "validator";
import CustomPopup from "../Pop/CustomPopup";
import { useState } from "react";
import { disconnectWallet, DontHaveAccount } from "../../Web3Client";
import { MdOutlineContentCopy } from 'react-icons/md'
import axiosInstance from '../../Axios/axios.config'
import axios from "axios";
import { BiHide, BiShow } from "react-icons/bi";

const Model = ({ show, hide, referedBy, link }) => {
  const {
    user,
    setUser,
    walletAddress,
    setWalletAddress,
    setLoggedIn,
    loggedIn,
    setBalance,
    setIsSignup,
    setOpen,
    loginWithEmail,
    setLoginWithEmail,
    setWalletPalceHolder,
    setConnectedWalletType,
    setWalletConnectionStatus,
    setNetworkId,
    setOpenConnector,
    defaultConnector,
    setUserId,
    setWelcomePop,
    setBagBeachTokenBscBalance,
    setBagBeachTokenBscBalanceUsd,
    setShowTutorial,
    setCreatedWithEmail,
    sessionEndPop,
    setSessionEndPop,
    checkWallet,
    setCheckWallet,
    setIsWithdraw,
    setNetworkModal,
    setShowNetworkAlert,
    setAddressModal
  } = useContext(GlobalContext);

  const [connectingWallet, setConnectingWallet] = useState('')
  const [providerObj, setProviderObj] = useState(null)
  const [showMerge, setShowMerge] = useState(false)

  const addressRef = useRef(null)
  const privateRef = useRef(null)

  const[showKey,setShowKey] = useState(false)

  const[newAccount,setNewAccount] = useState({
    address:null,
    privateKey:null
  }) 

  const handleSignMessage = async (publicAddress, nonce, obj) => {
    const provider = new ethers.providers.Web3Provider(obj);
    const signer = provider.getSigner();
    let new_message =
      "Hi there from Beach Collective! Sign this message to prove you have access to this wallet and we'll log you in. This won't cost you any gas.\n\nYou can ignore the message ID below, it's a security feature to protect your wallet from unauthorised access.\nTo stop hackers using your wallet, here's a unique message ID they can't guess:\n" +
      nonce +
      ".";
    let mySignature = await signer.signMessage(new_message);
    return mySignature;
    //     return window.web3.personal.sign(nonce, publicAddress);
  };



  const UserBackendLogin = async (obj) => {
    const provider = new ethers.providers.Web3Provider(obj);
    const signer = provider.getSigner();
    let account1 = await signer.getAddress();
    const wallet = account1;
    let dataObj = {};
    if (referedBy) {
      dataObj = { wallet_address: wallet, reference_user: referedBy };
    } else {
      dataObj = { wallet_address: wallet };
    }

    // setWalletAddress(wallet);
    // link
    if ((loginWithEmail || !walletAddress) && loggedIn) {


      let checkApi = await axiosInstance.post(`/user/user-account-check-before-linking/`, JSON.stringify(dataObj), {
        headers: {
          "Content-Type": "application/json"
        },
      })
      // let check =  checkApi.data

      if (checkApi.statusText === 'OK') {

        //   get nonce from backend
        let nonce = await axiosInstance.post(`/user/get-user-nonce-2/`, {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        let nonce_res = nonce.data;
        if(nonce.status === 200){
          //

        //   get userSignature message
        let signature = await handleSignMessage(wallet, nonce_res.nonce, obj);

        let payload = JSON.stringify({
          wallet_address: wallet,
          signature: signature,
        })

        let connect_wallet = await axiosInstance.post(`/user/link-wallet/`, payload,
          {
            headers: {
              "Content-Type": "application/json",
            }
          }
        );

        if (connect_wallet.status === 400) {
          let res = connect_wallet.data;
          toast.error(res.message, { containerId: 'toast' });
        }
        if (connect_wallet.status === 200) {
          setLoginWithEmail(false)
          setWalletAddress(wallet);
          setCheckWallet(wallet)
          setWalletPalceHolder("");
          setConnectingWallet('')
        }

        let withdrawDetails = await axiosInstance.get(`/user/user-withdrawal-state/`);

        let withdraw = withdrawDetails.data

        setIsWithdraw(withdraw.flag)
        }else if(nonce.status === 400){
          toast.error(nonce_res.error, { containerId: 'toast' });
        }
      } else {
        hide(false)
        setShowMerge(true)
      }
    } else {
      //   get nonce from backend
      let payload = JSON.stringify(dataObj)
      let nonce = await axios.post(`/user/get-user-nonce/`, payload,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

     if(nonce.status === 200){
      let nonce_res = nonce.data;
      //

      //   get userSignature message
      let signature = await handleSignMessage(wallet, nonce_res.nonce, obj);

      let loginPayload = JSON.stringify({
        wallet_address: wallet,
        signature: signature,
      })

      let login_wallet = await axios.post(`/user/user-login-with-wallet/`, loginPayload,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true
        }
      );

      if (login_wallet.statusText === 'OK') {
        setWalletAddress(wallet);
      } else {
        toast.error(login_wallet.message, { containerId: 'toast' });
      }

      let user_details = login_wallet.data;

      if (login_wallet.status === 400) {
        toast.error(user_details.error, { containerId: 'toast' });
      } else {

        localStorage.setItem('loggedIn', true)

        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${user_details.token.access}`

        setUser({
          id: user_details.data.user.id,
          default_pay: user_details.data.user.defaul_beach_pay_user,
          qrcode: user_details.data.user.qrcode,
          image: user_details.data.user.image,
          banner: user_details.data.user.banner,
          name: user_details.data.user.full_name,
          email: user_details.data.user.email,
          type: user_details.data.user.user_type,
          slug: user_details.data.user.slug_url,
          description: user_details.data.user.description,
          company: user_details.data.user.name_or_campany_name,
          disable_bag: user_details.data.user.disable_bag_flag,
          bag_type: user_details.data.user.disable_bag_type,
          permissions: user_details.data.user.user_permissions,
          native_permission: user_details.data.user.native_permission,
          refferal_qr_code : user_details.data.user.refferal_qr_code,
          payment_mode : user_details.data.user.payment_mode
        });
        if (validator.isEmail(user_details.data.user.username)) {
          setCreatedWithEmail(true);
        } else {
          setCreatedWithEmail(false);
        }
        setWalletPalceHolder(user_details.data.user.full_name);
        setLoggedIn(true);
      }


      setOpen(user_details.data.user.model_flag);
      setShowTutorial(user_details.data.user.tutorial_flag);
      setWelcomePop(user_details.data.user.show_first_info);
      setIsSignup(user_details.data.is_active);

      setLoginWithEmail(user_details.data.user.current_login_with_email);
      setUserId(user_details.data.user.id);

      let updatedDetails = await axiosInstance.get(`/user/wallet-details/`);

      let details = updatedDetails.data;

      setBalance(details.data.total_balance_usd);

      setBagBeachTokenBscBalance(details.data.beach_bsc_balance);

      setBagBeachTokenBscBalanceUsd(
        parseFloat(details.data.beach_bsc_balance_usd).toFixed(2)
      );

      let withdrawDetails = await axiosInstance.get(`/user/user-withdrawal-state/`);

      let withdraw = withdrawDetails.data

      setIsWithdraw(withdraw.flag)

     }else if(nonce.status === 400){
      let nonce_res = nonce.data;
      toast.error(nonce_res.error, { containerId: 'toast' });
     }
    }
  };

  const handleToggleShow = () =>{
    setShowKey(prev=>!prev)
  }

  const handleClose = () =>{
    if(newAccount.address){
      const rest = {address :null, privateKey : null}
      setNewAccount(rest)
    }
    hide(false)
  }

  const handleCreateNew = () =>{
    const { address, privateKey} = DontHaveAccount()
    setNewAccount({address,privateKey})
  }

  const copyAddress = async (type) =>{
      const rest = {address :null, privateKey : null}
      switch(type){
        case 'address':
          await navigator.clipboard.writeText(addressRef.current.value)
          addressRef.current.select()
          break;
        case 'private':
          await navigator.clipboard.writeText(privateRef.current.value)
          privateRef.current.select()
          setTimeout(()=>{
            setNewAccount(rest)
          },2000)
          break;
        default:
          return
      }
  }

  const mergeAccount = async () => {



    console.log(providerObj)
    //   get nonce from backend
    let nonce = await axiosInstance.post(`/user/get-user-nonce-2/`, {},
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );

    let nonce_res = nonce.data;
    //

    //   get userSignature message
    let signature = await handleSignMessage(connectingWallet, nonce_res.nonce, providerObj);
    // console.log(signature, "kkkk");

    let payload = JSON.stringify({
      wallet_address: connectingWallet,
      signature: signature,
    })

    let connect_wallet = await axiosInstance.post(`/user/link-wallet/`, payload,
      {
        headers: {
          "Content-Type": "application/json",
        }
      }
    );

    if (connect_wallet.status === 400) {
      let res = connect_wallet.data;
      toast.error(res.message, { containerId: 'toast' });
    }
    if (connect_wallet.statusText === 'OK') {
      setWalletAddress(connectingWallet);
      setCheckWallet(connectingWallet)
      setWalletPalceHolder("");
      setProviderObj(null)
      setConnectingWallet('')
      setShowMerge(false)
    }

    let withdrawDetails = await axiosInstance.get(`/user/user-withdrawal-state/`);

    let withdraw = withdrawDetails.data

    setIsWithdraw(withdraw.flag)


  }

  const { chainId, activate, active, deactivate, library } = useWeb3React();


  const handelLogout = async () => {

    let logout = await axiosInstance.post(`/user/user-logout/`, {}, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true
    });

    if (logout.statusText === 'OK') {
      setLoggedIn(false);
      disconnectWallet(deactivate, library, user.name);
      toast.success("Successfully logout", { containerId: 'toast' });
    } else {
      toast.warn("Something went wrong", { containerId: 'toast' });
    }
  };

  // network change
  const networkChangeEvent = async (chainId) => {
    console.log(typeof (chainId))
    if (chainId !== '56') {
      setNetworkId(chainId);
      setNetworkModal(true)
      setShowNetworkAlert(true)
      console.log('network change event ,', chainId)
    }
  }

  // change address accountsChanged

  const accountsChangedEvent = async (account) => {
    // setNetworkId(chainId);
    if (walletAddress != account[0]) {
      setAddressModal(true)
      handelLogout()

    }
    console.log('account change event ,', account)
  }



  // network switch
  const switchNetwork = async (provider_obj) => {
    const provider = new ethers.providers.Web3Provider(provider_obj, 'any');

    const { chainId } = await provider.getNetwork();

    if (chainId != 56) {

      try {
        await provider_obj.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x38' }], //binance chain
        });
      } catch (e) {
        if (e.code === 4902) {
          try {
            await provider_obj.request({
              jsonrpc: '2.0',
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: '0x38',
                  chainName: 'Binance Smart Chain Mainnet',
                  rpcUrls: ['https://bsc-dataseed.binance.org/'],
                  nativeCurrency: {
                    name: 'BNB',
                    symbol: 'BNB',
                    decimals: 18
                  },
                  blockExplorerUrls: ['https://bscscan.com']
                }
              ],
              id: 0
            })
          } catch (addError) {
            console.error(addError);
          }
        }
        // console.error(e)
      }

    }

    // if (chainId != 56){
    //   await provider_obj.request({
    //     method: 'wallet_switchEthereumChain',
    //     params: [{ chainId: '0x38' }], // chainId must be in hexadecimal numbers
    //   });

    // }

    const newChainId = await provider.getNetwork()
    debugger;
    setNetworkId(newChainId.chainId);
    console.log(newChainId.chainId)
  }


  // metamask connection details 

  const connectMetaMask = async () => {
    const provider = await detectEthereumProvider();
    console.log("provider000>", provider);

    if (provider) {
      // try {
      const netId = parseInt(provider.networkVersion);
      const res = await activate(connectors.injected);
      console.log(res);
      console.log(active);
      setTimeout(async () => {
        const provider = new ethers.providers.Web3Provider(connectors.metamaskProvider, 'any');
        const signer = provider.getSigner();
        let account1 = await signer.getAddress();

        console.log(account1)

        await switchNetwork(connectors.metamaskProvider);


        setOpenConnector(false);


        setConnectingWallet(() => account1)

        console.log("account", account1);
        console.log("checkWallet", checkWallet);
        console.log("wallet_address", account1);


        setConnectedWalletType("metamask");
        setWalletConnectionStatus(active);
        setProviderObj(connectors.metamaskProvider)
        if (loggedIn && checkWallet) {
          if (checkWallet.toLowerCase() === account1.toLowerCase()) {
            await UserBackendLogin(connectors.metamaskProvider);
          } else {
            toast.error('Your Email account is linked with another wallet address. Please try connect wallet with linked wallet address of your account', { containerId: 'toast' })
          }
        } else {
          await UserBackendLogin(connectors.metamaskProvider);
        }

        await connectors.metamaskProvider.on('networkChanged', (chainId) => networkChangeEvent(chainId))
        await connectors.metamaskProvider.on('accountsChanged', (account) => accountsChangedEvent(account))


      }, 3000);
      // } catch (exception) {
      //   console.log("exception", exception);
      // }
    } else {
      window.location.assign("https://metamask.app.link/dapp/beachcollective.io/");
    }
  };

  const connectBinanceWallet = async () => {
    // try {
    activate(connectors.binanceWalletProvider);
    console.log(active);
    setTimeout(async () => {
      const provider = new ethers.providers.Web3Provider(connectors.binanceWalletProvider, 'any');
      const signer = provider.getSigner();
      let account1 = await signer.getAddress();
      setOpenConnector(false);
      // const { chainId } = await provider.getNetwork()

      await switchNetwork(connectors.binanceWalletProvider);

      // setWalletAddress(account1);
      setConnectingWallet(account1)
      console.log("account", account1);
      console.log("wallet_address", account1);
      setConnectedWalletType("binanceWallet");
      setWalletConnectionStatus(active);
      setProviderObj(connectors.binanceWalletProvider)
      await UserBackendLogin(connectors.binanceWalletProvider);

      await connectors.binanceWalletProvider.on('networkChanged', (chainId) => networkChangeEvent(chainId))
      await connectors.binanceWalletProvider.on('accountsChanged', (account) => accountsChangedEvent(account))

    }, 3000);
    // } catch (exception) {
    //   console.log("exception", exception);
    // }
  };

  // coinbase wallet connection details 
  const connectCoinBase = async () => {
    // try {
    await activate(connectors.coinbaseProvider);
    console.log(active);
    setTimeout(async () => {
      const provider = new ethers.providers.Web3Provider(
        connectors.coinbaseProvider, 'any'
      );
      console.log(provider)
      const signer = provider.getSigner();
      let account1 = await signer.getAddress();
      // let { chainId } = await provider.getNetwork()

      setOpenConnector(false);
      await switchNetwork(connectors.coinbaseProvider);



      // setWalletAddress(account1);
      setConnectingWallet(account1)
      console.log("account", account1);
      console.log("wallet_address", account1);
      setConnectedWalletType("coinbaseWallet");
      setWalletConnectionStatus(active);
      // setNetworkId(chainId);
      setProviderObj(connectors.coinbaseProvider)
      await UserBackendLogin(connectors.coinbaseProvider);
      await connectors.coinbaseProvider.on('networkChanged', (chainId) => networkChangeEvent(chainId))
      await connectors.coinbaseProvider.on('accountsChanged', (account) => accountsChangedEvent(account))

    }, 3000);
    // } catch (exception) {
    //   console.log("exception", exception);
    // }
  };

  const connectWalletConnect = async () => {
    // try {
    // await activate(connectors.walletConnect);
    // walletConnectProvider;
    await connectors.walletConnectProvider.enable();

    console.log(active);
    setTimeout(async () => {
      const provider = new ethers.providers.Web3Provider(
        connectors.walletConnectProvider, 'any'
      );
      console.log('connectors.walletConnectProvider', connectors.walletConnectProvider)
      const signer = provider.getSigner();
      let account1 = await signer.getAddress();
      // let { chainId } = await provider.getNetwork()

      await switchNetwork(connectors.walletConnectProvider);
      setOpenConnector(false);

      // setWalletAddress(account1);
      setConnectingWallet(account1)
      console.log("account", account1);
      console.log("wallet_address", account1);
      setConnectedWalletType("walletConnect");
      setWalletConnectionStatus(active);
      // setNetworkId(chainId);
      setProviderObj(connectors.walletConnectProvider)
      await UserBackendLogin(connectors.walletConnectProvider);

      await connectors.walletConnectProvider.on('networkChanged', (chainId) => networkChangeEvent(chainId))
      await connectors.walletConnectProvider.on('accountsChanged', (account) => accountsChangedEvent(account))

    }, 3000);
    // } catch (exception) {
    //   console.log("exception", exception);
    // }
  };

  React.useEffect(() => {
    if(!loggedIn){
      if (defaultConnector) {
        connectMetaMask();
      }
    }
    // eslint-disable-next-line
  }, [loggedIn,defaultConnector]);

  return defaultConnector ? (
    <></>
  ) : (
    <>

      <CustomPopup show={show} onClose={handleClose} title={'Connect wallet'} >
        <div className={`content ${styles.connector_content} `}>
          <button
            type="button"
            className={`${styles.connector_btn} w-100 d-flex flex-column align-items-center`}
            onClick={connectMetaMask}
          >
            <img src="/images/metamaskIcon.svg" width={"50"} alt="metamask" />
            <span className="fs-4 my-2 text-dark">Metamask</span>

          </button>
          <button
            type="button"
            className={`${styles.connector_btn} w-100 d-flex flex-column align-items-center`}
            onClick={connectWalletConnect}
          >
            <img
              src="/images/walletConnectIcon.svg"
              width={"50"}
              alt="walletConnect"
            />
            <span className="fs-4 my-2 text-dark">WalletConnect</span>

          </button>
          <button
            type="button"
            className={`${styles.connector_btn} w-100 d-flex flex-column align-items-center`}
            onClick={connectBinanceWallet}
          >
            <img src="/images/binanceIcon.svg" width={"50"} alt="binance" />
            <span className="fs-4 my-2 text-dark">Binance Chain</span>

          </button>
          {/* <button
              type="button"
              className={`${styles.connector_btn} w-100 d-flex flex-column align-items-center`}
              onClick={connectCoinBase}
            >
              <img src="/images/coinbaseIcon.svg" width={"50"} alt="coinbase" />
              <span className="fs-4 my-2 text-dark">Coinbase Wallet</span>
             
            </button> */}
        </div>
        <div className='text-center my-3'>
          {
            newAccount.address ?
            <p className='text-dark'> Note : Copy the Private Key to import your account into  wallet</p>
            :
            <button className='btn btn-blue text-white' onClick={handleCreateNew}>I dont have a crypto wallet</button>         
          }
        </div>
       { 
        newAccount.address && <div>
        <p className='text-center pink-text mb-2'>Wallet Address</p>
          <div className='d-flex justify-content-center'>
            <div className='newWalletAddWrapper'>
              <input className='newWalletAdd' type={'text'} value={newAccount.address} ref={addressRef} readOnly={true} />
            </div>
            <button className='btn newWalletCopyBtn' onClick={()=>copyAddress('address')} title='Copy'>
              <MdOutlineContentCopy  size={20} />
            </button>
          </div>
          <br/>
          <p className='text-center pink-text mb-2'>Private Key</p>
          <div className='d-flex justify-content-center '>
            <button className='btn newWalletCopyBtn hideShow' onClick={handleToggleShow}>
              { showKey  ? <BiHide size={20} /> : <BiShow size={20}/>}
            </button>
            <div className='newWalletAddWrapper withHideShow'>
              <input className='newWalletAdd' type={showKey ? 'text' : 'password'} value={newAccount.privateKey} ref={privateRef} readOnly={true} />
            </div>
            <button className='btn newWalletCopyBtn' onClick={()=>copyAddress('private')} title='Copy'>
              <MdOutlineContentCopy size={20}/>
            </button>
          </div>
        </div>
      }
      </CustomPopup>


      <CustomPopup show={showMerge} onClose={() => setShowMerge(false)} >
        <p className='text-center fs-6 mt-3 px-2'>
        Please confirm if you want to merge logged in account with other active account with wallet address <span className='text-blue'>{connectingWallet}</span> . this will lead to transfer of all info from other account to current logged in account
        </p>
        <div className='text-center my-4'>
          <button className='btn btn-blue text-white me-4' onClick={mergeAccount}>
            Confirm
          </button>
          <button className='btn btn-danger' onClick={() => setShowMerge(false)}>
            Cancel
          </button>
        </div>
      </CustomPopup>

      <Modal show={sessionEndPop} className="popup-blue">
        <Modal.Header className="d-flex justify-content-end border-0 pb-0">
          <button
            className=" btn btn-transparent"
            onClick={setSessionEndPop(false)}
          >
            <img src="/images/map-close.svg" height={"15"} alt="close" />
          </button>
        </Modal.Header>
        <Modal.Body className="text-center pt-0">
          <div className="my-3">
            <div className="mb-4">
              <img
                src="/images/logo-small-device.svg"
                className="w-50"
                alt="logo"
              />
            </div>
            <h5 className="col-md-10  mx-auto text-blue">
              Session closed. You have been logged out.
            </h5>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Model;
