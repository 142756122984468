import React, { useEffect, useState } from 'react'
import Base from '../Layouts/Base'
import axios from 'axios'
import moment from "moment/moment";
import { Link } from 'react-router-dom';

function convertToPlain(rtf) {
    var tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = rtf;
    return tempDivElement.textContent || tempDivElement.innerText || "";
}

function cutString(s) {
    var cut = s.indexOf(' ', 150);
    if (cut == -1) return s;
    return s.substring(0, cut) + '...'
}

const Blog = () => {

    const [blogs, setBlogs] = useState([])

    useEffect(() => {
        async function getBlogs() {
            const blog_res = await axios.get('https://token.beachcollective.io/blog-api/beachcollective')
            const data = blog_res.data.data
            setBlogs(() => [...data])
        }
        getBlogs()
    }, [])

    return (
        <Base>
            <div className='container'>
                <div class="section-head text-center my-4">
                    <h1 class="text-blue">BLOG</h1>
                </div>
                <div className='row gy-5 gx-4 g-xl-5'>
                    {
                        blogs.map((blog, i) => (
                            <div class="col-lg-4" key={i}>
                                <article class="card post-card h-100 border-0 bg-transparent">
                                    <div class="card-body">
                                        <Link class="d-block" to={`/blog/${blog.slug}`} title={convertToPlain(blog.title)}>
                                            <div class="post-image position-relative">
                                                <img class="w-100 rounded" loading='lazy' src={'https://token.beachcollective.io/media/' + blog.image} alt="" />
                                            </div>
                                        </Link>
                                        <div className="card-meta d-flex justify-content-between align-items-center mb-3 mt-2">

                                            <div className='fs-6'>
                                                <div className='d-flex gap-2 align-items-center'>
                                                    <img loading='lazy' src={`https://token.beachcollective.io/media/${blog.created_by[0][3]}`} className='rounded-circle' style={{ objectFit: 'cover', border: '2px solid #007A97' }} width={30} height={30} />
                                                    <div>
                                                        <p className='m-0'>{blog.created_by[0][1]}</p>
                                                        <p className='m-0'>{blog.created_by[0][2]}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <i className="far fa-calendar"></i>{" "}
                                                <span>{moment(blog.posted_date).format("MMM D yyyy")}</span>
                                            </div>
                                        </div>
                                        <Link class="d-block title-link" to={`/blog/${blog.slug}`} title={convertToPlain(blog.title)}>
                                            <h3 class="mb-3 post-title">
                                                <p>
                                                    <span>
                                                        <span>
                                                            <span>
                                                                <strong>{convertToPlain(blog.title)}</strong>
                                                            </span>
                                                        </span>
                                                    </span>
                                                </p>
                                            </h3>
                                        </Link>
                                        <p>
                                            {cutString(convertToPlain(blog.description))}
                                        </p>
                                    </div>
                                </article>
                            </div>))
                    }
                </div>
            </div>
        </Base>
    )
}

export default Blog