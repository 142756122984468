import React, { useContext, useRef } from 'react'
import CustomPopup from '../Pop/CustomPopup'
import styles from './signup.module.css'
import { IoCloseSharp } from 'react-icons/io5'
import validator from "validator";
import GlobalContext from "../../Contexts/GlobalContext";
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom'
import axios from 'axios';
import axiosInstance from '../../Axios/axios.config';
import { checkPasswordValidity } from '../../helpers/utils'
import ReCAPTCHA from 'react-google-recaptcha';



const initialState = {
    userName: "",
    userPassword: "",
    userEmail: "",
    confirmPassword: "",
    type: ""
};

// const[trashCode,setTrashCode] = useState('')
const errorInitial = {
    userName: false,
    userPassword: false,
    userEmail: false,
    confirmPassword: false
};




const SignupModal = ({ show, onClose, openLogin }) => {

    const { 
        walletAddress,
        setLoggedIn,
        setBalance,
        setIsSignup,
        setLoginWithEmail,
        setUser,
        setWalletAddress,
        setWalletPalceHolder,
        setUserId,
        setWelcomePop,
        setBagBeachTokenBscBalance,
        setBagBeachTokenBscBalanceUsd,
        setShowTutorial
    } = useContext(GlobalContext);

    const [formFields, setFormFields] = React.useState(initialState);

    const [formErrors, setFormErrors] = React.useState(errorInitial);

    const [passwordError,setPasswordError] = React.useState('')

    const [otpError, setOtpError] = React.useState(false);

    const [isChecked, setIsChecked] = React.useState(false)

    const [otp, setOtp] = React.useState("");

    const [renderForm, setRenderForm] = React.useState('signup')

    const [showPassword, setShowPassword] = React.useState(false);

    const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

    const [accAlready, setAccAlready] = React.useState(false);

    const [error, setError] = React.useState('');

    const [type, setType] = React.useState('')

    const [captcha, setCaptcha] = React.useState(null);

    const captchaRef = useRef(null)

    const handleUserEmailValidation = (e) => {
        setFormFields({
            ...formFields,
            userEmail: e.target.value,
        })
        if (e.target.value) {
            if (!validator.isEmail(e.target.value)) {
                setFormErrors(prev => ({ ...prev, userEmail: true }))
            }
            else {
                setFormErrors(prev => ({ ...prev, userEmail: false }))
            }
        } else {
            setFormErrors(prev => ({ ...prev, userEmail: false }))
        }
    }



    const handleUserNameValidation = async (e) => {
        if (e.target.value) {
            if (e.target.value.length <= 15) {
                setFormFields({ ...formFields, userName: e.target.value });
                let payload = JSON.stringify({ name: e.target.value })
                const response = await axios.post(`/user/check-username/`, payload,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                if (response.statusText === 'OK') {
                    setFormErrors(prev => ({ ...prev, userName: false }))
                } else {
                    setFormErrors(prev => ({ ...prev, userName: true }))
                }
            }
        } else {
            setFormFields({ ...formFields, userName: e.target.value });
            setFormErrors(prev => ({ ...prev, userName: false }))
        }
    }

   

    const handleUserPasswordValidation = (e) => {
        setFormFields({
            ...formFields,
            userPassword: e.target.value,
        })
        if(e.target.value){
            let error = checkPasswordValidity(e.target.value)
            if(error){
                setPasswordError(error)
                setFormErrors(prev => ({ ...prev, userPassword: true }))
            }else{
                setPasswordError('')
                setFormErrors(prev => ({ ...prev, userPassword: false }))
            }
        }else{
            setPasswordError('')
            setFormErrors(prev => ({ ...prev, userPassword: false }))
        }
    }

    const handleConfirmPasswordValidation = (e) => {
        setFormFields({
            ...formFields,
            confirmPassword: e.target.value,
        })
        if(e.target.value) {
            if (formFields.userPassword !== e.target.value) {
                setFormErrors(prev => ({ ...prev, confirmPassword: true }))
            } else {
                setFormErrors(prev => ({ ...prev, confirmPassword: false }))
            }
        } else {
            setFormErrors(prev => ({ ...prev, confirmPassword: false }))
        }
    }

    const isFieldsProper = () => {
        if ((formFields.userEmail && !formErrors.userEmail)
            && (formFields.userName && !formErrors.userName)
            && (formFields.userPassword && !formErrors.userPassword)
            && (formFields.confirmPassword && !formErrors.confirmPassword)
            && type !== ''
            && isChecked
            && captcha) {
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {

        const token = captchaRef.current.getValue();
        captchaRef.current.reset();
        setCaptcha(null)

        let formData = new FormData();

        formData.append("email", formFields.userEmail.trim());

        formData.append("password", formFields.userPassword);

        formData.append("name", formFields.userName.trim());

        formData.append("model_flag", false);

        formData.append("user_type", type);

        formData.append('recaptcha_token',token)


        if (walletAddress) {
            const response = await axiosInstance.post(`/user/user-sign-up-email/`, formData,
                {
                    headers: {
                        'Content-Type': "application/json",
                    },
                }
            );
            let data = response.data

            if (response.statusText === 'OK') {
                toast.success('Account Created successfully', { containerId: 'toast' })
                setRenderForm('otp')
            }
            else {
                setError(data.error)
                setAccAlready(true)
                setTimeout(() => {
                    setAccAlready(false)
                    // setFormFields(initialState)
                    setError('')
                    setType('')
                    setIsChecked(false)
                    setFormErrors(errorInitial)
                }, 5000)
            }
        } else {
            const response = await axios.post(`/user/user-sign-up-email-without-wallet/`, formData,
                {
                    headers: {
                        'Content-Type': "application/json",
                    }
                },
            );
            let data = response.data

            if (response.statusText === 'OK') {
                toast.success('Account Created successfully', { containerId: 'toast' })
                setRenderForm('otp')
            }
            if (response.status === 400) {
                setError(data.error)
                setTimeout(() => {
                    setError('')
                }, 3000)
            }
        }


    };

    const autoLogin = async () => {

            let userResponse = await axiosInstance.get('/user/user-details/')
      
            if(userResponse.statusText === 'OK'){

                 // console.log('user dataccccccc--->',status)
                 toast.success("Log-in successful", { containerId: 'toast' });
                 localStorage.setItem('loggedIn', true)
      
            setLoggedIn(true)
              
            let userData = userResponse.data
      
            if(userData.data.wallet_address) {
              setWalletAddress(userData.data.wallet_address)
            }
            else {
                if (userData.data.full_name) {
                    setWalletPalceHolder(userData.data.full_name)
                } else {
                    setWalletPalceHolder(userData.data.username)
                }
            }
      
            setLoginWithEmail(userData.data.current_login_with_email)
            setUserId(userData.data.id)
            setWelcomePop(userData.data.show_first_info)
            setShowTutorial(userData.data.tutorial_flag)
            setBalance(userData.data.beach_balance)
            setIsSignup(userData.data.is_active)
            setUser({
              id: userData.data.id,
              default_pay: userData.data.defaul_beach_pay_user,
              qrcode: userData.data.qrcode,
              image: userData.data.image,
              name: userData.data.full_name,
              email: userData.data.email,
              type: userData.data.user_type,
              description: userData.data.description,
              banner: userData.data.banner,
              company: userData.data.name_or_campany_name,
              disable_bag: userData.data.disable_bag_flag,
              bag_type: userData.data.disable_bag_type,
              slug: userData.data.slug_url,
              permissions: userData.data.user_permissions,
              refferal_qr_code : userData.data.refferal_qr_code
          })
          let updatedDetails = await axiosInstance.get( `/user/wallet-details/`);
      
          let details =  updatedDetails.data
      
          // setLimitValue(details.data.withdrawl_limit_without_wallet_connection)
      
          setBalance(details.data.total_balance_usd)
      
          setBagBeachTokenBscBalance(details.data.beach_bsc_balance)
      
          setBagBeachTokenBscBalanceUsd(parseFloat(details.data.beach_bsc_balance_usd).toFixed(2))
      
            }
          
    
      };

      const isOtpFieldsProper = () => {
        if ((formFields.userEmail && !formErrors.userEmail)
            && otp && !otpError
            && captcha) {
            return false;
        }
        return true;
    }

    const verifyOTP = async () => {

        const token = captchaRef.current.getValue();
        captchaRef.current.reset();
        setCaptcha(null)

        let payload = JSON.stringify({
            'recaptcha_token': token,
            otp: otp,
            email: formFields.userEmail,
            'auto_login': true
        })

        const response = await axios.post(`/user/user-otp-verification-email/`, payload, {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true
        });

        if (response.statusText === 'OK') {
            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${response.data.token.access}`

            setOtp('')
            // setFormFields(initialState)
            setFormErrors(errorInitial)
            setRenderForm('success')
        }
        if (response.status === 400) {
            const res = response.data
            setOtpError(true)
            setTimeout(() => {
                setOtp('')
                setOtpError('')
            }, 3000)
            toast.error(res.error, { containerId: 'toast' })
        }
    };

    const reSendOTP = async () => {
        captchaRef.current.reset();
        setCaptcha(null)
        const response = await axiosInstance.get(`/user/user/otp-resend/`);
        if (response.statusText === 'OK') {
            toast.success('OTP is resent successfully ', { containerId: 'toast' })
        }
    };

    const goBack = () => {
        setRenderForm('signup')
    }

    const handleLogin = () => {
        autoLogin()
        onClose(false)
        setFormFields(initialState)
        setFormErrors(errorInitial)
        setType('')
        setRenderForm('signup')
        setIsChecked(false)
        // openLogin(true)
    }

    const close = () => {
        onClose(false)
        setIsChecked(false)
        setType('')
        setRenderForm('signup')
        setFormFields(initialState)
        setFormErrors(errorInitial)
        setOtp('')
    };

    const checked = (value) => {
        return value === type
    }

    const onExpired = () =>{
        setCaptcha(null)
        captchaRef.current.reset()
      }

    // signup form HTML
    function signupForm() {
        return (
            <div className={`col-12 d-flex  px-0 ${styles.sectionWrapper}`}>
                <div className={styles.imageSide}>
                    <img className={styles.image} src='/images/signup-img.png' />
                </div>

                <div className={styles.content}>
                    {

                        accAlready ? <div className="mb-3  text-blue">
                            <p className='fs-6'>
                                Your Connected wallet is linked with other Email/Username so
                                please follow either of the below ways and try sign up here
                                again:
                            </p>
                            <p className='fs-6'>1) disconnect Connected wallet and Try Sign up Again.</p>
                            <p className='fs-6'>2) Try with Another Wallet address connected.</p>
                            <p className='fs-6'>3) Or Unlink the Wallet address from other Email/Username.</p>
                        </div>
                            :
                            <div className='px-5 mt-3 text-start'>
                                <h5 className='text-dark'>We believe that you can make a difference</h5>
                                <p className='text-dark my-3 fs-6'>And so we are building a space to help give you the tools, the community and the hope, to do just that. Now let’s get started.</p>
                                <div className="mt-4 mb-3">
                                    <div className={`${styles.inputBox} ${formErrors.userEmail ? styles.outlineRed : ''}`}>
                                        <input
                                            className="text-center "
                                            type="text"
                                            value={formFields.userEmail}
                                            placeholder="Enter Email Address"
                                            onChange={handleUserEmailValidation}
                                        />
                                        {
                                            formFields.userEmail &&
                                            <span className={styles.status}>
                                                {
                                                    formErrors.userEmail ?
                                                        <IoCloseSharp color='red' />
                                                        :
                                                        <img src='/images/Green-tick.svg' />
                                                }
                                            </span>
                                        }
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className={`${styles.inputBox} ${formErrors.userName ? styles.outlineRed : ''}`}>
                                        <input
                                            className="text-center"
                                            type="text"
                                            value={formFields.userName}
                                            placeholder="Enter Username"
                                            onChange={handleUserNameValidation}
                                        />
                                        {
                                            formFields.userName &&
                                            <span className={styles.status}>
                                                {

                                                    formErrors.userName ?
                                                        <IoCloseSharp color='red' />
                                                        :
                                                        <img src='/images/Green-tick.svg' />
                                                }

                                            </span>
                                        }
                                    </div>
                                </div>
                                <div className="mb-3">
                                    <div className={`${styles.inputBox} ${formErrors.userPassword ? styles.outlineRed : ''}`}>
                                        <input
                                            className="text-center"
                                            type={showPassword ? 'text' : 'password'}
                                            value={formFields.userPassword}
                                            placeholder="Create Password"
                                            onChange={handleUserPasswordValidation}
                                        />

                                        <button className={`btn ${styles.toggleShow}`} onClick={() => setShowPassword(!showPassword)}>
                                            {
                                                showPassword ?
                                                    <img src='/images/show-eye.svg' />
                                                    :
                                                    <img src='/images/hide-eye.svg' />
                                            }
                                        </button>
                                        {
                                            formFields.userPassword &&
                                            <span className={styles.status}>
                                                {
                                                    formErrors.userPassword ?
                                                        <IoCloseSharp color='red' />
                                                        :
                                                        <img src='/images/Green-tick.svg' />
                                                }
                                            </span>
                                        }
                                    </div>
                                    { passwordError && <small className='d-block my-2 text-danger font-regular'>{passwordError}</small>}
                                </div>
                                <div className="mb-3">
                                    <div className={`${styles.inputBox} ${formErrors.confirmPassword ? styles.outlineRed : ''}`}>
                                        <input
                                            className="text-center"
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            value={formFields.confirmPassword}
                                            placeholder="Confirm Password"
                                            onChange={handleConfirmPasswordValidation}
                                        />
                                        <button className={`btn ${styles.toggleShow}`} onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                            {
                                                showConfirmPassword ?
                                                    <img src='/images/show-eye.svg' />
                                                    :
                                                    <img src='/images/hide-eye.svg' />
                                            }
                                        </button>
                                        {
                                            formFields.confirmPassword &&
                                            <span className={styles.status}>
                                                {
                                                    formErrors.confirmPassword ?
                                                        <IoCloseSharp color='red' />
                                                        :
                                                        <img src='/images/Green-tick.svg' />
                                                }
                                            </span>
                                        }
                                    </div>

                                </div>

                                <div className="mb-3 text-blue">
                                    <div className='d-flex justify-content-between mb-3'>
                                        <div className='d-flex justify-content-md-center align-items-center'>
                                            <label htmlFor='Individual' className={`me-2 ${styles.label}`} >Individual</label>
                                            <input type={'radio'} className={`${styles.radio}`} id='Individual' value='Individual' name='type' checked={checked('Individual')} onChange={(e) => setType(e.target.value)} />
                                        </div>
                                        <div className='d-flex justify-content-md-center align-items-center'>
                                            <label htmlFor='business' className={`me-2 ${styles.label}`}  >Business</label>
                                            <input type={'radio'} className={`${styles.radio}`} id='business' value='Business' name='type' checked={checked('Business')} onChange={(e) => setType(e.target.value)} />
                                        </div>

                                        <div className='d-flex justify-content-md-center align-items-center'>
                                            <label htmlFor='Not_for_profit' className={`me-2 ${styles.label}`} >Not for profit</label>
                                            <input type={'radio'} className={`${styles.radio}`} id='Not_for_profit' value='Not-for-profit' name='type' checked={checked('Not-for-profit')} onChange={(e) => setType(e.target.value)} />
                                        </div>
                                    </div>



                                </div>
                                <div className="d-flex justify-content-between font-regular mb-2">

                                    <p className='m-0'>
                                        <Link to='/terms-and-conditions' className={styles.termsLink}>Read T&Cs</Link>
                                    </p>
                                    <div className='d-flex justify-content-md-center align-items-center'>
                                        <label htmlFor='terms' className={`me-2 ${styles.label} text-blue`} >I have read and accepted the T&C’s</label>
                                        <input type={'checkbox'} className={`${styles.radio}`} name='terms' checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
                                    </div>

                                </div>
                                <div className="g-recaptcha">
                                    <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_SITE_KEY}
                                    ref={captchaRef}
                                    onExpired={onExpired}
                                    onChange={value=>setCaptcha(value)}
                                    />
                                </div>
                                {
                                    error && <div className='text-center text-danger mb-3'>
                                        {error}
                                    </div>
                                }
                                <div className='text-center mb-2'>
                                    <button className={`btn text-white  btn-blue w-100 ${styles.submitBtn} `} onClick={handleSubmit} disabled={isFieldsProper()} >
                                        Sign up
                                    </button>
                                </div>
                                <small className='text-dark  d-flex align-items-center text-decoration-underline m-0'>Already have an account? <button className='btn p-0 border-0 outline-0' onClick={handleLogin}><small>Sign in</small></button></small>
                            </div>
                    }
                </div>
            </div>
        )
    }


    // otp form HTMl
    function otpForm() {
        return (
            <div className="col-md-8">

                <div className='mb-4'>
                    <p className='text-center text-blue'>We sent a verification code via your email address.<br />
                    Please enter verification code sent to <span style={{ color: '#212121' }}>{formFields.userEmail}</span></p>
                </div>


                <div className="mb-4">
                    <div className={`${styles.inputBox} ${otpError ? styles.outlineRed : ''}`}>
                        <input
                            className="text-center "
                            type="text"
                            value={otp}
                            placeholder="Enter OTP"
                            onChange={(e) =>
                                setOtp(e.target.value)
                            }
                        />
                        {
                            otp &&
                            <span className={styles.status}>
                                {
                                    otpError ?
                                        <IoCloseSharp color='red' />
                                        :
                                        <img src='/images/Green-tick.svg' />
                                }
                            </span>
                        }
                    </div>
                </div>

                {
                    error && <div className='text-center text-danger mb-3'>
                        {error}
                    </div>
                }

                <div className="g-recaptcha">
                <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={captchaRef}
                onExpired={onExpired}
                onChange={value=>setCaptcha(value)}
                />
            </div>

                <div className='mb-4 text-center'>

                    <button className={`btn  btn-blue w-50 ${styles.submitBtn} ${styles.fs_1_2} text-capitalize bg-white  btn-outline-blue`} onClick={reSendOTP}>
                        Resend OTP
                    </button>

                </div>
                <div className='text-center mb-4'>

                    <button className={`btn text-white  btn-blue w-50 ${styles.submitBtn} ${styles.fs_1_2} text-capitalize `} onClick={verifyOTP} disabled={isOtpFieldsProper()}>
                        Verify
                    </button>

                </div>

            </div>
        )
    }

    function successHTML() {
        return (
            <div className={`col-12 d-flex  px-0 ${styles.sectionWrapper}`}>
                <div className={styles.imageSide}>
                    <img className={`${styles.image} d-none d-md-block`} src='/images/signup-success.png' />
                    <img className={`${styles.image} d-md-none`} src='/images/signup-success-xs-bottom.png' />
                </div>

                <div className={`${styles.imageTextWrapperCenter}`}>
                    <h4 className='text-light'>
                        <span className='font-regular'>Welcome to the</span> <br />
                        Beach Collective
                    </h4>
                </div>

                <img className={`${styles.bottomLogo} ${styles.bottomLogoCenter}`} src="/images/bc-logo.svg" width={35} alt="logo" />

                <div className={styles.content}>


                    <div className='d-md-none' style={{marginTop:'-28px'}}>
                        <img className={`${styles.image}`} src='/images/signup-success-xs.png' />
                    </div>
                    <div className='px-5 mt-3 text-start'>


                        <div className='d-flex align-items-center gap-4 my-5'>
                            <img src={`${process.env.PUBLIC_URL}/images/check-circle.svg`} width={66} height={66} />
                            <h4 className='text-dark'>
                                Your account has <br />
                                been created!
                            </h4>
                        </div>
                        <div className='d-flex align-items-center gap-4 my-5'>
                            <img src={`${process.env.PUBLIC_URL}/images/user_unselect.svg`} className='mx-1' width={60} height={60} />
                            <h4 className='text-dark'>
                                <span className='font-regular'>Username :</span>
                                {formFields.userName}
                            </h4>
                        </div>

                        <div className='my-5'>
                            <button className={`btn text-white  btn-blue w-100 ${styles.submitBtn} `} onClick={handleLogin} >
                                Sign in
                            </button>
                        </div>

                    </div>



                </div>




            </div>
        )
    }

    // function to render Form
    function renderFormHTML() {
        switch (renderForm) {
            case 'otp':
                return otpForm()
            case 'signup':
                return signupForm()
            case 'success':
                return successHTML()
            default:
                return
        }
    }

    return (
        <CustomPopup show={show} onClose={close} type={renderForm} goBack={renderForm === 'otp'} onBack={goBack} title={renderForm === 'otp' ? 'Please Enter Verifiction Code' : ''} tag={renderForm === 'otp' ? '(One Time Password)' : ''} >

            <div className="row justify-content-center">
                {renderFormHTML()}
            </div>
        </CustomPopup>
    )
}

export default SignupModal;