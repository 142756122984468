import React, { useEffect, useState } from 'react'
import Base from '../Layouts/Base'
import axios from 'axios'
import moment from "moment/moment";
import { useParams } from 'react-router-dom';

function convertToPlain(rtf) {
    var tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = rtf;
    return tempDivElement.textContent || tempDivElement.innerText || "";
}

const initial = {
    created: "",
    description: "",
    id: null,
    image: "",
    is_active: true,
    posted_date: null,
    show_in_beach_collective: true,
    show_in_beach_token: true,
    show_in_native_earth: false,
    slug: "",
    title: "",
    created_by : [[]]
}

const BlogDetail = () => {

    const { slug } = useParams()

    const [blog, setBlog] = useState(initial)

    useEffect(() => {
        async function getBlog() {
            const blog_res = await axios.get(`https://token.beachcollective.io/blog-api/${slug}`)
            const data = blog_res.data.data[0]
            setBlog(() => ({ ...data }))
        }
        getBlog()
    }, [])

    return (
        <Base>
            <div className='container'>
                <div className='mt-4'>
                    <h2 className='text-blue' style={{ lineHeight: 'normal' }}>{convertToPlain(blog.title)}</h2>
                </div>
                <div className='my-4 blog-image'>
                    <img className='img-fluid' src={`https://token.beachcollective.io/media/${blog.image}`} alt='img' />
                </div>
                <div className='d-flex gap-4 my-4 justify-content-between align-items-center'>
                    <div className='fs-3'>
                        <div className='d-flex gap-2 align-items-center'>
                            <img src={`https://token.beachcollective.io/media/${blog.created_by[0][3]}`} className='rounded-circle' style={{ objectFit: 'cover', border: '2px solid #007A97' }} width={50} height={50} />
                            <div>
                                <p className='m-0'>{blog.created_by[0][1]}</p>
                                <p className='m-0'>{blog.created_by[0][2]}</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <i class="far fa-calendar fs-3"></i>
                        <span className='ms-1 fs-3'>Posted on {moment(blog.posted_date).format("MMM D yyyy")}</span>
                    </div>
                </div>
                <div className='my-4 blog-body' dangerouslySetInnerHTML={{ __html: blog.description }} />
            </div>
        </Base>
    )
}

export default BlogDetail;