import React,{ useContext } from 'react'
import GlobalContext from '../../Contexts/GlobalContext';
import CustomPopup from "../Pop/CustomPopup";

const ActionPopup = ({show,close,signup,signin}) => {

  const {loggedIn} = useContext(GlobalContext)

  const openSignup = () =>{
    close(false)
    signup(true)
}
const openSignin = () =>{
    close(false)
    signin(true)
}


  return (
    <CustomPopup show={show}  onClose={()=>close(false)}  type='welcome'>
            
          <img src='/images/welcome-action-bg.webp' className='w-100 welcomePopupImage' />
          
          <div className='text-center mb-4'>
            <div className='text-uppercase text-white welcomePopup-heading'>
              <h3 className='mt-3'>
                    Beach Action
              </h3>
            </div>
           <div className='row justify-content-center'>
           <div className='col-10'>
           <p className='mb-5 text-dark'>
           On Beach Action you will be rewarded in $BEACH for the climate positive actions you take. 
           From picking up litter to shopping sustainably to planting a tree, any big or small action
           makes a difference.
           </p>
         </div>
           </div>
            
            {
              !loggedIn && <div className='row justify-content-center g-5 mb-5' >
                 <div className='col-5 col-md-3 d-flex justify-content-center'>
                 <button
                 className={`btn text-white btn-blue w-100 popupBtn`} 
                 type="button"
                 onClick={openSignin}
                 
               >
                 Sign in
               </button>
                   
                 </div>
                 <div className='col-5 col-md-3 d-flex justify-content-center'>
                   {/*<button className='btn btn-creme text-white' onClick={connectWallet} >Connect Wallet</button>*/}
                   <button
                 className={`btn text-white  blue-btn w-100 popupBtn`} 
                 type="button"
                 onClick={openSignup}
                 
               >
                 Sign up
               </button>
                 </div>
              
            </div>
          }
        </div>
    </CustomPopup>
  )
}

export default ActionPopup