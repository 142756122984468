import axios from 'axios'
import React, {useRef,useState} from 'react'
import { useEffect } from 'react'


const Guard = ({setAllow}) => {


    const inputRef = useRef(null)
    const [error,setError] = useState('')

    useEffect(()=>{
        inputRef.current.focus()
    })

    const allowUser = async () =>{

        let payload = JSON.stringify({
            'password': inputRef.current.value,
        })
        const response = await axios.post(`/user/protected-access-check/`,payload, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        let data = response.data

        if(response.statusText === 'OK'){
            setAllow(data.allow)
        }
        else{
            inputRef.current.value = ''
            setError('Password incorrect')
            setTimeout(()=>{
                setError('')
            },4000)
        }
    }

  return (
    <div className='container'>
        <div className="row justify-content-center align-items-center" style={{height:'100vh'}}>
            <div className='col-md-4 col-10 text-center'>
                <div className='form-row mb-4'>
                    <input ref={inputRef}  className='form-control text-center'  placeholder='Password' />
                </div>
               { error && <p className='text-danger text-center'>{error}</p>}
                <div>
                    <button className='btn-signup btn text-white' onClick={allowUser} >Submit</button>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Guard