import "./App.css";
import React, { useState } from "react";
import Home from "./Components/index/Home";
import { toast , ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./Components/Loader";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ActivationWithLink from "./Components/SignuPage/ActivationWithLink";
// import Index from "./Components/map/Index";
import NotFound from "./Components/NotFound";
// import ProfileIndex from "./Components/ProfileEdit/Index";
// import  BeachPay  from "./Components/BeachPay/BeachPay";
import useSessionStorage from "./hooks/useSessionStorage";
import { Provider } from "./Contexts/GlobalContext";
import useLocalStorage from "./hooks/useLocalStroage";
import Gallerypage from "./Components/Gallery/Gallerypage";
import Galleryuser from "./Components/Gallery/Galleryuser";
import About from "./Components/About/About";
import OurImpact from "./Components/Impact/OurImpact";
import Tokenomics from "./Components/Tokenomics/Tokenomics";
import Terms from './Pages/Terms'
import Disclouser from './Pages/Disclouser'

import ErrorNetwork from "./Components/ErrorNetwork";
import ErrorAddress from "./Components/ErrorAddress";
import BeachPayContext from "./Contexts/BeachPayContext";
import ImageCropper from "./Components/ImageCropper";
import SharePopup from "./Components/map/SharePopup";
import ReportModel from "./Components/map/ReportModel";
import axiosInstance from './Axios/axios.config'
import Guard from './Components/Login/Guard'
import CartContext from "./Contexts/CartContext";
import CarbonDetails from "./Pages/CarbonDetails";
import Blog from "./Pages/Blog";
import BlogDetail from "./Pages/BlogDetail";


// const LazyProfile = React.lazy(() => import("./Components/ProfileEdit/Index"));
const LazyMap = React.lazy(() => import("./Components/map/Index"));
const LazyBeachPay = React.lazy(() => import("./Components/BeachPay/BeachPay"));
const LazySignup = React.lazy(() =>import("./Components/SignuPage/SignupWithLink"));
const LazyImpact = React.lazy(() => import("./Pages/UserImpact"));
const LazyBusinessses = React.lazy(() => import("./Pages/Businesses"));
const LazyNotProfit= React.lazy(() => import("./Pages/NotForProfits"));
const Balance = React.lazy(() => import("./Pages/Balance"));
const Account = React.lazy(() => import("./Pages/Account"));
const Impact = React.lazy(() => import("./Pages/Impact"));
const ActivityTracker = React.lazy(() => import("./Pages/ActivityTracker"));
const Business = React.lazy(() => import("./Pages/Business"));
const NotForProfit = React.lazy(() => import("./Pages/NotForProfit"));
const Individual = React.lazy(() => import("./Pages/Individual"));
const LazyBeachActionHome = React.lazy(() => import("./Pages/BeachActionHome"));
const LazyBeachActionGallery = React.lazy(() => import("./Pages/BeachActionGallery"));

// shop
const LazyBeachShop = React.lazy(() => import("./Pages/BeachShop"));
const LazyBeachShopCarbon = React.lazy(() => import("./Pages/BeachShopCarbon"));
const LazyBeachShopCSR = React.lazy(() => import("./Pages/BeachShopCSR"));
const LazyBeachShopGallery = React.lazy(() => import("./Pages/BeachShopGallery"));
const LazyProductDetails = React.lazy(() => import("./Pages/ProductDetails"));
const LazyCart = React.lazy(() => import("./Pages/Cart"));
const LazyCheckout = React.lazy(() => import("./Pages/Checkout"));
const LazyOrderDetails = React.lazy(() => import("./Pages/OrderDetails"));
const LazyEnrollment = React.lazy(() => import("./Pages/Enrollment"));

const validate = (component,loginComponent,allow) =>{

  let rightComponent = allow  ? component :   loginComponent

  return rightComponent
}
// end shop

const toastOptions = {
  containerId:'notification',
  hideProgressBar:true,
  delay:7000,
}


const getNotification = async (setProfileModal='') => {


  let notification = await axiosInstance.get(`/user/new-activity-notification/`,{
    headers:{
      'Accept' : 'application/json'
    }
  });
  let notifData =  notification.data



    notifData.data.map(({activities})=>{

        activities.map(({ type,like,pay_request,pay_someone,post,paid_by_someone,given_to_post},i) =>      
    {
    let note;
    
     if(type === 'someone_like'){
      note = `${like?.user?.full_name? like?.user?.full_name : like?.user?.email ? like?.user?.email : 'Someone' }  liked your "${like?.post?.ACTIVITY}" post `
     }else if(type === 'paid_by_someone'){
      note = `You received ${pay_someone?.amount < 0.01 ? '<$0.01' : '$'+pay_someone?.amount} USD from ${pay_someone?.user?.full_name? pay_someone?.user?.full_name :  pay_someone?.user?.email ? pay_someone?.user?.email : 'someone' }`
     }else if(type === 'pay_request_notification'){
      note = `${pay_request?.code} Payment is requested by ${pay_request?.created_by?.full_name}`
     }else if(type === 'someone_raised_pay_request'){
      note = `${pay_request?.code} Pay Me request on behalf of you is generated by ${pay_request?.created_by?.full_name}`
     }
    

      let onClick = ()=>{
        if(type === 'someone_like'){
          return () =>{
            window.location= '/action/'+like?.post.W3W
          }
        }else if( type === 'given_to_post'){
          return () =>{
            window.location= '/action/'+given_to_post?.post.W3W
        } 
        }
        else if(type === 'paid_by_someone'){
          return () =>{
            window.location= '/pay/'   
          }
        }
      } 
    toast(note,{...toastOptions,onClick:onClick()})

    }
    )
    })

}

function App() {

  const [allow, setAllow] = useState(false) //gCiL#A84da1H

  const [walletAddress, setWalletAddress] = useState(null);
  const [user, setUser] = useState({
    id : '',
    default_pay :'',
    qrcode : '',
    image: '', 
    name: '',
    banner:'',
    email : '',
    type:  '',
    slug: '',
    description : '',
    company :''
  });

  const [showTutorial, setShowTutorial] = useLocalStorage(
    "showTutorial",
    false
  );

  const [userId, setUserId] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const [balance, setBalance] = useState(0);
  const [isSignup, setIsSignup] = useState(true);
  const [createdWithEmail, setCreatedWithEmail] = useState(null);

  const [loginWithEmail, setLoginWithEmail] = useState(false);
  const [connectedWalletType, setConnectedWalletType] = useLocalStorage(
    "connectedWalletType",
    null
  );
  const [checkWallet, setCheckWallet] = useState("")

  const [networkId, setNetworkId] = useLocalStorage("networkId", "");

  const [walletConnectionStatus, setWalletConnectionStatus] = useLocalStorage( "walletConnectionStatus",false);

  const [walletPalceHolder, setWalletPalceHolder] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const [openConnector, setOpenConnector] = useState(false);
  const [Web3provider, setWeb3Provider] = useLocalStorage("Web3provider", {});

  const [defaultConnector, setDefaultConnector] = useState(false);

  const [welcomePop, setWelcomePop] = useState(false);

  const [BagbeachTokenBscBalance, setBagBeachTokenBscBalance] = useState(0);

  const [BagbeachTokenBscBalanceUsd, setBagBeachTokenBscBalanceUsd] = useState(0);

  const [sessionEndPop, setSessionEndPop] = useState(false);

  const [userType,setUserType] = useState('')

  const [pageCount, setPageCount] = useState(1);
  const[maxPageCount,setMaxPageCount] = useState(20)

  const [isWithdraw,setIsWithdraw] = useState(true);


  const [networkModal,setNetworkModal] = React.useState(false)
  const [showNetworkAlert,setShowNetworkAlert] = React.useState(false)


  
  const [AddressModal,setAddressModal] = React.useState(false)
  // const [showAddressAlert,setShowAddressAlert] = React.useState(false)

  const [profileModal, setProfileModal] = useState(false);


  // Beachpay states

  const [activeTab, setActiveTab] = useState('home')

  const [sendTo, setSendTo] = useState({
    name: '',
    wallet_add:'',
    image: '',
    email:''
  })

  const [sendFor, setSendFor] = useState('send')

  const [payCode, setPayCode] = useState("");

  const [sharePopup, setSharePopup] = React.useState(false)
  const [sharePostId, setSharePostId] = React.useState('')

  const [reportPopup,setReportPopup] = useState(false)
  const [reportPostId,setReportPostId] = useState('')

  const [sharingLink, setSharingLink] = useState(false);

  const [loginPopup, setLoginPopup] = useState(false);

  
  const [showPostControl, setShowPostControl] = React.useState(false)

  const[postingType,setPostType] = React.useState('')

  React.useEffect(() => {
    let interval;
    if (window.location.hash) {
      let last = window.location.hash.split("/");
      if (last[last.length - 1] === "all") window.location.href = "/action";
      else window.location.href = "/action/" + last[last.length - 1];
    }
    if(loggedIn){
      interval = setInterval(()=>{
        getNotification(setProfileModal)
      },1000 * 60 * 60)
    }
    return (()=>{
      clearInterval(interval)
    })

  }, [loggedIn]);

  React.useLayoutEffect(()=>{

    const getUser = async () =>{
    let loginStatus = localStorage.getItem('loggedIn')

    
    if(loginStatus){
      let userResponse = await axiosInstance.get('/user/user-details/')

      if(userResponse.statusText === 'OK'){

      setLoggedIn(true)
        
      let userData = userResponse.data

      if(userData.data.wallet_address) {
        setWalletAddress(userData.data.wallet_address)
      }
      else {
          if (userData.data.full_name) {
              setWalletPalceHolder(userData.data.full_name)
          } else {
              setWalletPalceHolder(userData.data.username)
          }
      }

      setLoginWithEmail(userData.data.current_login_with_email)
      setUserId(userData.data.id)
      setWelcomePop(userData.data.show_first_info)
      setShowTutorial(userData.data.tutorial_flag)
      setBalance(userData.data.beach_balance)
      setIsSignup(userData.data.is_active)
      setUser({
        id: userData.data.id,
        default_pay: userData.data.defaul_beach_pay_user,
        qrcode: userData.data.qrcode,
        image: userData.data.image,
        name: userData.data.full_name,
        email: userData.data.email,
        type: userData.data.user_type,
        description: userData.data.description,
        banner: userData.data.banner,
        company: userData.data.name_or_campany_name,
        disable_bag: userData.data.disable_bag_flag,
        bag_type: userData.data.disable_bag_type,
        slug: userData.data.slug_url,
        permissions: userData.data.user_permissions,
        native_permission: userData.data.native_permission,
        refferal_qr_code : userData.data.refferal_qr_code
    })
    let updatedDetails = await axiosInstance.get( `/user/wallet-details/`);

    let details =  updatedDetails.data

    // setLimitValue(details.data.withdrawl_limit_without_wallet_connection)

    setBalance(details.data.total_balance_usd)

    setBagBeachTokenBscBalance(details.data.beach_bsc_balance)

    setBagBeachTokenBscBalanceUsd(parseFloat(details.data.beach_bsc_balance_usd).toFixed(2))

      }
    }
  }
    getUser()
},[])

  return (
    <React.Fragment>
    <Provider
      value={{
        allow,
        userId,
        setUserId,
        walletAddress,
        setWalletAddress,
        user,
        setUser,
        loggedIn,
        setLoggedIn,
        balance,
        setBalance,
        isSignup,
        setIsSignup,
        loginWithEmail,
        setLoginWithEmail,
        setWalletPalceHolder,
        walletPalceHolder,
        connectedWalletType,
        setConnectedWalletType,
        walletConnectionStatus,
        setWalletConnectionStatus,
        networkId,
        setNetworkId,
        setOpen,
        isOpen,
        openConnector,
        setOpenConnector,
        Web3provider,
        setWeb3Provider,
        defaultConnector,
        setDefaultConnector,
        welcomePop,
        setWelcomePop,
        BagbeachTokenBscBalance,
        setBagBeachTokenBscBalance,
        BagbeachTokenBscBalanceUsd,
        setBagBeachTokenBscBalanceUsd,
        showTutorial,
        setShowTutorial,
        createdWithEmail,
        setCreatedWithEmail,
        sessionEndPop,
        setSessionEndPop,
        pageCount, 
        setPageCount,
        maxPageCount,
        setMaxPageCount,
        userType,
        setUserType,
        checkWallet, 
        setCheckWallet,
        isWithdraw,
        setIsWithdraw,
        setNetworkModal,
        showNetworkAlert,
        setShowNetworkAlert,
        AddressModal,
        setAddressModal,
        profileModal,
        setProfileModal,
        showPostControl, 
        setShowPostControl,
        setSharePopup,
        setSharePostId,
        setReportPopup,
        setReportPostId,
        sharingLink, 
        setSharingLink,
        loginPopup, 
        setLoginPopup,
        postingType,
        setPostType
      }}
    >
      <CartContext>
      <BeachPayContext.Provider value={{activeTab, setActiveTab,sendTo, setSendTo,sendFor, setSendFor,payCode, setPayCode}}>
      <Router>
        <React.Suspense fallback={<Loader />}>
          <Routes>
            <Route  path="/" element={ <Home /> } />

            <Route  path="/action"  element={<LazyBeachActionHome />}></Route>

            <Route  path="/action/feed"  element={<LazyBeachActionGallery />}></Route>

            <Route path="/action/map" element={<LazyMap />}>
              <Route path=":add" element={<LazyMap />}></Route>
            </Route>

           <Route
              path="/beachaction"
              element={<Navigate to="/action" replace />}
            />

           {/*Route path="/pay" element={<Navigate to="/beachpay" replace />} />*/}

           {/*<Route path="profileEdit" element={<LazyProfile />}></Route>*/}

            <Route path="/gallery/page/:page" exact element={<Gallerypage />}></Route>
            <Route path="/gallery/:username" exact element={<Galleryuser />}></Route>

            <Route path="/pay" element={<LazyBeachPay />}></Route>

            <Route path="sign-up/:username" element={<LazySignup />}></Route>

            <Route path="impact/:username" element={<LazyImpact />}></Route>


            <Route path="businesses" element={<LazyBusinessses />} />

            <Route path="notforprofits" element={<LazyNotProfit />} />


            {loggedIn && <Route path="balance" element={<Balance />} />}

            {loggedIn && <Route path="account" element={<Account />} />}

            {loggedIn && <Route path="impact" element={<Impact />} />}

            {loggedIn && <Route path="activity" element={<ActivityTracker />} />}


            <Route path="business/:user" element={<Business />} />

            <Route path="not-for-profit/:user" element={<NotForProfit />} />

            <Route path="individual/:user" element={<Individual />} />

            <Route path="image" element={<ImageCropper />} />

            <Route
              path="activation/:uid"
              element={<ActivationWithLink />}
            ></Route>
            <Route
              path="/about"
              element={<About />}
            />
            <Route
              path="/terms-and-conditions"
              element={<Terms />}
            />
            <Route
              path="/risk-disclosure"
              element={<Disclouser />}
            />
            <Route
              path="/our-impact"
              element={<OurImpact />}
            />
            <Route
              path="/tokenomics"
              element={<Tokenomics />}
            />

            {/* shop */}
            <Route path="/shop" element={validate(<LazyBeachShop />,<Guard setAllow={setAllow} />,allow)} >
              <Route path=":category"  element={validate(<LazyBeachShop />,<Guard setAllow={setAllow} />,allow)} />
            </Route>

          { loggedIn && (user.type === 'Business' || user.type === 'Not-for-profit') && <React.Fragment>

           {user.permissions.includes('shop.carbon') && <Route path="/shop/carbon" element={validate(<LazyBeachShopCarbon />,<Guard setAllow={setAllow} />,allow)} />}

           {user.permissions.includes('shop.csr') && <Route path="/shop/csr" element={validate(<LazyBeachShopCSR />,<Guard setAllow={setAllow} />,allow)} /> }
    </React.Fragment> }

    {
      walletAddress && (user.type === 'Business' || user.type === 'Not-for-profit') && <Route path="/enroll" element={validate(<LazyEnrollment />,<Guard setAllow={setAllow} />,allow)} />
    }

            <Route path="/view-details/:id" element={validate(<LazyProductDetails />,<Guard setAllow={setAllow} />,allow)} />

            <Route path="/shop/cart" element={validate(<LazyCart />,<Guard setAllow={setAllow} />,allow)} />

            <Route path="/shop/feed" element={validate(<LazyBeachShopGallery/>,<Guard setAllow={setAllow} />,allow)} />

            <Route path="/shop/check-out" element={validate(<LazyCheckout />,<Guard setAllow={setAllow} />,allow)} />

            <Route path="/shop/order-history" element={validate(<LazyOrderDetails />,<Guard setAllow={setAllow} />,allow)} />
            {/* end shop */}

            <Route path="/carbon-details/:id" element={validate(<CarbonDetails />,<Guard setAllow={setAllow} />,allow)} />

            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<BlogDetail />} />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </React.Suspense>
      </Router>
      </BeachPayContext.Provider>
      </CartContext>
      <ErrorNetwork show={networkModal} close={setNetworkModal} /> 
      <ErrorAddress show={AddressModal} close={setAddressModal} /> 
      <ReportModel  show={reportPopup} setShow={setReportPopup} clearReportPost={setReportPostId} reportPost={reportPostId} />
    </Provider>
    <SharePopup show={sharePopup} setShow={setSharePopup} w3w={sharePostId} />
   
    <ToastContainer
                    position="top-right"
                    limit={1}
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    containerId={'toast'}
                    rtl={false}
                    enableMultiContainer
                    pauseOnFocusLoss={false}
                    draggable={false}
                    pauseOnHover
                  />
    <ToastContainer enableMultiContainer  limit={1} containerId={'notification'} position={toast.POSITION.TOP_RIGHT} /> 
  </React.Fragment>
  );
}

export default App;
