import React, { useContext, useEffect, useState } from "react";
import GlobalContext from "../../Contexts/GlobalContext";
import VideoElement from "../map/VideoElement";
// import ReportModel from "../map/ReportModel";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { BiDownArrow, BiUpArrow } from "react-icons/bi";
import { BsFillEyeFill } from "react-icons/bs";
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import { AiFillCaretDown , AiOutlineSave} from "react-icons/ai";
import { BiDotsVerticalRounded } from "react-icons/bi";
import {MdOutlineSearch , MdModeEdit} from 'react-icons/md'
import Select, { components } from "react-select";
import DatePicker from "rsuite/DatePicker";
import "rsuite/dist/rsuite.min.css";
import { Link, useParams, useNavigate } from "react-router-dom";
import BeachPayContext from "../../Contexts/BeachPayContext";
import NotFound from '../NotFound'
import axios from 'axios'
import axiosInstance from "../../Axios/axios.config";


const CategoryOptions = [
  { value: "default", label: "Category" },

  { value: "Cleaning", label: "Cleaning" },

  { value: "Footprint", label: "Footprint" },

  { value: "Kindness", label: "Giving" },

  { value: "Planting", label: "Planting" },

  { value: "Conservation", label: "Conservation" },

  { value: "Wildlife", label: "Nature" },
];

const LocationOptions = [
  { value: "default", label: "Location" },
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Albania", label: "Albania" },
  { value: "Algeria", label: "Algeria" },
  { value: "American Samoa", label: "American Samoa" },
  { value: "Andorra", label: "Andorra" },
  { value: "Angola", label: "Angola" },
  { value: "Anguilla", label: "Anguilla" },
  { value: "Antarctica", label: "Antarctica" },
  { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
  { value: "Argentina", label: "Argentina" },
  { value: "Armenia", label: "Armenia" },
  { value: "Aruba", label: "Aruba" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Barbados", label: "Barbados" },
  { value: "Belarus", label: "Belarus" },
  { value: "Belgium", label: "Belgium" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Benin" },
  { value: "Bermuda", label: "Bermuda" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Bolivia", label: "Bolivia" },
  { value: "Bonaire", label: "Bonaire" },
  { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
  { value: "Botswana", label: "Botswana" },
  { value: "Bouvet Island", label: "Bouvet Island" },
  { value: "Brazil", label: "Brazil" },
  { value: "Brunei Darussalam", label: "Brunei Darussalam" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Burundi", label: "Burundi" },
  { value: "Cabo Verde", label: "Cabo Verde" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Canada", label: "Canada" },
  { value: "Cayman Islands", label: "Cayman Islands" },
  { value: "Central African Republic", label: "Central African Republic" },
  { value: "Chad", label: "Chad" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Christmas Island", label: "Christmas Island" },
  { value: "Cocos Islands", label: "Cocos Islands" },
  { value: "Colombia", label: "Colombia" },
  { value: "Comoros", label: "Comoros" },
  { value: "Congo", label: "Congo" },
  { value: "Congo", label: "Congo" },
  { value: "Cook Islands", label: "Cook Islands" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Croatia", label: "Croatia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Curaçao", label: "Curaçao" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Czechia", label: "Czechia" },
  { value: "Côte d'Ivoire", label: "Côte d'Ivoire" },
  { value: "Denmark", label: "Denmark" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominican Republic", label: "Dominican Republic" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Egypt", label: "Egypt" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Estonia", label: "Estonia" },
  { value: "Eswatini", label: "Eswatini" },
  { value: "Ethiopia", label: "Ethiopia" },
  { value: "Falkland Islands", label: "Falkland Islands" },
  { value: "Faroe Islands", label: "Faroe Islands" },
  { value: "Fiji", label: "Fiji" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "French Guiana", label: "French Guiana" },
  { value: "French Polynesia", label: "French Polynesia" },
  {
    value: "French Southern Territories",
    label: "French Southern Territories",
  },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia", label: "Gambia" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Ghana", label: "Ghana" },
  { value: "Gibraltar", label: "Gibraltar" },
  { value: "Greece", label: "Greece" },
  { value: "Greenland", label: "Greenland" },
  { value: "Grenada", label: "Grenada" },
  { value: "Guadeloupe", label: "Guadeloupe" },
  { value: "Guam", label: "Guam" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guernsey", label: "Guernsey" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haiti" },
  {
    value: "Heard Island and McDonald Islands",
    label: "Heard Island and McDonald Islands",
  },
  { value: "Holy See", label: "Holy See" },
  { value: "Honduras", label: "Honduras" },
  { value: "Hong Kong", label: "Hong Kong" },
  { value: "Hungary", label: "Hungary" },
  { value: "Iceland", label: "Iceland" },
  { value: "India", label: "India" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Iran", label: "Iran" },
  { value: "Iraq", label: "Iraq" },
  { value: "Ireland", label: "Ireland" },
  { value: "Isle of Man", label: "Isle of Man" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Jersey", label: "Jersey" },
  { value: "Jordan", label: "Jordan" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kiribati", label: "Kiribati" },
  { value: "Korea", label: "Korea" },
  { value: "Korea", label: "Korea" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  {
    value: "Lao People's Democratic Republic",
    label: "Lao People's Democratic Republic",
  },
  { value: "Latvia", label: "Latvia" },
  { value: "Lebanon", label: "Lebanon" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libya", label: "Libya" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Macao", label: "Macao" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Maldives", label: "Maldives" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  { value: "Martinique", label: "Martinique" },
  { value: "Mauritania", label: "Mauritania" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mayotte", label: "Mayotte" },
  { value: "Mexico", label: "Mexico" },
  { value: "Micronesia", label: "Micronesia" },
  { value: "Moldova", label: "Moldova" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Montserrat", label: "Montserrat" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Namibia", label: "Namibia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Nepal" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "New Caledonia", label: "New Caledonia" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "Niue", label: "Niue" },
  { value: "Norfolk Island", label: "Norfolk Island" },
  { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
  { value: "Norway", label: "Norway" },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palau" },
  { value: "Palestine, State of", label: "Palestine, State of" },
  { value: "Panama", label: "Panama" },
  { value: "Papua New Guinea", label: "Papua New Guinea" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Philippines", label: "Philippines" },
  { value: "Pitcairn", label: "Pitcairn" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Puerto Rico", label: "Puerto Rico" },
  { value: "Qatar", label: "Qatar" },
  {
    value: "Republic of North Macedonia",
    label: "Republic of North Macedonia",
  },
  { value: "Romania", label: "Romania" },
  { value: "Russian Federation", label: "Russian Federation" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Réunion", label: "Réunion" },
  { value: "Saint Barthélemy", label: "Saint Barthélemy" },
  {
    value: "Saint Helena, Ascension and Tristan da Cunha",
    label: "Saint Helena, Ascension and Tristan da Cunha",
  },
  { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
  { value: "Saint Lucia", label: "Saint Lucia" },
  { value: "Saint Martin", label: "Saint Martin" },
  { value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon" },
  {
    value: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines",
  },
  { value: "Samoa", label: "Samoa" },
  { value: "San Marino", label: "San Marino" },
  { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia", label: "Serbia" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapore" },
  { value: "Sint Maarten", label: "Sint Maarten" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Solomon Islands", label: "Solomon Islands" },
  { value: "Somalia", label: "Somalia" },
  { value: "South Africa", label: "South Africa" },
  { value: "South Georgia ", label: "South Georgia" },
  { value: "South Sudan", label: "South Sudan" },
  { value: "Spain", label: "Spain" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Sudan", label: "Sudan" },
  { value: "Suriname", label: "Suriname" },
  { value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen" },
  { value: "Sweden", label: "Sweden" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Syrian Arab Republic", label: "Syrian Arab Republic" },
  { value: "Taiwan", label: "Taiwan" },
  { value: "Tajikistan", label: "Tajikistan" },
  { value: "Tanzania", label: "Tanzania" },
  { value: "Thailand", label: "Thailand" },
  { value: "Timor-Leste", label: "Timor-Leste" },
  { value: "Togo", label: "Togo" },
  { value: "Tokelau", label: "Tokelau" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
  { value: "Tunisia", label: "Tunisia" },
  { value: "Turkey", label: "Turkey" },
  { value: "Turkmenistan", label: "Turkmenistan" },
  { value: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Uganda", label: "Uganda" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "United Arab Emirates", label: "United Arab Emirates" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "United States", label: "United States" },
  { value: "United States of America", label: "United States of America" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Uzbekistan", label: "Uzbekistan" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Viet Nam", label: "Viet Nam" },
  { value: "Virgin Islands (British)", label: "Virgin Islands (British)" },
  { value: "Virgin Islands (U.S.)", label: "Virgin Islands (U.S.)" },
  { value: "Wallis and Futuna", label: "Wallis and Futuna" },
  { value: "Western Sahara", label: "Western Sahara" },
  { value: "Yemen", label: "Yemen" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" },
  { value: "Åland Islands", label: "Åland Islands" },
];

const selectStyles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #007697",
    borderRadius: "40px",
    padding: "5px 4px",
    minHeight: "20px",
    fontSize: "1rem",
    color: "#007697",
    "&:hover": {
      border: "1px solid #007697",
    },
  }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#e8b078" : isSelected ? "#e8b078" : null,
      color: isFocused ? "#fff" : "#33333",
    };
  },
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
  dropdownIndicator: (styles) => ({ ...styles, padding: "0 5px" }),
};

const CaretDownIcon = () => {
  return <AiFillCaretDown color="#007a97" />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};


const Galleryuser = () => {

    const {username} = useParams()

  

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);



  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  const [filterDate, setFilterDate] = useState(null);
  const [locationFilter, setLocationFilter] = useState(LocationOptions[0]);
  const [categoryFilter, setCategoryFilter] = useState(CategoryOptions[0]);

  const [smPosts, setSmPosts] = useState([]);
  const [mdPosts, setMdPosts] = useState([]);

  const [reportPost, setReportPost] = React.useState("");

  const [openReport, setReportPop] = React.useState(false);

  const [next, setNext] = React.useState("");

  const { walletAddress, walletPalceHolder} = useContext(GlobalContext);

  const [search, setSearch] = React.useState("");

  const [isClickable,setClickable] = useState(true)

  const [searchByType, setSearchByType] = React.useState(false);

  const [notFound, setNotFound] = React.useState(false);


  const loadPostsOnScroll = React.useCallback(
    async (e) => {
      // const bottom = e.target.scrollHeight <= e.target.scrollTop + e.target.offsetHeight;

      const windowHeight =
        "innerHeight" in window
          ? window.innerHeight
          : document.documentElement.offsetHeight;
      const body = document.body;
      const html = document.documentElement;
      const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
      );
      const windowBottom = Math.round(windowHeight + window.pageYOffset);

      const bottom = windowBottom >= docHeight;

      if (bottom) {
        
          if (next) {
            const response = await axios.post(next.slice(axios.defaults.baseURL.length),JSON.stringify({'user':username}),{
                headers: {
                    "Content-Type": "application/json",
                  }
              });

              if(response.statusText === 'OK'){
                const data =  response.data;

                let temp = [...smPosts, ...data?.data];
                setSmPosts(temp);

                setNext(data?.next);
              }else{
                setNotFound(true)
               }
            
          }
        }
    },
    [next, smPosts, walletAddress, walletPalceHolder]
  );



  useEffect(() => {
    window.addEventListener("scroll", loadPostsOnScroll);
    return () => {
      window.removeEventListener("scroll", loadPostsOnScroll);
    };
  }, [loadPostsOnScroll]);

//   const setGive = (user) => {
//     openGive(true);
//     setGiveTo(user);
//   };

//   const setReport = (post) => {
//     setReportPop(true);
//     setReportPost(post);
//   };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * 9) % mdPosts.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    MapSearchResult(searchByType);
  }, [filterDate, locationFilter, categoryFilter, itemOffset,searchByType]);

  async function MapSearchResult(bySearch){
    let response;
    let urlString = '';
    // console.log(filterDate, locationFilter.value, categoryFilter.value);
    let newFilterDate = filterDate;
    let newlocationFilter = locationFilter.value;
    let newCategoryFilter = categoryFilter.value;

    let activity_name = ''
    if(search){
      activity_name = search

      for(let i=0 ; i < activity_name.length ; i++){
        activity_name = activity_name.replace(" ","_")
      }

    }
    if(search && bySearch){
      urlString = `&activity=${activity_name}`;
    }

    if (newFilterDate) {
      urlString = `&date=${newFilterDate}`;
    }
    if (newlocationFilter !== "default") {
      urlString += `&location=${newlocationFilter}`;
    }
    if (newCategoryFilter !== "default") {
      urlString += `&category=${newCategoryFilter}`;
    } 

    
      if (window.innerWidth > 767) {
       
        response = await axios.post(`/map/user-activity-post/?all=true${urlString}`,JSON.stringify({'user':username}),
          {
            headers: {
                "Content-Type": "application/json",
              }
          }
        );

        if(response.statusText === 'OK'){
          const data =  response.data;
          // console.log('gallery-data-->',data)
          setMdPosts(data?.data);
          const endOffset = itemOffset + 9;
          setCurrentItems(data?.data.slice(itemOffset, endOffset));
          setPageCount(Math.ceil(data?.data.length / 9));
        }else{
          setNotFound(true)
         }     
      } else {
        response = await axios.post(`/map/user-activity-post/?all=true${urlString}`,JSON.stringify({'user':username}),
          {
            headers: {
                "Content-Type": "application/json",
              }
          }
        );

        if(response.statusText === 'OK'){
          const data =  response.data;
          let temp = [...smPosts, ...data?.data];
          setSmPosts(temp);
          if(next) {
            setNext(data?.next);
          }
       }else{
        setNotFound(true)
       }
      }
    
  };

  const handleDateFilter = (value) => {
    if (value) {
      let date = value.getDate() < 10 ? "0" + value.getDate() : value.getDate();
      let month =
        value.getMonth() + 1 < 10
          ? "0" + (value.getMonth() + 1)
          : value.getMonth() + 1;
      let year = value.getFullYear();
      setFilterDate(year + "-" + month + "-" + date);
      setClickable(false)
    }
  };

  const handleSearchType = (e)=>{
    setSearch(e.target.value)
    setClickable(false)
  } 
    

  const handleSearch = () =>{
    setSearchByType(true)
  } 

  const serachOnEnter = (event) =>{
    if (event.keyCode === 13){
      handleSearch()
    }
  }

  const handleCategoryFilter = (category) =>{
    setCategoryFilter(category)
 
    setClickable(false)
  } 
  const handleLocationFilter = (location) =>{
    setLocationFilter(location)
    // let temp = []
    // showPost.forEach(post =>{
    //     if(post.LOCATION === location.value){
    //         temp.push(post)
    //     }
    // })
    // setPosts(temp)
    setClickable(false)
  } 

  const handleReset = () =>{
    setSearchByType(false)
    setSearch(()=>'')
    setLocationFilter(LocationOptions[0])
    setCategoryFilter(CategoryOptions[0])
    setFilterDate('')
    setClickable(true)
  } 
  
  const deletePost = async (id) =>{
  
    const response = await axiosInstance.get(`/map/activity-delete-id/${id}/`);

    if(response.statusText === 'OK'){
     MapSearchResult()
    }

  }

  const handleEdit = async (id,title,setEdit)=>{

    let formData = new FormData();

    formData.append("activity", title);

    const response = await axiosInstance.post(`/map/map-data-update/${id}/`,formData,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest"
        }
      }
    );

    if (response.statusText === 'OK') {
      setEdit(false)
      MapSearchResult()
    }
  }

  return (
    notFound
    ?
    <NotFound />
    :
    <div className="gallery_page">
    <div className="text-end mb-3 me-lg-3 pe-3">
    <button
    className="btn btn-creme p-2 me-2 text-white "
    type="button"
    id="ViewButton"
    data-bs-toggle="dropdown"
    aria-expanded="false"
  >
    <BsFillEyeFill size={20} color={"#fff"} />
  </button>
  <ul
    className="dropdown-menu p-0"
    id="dropdownMenu1"
    aria-labelledby="ViewButton"
  >
    <li className="dropdown-item p-0">
      <Link className="anchorBtn w-100" to={'/action'}>
        Map
      </Link>
    </li>
    <li className="dropdown-item p-0 btn-creme ">
      <Link className="anchorBtn w-100 text-white" to={'/gallery/'+username}>
        Gallery
      </Link>
    </li>
  </ul>
</div>
      <div className="gallery_filter_container">
      <div className="gallery_search_wrapper">
      <p className="my-3"></p>
      <div className="gallery_search_container">
        <input className="gallery_search_input" placeholder="Search" value={search} onKeyUp={serachOnEnter} onChange={handleSearchType} />
        <button  className="btn btn-transparent gallery_search_btn" onClick={handleSearch}>
            <MdOutlineSearch size={20} />
        </button>
      </div>
      </div>
      <div className="gallery_sort">
        <p className="text-center text-blue mb-1">Sort By</p>
        <div className="sort_controls_container">
          <Select
            options={CategoryOptions}
            isSearchable={false}
            className="gallery_select"
            isClearable={false}
            onChange={handleCategoryFilter}
            name={"category"}
            components={{ DropdownIndicator }}
            styles={selectStyles}
            value={categoryFilter}
          />
          <DatePicker
            className="custom_datepicker gallery_select"
            onClean={() => setFilterDate("")}
            onChange={handleDateFilter}
            placeholder="Date"
          />
          <Select
            options={LocationOptions}
            isSearchable={false}
            isClearable={false}
            className="gallery_select"
            onChange={handleLocationFilter}
            name={"location"}
            components={{ DropdownIndicator }}
            styles={selectStyles}
            value={locationFilter}
          />
          <button  className="btn filter_restBtn gallery_select" disabled={isClickable} onClick={handleReset} >
              Reset
          </button>
        </div>
      </div>
      </div>

      <div className="d-none d-md-block">
        <div className="d-grid gallery-grid ">
          {currentItems.map((post, i) => {
            return (
              <MdCard
                {...post}
                deletePost={deletePost}
                handleEdit={handleEdit}
                key={i}
                
              />
            );
          })}
        </div>
        <ReactPaginate
          breakLabel={<IoEllipsisVerticalSharp />}
          pageClassName="pagination_li"
          previousLabel={<BiUpArrow />}
          onPageChange={handlePageClick}
          pageRangeDisplayed={2}
          pageCount={pageCount}
          breakClassName="ellipses"
          containerClassName="pagination_react_custom"
          nextLabel={<BiDownArrow />}
          previousClassName="nav_buttons"
          activeClassName="page_active"
          nextClassName="nav_buttons"
          renderOnZeroPageCount={null}
        />
      </div>

      <div className="d-block d-md-none">
        <div className="d-grid gallery-grid ">
          {smPosts.map((post, i) => {
            return (
              <MdCard
                {...post}
                deletePost={deletePost}
                handleEdit={handleEdit}
                key={i}
              />
            );
          })}
        </div>
      </div>
      {/*<ReportModel
        show={openReport}
        setShow={setReportPop}
        reportPost={reportPost}
        updatePosts={setSmPosts}
        clearReportPost={setReportPost}
        />*/}
    </div>
  );
};

const MdCard = ({
  id,
  category,
  ACTIVITY,
  created,
  image_1,
  like_flag,
  user,
  setGive,
  W3W,
  openPostOnMap,
  total_likes,
  deletePost,
  report_flag,
  handleEdit
}) => {
  const { walletAddress, walletPalceHolder, loggedIn, setSharePopup, setSharePostId, setReportPostId , setReportPopup } = useContext(GlobalContext);

  const redirect = useNavigate()

  const {setActiveTab,setSendTo,setSendFor} = useContext(BeachPayContext)

  const [like, setLike] = useState(like_flag);
  
  const [readMore, setReadMore] = useState(false);

  const [likeCount, setLikeCount] = useState(total_likes);

  const [edit, setEdit] = useState(false);

  const [title, setTitle] = useState(ACTIVITY);

  const handelLike = async (id) => {
    if(loggedIn){
     
      const response = await axiosInstance.get(`/map/post-like/${id}/`);
  
      if (response.statusText === 'OK') {
        const res =  response.data;
        setLike(res.like_flag);
        setLikeCount(res.total_likes);
      }
    }
  };

  

  async function handleSendNext(payload){

    let jsonPayload = JSON.stringify({
      search: payload
    })
    
    let response = await axiosInstance.post(`/user/user-search/`,jsonPayload,
      {
        headers: {
          "Content-Type": "application/json"
        },
      }
    );

    let res =  response.data


    // console.log('next res-->', res )

    if (response.statusText === 'OK') {
        setSendTo(prev=>({...prev, name: res.data.full_name, image: res.data.image,wallet_add : res.data.wallet_address , email : res.data.email }))
        setSendFor('send_confirm')
        setActiveTab('send')
        redirect('/pay')   
    }
  }

  const handleShare = (postId) =>{
    setSharePostId(postId)
    setSharePopup(true)
  }

  const handleReport = (postId) =>{
    setReportPostId(postId)
    setReportPopup(true)
  }

  return (
    <div className="card border-0 p-2 gallery-card">
    <div className="d-flex justify-content-between align-items-center p-1">
    <div className="d-flex align-items-center">
    
  {

   user.user_type !== 'Individual' ? (
      <Link className="d-flex align-items-center userNameLink" to={user.user_type === 'Business' ? `/business/${user.slug_url}` : `/not-for-profit/${user.slug_url}`}>
          <img
          className="rounded-circle"
          src={
            user.image
              ? user.image
              : "https://api.beachcollective.io/media/default-user.png"
          }
          width={"25"}
          height={"25"}
          alt={""}
        />
        <p  className="text-blue mb-0 ms-1">{user.full_name}</p>
      </Link>
    )
    :

    (
      <React.Fragment>
          <img
          className="rounded-circle"
          src={
            user.image
              ? user.image
              : "https://api.beachcollective.io/media/default-user.png"
          }
          width={"25"}
          height={"25"}
          alt={""}
        />
        <p  className="text-blue mb-0 ms-1">{user.full_name}</p>
      </React.Fragment>
    )
  }
  
  
  <small className="text-blue d-flex justify-content-between fs-6 pb-2 pb-md-0">
    <span
      className={`px-3  ms-2 d-flex align-items-center  text-white rounded-25 badge ${category }`}
      style={{ fontSize: "10px" }}
    >
      {category}
    </span>
    <Link
      to={`/action/${W3W}`}
      className="ms-3 d-block d-sm-inline"
      style={{ fontSize: "12px", cursor: "pointer", color:'#007a97', textDecoration:'none' }}     
    >
      {moment(created).isSame(new Date(), "D")
        ? moment(created).format("LT, [Today]")
        : moment(new Date()).isSame(moment(created).add(1, "day"), "D")
        ? moment(created).format("LT, [Yesterday]")
        : moment(created).format("LT, ddd DD MMM, YYYY")}
    </Link>
  </small>
  {
    ( (user.wallet_address && walletAddress == user.wallet_address) || walletPalceHolder == user.full_name  || walletPalceHolder == user.email) &&
   <span>
      {
        edit ?
      <button className="bg-transparent" onClick={()=>handleEdit(id,title,setEdit)}>
      <AiOutlineSave color={'007a97'} size={20} />
      </button>
      :
      <button className="bg-transparent" size={20} onClick={()=>setEdit(true)}>
      <MdModeEdit color={'007a97'} />
      </button>
    }
    </span>
   }
    </div>
   {
    ( (user.wallet_address && walletAddress == user.wallet_address) || walletPalceHolder == user.full_name  || walletPalceHolder == user.email) &&
      <div class="dropdown">
       <button class="btn btn-transparent p-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
         <BiDotsVerticalRounded />
       </button>
       <ul class="dropdown-menu" style={{minWidth:'50px'}} aria-labelledby="dropdownMenuButton1">
         <li className="px-2" onClick={()=>deletePost(id)} style={{cursor:'pointer'}} >Delete</li>
       </ul>
     </div>
   }
   </div>
      {image_1?.split("/")[image_1?.split("/").length - 1].indexOf(".mp4") >
      0 ? (
        <VideoElement src={image_1} />
      ) : (
        <img
          src={image_1 ? image_1 : `/images/${category}.svg`}
          alt="img"
          className="main_img"
        />
      )}

      <div className="py-2">
    {/*<Link className='actionBtn shopBtn' to={`/gallery/${user.slug_url}`}>
      Actions
      </Link> */}  
        <button className='actionBtn payBtn mx-1' onClick={()=>handleSendNext(user.full_name)}>Pay</button>
        <Link className='actionBtn webBtn' to={ user?.user_type === 'Individual' ? `/individual/${user.slug_url}` : user?.user_type === 'Business' ? `/business/${user.slug_url}` : `/not-for-profit/${user.slug_url}` }>
        Profile
      </Link> 
    </div>

      <div className="card-body d-flex flex-column flex-xl-row p-0 pt-2">
      {
        edit ?
        <textarea rows={'2'} value={title} style={{width:'100%',display:'block',resize:'none'}} onChange={(e)=>setTitle(e.target.value)} />
        :
        <p
        className="text-blue flex-grow-1 m-0"
        style={{ fontSize: "12px", lineHeight: "normal" }}
      >
        { ACTIVITY?.length > 95 ?  ACTIVITY?.slice(0,95)+'...' : ACTIVITY   }

        { ACTIVITY?.length > 95 && <span className="readMoreBtn" onClick={()=>setReadMore(!readMore)} >{readMore ? 'Read less' : 'Read more'}</span>}
      </p>
    }
        <div className="d-flex mt-2 mt-xl-0  ms-2 align-items-center">
          <div className="d-flex flex-column align-items-center">
            <button
              className="btn outline-none gallery_action_btn  btn-creme rounded-circle p-1"
              onClick={() => handelLike(id)}
              type="button"
            >
              <span className="me-1 text-white" style={{ fontSize: "10px" }}>
                {likeCount}
              </span>
              <img
                src={like ? "/images/sandLike.svg" : "/images/gallery-like.svg"}
                height={"14"}
                alt="option"
              />
            </button>
            <span
              className="mt-1"
              style={{
                color: "#DA9D5F",
                fontSize: "8px",
                lineHeight: "normal",
              }}
            >
              Like
            </span>
          </div>
          <div className="d-flex flex-column align-items-center">
            <button
              className="btn outline-none btn-creme gallery_action_btn  rounded-circle p-1"
              type="button"
              onClick={()=>handleShare(id)}
            >
              <img src="/images/gallery-share.svg" width={"14"} alt="option" />
            </button>
            <span
              className="mt-1 "
              style={{
                color: "#DA9D5F",
                fontSize: "8px",
                lineHeight: "normal",
              }}
            >
              Share
            </span>
          </div>
          <div className="d-flex flex-column align-items-center">
            {walletAddress === user.wallet_address ||
            walletPalceHolder === user.email ? (
              <button
                className="btn outline-none btn-creme gallery_action_btn  rounded-circle p-1"
                disabled
                type="button"
                
              >
                <img
                  src="/images/gallery-give.svg"
                  height={"11"}
                  alt="option"
                />
              </button>
            ) : (
              <button
                className="btn outline-none btn-creme gallery_action_btn  rounded-circle p-1"
                type="button"
                
              >
                <img
                  src="/images/gallery-give.svg"
                  height={"11"}
                  alt="option"
                />
              </button>
            )}
            <span
              className="mt-1 "
              style={{
                color: "#DA9D5F",
                fontSize: "8px",
                lineHeight: "normal",
              }}
            >
              Give
            </span>
          </div>
          <div className="d-flex flex-column align-items-center">
                <button
                  className="btn outline-none btn-creme gallery_action_btn rounded-circle p-1"
                  type="button"
                  onClick={()=>handleReport(id)}
                >
                  <img
                    src="/images/gallery-report.svg"
                    height={"15"}
                    alt="option"
                  />
                </button>
                <span
                  className="mt-1 "
                  style={{
                    color: "#DA9D5F",
                    fontSize: "8px",
                    lineHeight: "normal",
                  }}
                >
                  Report
                </span>
              
          </div>
        </div>
      </div>
    </div>
  );
};

export default Galleryuser;
