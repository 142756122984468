import React, { useContext } from "react";
import axiosInstance from "../../Axios/axios.config";
import GlobalContext from "../../Contexts/GlobalContext";
import CustomPopup from "../Pop/CustomPopup";

const WelcomePopup = ({openHomePopup}) => {
  const { welcomePop, setWelcomePop } = useContext(GlobalContext);

  const updateFlag = async () => {

    let payLoad = JSON.stringify({
      show_first_info: false,
    })

    const response = await axiosInstance.post(`/user/user-model-flag/`,payLoad,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    if (response.statusText === 'OK') {
      setWelcomePop(!welcomePop);
      openHomePopup(true)
    }
  };

  return (
    <CustomPopup show={welcomePop} onClose={updateFlag} >
        <div className="text-center mb-4">
          <div className="mb-4">
            <img
              src="/images/logo-small-device.svg"
              className="w-50"
              alt="logo"
            />
          </div>
          <h5 className="col-md-10  mx-auto text-blue">
            Welcome to the Beach Tribe
          </h5>
          <p className="col-md-10  mx-auto px-md-2 mb-4">
            Check out our first portal Beach Action to start your journey
            towards making the health of our planet a part of everything you do.
          </p>
        </div>
    </CustomPopup>
  );
};

export default WelcomePopup;
