import { ADD_TO_CART_BAG, ADD_TO_CART_WALLET, REMOVE_FROM_CART_BAG, REMOVE_FROM_CART_WALLET, EMPTY_CART_BAG, EMPTY_CART_WALLET, EMPTY_CART_BAG_CARBON, EMPTY_CART_WALLET_CARBON, ADD_TO_CART_BAG_CARBON, ADD_TO_CART_WALLET_CARBON, REMOVE_FROM_CART_BAG_CARBON, REMOVE_FROM_CART_WALLET_CARBON } from "../actions/cartActions";

const cartReducer = (state, action) => {

    let fee, sub_total, total_amount, temp;

    let bag = {
        bag_products: [],
        total: 0,
        sub_total: 0,
        fee: 0
    }
    let wallet = {
        wallet_products: [],
        wallet_total: 0,
        wallet_sub_total: 0,
        wallet_fee: 0
    }
    let wallet_carbon = {
        wallet_carbon_products: [],
        wallet_carbon_total: 0,
        wallet_carbon_sub_total: 0,
        wallet_carbon_fee: 0
    }
    let bag_carbon = {
        bag_carbon_products: [],
        bag_carbon_total: 0,
        bag_carbon_sub_total: 0,
        bag_carbon_fee: 0
    }

    switch (action.type) {

        case ADD_TO_CART_BAG:{
            temp = [...action.products]
            total_amount = action.products.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.total_value),
                0.00
            );
            fee = action.products.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.platform_fee_value),
                0.00
            )
            sub_total = action.products.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.product_value),
                0.00
            )

            bag = {
                bag_products: [...temp],
                bag_total: total_amount,
                bag_sub_total: sub_total,
                bag_fee: fee,
                bag_carbon: state.bag.bag_carbon
            }
            return { ...state, bag }
        }
        case ADD_TO_CART_WALLET:{
            temp = [...action.products]
            total_amount = action.products.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.total_value),
                0.00
            );
            fee = action.products.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.platform_fee_value),
                0.00
            )
            sub_total = action.products.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.product_value),
                0.00
            )
            wallet = {
                wallet_products: [...temp],
                wallet_total: total_amount,
                wallet_sub_total: sub_total,
                wallet_fee: fee,
                wallet_carbon: state.wallet.wallet_carbon
            }
            return { ...state, wallet }
        }
        case REMOVE_FROM_CART_BAG:{
            total_amount = action.products.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.total_value),
                0.00
            );
            fee = action.products.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.platform_fee_value),
                0.00
            )
            sub_total = action.products.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.product_value),
                0.00
            )
            bag = {
                bag_products: [...action.products],
                bag_total: total_amount,
                bag_sub_total: sub_total,
                bag_fee: fee,
                bag_carbon: state.bag.bag_carbon
            }

            return { ...state, bag }
        }
        case REMOVE_FROM_CART_WALLET:{
            total_amount = action.products.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.total_value),
                0.00
            );
            fee = action.products.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.platform_fee_value),
                0.00
            )
            sub_total = action.products.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.product_value),
                0.00
            )

            wallet = {
                wallet_products: [...action.products],
                wallet_total: total_amount,
                wallet_sub_total: sub_total,
                wallet_fee: fee,
                wallet_carbon: state.wallet.wallet_carbon
            }

            return { ...state, wallet }
        }
        case ADD_TO_CART_BAG_CARBON:{
            temp = [...action.products]
            total_amount = action.products.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.total_value),
                0.00
            );
            fee = action.products.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.platform_fee_value),
                0.00
            )
            sub_total = action.products.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.product_value),
                0.00
            )
            bag_carbon = {
                bag_carbon_products: [...temp],
                bag_carbon_total: total_amount,
                bag_carbon_sub_total: sub_total,
                bag_carbon_fee: fee
            }
            return { ...state, bag: { ...state.bag, bag_carbon } }
        }
        case ADD_TO_CART_WALLET_CARBON:{
            temp = [...action.products]
            total_amount = action.products.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.total_value),
                0.00
            );
            fee = action.products.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.platform_fee_value),
                0.00
            )
            sub_total = action.products.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.product_value),
                0.00
            )
            wallet_carbon = {
                wallet_carbon_products: [...temp],
                wallet_carbon_total: total_amount,
                wallet_carbon_sub_total: sub_total,
                wallet_carbon_fee: fee
            }
            return { ...state, wallet: { ...state.wallet, wallet_carbon } }
        }
        case REMOVE_FROM_CART_BAG_CARBON:{
            total_amount = action.products.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.total_value),
                0.00
            );
            fee = action.products.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.platform_fee_value),
                0.00
            )
            sub_total = action.products.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.product_value),
                0.00
            )
            bag_carbon = {
                bag_carbon_products: [...action.products],
                bag_carbon_total: total_amount,
                bag_carbon_sub_total: sub_total,
                bag_carbon_fee: fee
            }
            return { ...state, bag: { ...state.bag, bag_carbon } }
        }
        case REMOVE_FROM_CART_WALLET_CARBON:{
            total_amount = action.products.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.total_value),
                0.00
            );
            fee = action.products.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.platform_fee_value),
                0.00
            )
            sub_total = action.products.reduce(
                (accumulator, currentValue) => accumulator + (currentValue.product_value),
                0.00
            )
            wallet_carbon = {
                wallet_products: [...action.products],
                wallet_carbon_total: total_amount,
                wallet_carbon_sub_total: sub_total,
                wallet_carbon_fee: fee
            }
            return { ...state, wallet: { ...state.wallet, wallet_carbon } }
        }
        case EMPTY_CART_BAG:{
            bag = {
                ...bag,
                bag_carbon: state.bag.bag_carbon
            }
            return { ...state, bag }
        }
        case EMPTY_CART_WALLET:{
            wallet = {
                ...wallet,
                wallet_carbon: state.wallet.wallet_carbon
            }
            return { ...state, wallet }
        }
        case EMPTY_CART_BAG_CARBON:{
            bag = {
                ...state.bag,
                bag_carbon
            }
            return { ...state, bag }
        }
        case EMPTY_CART_WALLET_CARBON:{
            wallet = {
                ...state.wallet,
                wallet_carbon
            }
            return { ...state, wallet }
        }
        default:
            return state
    }
}

export default cartReducer;