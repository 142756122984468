import React,{ useEffect, useState } from 'react'
import { HiChevronDown } from 'react-icons/hi'
import Web3 from 'web3'
import { useNavigate } from 'react-router-dom'
import {IoCloseSharp} from 'react-icons/io5'


const OurImpact = () => {

  const[pageData,setPageData] = useState({
    address: 0,
    amount_of_actions: 0,
    amount_of_users: 0,
    beachpay_transaction_count: 0,
    business_users: [],
    cleaning: 0,
    conservation: 0,
    countries: [],
    footprint: 0,
    kindness: 0,
    notforprofit_users: [],
    planting: 0,
    raised:0,
    total_fee_raised:0,
    transaction_spent:0,
    wildlife: 43,
    workers_paid:0,
    carbon_raised:0,
    ocean_raised:0
})

const navigate = useNavigate()

  useEffect(async()=>{

    var Ocean_Fund = '0x6f1DE2Ee96Bb344c9e88560bdEF1604c2EA87682';
    var Carbon_Fund = '0xB4A97CC0Aa3DEC054E9CbDb536363a48f82cbcAE';

    let oceanRaisedData = 0;
    let carbonRaisedData = 0;

    const response = await fetch(`https://api.beachcollective.io/user/our-impact-view/`);

    const oceanApi = await fetch(`https://api.bscscan.com/api?module=account&action=txlist&address=${Ocean_Fund}&startblock=0&endblock=99999999&page=1&offset=1000&sort=asc&apikey=HR5JP1GRHZHY1118PRVTHFSXB8X9KZGIXN`);

    const carbonApi = await fetch(`https://api.bscscan.com/api?module=account&action=txlist&address=${Carbon_Fund}&startblock=0&endblock=99999999&page=1&offset=1000&sort=asc&apikey=HR5JP1GRHZHY1118PRVTHFSXB8X9KZGIXN`);
  

    let data = await response.json()

    setPageData(data)

    let oceanData = await oceanApi.json()

    for (let i of oceanData.result) {
      if (i.from.toLowerCase() !== Ocean_Fund.toLowerCase()) {
        oceanRaisedData += parseInt(i.value);
      }
    }
    
    let carbonData = await carbonApi.json()

    for (let i of carbonData.result) {
      if (i.from.toLowerCase() !== Carbon_Fund.toLowerCase()) {
        carbonRaisedData += parseInt(i.value);
      }
    }

    oceanRaisedData = Web3.utils.fromWei(`${oceanRaisedData}`, 'ether');

    carbonRaisedData = Web3.utils.fromWei(`${carbonRaisedData}`, 'ether');


    setPageData(prev=>({
      ...prev,
      carbon_raised:carbonRaisedData,
      ocean_raised:oceanRaisedData
    }))



    },[])


  return (
    <div className='impactPage_wrapper'>
      <section className='pageSection'>
        <div className='pageSectionHeaderWrapper'>
          <div className='pageSectionHeader'>
          <div className='closeWrapper'>
            <button className='btn btn-transparent p-0 closeBtn' onClick={()=>window.close()}>
              <IoCloseSharp color='#fff' size={35} />
            </button>
          </div>
            <img src='/images/impact_pexels.webp' className='img-fluid' alt='backgrd' />
            <h2>Our Impact</h2>
          </div>
        </div>
        <div className='backgroundImg topSectionTop'>
          <div className='container'>
            <div className='topSectionContent'>
              {/*<div className='row align-items-center'>
                <div className='col-md-6 text-center'>
                  <img className='topSectionTopImg' src='/images/impact_action_mobile.webp' />
                </div>
                <div className='col-md-6 mt-4'>
                  <h2 className='text-white'>
                    Our work supports people,<br />
                    planet and addresses<br />
                    half of the sustainable<br />
                    development goals.<br />
                  </h2>
                  <div className='text-center text-md-start'>
                    <Link to='/' className='btn text-uppercase mt-3 btn-lg backToHomeBtn'>
                      Go to Home
                    </Link>
                  </div>
                </div>
  </div>*/}
  <div className='categoryListBox'>
  <div className='row justify-content-center'>
    <div className='col-4 col-md-2 my-3'>
      <div className='badgeWrapper'>
        <p className='badgeWrapper_title'>Cleaning</p>
        <img className='badgeWrapper_img' alt='category' src='/images/Cleaning.svg' /><br />
        <span className='badgeWrapper_count'>{pageData.cleaning}</span>
      </div>
    </div>
    <div className='col-4 col-md-2 my-3'>
      <div className='badgeWrapper'>
        <p className='badgeWrapper_title'>Conservation</p>
        <img className='badgeWrapper_img' alt='category' src='/images/Conservation.svg' /><br />
        <span className='badgeWrapper_count'>{pageData.conservation}</span>
      </div>
    </div>
    <div className='col-4 col-md-2 my-3'>
      <div className='badgeWrapper'>
        <p className='badgeWrapper_title'>Footprint</p>
        <img className='badgeWrapper_img' alt='category' src='/images/Footprint.svg' /><br />
        <span className='badgeWrapper_count'>{pageData.footprint}</span>
      </div>
    </div>
    <div className='col-4 col-md-2 my-3'>
      <div className='badgeWrapper'>
        <p className='badgeWrapper_title'>Planting</p>
        <img className='badgeWrapper_img' alt='category' src='/images/Planting.svg' /><br />
        <span className='badgeWrapper_count'>{pageData.planting}</span>
      </div>
    </div>
    <div className='col-4 col-md-2 my-3'>
      <div className='badgeWrapper'>
        <p className='badgeWrapper_title'>Kindness</p>
        <img className='badgeWrapper_img' alt='category' src='/images/Kindness.svg' /><br />
        <span className='badgeWrapper_count'>{pageData.kindness}</span>
      </div>
    </div>
    <div className='col-4 col-md-2 my-3'>
      <div className='badgeWrapper'>
        <p className='badgeWrapper_title'>Nature</p>
        <img className='badgeWrapper_img' alt='category' src='/images/Wildlife.svg' /><br />
        <span className='badgeWrapper_count'>{pageData.wildlife}</span>
      </div>
    </div>

  </div>
</div>

            </div>
          </div>
        </div>

       {/* <div className='categoryListBoxWrapper'>
          <div className='container mb-5'>
           
          </div>
</div>*/}

        <div className='backgroundImg topSectionBottom'>
          <div className='row justify-content-center'>
            <div className='col-10 col-md-8'>
              <div className='row'>
                <div className='col-sm-6 mb-3'>
                  <div className='d-flex align-items-center text-dark justify-content-between rounded bg-white py-2 px-3 '>
                    <p>Amount of users:</p>
                    <p className='m-0'>
                      <strong>{pageData.amount_of_users}</strong>
                    </p>
                  </div>
                </div>
                <div className='col-sm-6 mb-3'>
                  <div className='d-flex align-items-center text-dark justify-content-between rounded bg-white py-2 px-3'>
                    <p>Amount of actions:</p>
                    <p className='m-0'>
                      <strong>{pageData.amount_of_actions}</strong>
                    </p>
                  </div>
                </div>
                <div className='col-sm-6 mb-3'>
                  <div className='d-flex align-items-center text-dark justify-content-between rounded bg-white py-2 px-3 '>
                    <p>$BEACH Addresses:</p>
                    <p className='m-0'>
                      <strong>{pageData.address}</strong>
                    </p>
                  </div>
                </div>
                <div className='col-sm-6 mb-3'>
                  <div className='d-flex align-items-center text-dark justify-content-between rounded bg-white py-2 px-3'>
                    <p>Workers paid:</p>
                    <p className='m-0'>
                      <strong>{pageData.workers_paid}</strong>
                    </p>
                  </div>
                </div>
                <div className='col-sm-6 mb-3'>
                  <div className='d-flex align-items-center text-dark justify-content-between rounded bg-white py-2 px-3 '>
                    <p>Total fees raised:</p>
                    <p className='m-0'>
                      <strong>${pageData.total_fee_raised}</strong>
                    </p>
                  </div>
                </div>
                <div className='col-sm-6 mb-3'>

                  <div class="dropdown">
                    <button class="dropDownBtn py-2 rounded px-3 text-dark dropdown-toggle" type="button" id="countriesDropDown" data-bs-toggle="dropdown" aria-expanded="false">
                      <p>Countries:</p>
                      <div>
                        <span className='me-3'>{pageData.countries.length}</span>
                        <HiChevronDown size={20} />
                      </div>
                    </button>
                    <ul class="dropDownList dropdown-menu" aria-labelledby="countriesDropDown">
                      {
                        pageData.countries.map(country=><li>{country}</li>)
                      }
                    </ul>
                  </div>
                </div>
                <div className='col-sm-6 mb-3'>
                  <div className='d-flex align-items-center text-dark justify-content-between rounded bg-white py-2 px-3 '>
                    <p>Raised for carbon: </p>
                    <p className='m-0'>
                      <strong>${Math.round(pageData.carbon_raised)}</strong>
                    </p>
                  </div>
                </div>
                <div className='col-sm-6 mb-3'>

                  <div class="dropdown">
                    <button class="dropDownBtn py-2 rounded px-3 text-dark dropdown-toggle" type="button" id="notForProfitDropDown" data-bs-toggle="dropdown" aria-expanded="false">
                      <p>Not-for-profits:</p>
                      <div>
                        <span className='me-3'>{pageData.notforprofit_users.length}</span>
                        <HiChevronDown size={20} />
                      </div>
                    </button>
                    <ul class="dropDownList dropdown-menu" aria-labelledby="notForProfitDropDown">
                    {
                      pageData.notforprofit_users.map(user=><li>{user}</li>)
                    }
                    </ul>
                  </div>
                </div>
                <div className='col-sm-6 mb-3'>
                  <div className='d-flex align-items-center text-dark justify-content-between rounded bg-white py-2 px-3 '>
                    <p>Raised for oceans:</p>
                    <p className='m-0'>
                      <strong>${Math.round(pageData.ocean_raised)}</strong>
                    </p>
                  </div>
                </div>
                <div className='col-sm-6 mb-3'>

                  <div class="dropdown">
                    <button class="dropDownBtn py-2 rounded px-3 text-dark dropdown-toggle" type="button" id="bussinessDropDown" data-bs-toggle="dropdown" aria-expanded="false">
                      <p>Businesses:</p>
                      <div>
                        <span className='me-3'>{pageData.business_users.length}</span>
                        <HiChevronDown size={20} />
                      </div>
                    </button>
                    <ul class="dropDownList dropdown-menu" aria-labelledby="bussinessDropDown">
                    {
                      pageData.business_users.map(user=><li>{user}</li>)
                    }
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='pageSection'>
        <div className='pageSectionHeaderWrapper'>
          <div className='pageSectionHeader'>
            <img src='/images/impact_andreas.webp' className='img-fluid' alt='backgrd' />
          </div>
        </div>
        <div className='backgroundImg bottomSectionTop'>
          <div className='text-center bottomSectionTopImgContainer topSectionContent '>
            <img className='contentImg' src='/images/impact_pay_mobile.webp' />
            <p id='sentData'>${Math.floor(pageData.transaction_spent)} sent</p>
            <p id='transData'>{pageData.beachpay_transaction_count} transactions</p>
          </div>
        </div>
      </section>
    </div>
  )
}

export default OurImpact