import React, { createContext, useReducer, useEffect, useContext } from 'react'
import cartReducer from '../reducers/cartReducer'
import { ADD_TO_CART_BAG, ADD_TO_CART_BAG_CARBON, ADD_TO_CART_WALLET, ADD_TO_CART_WALLET_CARBON } from '../actions/cartActions';
import axiosInstance from '../Axios/axios.config';
import GlobalContext  from './GlobalContext';

export const CartStore = createContext(null)

const cartInitialState = {
    bag:{
        bag_products:[],
        bag_total:0,
        bag_sub_total:0,
        bag_fee:0,
        bag_carbon:{
            bag_carbon_products: [],
            bag_carbon_total: 0,
            bag_carbon_sub_total: 0,
            bag_carbon_fee: 0
        }
    },
    wallet:{
        wallet_products:[],
        wallet_total:0,
        wallet_sub_total:0,
        wallet_fee:0,
        wallet_carbon:{
            wallet_carbon_products: [],
            wallet_carbon_total: 0,
            wallet_carbon_sub_total: 0,
            wallet_carbon_fee: 0
        }
    }
}

const CartContext = ({children}) => {

    const { loggedIn } = useContext(GlobalContext)

    const[cart,dispatch] = useReducer(cartReducer,cartInitialState) 

    useEffect(()=>{
        async function getCart(){
         let response = await axiosInstance.get(`/shop/enrollment/cart-data/`)
         if(response.status === 200){
             const {bag_cart_data,wallet_cart_data} = response.data
             let bag_products = bag_cart_data.filter(product => product.product.product_type === 'shop' || product.product.product_type === 'csr' || product.product.product_type === 'giftCard')
             let bag_carbon_products = bag_cart_data.filter(product => product.product.product_type === 'carbon' || product.product.product_type === 'nft' || product.product.product_type === 'resale')
             let wallet_products = wallet_cart_data.filter(product => product.product.product_type === 'shop' || product.product.product_type === 'csr' || product.product.product_type === 'giftCard')
             let wallet_carbon_products = wallet_cart_data.filter(product => product.product.product_type === 'carbon' || product.product.product_type === 'nft' || product.product.product_type === 'resale')
             dispatch({type:ADD_TO_CART_BAG,products:bag_products})
             dispatch({type:ADD_TO_CART_WALLET,products:wallet_products})
             dispatch({type:ADD_TO_CART_BAG_CARBON,products:bag_carbon_products})
             dispatch({type:ADD_TO_CART_WALLET_CARBON,products:wallet_carbon_products})
         }  
        }   
        if(loggedIn){
            getCart() 
        }
        
     },[loggedIn])

    return (
        <CartStore.Provider value={{cart,dispatch}} >
            {children}
        </CartStore.Provider>
    )
}


export default CartContext;
