import React, { useCallback, useContext, useEffect, useState } from "react";
import GlobalContext from "../../Contexts/GlobalContext";
import VideoElement from "../map/VideoElement";
// import ReportModel from "../map/ReportModel";
import moment from "moment";
import { AiFillCaretDown ,AiOutlineSave} from "react-icons/ai";
import { BsFillEyeFill } from "react-icons/bs";
import { BiDotsVerticalRounded } from "react-icons/bi";
import {MdOutlineSearch,MdModeEdit} from 'react-icons/md'
import Select, { components } from "react-select";
import DatePicker from "rsuite/DatePicker";
import "rsuite/dist/rsuite.min.css";
import {useParams, Link , useNavigate } from "react-router-dom";
import BeachPayContext from "../../Contexts/BeachPayContext";
import axios from 'axios'
import axiosInstance from "../../Axios/axios.config";

const CategoryOptions = [
  { value: "default", label: "Category" },

  { value: "Cleaning", label: "Cleaning" },

  { value: "Footprint", label: "Footprint" },

  { value: "Kindness", label: "Giving" },

  { value: "Planting", label: "Planting" },

  { value: "Conservation", label: "Conservation" },

  { value: "Wildlife", label: "Nature" },
];

const LocationOptions = [
  { value: "default", label: "Location" },
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Albania", label: "Albania" },
  { value: "Algeria", label: "Algeria" },
  { value: "American Samoa", label: "American Samoa" },
  { value: "Andorra", label: "Andorra" },
  { value: "Angola", label: "Angola" },
  { value: "Anguilla", label: "Anguilla" },
  { value: "Antarctica", label: "Antarctica" },
  { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
  { value: "Argentina", label: "Argentina" },
  { value: "Armenia", label: "Armenia" },
  { value: "Aruba", label: "Aruba" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Barbados", label: "Barbados" },
  { value: "Belarus", label: "Belarus" },
  { value: "Belgium", label: "Belgium" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Benin" },
  { value: "Bermuda", label: "Bermuda" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Bolivia", label: "Bolivia" },
  { value: "Bonaire", label: "Bonaire" },
  { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
  { value: "Botswana", label: "Botswana" },
  { value: "Bouvet Island", label: "Bouvet Island" },
  { value: "Brazil", label: "Brazil" },
  { value: "Brunei Darussalam", label: "Brunei Darussalam" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Burundi", label: "Burundi" },
  { value: "Cabo Verde", label: "Cabo Verde" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Canada", label: "Canada" },
  { value: "Cayman Islands", label: "Cayman Islands" },
  { value: "Central African Republic", label: "Central African Republic" },
  { value: "Chad", label: "Chad" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Christmas Island", label: "Christmas Island" },
  { value: "Cocos Islands", label: "Cocos Islands" },
  { value: "Colombia", label: "Colombia" },
  { value: "Comoros", label: "Comoros" },
  { value: "Congo", label: "Congo" },
  { value: "Congo", label: "Congo" },
  { value: "Cook Islands", label: "Cook Islands" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Croatia", label: "Croatia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Curaçao", label: "Curaçao" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Czechia", label: "Czechia" },
  { value: "Côte d'Ivoire", label: "Côte d'Ivoire" },
  { value: "Denmark", label: "Denmark" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominican Republic", label: "Dominican Republic" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Egypt", label: "Egypt" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Estonia", label: "Estonia" },
  { value: "Eswatini", label: "Eswatini" },
  { value: "Ethiopia", label: "Ethiopia" },
  { value: "Falkland Islands", label: "Falkland Islands" },
  { value: "Faroe Islands", label: "Faroe Islands" },
  { value: "Fiji", label: "Fiji" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "French Guiana", label: "French Guiana" },
  { value: "French Polynesia", label: "French Polynesia" },
  {
    value: "French Southern Territories",
    label: "French Southern Territories",
  },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia", label: "Gambia" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Ghana", label: "Ghana" },
  { value: "Gibraltar", label: "Gibraltar" },
  { value: "Greece", label: "Greece" },
  { value: "Greenland", label: "Greenland" },
  { value: "Grenada", label: "Grenada" },
  { value: "Guadeloupe", label: "Guadeloupe" },
  { value: "Guam", label: "Guam" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guernsey", label: "Guernsey" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haiti" },
  {
    value: "Heard Island and McDonald Islands",
    label: "Heard Island and McDonald Islands",
  },
  { value: "Holy See", label: "Holy See" },
  { value: "Honduras", label: "Honduras" },
  { value: "Hong Kong", label: "Hong Kong" },
  { value: "Hungary", label: "Hungary" },
  { value: "Iceland", label: "Iceland" },
  { value: "India", label: "India" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Iran", label: "Iran" },
  { value: "Iraq", label: "Iraq" },
  { value: "Ireland", label: "Ireland" },
  { value: "Isle of Man", label: "Isle of Man" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Jersey", label: "Jersey" },
  { value: "Jordan", label: "Jordan" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kiribati", label: "Kiribati" },
  { value: "Korea", label: "Korea" },
  { value: "Korea", label: "Korea" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  {
    value: "Lao People's Democratic Republic",
    label: "Lao People's Democratic Republic",
  },
  { value: "Latvia", label: "Latvia" },
  { value: "Lebanon", label: "Lebanon" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libya", label: "Libya" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Macao", label: "Macao" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Maldives", label: "Maldives" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  { value: "Martinique", label: "Martinique" },
  { value: "Mauritania", label: "Mauritania" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mayotte", label: "Mayotte" },
  { value: "Mexico", label: "Mexico" },
  { value: "Micronesia", label: "Micronesia" },
  { value: "Moldova", label: "Moldova" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Montserrat", label: "Montserrat" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Namibia", label: "Namibia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Nepal" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "New Caledonia", label: "New Caledonia" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "Niue", label: "Niue" },
  { value: "Norfolk Island", label: "Norfolk Island" },
  { value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
  { value: "Norway", label: "Norway" },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palau" },
  { value: "Palestine, State of", label: "Palestine, State of" },
  { value: "Panama", label: "Panama" },
  { value: "Papua New Guinea", label: "Papua New Guinea" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Philippines", label: "Philippines" },
  { value: "Pitcairn", label: "Pitcairn" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Puerto Rico", label: "Puerto Rico" },
  { value: "Qatar", label: "Qatar" },
  {
    value: "Republic of North Macedonia",
    label: "Republic of North Macedonia",
  },
  { value: "Romania", label: "Romania" },
  { value: "Russian Federation", label: "Russian Federation" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Réunion", label: "Réunion" },
  { value: "Saint Barthélemy", label: "Saint Barthélemy" },
  {
    value: "Saint Helena, Ascension and Tristan da Cunha",
    label: "Saint Helena, Ascension and Tristan da Cunha",
  },
  { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
  { value: "Saint Lucia", label: "Saint Lucia" },
  { value: "Saint Martin", label: "Saint Martin" },
  { value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon" },
  {
    value: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines",
  },
  { value: "Samoa", label: "Samoa" },
  { value: "San Marino", label: "San Marino" },
  { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia", label: "Serbia" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapore" },
  { value: "Sint Maarten", label: "Sint Maarten" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Solomon Islands", label: "Solomon Islands" },
  { value: "Somalia", label: "Somalia" },
  { value: "South Africa", label: "South Africa" },
  { value: "South Georgia ", label: "South Georgia" },
  { value: "South Sudan", label: "South Sudan" },
  { value: "Spain", label: "Spain" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Sudan", label: "Sudan" },
  { value: "Suriname", label: "Suriname" },
  { value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen" },
  { value: "Sweden", label: "Sweden" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Syrian Arab Republic", label: "Syrian Arab Republic" },
  { value: "Taiwan", label: "Taiwan" },
  { value: "Tajikistan", label: "Tajikistan" },
  { value: "Tanzania", label: "Tanzania" },
  { value: "Thailand", label: "Thailand" },
  { value: "Timor-Leste", label: "Timor-Leste" },
  { value: "Togo", label: "Togo" },
  { value: "Tokelau", label: "Tokelau" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
  { value: "Tunisia", label: "Tunisia" },
  { value: "Turkey", label: "Turkey" },
  { value: "Turkmenistan", label: "Turkmenistan" },
  { value: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Uganda", label: "Uganda" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "United Arab Emirates", label: "United Arab Emirates" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "United States", label: "United States" },
  { value: "United States of America", label: "United States of America" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Uzbekistan", label: "Uzbekistan" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Viet Nam", label: "Viet Nam" },
  { value: "Virgin Islands (British)", label: "Virgin Islands (British)" },
  { value: "Virgin Islands (U.S.)", label: "Virgin Islands (U.S.)" },
  { value: "Wallis and Futuna", label: "Wallis and Futuna" },
  { value: "Western Sahara", label: "Western Sahara" },
  { value: "Yemen", label: "Yemen" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" },
  { value: "Åland Islands", label: "Åland Islands" },
];

const selectStyles = {
  control: (base, state) => ({
    ...base,
    border: "1px solid #007697",
    borderRadius: "40px",
    padding: "5px 4px",
    minHeight: "20px",
    fontSize: "1rem",
    color: "#007697",
    "&:hover": {
      border: "1px solid #007697",
    },
  }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#e8b078" : isSelected ? "#e8b078" : null,
      color: isFocused ? "#fff" : "#33333",
    };
  },
  menuList: (base) => ({
    ...base,
    padding: 0,
  }),
  dropdownIndicator: (styles) => ({ ...styles, padding: "0 5px" }),
};

const CaretDownIcon = () => {
  return <AiFillCaretDown color="#007a97" />;
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};



const Gallerypage = () => {
    
  const {page} = useParams()

  const navigateTo = useNavigate()

  const [isClickable,setClickable] = useState(true)

  const [locationFilter, setLocationFilter] = useState(LocationOptions[0]);

  const [categoryFilter, setCategoryFilter] = useState(CategoryOptions[0]);

  const [Posts, setPosts] = useState([]);

  // const[showPost,setShowPosts] = useState([])

//   const [reportPost, setReportPost] = React.useState("");

//   const [openReport, setReportPop] = React.useState(false);

  // const [sharePost, setSharePost] = React.useState("");

  // const [openShare, setSharePop] = React.useState(false);

  const { walletAddress, walletPalceHolder,pageCount,setPageCount,maxPageCount,loggedIn} = useContext(GlobalContext);

  const [search, setSearch] = React.useState("");
  
  const [searchByType, setSearchByType] = React.useState(false);

  const [filterDate, setFilterDate] = React.useState("");


  const getPosts = useCallback(async ()=> {
    let response;
    let url = ''
    let activity_name = ''
    if(search){
      activity_name = search
  
      for(let i=0 ; i < activity_name.length ; i++){
        activity_name = activity_name.replace(" ","_")
      }
  
    }
    if(search && searchByType){
      url = `&activity=${activity_name}`
    }
    if(filterDate){
      url += `&date=${filterDate}`
    }
    if(locationFilter.value !== 'default'){
      url += `&location=${locationFilter.value}`
    }
    if(categoryFilter.value !== 'default'){
      url += `&category=${categoryFilter.value}`
    }
    
    if (loggedIn) {
     
        response = await axiosInstance.get(`/map/all-map-post-2/?all=false&page=${page}${url}`);
      
    } else {
     
        response = await axios.get(`/map/all-map-post/?all=false&page=${page}${url}`);
    }
      
    if(response.statusText === 'OK'){
      const data =  response.data;
      setPosts([...data.data])
    }
  
   
  },[page,loggedIn,filterDate,locationFilter,categoryFilter,searchByType,search])
  

  useEffect(() => {
        getPosts()      
  }, [getPosts]);

  

//   const setGive = (user) => {
//     openGive(true);
//     setGiveTo(user);
//   };

//   const setReport = (post) => {
//     setReportPop(true);
//     setReportPost(post);
//   };



  const handleDateFilter = (value) => {
    if (value) {
      let date = value.getDate() < 10 ? "0" + value.getDate() : value.getDate();
      let month =
        value.getMonth() + 1 < 10
          ? "0" + (value.getMonth() + 1)
          : value.getMonth() + 1;
      let year = value.getFullYear();
      setFilterDate(year + "-" + month + "-" + date);
      setClickable(false)
    }
  };

  const handleSearchType = (e)=>{
    setSearch(e.target.value)
    setClickable(false)
  } 
    

  const handleSearch = () =>{
    setSearchByType(true)
  } 

  const handleClearDate = () =>{
    setFilterDate('')
  }

  const serachOnEnter = (event) =>{
    if (event.keyCode === 13){
      handleSearch()
    }
  }

  const handleCategoryFilter = (category) =>{
    setCategoryFilter(category)
 
    setClickable(false)
  } 
  const handleLocationFilter = (location) =>{
    setLocationFilter(location)
    // let temp = []
    // showPost.forEach(post =>{
    //     if(post.LOCATION === location.value){
    //         temp.push(post)
    //     }
    // })
    // setPosts(temp)
    setClickable(false)
  } 
  const handleReset = () =>{
    setSearchByType(false)
    setSearch(()=>'')
    setLocationFilter(LocationOptions[0])
    setCategoryFilter(CategoryOptions[0])
    setFilterDate('')
    setClickable(true)
  } 

  const handlePreviousPage = () =>{
    setPageCount(pageCount => pageCount - 1)
    navigateTo(`/gallery/page/${pageCount-1}`,{ replace: true })
  }

  const handleNextPage = () =>{
    setPageCount(pageCount => pageCount + 1)
    navigateTo(`/gallery/page/${pageCount+1}`,{ replace: true })
  }

  const deletePost = async (id) =>{
    

    const response = await axiosInstance.get(`/map/activity-delete-id/${id}/`);

    if(response.statusText === 'OK'){
      getPosts();
    }

  }

  const handleEdit = async (id,title,setEdit)=>{

    let formData = new FormData();

    formData.append("activity", title);

    const response = await axiosInstance.post(`/map/map-data-update/${id}/`,formData,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
      }
    );

    if (response.statusText === 'OK') {
      setEdit(false)
      getPosts();
    }
  }

  return (
    <div className="gallery_page">
      <div className="text-end closeBtnContainer">
      
      <button
        className="btn btn-creme p-2 me-2 text-white "
        type="button"
        id="ViewButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <BsFillEyeFill size={20} color={"#fff"} />
      </button>
      <ul
        className="dropdown-menu p-0"
        id="dropdownMenu1"
        aria-labelledby="ViewButton"
      >
        <li className="dropdown-item p-0">
          <Link className="anchorBtn w-100" to={'/action'}>
            Map
          </Link>
        </li>
        <li className="dropdown-item p-0 btn-creme ">
          <Link className="anchorBtn w-100 text-white" to={'/gallery/page/1'}>
            Gallery
          </Link>
        </li>
      </ul>
      
      </div>
      <div className="gallery_filter_container">
      <div className="gallery_search_wrapper">
      <p className="my-3"></p>
      <div className="gallery_search_container">
        <input className="gallery_search_input" placeholder="Search" value={search} onKeyUp={serachOnEnter} onChange={handleSearchType} />
        <button  className="btn btn-transparent gallery_search_btn" onClick={handleSearch}>
            <MdOutlineSearch size={20} />
        </button>
      </div>
      </div>
      <div className="gallery_sort">
        <p className="text-blue mb-1 ps-md-3">Sort By</p>
        <div className="sort_controls_container">
          <Select
            options={CategoryOptions}
            isSearchable={false}
            className="gallery_select"
            isClearable={false}
            onChange={(value) => {
              handleCategoryFilter(value);
            }}
            name={"category"}
            components={{ DropdownIndicator }}
            styles={selectStyles}
            value={categoryFilter}
          />
          <DatePicker
            className="custom_datepicker gallery_select"
            onClean={handleClearDate}
            onChange={handleDateFilter}
            placeholder="Date"
          />
          <Select
            options={LocationOptions}
            isSearchable={false}
            isClearable={false}
            className="gallery_select"
            onChange={(value) => {
              handleLocationFilter(value);
            }}
            name={"location"}
            components={{ DropdownIndicator }}
            styles={selectStyles}
            value={locationFilter}
          />
          <button  className="btn filter_restBtn gallery_select" disabled={isClickable} onClick={handleReset} >
              Reset
          </button>
        </div>
      </div>
      </div>

      
        <div className="d-grid gallery-grid ">
          {Posts.map((post, i) => {
            return (
              <MdCard
                {...post}
                key={i}
                deletePost={deletePost}
                handleEdit={handleEdit}
              />
            );
          })}
        </div>
      

      
      {/*<ReportModel
        show={openReport}
        setShow={setReportPop}
        reportPost={reportPost}
        updatePosts={setPosts}
        clearReportPost={setReportPost}
      />*/}
      <div className="gallery_nav">
            <button className="backBtn" disabled={pageCount <= 1 ? true :false } onClick={handlePreviousPage} >
              Previous
            </button>
            <span className="pageCount">{pageCount}</span>
            <button className="nextBtn" disabled={pageCount >= maxPageCount ? true :false } onClick={handleNextPage} >
            Next
            </button>
      </div>
    </div>
  );
};

const MdCard = ({
  id,
  category,
  ACTIVITY,
  created,
  image_1,
  like_flag,
  user,
  W3W,
  total_likes,
  report_flag,
  deletePost,
  handleEdit
}) => {
  const { walletAddress, walletPalceHolder, loggedIn , setSharePostId , setSharePopup ,setReportPostId , setReportPopup } = useContext(GlobalContext);

  const redirect = useNavigate()

  const {setActiveTab,setSendTo,setSendFor} = useContext(BeachPayContext)

  const [like, setLike] = useState(like_flag);

  const [likeCount, setLikeCount] = useState(total_likes);

  const [readMore, setReadMore] = useState(false);

  const [edit, setEdit] = useState(false);

  const [title, setTitle] = useState(ACTIVITY);



  const handelLike = async (id) => {
    if(loggedIn){
      
      const response = await axiosInstance.get(`/map/post-like/${id}/`);
  
      if (response.statusText === 'OK') {
        const res =  response.data;
        setLike(res.like_flag);
        setLikeCount(res.total_likes);
      }
    }
  };


  const handleShare = (postId) =>{
    setSharePostId(postId)
    setSharePopup(true)
  }
  const handleReport = (postId) =>{
    setReportPostId(postId)
    setReportPopup(true)
  }

  async function handleSendNext(payload){

   let jsonPayload = JSON.stringify({
      search: payload
    })

    let response = await axiosInstance.post(`/user/user-search/`,jsonPayload,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );

    let res =  response.data


    // console.log('next res-->', res )

    if (response.statusText === 'OK') {
        setSendTo(prev=>({...prev, name: res.data.full_name, image: res.data.image,wallet_add : res.data.wallet_address , email : res.data.email }))
        setSendFor('send_confirm')
        setActiveTab('send')
        redirect('/pay')   
    }
  }




  return (
    <div className="card border-0 p-2 gallery-card">
      <div className="d-flex justify-content-between align-items-center p-1">
       <div className="d-flex align-items-center">
       <img
       className="rounded-circle"
       src={
         user.image
           ? user.image
           : "https://api.beachcollective.io/media/default-user.png"
       }
       width={"25"}
       height={"25"}
       alt={""}
     />
     
     <Link to={'/gallery/'+user.slug_url} className="text-blue blueLink mb-0 ms-1 usernameLink">{user.full_name}</Link>
     
     <small className="text-blue d-flex justify-content-between fs-6 pb-2 pb-md-0">
       <span
         className={`px-3  ms-2 d-flex align-items-center  text-white rounded-25 badge ${category }`}
         style={{ fontSize: "10px" }}
       >
         {category}
       </span>
       <Link
        to={`/action/${W3W}`}
         className="ms-3 d-block d-sm-inline"
         style={{ fontSize: "12px", cursor: "pointer", color:'#007a97', textDecoration:'none' }}
        
       >
         {moment(created).isSame(new Date(), "D")
           ? moment(created).format("LT, [Today]")
           : moment(new Date()).isSame(moment(created).add(1, "day"), "D")
           ? moment(created).format("LT, [Yesterday]")
           : moment(created).format("LT, ddd DD MMM, YYYY")}
       </Link>
     </small>
       {
        ( (user.wallet_address && walletAddress === user.wallet_address) || walletPalceHolder === user.full_name  || walletPalceHolder === user.email) &&
       <span>
          {
            edit ?
          <button className="bg-transparent" onClick={()=>handleEdit(id,title,setEdit)}>
          <AiOutlineSave color={'007a97'} size={20} />
          </button>
          :
          <button className="bg-transparent" size={20} onClick={()=>setEdit(true)}>
          <MdModeEdit color={'007a97'} />
          </button>
        }
        </span>
       }
       </div>
      
      {
       ( (user.wallet_address && walletAddress === user.wallet_address) || walletPalceHolder === user.full_name  || walletPalceHolder === user.email) &&
         <div class="dropdown">
          <button class="btn btn-transparent p-0" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
            <BiDotsVerticalRounded />
          </button>
          <ul class="dropdown-menu" style={{minWidth:'50px'}} aria-labelledby="dropdownMenuButton1">
            <li className="px-2" onClick={()=>deletePost(id)} style={{cursor:'pointer'}} >Delete</li>
          </ul>
        </div>
      }
      </div>
      {image_1 ? 
        (
          image_1.includes(".mp4") ? 
          (
            <VideoElement src={image_1} />
          ) : (
            <img
              src={image_1}
              alt="img"
              className="main_img"
            />
          )
      )
      :
      <img className="main_img" src={`/images/${category}.svg`} alt='category'/>
    
    }
    <div className="py-2">
    <Link className='actionBtn shopBtn' to={`/gallery/${user.slug_url}`}>
      Actions
    </Link>          
        <button className='actionBtn payBtn mx-1' onClick={()=>handleSendNext(user.full_name)}>Pay</button>
        <Link className='actionBtn webBtn' to={ user?.user_type === 'Individual' ? `/individual/${user.slug_url}` : user?.user_type === 'Business' ? `/business/${user.slug_url}` : `/not-for-profit/${user.slug_url}` }>
        Profile
      </Link> 
    </div>

      <div className="card-body d-flex flex-column flex-xl-row p-0 pt-2">
        {
          edit ?
          <textarea rows={'2'} value={title} style={{width:'100%',display:'block',resize:'none'}} onChange={(e)=>setTitle(e.target.value)} />
          :
          <p
          className="text-blue flex-grow-1 m-0"
          style={{ fontSize: "12px", lineHeight: "normal" }}
        >
          { (ACTIVITY?.length > 95 && !readMore) ?  ACTIVITY?.slice(0,95)+'...' : ACTIVITY   }

          { ACTIVITY?.length > 95 && <span className="readMoreBtn" onClick={()=>setReadMore(!readMore)} >{readMore ? 'Read less' : 'Read more'}</span>}
        </p>
      }
        <div className="d-flex mt-2 mt-xl-0  ms-2 align-items-center">
          <div className="d-flex flex-column align-items-center">
            <button
              className="btn outline-none gallery_action_btn  btn-creme rounded-circle p-1"
              onClick={() => handelLike(id)}
              type="button"
            >
              <span className="me-1 text-white" style={{ fontSize: "10px" }}>
                {likeCount}
              </span>
              <img
                src={like ? "/images/sandLike.svg" : "/images/gallery-like.svg"}
                height={"14"}
                alt="option"
              />
            </button>
            <span
              className="mt-1"
              style={{
                color: "#DA9D5F",
                fontSize: "8px",
                lineHeight: "normal",
              }}
            >
              Like
            </span>
          </div>
          <div className="d-flex flex-column align-items-center">
            <button
              className="btn outline-none btn-creme gallery_action_btn  rounded-circle p-1"
              type="button"
              onClick={()=>handleShare(id)}
            >
              <img src="/images/gallery-share.svg" width={"14"} alt="option" />
            </button>
            <span
              className="mt-1 "
              style={{
                color: "#DA9D5F",
                fontSize: "8px",
                lineHeight: "normal",
              }}
            >
              Share
            </span>
          </div>
          <div className="d-flex flex-column align-items-center">
            {walletAddress === user.wallet_address ||
            walletPalceHolder === user.email ? (
              <button
                className="btn outline-none btn-creme gallery_action_btn  rounded-circle p-1"
                disabled
                type="button"
                
              >
                <img
                  src="/images/gallery-give.svg"
                  height={"11"}
                  alt="option"
                />
              </button>
            ) : (
              <button
                className="btn outline-none btn-creme gallery_action_btn  rounded-circle p-1"
                type="button"
                
              >
                <img
                  src="/images/gallery-give.svg"
                  height={"11"}
                  alt="option"
                />
              </button>
            )}
            <span
              className="mt-1 "
              style={{
                color: "#DA9D5F",
                fontSize: "8px",
                lineHeight: "normal",
              }}
            >
              Give
            </span>
          </div>
          <div className="d-flex flex-column align-items-center">
                <button
                  className="btn outline-none btn-creme gallery_action_btn rounded-circle p-1"
                  onClick={()=>handleReport(id)}
                  type="button"
                >
                  <img
                    src="/images/gallery-report.svg"
                    height={"15"}
                    alt="option"
                  />
                </button>
                <span
                  className="mt-1 "
                  style={{
                    color: "#DA9D5F",
                    fontSize: "8px",
                    lineHeight: "normal",
                  }}
                >
                  Report
                </span>
              
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallerypage;
