import React from 'react'
// import { RxExternalLink } from 'react-icons/r'

const Order = ({ order }) => {
    return (
        <div className='orderWrapper mb-3'>
            <div className='row'>
                <div className='col-6'>
                    <p className='m-0 order_id'>Order <span className='text-blue'>#{order.order_id}</span></p>
                </div>
                <div className='col-6 text-end'>
                    <p className={`m-0 orderStatus ${order.status}`}>{order.status}</p>
                </div>


                <div className='col-12'>
                    <p className='m-0'>Products</p>
                    {order?.order_product.map(product => {
                        return <div className='row'>
                            <div className='col-8'>
                                <p className='m-0 font-regular text-capitalize'>{product.product.name} </p>
                            </div>
                            <div className='col-4 text-end'>
                                <p className='m-0'>${product.total_value}</p>
                            </div>

                            {
                                product.product_type === 'carbon' &&
                               <React.Fragment>
                               <div className='col-8'>
                               <p className='m-0 order_id'>Number of NFTs</p>
                           </div>
                           <div className='col-4  text-end'>
                               <p className='m-0'>{product.marketplace_link.length}</p>
                           </div>
                           <div className='col-12'>
                               {
                                   product.marketplace_link.map(link =>
                                       <a href={link} className='d-inline-flex font-regular align-items-center' target='_blank'>Market place</a>
                                   )
                               }
                           </div>
                               </React.Fragment>
                            }


                        </div>
                    })}
                </div>
                <div className='col-12'>
                    <hr className='my-2' />
                </div>
                <div className='col-6'>
                    <p className='m-0'>Total</p>
                </div>
                <div className='col-6 text-end'>
                    <p className='m-0'>${order.net_total}</p>
                </div>

            </div>
        </div>
    )
}

export default Order