import React, { useState, useRef, useEffect, useCallback } from 'react'
import ReactCrop, {centerCrop, makeAspectCrop} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

function toCanvas(image,crop,scale = 1,rotate = 0,) {
            
    const TO_RADIANS = Math.PI / 180
      
    const canvas = document.createElement('canvas')
        
    const ctx = canvas.getContext('2d')
      
        if (!ctx) {
          throw new Error('No 2d context')
        }
    
        const scaleX = image.naturalWidth / image.width
        const scaleY = image.naturalHeight / image.height
        // devicePixelRatio slightly increases sharpness on retina devices
        // at the expense of slightly slower render times and needing to
        // size the image back down if you want to download/upload and be
        // true to the images natural size.
        const pixelRatio = window.devicePixelRatio
        // const pixelRatio = 1
      
        canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
        canvas.height = Math.floor(crop.height * scaleY * pixelRatio)
      
        ctx.scale(pixelRatio, pixelRatio)
        ctx.imageSmoothingQuality = 'high'
      
        const cropX = crop.x * scaleX
        const cropY = crop.y * scaleY
      
        const rotateRads = rotate * TO_RADIANS
        const centerX = image.naturalWidth / 2
        const centerY = image.naturalHeight / 2
      
        ctx.save()
      
        // 5) Move the crop origin to the canvas origin (0,0)
        ctx.translate(-cropX, -cropY)
        // 4) Move the origin to the center of the original position
        ctx.translate(centerX, centerY)
        // 3) Rotate around the origin
        ctx.rotate(rotateRads)
        // 2) Scale the image
        ctx.scale(scale, scale)
        // 1) Move the center of the image to the origin (0,0)
        ctx.translate(-centerX, -centerY)
        ctx.drawImage(
          image,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight,
          0,
          0,
          image.naturalWidth,
          image.naturalHeight
        )
        ctx.restore()

        return canvas.toDataURL('image/png',1)
}

function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}

function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
  ) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width:100
        },
        aspect,
        mediaWidth,
        mediaHeight,
      ),
      mediaWidth,
      mediaHeight,
    )
  }

const ImageCropper = ({setFile,setCoverPreview}) => {

    const [imgSrc, setImgSrc] = useState('')
    const [fileName, setFileName] = useState(null)
    const imgRef = useRef(null)
    const [crop, setCrop] = useState({
      unit: '%',
      width:10,
      x: 0,
      y: 0,
    })
    const [completedCrop, setCompletedCrop] = useState()
    const aspect  = 16 / 9

    const onDarg = useCallback(()=>{
        if (
          completedCrop?.width &&
          completedCrop?.height &&
          imgRef.current 
        ) {
          // We use canvasPreview as it's much faster than imgPreview.
          let imageUrl = toCanvas(imgRef.current,completedCrop)

          setCoverPreview(imageUrl)

          let fileObject = dataURLtoFile(imageUrl,fileName)

          setFile(fileObject)

        }
      
},[completedCrop])
  
    function onSelectFile(e) {
      if (e.target.files && e.target.files.length > 0) {
        let file_Name = e.target.files[0].name
        setCrop(undefined) // Makes crop preview update between images.
        setFileName(file_Name)
        const reader = new FileReader()
        reader.addEventListener('load', () =>
          setImgSrc(reader.result?.toString() || ''),
        )
        reader.readAsDataURL(e.target.files[0])
      }
    }
  
    function onImageLoad(e) {
      if (aspect){
        const { height } = e.currentTarget
        if(window.innerWidth > 767){
          setCrop(centerAspectCrop(130, height, aspect))
        }else{
          setCrop(centerAspectCrop(80, height, aspect))
        }      
      }
    }


    useEffect(()=>{
        onDarg()
    },[onDarg])

  return (
    <div>
        <div className="Crop-Controls text-center">
            <input id='coverImage' className='d-none' type="file" accept="image/png,image/jpg,image/jpeg" onChange={onSelectFile} />
            <label htmlFor='coverImage' className='btn btn-blue text-white'>Choose Cover Image</label>
        </div>   
        <div className='my-2'>
        {!!imgSrc && (
            <ReactCrop
            crop={crop}
            locked={true}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={aspect}
            >
            <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{maxWidth:'100%'}}
                onLoad={onImageLoad}
            />
            </ReactCrop>
        )}    
        </div>
        
    </div>
  )
}

export default ImageCropper