import React, { useState, useRef} from 'react'
import { TwitterShareButton, LinkedinShareButton , FacebookShareButton } from "react-share";
import CustomPopup from '../Pop/CustomPopup';

const SharePopup = ({show,setShow,w3w}) => {

  const inputPost = useRef(null)

  const [isCopied, setCopied] = useState(false)

  const copyUrl = () => {
    inputPost.current.select();
    navigator.clipboard.writeText(inputPost.current.value);
    setCopied(true)
    setTimeout(()=>{
       setCopied(false)
    },2000)
  };

  const close = () =>{
    setShow(false)
  }

  return ( 
    <CustomPopup show={show} onClose={close} type='welcome' isSmall={true}>

    <img className='w-100' src={`${process.env.PUBLIC_URL}/images/post-success-banner.png`} alt='bg' />
    <div className='text-center mb-4'>
           <div className='text-capitalize text-white welcomePopup-heading'>
                <h2 className='mt-3'>
                  Share Post
                </h2>
           </div>
    </div>
    <div className='mt-4 mb-5 text-center' >
                    <input ref={inputPost} readOnly={true} hidden={true} value={`https://beachcollective.io/share/${w3w}/`} />
                    <div className='d-flex justify-content-center sharepopup align-items-start'>
                        <div className='shareCopyWrapper'>
                            <button className='shareLinkCopyBtn shareBtn' onClick={copyUrl}>
                                <img src='/images/copyIcon.png' height={45} alt='copy' />
                            </button>
                           {isCopied && <p className='mt-1 mb-0' style={{fontSize:'9px'}}>
                               Copied!
                            </p>}
                        </div>
                        <LinkedinShareButton className='shareBtn'
                            url={`https://beachcollective.io/share/${w3w}/`}
                            summary={'#BeachAction #BeachCollective #ClimateChange #ActOnClimate #ProtectOurOceans #SaveOurSeas @beach_token #BEACH'}
                        >
                            <img src='/images/LinkedinSharelogoSquare.png' height={55} alt='linkedin' />
                        </LinkedinShareButton>
                        <FacebookShareButton url={`https://beachcollective.io/share/${w3w}/`}
                            hashtags={'#BeachAction #BeachCollective #ClimateChange #ActOnClimate #ProtectOurOceans #SaveOurSeas @beach_token #BEACH'}
                            quote={'#BeachAction #BeachCollective #ClimateChange #ActOnClimate #ProtectOurOceans #SaveOurSeas @beach_token #BEACH'}
                            className='shareBtn'>
                            <img src='/images/facebookSharelogo.png' height={55} alt='facebook' />
                        </FacebookShareButton>

                        <TwitterShareButton className='shareBtn'
                            url={`https://beachcollective.io/share/${w3w}/ @beach_token  $BEACH`}
                            hashtags={['BeachAction', 'BeachAction', 'BeachCollective', 'ClimateChange', 'ActOnClimate', 'ProtectOurOceans', 'SaveOurSeas']}
                            related={['beach_token']}
                        >
                            <img src='/images/twitterSharelogo.png' height={55} alt='twitter' />

                        </TwitterShareButton>

                    </div>
                </div>
                <div className='my-3'></div>

    </CustomPopup>
  )
}

export default SharePopup