import { useEffect, useRef } from "react";
import jazzicon from "@metamask/jazzicon";

function MetaMaskAvatar(props) {
  // console.log("acc->", props.account);
  const avatarRef = useRef();
  useEffect(() => {
    const element = avatarRef.current;
    if (element && props.account) {
      const addr = props.account.slice(2, 10);
      const seed = parseInt(addr, 16);
      const icon = jazzicon(32, seed);
      // console.log("icon", icon);
      if (element.firstChild) {
        element.removeChild(element.firstChild);
      }
      element.appendChild(icon);
    }
  }, [props.account, avatarRef]);
  return <div id="india" ref={avatarRef} />;
}

export default MetaMaskAvatar;
