import React, { useContext } from 'react'

import CustomPopup from "../Pop/CustomPopup";
import GlobalContext from '../../Contexts/GlobalContext';
import { useNavigate } from 'react-router-dom';

const HomePopup = ({show,close,signup,signin}) => {

    const navigate = useNavigate()

    const { loggedIn } = useContext(GlobalContext)

    const openSignup = () =>{
        close(false)
        signup(true)
    }
    const openSignin = () =>{
        close(false)
        signin(true)
    }

    const handleNavigate = () =>{
      navigate('/action/map')
      close(false)
    }
  


    return (
      
        <CustomPopup show={show} onClose={()=>close(false)} type='welcome'  >

        <img src='/images/welcome-bg.webp' className='w-100 welcomePopupImage' />

           <div className='text-center mb-4'>
           <div className='text-uppercase text-white welcomePopup-heading'>
                <p className='fs-5' style={{letterSpacing:'2px',fontFamily:'qanelas_softregular'}}>Welcome to the</p>
                <h3>
                  Beach Collective
                </h3>
           </div>
         {
          loggedIn ?
          <p className='fs-4 mb-5 text-dark px-3 px-md-0'>
            Are you ready to make a difference?
          </p>
          :
          <p className='fs-4 mb-5 text-dark px-3 px-md-0'>
          The blue circular economy platform powered <br className='d-md-inline d-none' />
           by our native currency $BEACH
          </p>
         }
           
          { loggedIn ?
             <div className='row justify-content-center g-5 mb-5' >
                <div className='col-5 col-md-3 d-flex justify-content-center'>
                <button className={`btn text-white btn-blue w-100 popupBtn`} type="button" onClick={handleNavigate}>
                Take Action
              </button>
                  
                </div>
                <div className='col-5 col-md-3 d-flex justify-content-center'>
                  {/*<button className='btn btn-creme text-white' onClick={connectWallet} >Connect Wallet</button>*/}
                  <button
                className={`btn text-white btn-danger w-100 popupBtn`} 
                type="button"
                onClick={()=>close(false)}
                
              >
                Not Now
              </button>
                </div>
             
           </div>
           :
           <div className='row justify-content-center g-5 mb-5' >
                <div className='col-5 col-md-3 d-flex justify-content-center'>
                <button
                className={`btn text-white btn-blue w-100 popupBtn`} 
                type="button"
                onClick={openSignin}
                
              >
                Sign in
              </button>
                  
                </div>
                <div className='col-5 col-md-3 d-flex justify-content-center'>
                  {/*<button className='btn btn-creme text-white' onClick={connectWallet} >Connect Wallet</button>*/}
                  <button
                className={`btn text-white  blue-btn w-100 popupBtn`} 
                type="button"
                onClick={openSignup}
                
              >
                Sign up
              </button>
                </div>
             
           </div>
        }
           
           </div>
            
            
        </CustomPopup>
    )
}

export default HomePopup