import React,{useRef,useState} from 'react'
import {BsPlay} from 'react-icons/bs'

const VideoElement = ({src}) => {
    const [playState,setPlayState] = useState(false)
    const videoRef = useRef(null)
    const play = (action) =>{
        switch(action){
            case 'play':
                setPlayState(true)
                videoRef.current.play()
                break
            case 'pause':
                setPlayState(false)
                videoRef.current.pause()
                break
            default:
                return
        }
        
    }
  return (
    <div className='video_container'>
       { !playState && <button className='palyBtn' onClick={() => play('play')} >
       <BsPlay size={30}  color={'#007A97'} />
       </button> 
    }
        <video controls={false}  alt="video" onEnded={()=>setPlayState(false)} onClick={() => play('pause')}  className='main_video' src={src} ref={videoRef} />
    </div>
  )
}

export default VideoElement