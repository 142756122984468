import React,{useContext} from 'react'
import { ethers } from "ethers";
import CustomPopup from "./Pop/CustomPopup";
import GlobalContext from '../Contexts/GlobalContext';
import { getProvider } from '../connectors';

const ErrorNetwork = ({show,close}) => {

    const {
        connectedWalletType,
        setNetworkId,
        setShowNetworkAlert 
    } = useContext(GlobalContext)

       // network switch
   const switchNetwork = async (provider_obj) => {

    const provider = new ethers.providers.Web3Provider(provider_obj);

    const { chainId } = await provider.getNetwork();
    if(chainId != 56 ){

    try {
      await provider_obj.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x38' }], //binance chain
      });
      close(false)
      setShowNetworkAlert(false)
    } catch (e) {
      if (e.code === 4902) {
        try {
          await provider_obj.request({
            jsonrpc: '2.0',
            method: 'wallet_addEthereumChain',
            params: [
                {
                    chainId: '0x38',
                    chainName: 'Binance Smart Chain Mainnet',
                    rpcUrls: ['https://bsc-dataseed.binance.org/'],
                    nativeCurrency: {
                        name: 'BNB',
                        symbol: 'BNB',
                        decimals: 18
                    },
                    blockExplorerUrls: ['https://bscscan.com']
                }
            ],
            id: 0
        })
        close(false)
        setShowNetworkAlert(false)
        } catch (addError) {
          console.error(addError);
        }
      }
      // console.error(e)
    }

  }


    const newChainId  = await provider.getNetwork()
    // debugger;
    setNetworkId(newChainId.chainId);
    console.log(newChainId.chainId) 
  }

  const changeNetwork = async () =>{
    const provider_obj = getProvider(connectedWalletType);

    await switchNetwork(provider_obj)
  }
    

  return (
    <CustomPopup show={show} onClose={()=>close(false)}  >
        <div className='row my-4 justify-content-center'>
            <div className='col-10 '>
                <p className='text-dark fs-5 text-center'>
                Oops! It seems your active network on wallet now is not our Supported SmartChain Main network.
                </p>
                <p className='text-dark fs-5 text-center mt-3'>
                Please switch back to SmartChain Main network and try again
                </p>
                <div className='text-center mt-5'>
                    <button className='btn fs-5 blue-btn height-50 text-white px-4' onClick={changeNetwork}>
                        Switch Network
                    </button>
                </div>
            </div>
        </div>
        
    </CustomPopup>
  )
}

export default ErrorNetwork