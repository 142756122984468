import React, { useContext, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import styles from './signup.module.css'
import validator from "validator";
import CustomPopup from "../Pop/CustomPopup";
import { IoCloseSharp } from 'react-icons/io5'
import GlobalContext from "../../Contexts/GlobalContext";
import axios from "axios";
import axiosInstance from "../../Axios/axios.config";
import { useNavigate } from "react-router-dom";
import { checkPasswordValidity } from "../../helpers/utils";
import ReCAPTCHA from "react-google-recaptcha"

const initialLoginState = {
  userEmail: "",
  userPassword: ""
};

const errorInitial = {
  userPassword: false,
  userEmail: false,
  userNewPassword: false,
  confirmPassword: false,
  forgotOTP: false
};

const LoginModal = ({ show, hide, openSignup }) => {
  const {
    loggedIn,
    setLoggedIn,
    setBalance,
    setIsSignup,
    setLoginWithEmail,
    setUser,
    setWalletAddress,
    setWalletPalceHolder,
    setUserId,
    setWelcomePop,
    setBagBeachTokenBscBalance,
    setBagBeachTokenBscBalanceUsd,
    setCheckWallet,
    setShowTutorial,
    setCreatedWithEmail,
    setUserType
  } = useContext(GlobalContext);


  const navigate = useNavigate()

  const [loginForm, setLoginForm] = React.useState(initialLoginState);

  const [formErrors, setFormErrors] = React.useState(errorInitial);

  const [passwordError, setPasswordError] = React.useState('')

  const [otp, setOTP] = React.useState("");

  const [otpError, setOTPError] = React.useState(false);

  const [forgotEmail, setForgotEmail] = React.useState("");

  const [forgotEmailError, setForgotEmailError] = React.useState(false);

  const [newPassword, setNewPassword] = React.useState("");

  const [activeForm, setActiveForm] = React.useState("login");

  const [forgotOTP, setForgotOTP] = React.useState("");

  const [confirmPassword, setConfirmPassword] = React.useState("");

  const [showPassword, setShowPassword] = React.useState(false);

  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const [logout, setLogout] = React.useState(false);

  const [errorMsg, setErrorMsg] = React.useState("");

  const [captcha, setCaptcha] = React.useState(null);

  const captchaRef = useRef(null)

  const handleClick = async () => {

    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    setCaptcha(null)

    let payload = JSON.stringify({
      recaptcha_token : token ,
      email: loginForm.userEmail.trim(),
      password: loginForm.userPassword,
    })

    const response = await axios.post(`/user/user-login-with-email/`, payload,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true
      }
    );

    let status = response.data

    if (response.status === 400) {
      setErrorMsg(status.error);
      const flag = true || status.is_verified
      setTimeout(() => {
        setErrorMsg("");
        if (!flag) {
          setActiveForm("otp");
        } else {
          setLoginForm(initialLoginState);
        }
      }, 5000);
    }

    if (response.statusText === 'OK') {
      setActiveForm('success')
      // console.log('user dataccccccc--->',status)
      toast.success("Log-in successful", { containerId: 'toast' });
      localStorage.setItem('loggedIn', true)

      axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${status.token.access}`
      setLoggedIn(true);
      if (validator.isEmail(status.data.user.username)) {
        setCreatedWithEmail(true);
      } else {
        setCreatedWithEmail(false);
      }
      setLoginWithEmail(status.data.user.current_login_with_email);
      setUserId(status.data.user.id);
      setWelcomePop(status.data.user.show_first_info);
      setShowTutorial(status.data.user.tutorial_flag);
      setBalance(status.data.beach_balance);
      setIsSignup(status.data.user.is_active);
      if (status.data.user.wallet_address) {
        setWalletAddress(status.data.user.wallet_address);
      }
      if (status.data.user.full_name) {
        setWalletPalceHolder(status.data.user.full_name);
      } else {
        setWalletPalceHolder(status.data.user.username);
      }

      setCheckWallet(status.data.address)
      setUser({
        id: status.data.user.id,
        default_pay: status.data.user.defaul_beach_pay_user,
        qrcode: status.data.user.qrcode,
        image: status.data.user.image,
        name: status.data.user.full_name,
        banner: status.data.user.banner,
        email: status.data.user.email,
        type: status.data.user.user_type,
        slug: status.data.user.slug_url,
        description: status.data.user.description,
        company: status.data.user.name_or_campany_name,
        disable_bag: status.data.user.disable_bag_flag,
        bag_type: status.data.user.disable_bag_type,
        permissions: status.data.user.user_permissions,
        native_permission: status.data.user.native_permission,
        refferal_qr_code: status.data.user.refferal_qr_code,
        payment_mode : status.data.user.payment_mode
      })

      // hide(false);
      // setLoginForm(initialLoginState);

      let updatedDetails = await axiosInstance.get(`/user/wallet-details/`);

      let details = updatedDetails.data
      setBalance(details.data.total_balance_usd);
      setUserType(details.data.user.user_type)
      setBagBeachTokenBscBalance(details.data.beach_bsc_balance);

      setBagBeachTokenBscBalanceUsd(
        parseFloat(details.data.beach_bsc_balance_usd).toFixed(2)
      );
    }

  };

  const isLoginFieldsProper = () => {
    if ((loginForm.userEmail && !formErrors.userEmail)
      && (loginForm.userPassword && !formErrors.userPassword) && captcha) {
      return false;
    }
    return true;
  }
  const isForgotEmailProper = () => {
    if (forgotEmail && !forgotEmailError) {
      return false;
    }
    return true;
  }
  const isForgotOtpProper = () => {
    if (forgotOTP && !formErrors.forgotOTP && captcha) {
      return false;
    }
    return true;
  }

  const forgotOtpVerification = async () => {

    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    setCaptcha(null)


    let payload = JSON.stringify({
      'recaptcha_token': token,
      'email': forgotEmail,
      'otp': forgotOTP
    })

    const response = await axios.post(`/user/forget-password-otp-verification/`, payload, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    const data = await response.data
    if (response.statusText === 'OK') {
      setForgotOTP("");
      setActiveForm('newPassword')
    } else {
      setErrorMsg(data.error)
      setTimeout(() => {
        setErrorMsg("");
      }, 5000);
      // toast.error("OTP is incorrect", { containerId: 'toast' });
    }
  };

  const restPassword = async () => {
    let payload = JSON.stringify({
      'email': forgotEmail,
    })
    const response = await axios.post(`/user/forget-password-otp/`, payload, {
      headers: {
        'Content-Type': 'application/json',
      }
    });

    const data = response.data
    if (response.status === 400) {
      setErrorMsg(data.error)
      setTimeout(() => {
        setErrorMsg("");
      }, 5000);
      return
    }
    if (response.statusText === 'OK') {
      setActiveForm('newPasswordOtp')
    }
  };

  const handleForget = async () => {
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    setCaptcha(null)

    let payload = JSON.stringify({
      'recaptcha_token': token,
      'email': forgotEmail,
      'new_password': newPassword,
      'conf_password': confirmPassword,
      'logout_flag': logout
    })

    const response = await axios.post(`/user/password-reset/`, payload, {
      headers: {
        'Content-Type': 'application/json',
      }
    });

    const data = response.data
    if (response.status === 400) {
      setErrorMsg(data.error)
      setTimeout(() => {
        setErrorMsg("");
      }, 5000);
      return
    }
    if (response.statusText === 'OK') {
      setForgotEmail("");
      setNewPassword("");
      setConfirmPassword("");
      setActiveForm('forgotSuccess')
    }
  };
  const reSendOTP = async () => {
    
    captchaRef.current.reset();
    setCaptcha(null)

    let payload = JSON.stringify({
      'email': loginForm.userEmail
    })

    const response = await axios.post(`/user/otp-resend2/`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (response.statusText === 'OK') {
      setErrorMsg(
        "Code is resent to your email please check and enter above!"
      );
      setTimeout(() => {
        setErrorMsg("");
      }, 5000);
    }
  };
  const reSendForgotOTP = async () => {

    captchaRef.current.reset();
    setCaptcha(null)
    
    let payload = JSON.stringify({
      'email': forgotEmail
    })

    const response = await axios.post(`/user/forget-password-otp/`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    if (response.statusText === 'OK') {
      setErrorMsg(
        "Code is resent to your email please check and enter above!"
      );
      setTimeout(() => {
        setErrorMsg("");
      }, 5000);
    }
  };
  const verifyOTP = async () => {
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    setCaptcha(null)


    let payload = JSON.stringify({
      'recaptcha_token': token,
      'otp': otp,
      'email': loginForm.userEmail,
      'auto_login': true
    })

    const auto_login = await axios.post(`/user/user-otp-verification-email/`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    const resData =  auto_login.data;
    if (auto_login.statusText === 'OK') {
      setOTP("");
      setForgotEmail("");
      setActiveForm("login");
      handleClick();
    } else {
      setOTP("");
      setErrorMsg(resData.error);
      setTimeout(() => {
        setErrorMsg("");
      }, 5000);
    }
  };



  const handelEmailUsername = (e) => {
    let value = e.target.value;
    setLoginForm({ ...loginForm, userEmail: e.target.value })
    if (value.indexOf("@") > 0) {
      if (validator.isEmail(value)) {
        formErrors.userEmail = false
      } else {
        formErrors.userEmail = true
      }
    } else {
      formErrors.userEmail = false
    }
  }

  const handleForgotEmail = (e) => {
    setForgotEmail(e.target.value)
    if (e.target.value) {
      if (!validator.isEmail(e.target.value)) {
        setForgotEmailError(true)
      } else {
        setForgotEmailError(false)
      }
    } else {
      setForgotEmailError(false)
    }
  }

  const handleConfirmPassword = (e) => {
    setConfirmPassword(e.target.value)
    if (e.target.value) {
      if (newPassword !== e.target.value) {
        formErrors.confirmPassword = true
      } else {
        formErrors.confirmPassword = false
      }
    } else {
      formErrors.confirmPassword = false
    }
  }

  const isNewPasswordProper = () => {
    if ((newPassword && !formErrors.userNewPassword)
      && (confirmPassword && !formErrors.confirmPassword) && captcha) {
      return false
    }
    return true
  }

  const handleNewPassword = e => {
    setNewPassword(e.target.value)
    if (e.target.value) {
      let error = checkPasswordValidity(e.target.value)
      if (error) {
        setPasswordError(error)
        setFormErrors(prev => ({ ...prev, userNewPassword: true }))
      } else {
        setPasswordError('')
        setFormErrors(prev => ({ ...prev, userNewPassword: false }))
      }
    } else {
      setPasswordError('')
      setFormErrors(prev => ({ ...prev, userNewPassword: false }))
    }
  }

  const close = () => {
    hide(false)
    setActiveForm('login')
    setLoginForm(initialLoginState)
    setForgotEmail("");
    setForgotEmailError(false);
    setNewPassword("");
    setForgotOTP("");
    setConfirmPassword('')
  }

  const handlePay = () => {
    navigate('/pay')
    close()
  }
  const handleAction = () => {
    navigate('/action')
    close()
  }
  const handleAbout = () => {
    navigate('/about')
    close()
  }

  const onExpired = () =>{
    setCaptcha(null)
    captchaRef.current.reset()
  }



  function getSuccessMsg() {
    return (
      <div className="col-md-8">
        <div className="text-center my-4">
          <h4 className="text-blue">
            Your Password has been reset successfully!
          </h4>
          <div className="text-center mt-4 ">
            <button className={`btn text-white  btn-blue w-50 ${styles.submitBtn}`} type="button" onClick={()=>setActiveForm('login')}>
              ok
            </button>
          </div>
        </div>
      </div>
    );
  }

  function getLoginForm() {
    return (
      <div className={`col-12 d-flex  px-0 ${styles.sectionWrapper}`}>
        <div className={styles.imageSide}>
          <img className={`${styles.image} d-md-block d-none`} src='/images/signin-md-img.png' alt="banner" />
          <img className={`${styles.image} d-md-none d-block`} src='/images/signin-xs-img.png' alt="banner" />
        </div>

        <div className={`${styles.imageTextWrapper} text-dark d-none d-md-block`}>
          <h4>Take Action Today!</h4>
          <p className="fs-6 mt-5">
            The Beach Collective is a blue circular economy <br />
            platform powered by a smart currency ($BEACH) <br />
            which internalizes the health of our oceans into <br />
            every transaction, and a social platform which <br />
            rewards direct climate action.
          </p>
        </div>

        <img className={styles.bottomLogo} src="/images/bc-logo.svg" width={35} alt="logo" />

        <div className={styles.content}>
          <div className='px-5 my-md-3 mt-3 text-start'>
            <h5 className='text-dark mb-5 text-center text-md-start'>Sign In</h5>
            {errorMsg && <div className="text-center text-danger mb-3">
              {errorMsg}
            </div>}
            <div className="mb-3">
              <div className={`${styles.inputBox} ${formErrors.userEmail ? styles.outlineRed : ''}`}>
                <input
                  className="text-center "
                  value={loginForm.userEmail}
                  type="text"
                  placeholder="Enter Username or Email Address"
                  onChange={handelEmailUsername}
                />
                {
                  loginForm.userEmail &&
                  <span className={styles.status}>
                    {
                      formErrors.userEmail ?
                        <IoCloseSharp color='red' />
                        :
                        <img src='/images/Green-tick.svg' alt='tick' />
                    }
                  </span>
                }
              </div>
            </div>
            <div className="mb-3">
              <div className={`${styles.inputBox} ${formErrors.userPassword ? styles.outlineRed : ''}`}>
                <input
                  className="text-center"
                  type={showPassword ? 'text' : 'password'}
                  value={loginForm.userPassword}
                  placeholder="Enter your Password"
                  onChange={(e) =>
                    setLoginForm({
                      ...loginForm,
                      userPassword: e.target.value,
                    })
                  }
                />
                <button className={`btn ${styles.toggleShow}`} onClick={() => setShowPassword(!showPassword)}>
                  {
                    showPassword ?
                      <img src='/images/show-eye.svg' />
                      :
                      <img src='/images/hide-eye.svg' />
                  }
                </button>
                {
                  loginForm.userPassword &&
                  <span className={styles.status}>
                    {
                      formErrors.userPassword ?
                        <IoCloseSharp color='red' />
                        :
                        <img src='/images/Green-tick.svg' alt='tick' />
                    }
                  </span>
                }
              </div>

            </div>

            <div className="g-recaptcha ">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                onExpired={onExpired}
                ref={captchaRef}
                onChange={value=>setCaptcha(value)}
              />
            </div>


            <div className="text-center">
              <button
                className={`btn text-white  btn-blue w-100 ${styles.submitBtn}`}
                type="button"
                onClick={handleClick}
                disabled={isLoginFieldsProper()}
              >
                Sign in
              </button>
            </div>
            <div className=" my-4">
              <small className="text-dark d-flex align-items-center text-decoration-underline">
                Don't have an account yet?
                <button
                  className="btn p-0 m-0 forgot_link"
                  onClick={() => {
                    hide(!show);
                    openSignup(true);
                  }}
                >
                  <small>Signup</small>
                </button>
              </small>
            </div>


            <button
              className="btn m-0 p-0 forgot_link"
              onClick={() => setActiveForm("forgot")}
            >
              <small> Forgot Password?</small>
            </button>


          </div>
        </div>
      </div>

    );
  }

  function getLoginSuccess() {
    return (
      <div className={`col-12 d-flex  px-0 ${styles.sectionWrapper}`}>
        <div className={styles.imageSide}>
          <img className={`${styles.image} d-md-block d-none`} src='/images/signin-login-success.png' alt="banner" />
          <img className={`${styles.image} d-md-none d-block`} src='/images/signin-success-xs.png' alt="banner" />
        </div>

        <img className={styles.bottomLogo} src="/images/bc-logo.svg" width={35} alt="logo" />

        <div className={styles.content}>
          <div className='px-5 mt-5   text-start'>
            <h5 className='text-dark mb-5 text-start'>Welcome back, <strong>{loginForm.userEmail}</strong></h5>
            <div className="my-4">
              <button className={`btn text-white  btn-blue w-100 ${styles.submitBtn} fs-5`} type="button" onClick={handleAction}>
                POST ON BEACH ACTION
              </button>
            </div>
            <div className="my-4">
              <button className={`btn text-white  btn-signup w-100 ${styles.submitBtn} fs-5`} type="button" onClick={handlePay}>
                SEND / RECEIVE A PAYMENT
              </button>
            </div>
            <div className="my-4">
              <button className={`btn text-white  w-100 ${styles.submitBtn} fs-5`} style={{ background: '#00A7E1' }} type="button" onClick={handleAbout}>
                ABOUT US
              </button>
            </div>
            <div className="text-center mt-5 d-none d-md-block">
              <img src="/images/user_unselect.svg" className="" width={60} height={60} alt="logo" />
            </div>
            <div className="d-md-none position-relative text-center" style={{ marginBottom: '-75px' }}>
              <img src="/images/signin-profile-logo.svg" width={60} height={60} alt="logo" />
            </div>
          </div>
        </div>
      </div>
    )
  }

  function getOtpForm() {
    return (
      <div className="col-11 col-md-8">

        <div className='mb-4'>
          <p className='text-center text-blue'>We sent a verification code via your email address.<br />
          Please enter verification code sent to your email address.</p>
        </div>

        {errorMsg && <div className="text-center text-danger mb-3">
          {errorMsg}
        </div>
        }

        <div className="mb-4">
          <div className={`${styles.inputBox} ${otpError ? styles.outlineRed : ''}`}>
            <input
              className="text-center"
              type={'number'}
              value={otp}
              placeholder="Enter OTP"
              onChange={(e) => setOTP(e.target.value)}
            />
            {
              otp &&
              <span className={styles.status}>
                {
                  otpError ?
                    <IoCloseSharp color='red' />
                    :
                    <img src='/images/Green-tick.svg' alt='tick' />
                }
              </span>
            }
          </div>
        </div>

        <div className="g-recaptcha">
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_SITE_KEY}
          ref={captchaRef}
          onExpired={onExpired}
          onChange={value=>setCaptcha(value)}
        />
      </div>

        <div className='mb-4 text-center'>

          <button className={`btn  btn-blue w-50 ${styles.submitBtn} ${styles.fs_1_2} text-capitalize bg-white  btn-outline-blue`} onClick={reSendOTP}>
            Resend Password
          </button>

        </div>
        <div className='text-center mb-4'>

          <button className={`btn text-white  btn-blue w-50 ${styles.submitBtn} ${styles.fs_1_2} text-capitalize `} onClick={verifyOTP} disabled={otp === ''} >
            Verify
          </button>

        </div>

      </div>
    );
  }

  function getForgotForm() {
    return (

      <div className="col-11 col-md-8">
        {errorMsg && <div className="text-center text-danger mb-3">
          {errorMsg}
        </div>}

        <div className="mb-3">
          <div className={`${styles.inputBox} ${forgotEmailError ? styles.outlineRed : ''}`}>
            <input
              className="text-center "
              value={forgotEmail}
              type="text"
              placeholder="Enter your email address"
              onChange={handleForgotEmail}
            />
            {
              forgotEmail &&
              <span className={styles.status}>
                {
                  forgotEmailError ?
                    <IoCloseSharp color='red' />
                    :
                    <img src='/images/Green-tick.svg' alt="tick" />
                }
              </span>
            }
          </div>
        </div>


        <div className="text-center my-4">
          <button
            className={`btn text-white  btn-blue w-50 ${styles.submitBtn}`}
            onClick={restPassword}
            disabled={isForgotEmailProper()}
          >
            Reset
          </button>
        </div>
      </div>

    );
  }

  function getNewPasswordForm() {
    return (
      <div className='col-11 col-md-8'>
        <div className="mb-3">
          <div className={`${styles.inputBox} ${formErrors.userNewPassword ? styles.outlineRed : ''}`}>
            <input
              className="text-center"
              type={showPassword ? 'text' : 'password'}
              value={newPassword}
              placeholder="Create new password"
              onChange={handleNewPassword}
            />

            <button className={`btn ${styles.toggleShow}`} onClick={() => setShowPassword(!showPassword)}>
              {
                showPassword ?
                  <img src='/images/show-eye.svg' alt='eye' />
                  :
                  <img src='/images/hide-eye.svg' alt="hide" />
              }
            </button>
            {
              newPassword &&
              <span className={styles.status}>
                {
                  formErrors.userNewPassword ?
                    <IoCloseSharp color='red' />
                    :
                    <img src='/images/Green-tick.svg' alt='tick' />
                }
              </span>
            }
          </div>
          {passwordError && <small className='d-block my-2 text-danger font-regular'>{passwordError}</small>}
        </div>
        <div className="mb-3">
          <div className={`${styles.inputBox} ${formErrors.confirmPassword ? styles.outlineRed : ''}`}>
            <input
              className="text-center"
              type={showConfirmPassword ? 'text' : 'password'}
              value={confirmPassword}
              placeholder="Confirm password"
              onChange={handleConfirmPassword}
            />
            <button className={`btn ${styles.toggleShow}`} onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
              {
                showConfirmPassword ?
                  <img src='/images/show-eye.svg' />
                  :
                  <img src='/images/hide-eye.svg' />
              }
            </button>
            {
              confirmPassword &&
              <span className={styles.status}>
                {
                  formErrors.confirmPassword ?
                    <IoCloseSharp color='red' />
                    :
                    <img src='/images/Green-tick.svg' alt='tick' />
                }
              </span>
            }
          </div>

        </div>

        <div className="g-recaptcha ">
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_SITE_KEY}
          ref={captchaRef}
          onExpired={onExpired}
          onChange={value=>setCaptcha(value)}
        />
      </div>


        <div className='d-flex justify-content-center gap-2 align-items-center'>
          <input type={'checkbox'} className={`${styles.radio}`} name='logout' id="logout" checked={logout} onChange={e => setLogout(e.target.checked)} />
          <label htmlFor='logout' className={`me-2 ${styles.label} text-blue`} >Do you want to logout from all devices?</label>
        </div>

        

        <div className="text-center my-4">
          <button
            className={`btn text-white  btn-blue w-50 ${styles.submitBtn}`}
            onClick={handleForget}
            disabled={isNewPasswordProper()}
          >
            Submit
          </button>
        </div>
      </div>
    )
  }

  function getForgotOtpForm() {
    return (
      <div className="col-11 col-md-8">
        {errorMsg && <div className="text-center text-danger mb-3">
          {errorMsg}
        </div>}
        <div className="mb-3">
        <div className={`${styles.inputBox} ${formErrors.forgotOTP ? styles.outlineRed : ''}`}>
          <input
            className="text-center"
            type={'number'}
            value={forgotOTP}
            placeholder="Enter OTP"
            onChange={(e) => setForgotOTP(e.target.value)}
          />

          {
            forgotOTP &&
            <span className={styles.status}>
              {
                formErrors.forgotOTP ?
                  <IoCloseSharp color='red' />
                  :
                  <img src='/images/Green-tick.svg' alt='tick' />
              }
            </span>
          }
        </div>
        </div>

        <div className="g-recaptcha">
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_SITE_KEY}
          ref={captchaRef}
          onExpired={onExpired}
          onChange={value=>setCaptcha(value)}
        />
      </div>

        <div className="text-center mt-4  ">
          <button className={`btn  btn-blue w-50 ${styles.submitBtn} ${styles.fs_1_2} text-capitalize bg-white  btn-outline-blue`} onClick={reSendForgotOTP}>
            Resend Password
          </button>

        </div>
        <div className="text-center my-4">
          <button
            className={`btn text-white  btn-blue w-50 ${styles.submitBtn}  ${styles.fs_1_2} text-capitalize`}
            onClick={forgotOtpVerification}
            disabled={isForgotOtpProper()}
          >
            Verify
          </button>
        </div>
      </div>

    )
  }


  const getForm = () => {
    switch (activeForm) {
      case "login":
        return getLoginForm();
      case 'success':
        return getLoginSuccess()
      case "forgot":
        return getForgotForm();
      case "forgotSuccess":
        return getSuccessMsg();
      case "otp":
        return getOtpForm();
      case "newPassword":
        return getNewPasswordForm();
      case "newPasswordOtp":
        return getForgotOtpForm();
      default:
        return null;
    }
  };

  useEffect(() => {
    if (!loggedIn && localStorage.getItem('user_autofill') && localStorage.getItem('loggedIn') !== 'true') {
      let username = localStorage.getItem('user_autofill')
      setLoginForm(prev => ({ ...prev, userEmail: username }))
      hide(true)
    }
  }, [])


  const handleBack = () => {
    setForgotEmail('')
    setForgotEmailError(false)
    setActiveForm('login')
  }

  return (
    <CustomPopup show={show} onClose={close} title={
      (activeForm === "login" || activeForm === "success") ?
        "" : activeForm === "otp" ?
          "Please Enter Verifiction Code" : "Forgot Password"
    }
      tag={activeForm === "otp" ? '(One Time Password)' : ''}
      goBack={activeForm === 'forgot'}
      onBack={handleBack}
    >


      <div className='row justify-content-center'>
        {
          getForm()
        }
      </div>
    </CustomPopup>
  );
};

export default LoginModal;
