import React, { useContext, useState, useRef } from 'react'
import GlobalContext from '../../Contexts/GlobalContext';
import { Link } from "react-router-dom";
import { MdContentCopy } from "react-icons/md";
import { FaEthereum } from "react-icons/fa";
import { AiOutlineTeam } from "react-icons/ai";
import { BiLogOut, BiWallet, BiInfoCircle, BiLogIn } from "react-icons/bi";
import Model from "../ConnectWallet/Model";
import { isMetamask } from "../../connectors";
import useLocalStorage from "../../hooks/useLocalStroage";
import ReferencePopup from "../ReferencePopup";
import HomePopup from "../WelcomePopups/HomePopup";
import PayPopup from "../WelcomePopups/PayPopup";
import ActionPopup from "../WelcomePopups/ActionPopup";
import WelcomePopup from '../WelcomePopups/WelcomePopup'
import { useWeb3React } from '@web3-react/core'
import SignupModalNew from "../index/SignupModal";
import LoginModal from "../index/LoginModal";
import { toast } from "react-toastify";
import { disconnectWallet } from "../../Web3Client";
import ProfileModel from "../index/ProfileModel";

import { Helmet, HelmetProvider } from "react-helmet-async";

import { Carousel , CarouselItem } from 'react-bootstrap';
import axiosInstance from '../../Axios/axios.config';
import { useEffect } from 'react';

const About = () => {

  const {
    user,
    walletAddress,
    loggedIn,
    loginWithEmail,
    setLoggedIn,
    openConnector,
    setOpenConnector,
    setDefaultConnector,
    setProfileModal
  } = useContext(GlobalContext);

  const { deactivate, library } = useWeb3React()

  const menuDrop = useRef(null)

  const videoRef = useRef(null)


  const [isOpen, setOpen] = useState(false);

  const [openedMenu, setOpenedMenu] = useState(false);

  const [loginPopup, setLoginPopup] = useState(false);

  const [sharingLink, setSharingLink] = useState(false);

  const [homePop, setHomePop] = useLocalStorage('homePop', true);
  const [actionPop, setActionPopup] = useState(false);
  const [payPop, setPayPop] = useState(false);

  const handelLogout = async () => {
   
    let logout = await axiosInstance.post(`/user/user-logout/`,{}, {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials : true
    });

    if (logout.statusText === 'OK') {
      setLoggedIn(false);
      disconnectWallet(deactivate,library,user.name);
      toast.success("Successfully logout");
    } else {
      toast.warn("Something went wrong");
    }
  };

  const copytexturl = () => {
    setTimeout(async () => {
      // copyToClipboard(copyText.value);
      await navigator.clipboard.writeText(walletAddress);
      // alert("copied to clipboard");
    }, 2000);
  };

  const connect = async () => {
    if (isMetamask) {
      console.log(typeof isMetamask);
      console.log("insde connectwallet.metamask");
      setDefaultConnector(true);
    } else {
      setOpenConnector(!openConnector);
    }
  };

  const removeOverLay = () => {
    if (openedMenu) {
      setOpenedMenu(false)
    }
  }

  const handleMenu = () => {
    if (menuDrop.current.classList.contains('show')) {
      menuDrop.current.classList.remove('show')
    }
    setOpenedMenu(!openedMenu)
  }

  const attemptPlay = () => {
    videoRef &&
      videoRef.current &&
      videoRef.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
      videoRef.current.currentTime = 5
  };

  useEffect(()=>{
    attemptPlay()
  },[])

  return (
    <div>
    <HelmetProvider>
      <Helmet>
        <meta property="og:url" content="https://beachcollective.io/about"/>
      </Helmet>
    </HelmetProvider>
      <nav className="navbar navbar-expand-lg bg-light primary-menu">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/"><img src="/images/bc-logo.svg" height={60} alt="img" /></Link>

          <div className='d-flex d-lg-none align-items-end justify-content-center'>
            <button className="navbar-toggler me-3" onClick={() => setOpenedMenu(false)} type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon">
                <img src="/images/menuBar.svg" className="img-fluid" alt="bar" />
              </span>
            </button>
            <Link className='userNameLink me-2 mb-1' to={'/account'}>{user.name}</Link>
            <div className="dropdown">

              {!user.image ?
                <span onClick={handleMenu} id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                
                  {
                    openedMenu ? <img src="/images/user_unselect.svg" alt='user' width={30} />
                      :
                      <img src="/images/user_select.svg" alt='user' width={30} />
                  }

                </span>
                :
                <span onClick={handleMenu} id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                
                  <img className="rounded-circle" src={user.image || "https://api.beachcollective.io/media/default-user.png"} alt='user' width={30} height={30} />

                </span>

              }


              <ul
                className="dropdown-menu p-0"
                id="dropdownMenuButtonHome"
                aria-labelledby="dropdownMenuButtonHome"
                onClick={() => setOpenedMenu(false)}
              >
                {(walletAddress || loginWithEmail) && (
                  <li className="dropdown-item border-bottom">
                    <div className="user-img d-flex justify-content-between align-items-center">
                      <span className=" me-2">
                        {user.name ? user.name : ""}
                      </span>
                      <img
                        src={user.image || "https://api.beachcollective.io/media/default-user.png"
                        }
                        id="user_image-1"
                        style={{ height: "40px", width: "40px" }}
                        className="rounded-circle ml-3"
                        alt="img"
                        onClick={()=>setProfileModal(true)}
                      />
                    </div>
                  </li>
                )}


                {loggedIn && (
                  <>
                    <li
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                    >
                      <BiWallet />
                      <Link to={'/account'} className="ms-2 userLink">My Account</Link>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => setSharingLink(!sharingLink)}
                      style={{ cursor: "pointer" }}
                    >
                      <AiOutlineTeam />
                      <span className=" ms-2">Generate Referral</span>
                    </li>
                  </>
                )}
                {!loggedIn && (
                  <li
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={() => setLoginPopup(!loginPopup)}
                  >
                    <BiLogIn />
                    <span className="ms-2">Login</span>
                  </li>
                )}

                {!walletAddress && (
                  <li
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={connect}
                  >
                    {/* eslint-disable-next-line */}
                    <span className="text-dark">
                      <BiWallet />
                      <span className=" ms-2">Connect wallet</span>
                    </span>
                  </li>
                )}
                {walletAddress && !loginWithEmail && (
                  <>
                    <li
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                    >
                      {/* eslint-disable-next-line */}
                      <a href="#" className="text-dark">
                        <FaEthereum />
                        <span className=" ms-2">View</span>
                      </a>
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={copytexturl}
                      style={{ cursor: "pointer" }}
                    >
                      <MdContentCopy />
                      <span className=" ms-2">Copy</span>
                    </li>
                  </>
                )}

                {/*
loggedIn && !loginWithEmail &&
<li className="dropdown-item" style={{ cursor: 'pointer' }} onClick={handelLogout} >
<AiOutlineDisconnect />
<span className="ms-2">Disconnect</span>
</li>
*/}
                {loggedIn && (
                  <li
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={handelLogout}
                  >
                    <BiLogOut />
                    <span className="ms-2">Sign out</span>
                  </li>
                )}
                
                <li
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  onClick={() => setHomePop(true)}
                >
                  <BiInfoCircle />
                  <span className="ms-2">Help</span>
                </li>
              </ul>
            </div>
          </div>
          <div className="collapse navbar-collapse" ref={menuDrop} id="navbarTogglerDemo02">
            <ul className="navbar-nav me-auto mb-lg-2 mb-lg-0">
            <li className="nav-item">
            <Link className="nav-link active" to="/about">ABOUT</Link>
          </li>
              <li className="nav-item">
                <Link className="nav-link" aria-current="page" to="/action">ACTION</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/pay">PAY</Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" target='_blank' rel="noreferrer" href="https://token.beachcollective.io">TOKEN</a>
              </li>
             
              {/* <li className="nav-item">
                <Link className="nav-link" to="/">BEACH NOMAD</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/">BEACH SWAP</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/">BEACH FARM</Link>
</li>*/}
            </ul>
            <ul className="navbar-nav ms-auto mb-lg-2 mb-lg-0">
              <li className="nav-item" onClick={() => setOpen(true)}>
                <span className="nav-link d-lg-none" >
                  SIGN UP
                </span>
                <button className="btn blue-btn d-none d-lg-inline me-3 text-white"> SIGN UP </button>
              </li>
              <li className='nav-item d-inline-flex align-items-center'>
              <Link className='userNameLink me-2 ' to={'/account'}>{user.name}</Link>
              </li>
              <li className="nav-item dropdown  d-none d-lg-inline ">
                {!user.image ?
                  <span onClick={() => setOpenedMenu(!openedMenu)} id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  
                  {
                      openedMenu ? <img src="/images/user_select.svg" alt='user' width={30} />
                        :
                        <img src="/images/user_unselect.svg" alt='user' width={30} />
                    }

                  </span>
                  :
                  <span onClick={() => setOpenedMenu(!openedMenu)} id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  
                    <img className="rounded-circle" src={user.image || "https://api.beachcollective.io/media/default-user.png"} alt='user' width={30} height={30} />

                  </span>

                }
                <ul
                  className="dropdown-menu p-0"
                  id="dropdownMenuButtonHome"
                  aria-labelledby="dropdownMenuButtonHome"
                  onClick={() => setOpenedMenu(false)}
                >
                  {(walletAddress || loginWithEmail) && (
                    <li className="dropdown-item border-bottom">
                      <div className="user-img d-flex justify-content-between align-items-center">
                        <span className=" me-2">
                          {user.name ? user.name : ""}
                        </span>
                        <img
                          src={ user.image || "https://api.beachcollective.io/media/default-user.png"
                          }
                          id="user_image-1"
                          onClick={()=>setProfileModal(true)}
                          style={{ height: "40px", width: "40px" }}
                          className="rounded-circle ml-3"
                          alt="img"
                        />
                      </div>
                    </li>
                  )}


                  {loggedIn && (
                    <>
                      <li
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                      >
                        <BiWallet />
                        <Link to={'/account'} className="ms-2 userLink">My Account</Link>
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={() => setSharingLink(!sharingLink)}
                        style={{ cursor: "pointer" }}
                      >
                        <AiOutlineTeam />
                        <span className=" ms-2">Generate Referral</span>
                      </li>
                    </>
                  )}

                  {!loggedIn && (
                    <li
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={() => setLoginPopup(!loginPopup)}
                    >
                      <BiLogIn />
                      <span className="ms-2">Login</span>
                    </li>
                  )}

                  {!walletAddress  && (
                    <li
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={connect}
                    >
                      {/* eslint-disable-next-line */}
                      <span className="text-dark">
                        <BiWallet />
                        <span className=" ms-2">Connect wallet</span>
                      </span>
                    </li>
                  )}


                  {walletAddress && !loginWithEmail && (
                    <>
                      <li
                        className="dropdown-item"
                        style={{ cursor: "pointer" }}
                      >
                        {/* eslint-disable-next-line */}
                        <a href="#" className="text-dark">
                          <FaEthereum />
                          <span className=" ms-2">View</span>
                        </a>
                      </li>
                      <li
                        className="dropdown-item"
                        onClick={copytexturl}
                        style={{ cursor: "pointer" }}
                      >
                        <MdContentCopy />
                        <span className=" ms-2">Copy</span>
                      </li>
                    </>
                  )}

                  {/*
    loggedIn && !loginWithEmail &&
      <li className="dropdown-item" style={{ cursor: 'pointer' }} onClick={handelLogout} >
        <AiOutlineDisconnect />
        <span className="ms-2">Disconnect</span>
      </li>
*/}
                  {loggedIn && (
                    <li
                      className="dropdown-item"
                      style={{ cursor: "pointer" }}
                      onClick={handelLogout}
                    >
                      <BiLogOut />
                      <span className="ms-2">Sign out</span>
                    </li>
                  )}
                 
                  <li
                    className="dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={() => setHomePop(true)}
                  >
                    <BiInfoCircle />
                    <span className="ms-2">Help</span>
                  </li>
                </ul>
              </li>

            </ul>
          </div>
        </div>
      </nav>

      <div className={`${openedMenu ? 'overlay' : ''} `} onClick={removeOverLay} >

      <Carousel className="about-carousel" indicators={false} interval={1000000} > 
      <CarouselItem>
       <div className='slideCustom firstSlide'>
              <div className='container text-center text-white'>
              <h2>BEACH<span style={{fontWeight:'400'}} >COLLECTIVE</span> </h2>
              <p className='mt-5 mt-md-5'>

              Beach Collective is a social platform and marketplace designed to make it easy, fun and rewarding to do good
           </p>
              <div className='videoContainer'>
              <video  className="rounded-4" height="100%" 
              controls  
              playsInline 
              loop 
              muted
              ref={videoRef}
              src="/images/beach_collective.mp4"
              / >
              </div>
               <button className='btn btn-signup text-uppercase slideerBtn text-white' onClick={()=>setOpen(true)}>Sign up</button>
              </div>
       </div>
        
         
        
      </CarouselItem>
      <CarouselItem>
      <div className='slideCustom secondSlide'>
      <div className='container text-center text-white'>
      <h2>BEACH<span style={{fontWeight:'400'}}>COLLECTIVE</span></h2>
      <p className='mt-5 mb-5'>
      With your help, the Beach Collective will play
      its part in a single worldwide economy where every <br className='d-none d-xl-inline' /> transaction funds ocean regeneration and climate action
      </p>
      <div className='videoContainer '></div> 
      <button className='btn btn-signup text-white text-uppercase slideerBtn' onClick={()=>setOpen(true)} >Sign up</button>
      </div>
      </div>
      </CarouselItem>
      </Carousel>

        <div className='topWrapper'>
          <div className="layer" >
            <div className='container content'>
              <div className='row align-items-center'>
                <div className='d-none d-lg-flex justify-content-center col-lg-2'>
                  <Link to="/action" className="imgContainer">
                    <img src="/images/ic-beachAction.png" className='img-fluid' alt="action" />
                  </Link>
                </div>
                <div className='col-lg-8'>
                  <h2>BEACH ACTION</h2>
                  <p>
                    is a social platform which rewards <br />
                    direct climate action
                  </p>
                </div>
                <div className='col-lg-2'>
                  <Link to="/action" className="btn  blue-btn text-white">
                    VISIT
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="layer" >
            <div className='container content'>
              <div className='row align-items-center'>
                <div className='d-none d-lg-flex justify-content-center col-lg-2'>
                  <Link to="/pay" className="imgContainer">
                    <img src="/images/ic-beachPay.png" className='img-fluid' alt="pay" />
                  </Link>
                </div>
                <div className='col-lg-8'>
                  <h2>BEACH PAY</h2>
                  <p>
                  is a payment portal to send and <br/> receive $BEACH 
                 </p>
                 
                  {
                    /* 
                     <p>
                    is a low-friction payment portal for real world purchases <br />
                    that funds ocean conservation and climate action
                  </p>
                    */
                  }
                </div>
                <div className='col-lg-2'>
                  <Link to="/pay" className="btn  blue-btn text-white">
                    VISIT
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="layer " >
            <div className='container content'>
              <div className='row align-items-center'>
                <div className='d-none d-lg-block col-lg-2'>

                </div>
                <div className='col-lg-8'>
                  <h2>BEACH SHOP</h2>
                  <p>
                    is an online marketplace connecting planet-friendly brands<br />
                    and products with climate conscious consumers

                  </p>
                </div>
                <div className='col-lg-2'>
                  <h3>Coming  Soon</h3>
                </div>
              </div>
            </div>
          </div>
          {/*<div className="layer" >
            <div className='container content'>
              <div className='row align-items-center'>
                <div className='d-none d-lg-block col-lg-2'>

                </div>
                <div className='col-lg-8'>
                  <h2>BEACH NOMAD</h2>
                  <p>
                    is a freelancer portal for the rapidly growing <br />
                    “work from anywhere” crowd
                  </p>
                </div>
                <div className='col-lg-2'>
                  <h3>Coming  Soon</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="layer " >
            <div className='container content'>
              <div className='row align-items-center'>
                <div className='d-none d-lg-block col-lg-2'>

                </div>
                <div className='col-lg-8'>
                  <h2>BEACH SWAP</h2>
                  <p>
                    is an exchange where people can securely trade tokens
                  </p>
                </div>
                <div className='col-lg-2'>
                  <h3>Coming  Soon</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="layer" >
            <div className='container content'>
              <div className='row align-items-center'>
                <div className='d-none d-lg-block col-lg-2'>

                </div>
                <div className='col-lg-8'>
                  <h2>BEACH NFT</h2>
                  <p>
                    is a portal for the creation and low-carbon trading of NFTs
                  </p>
                </div>
                <div className='col-lg-2'>
                  <h3>Coming  Soon</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="layer" >
            <div className='container content'>
              <div className='row align-items-center'>
                <div className='d-none d-lg-block col-lg-2'>

                </div>
                <div className='col-lg-8'>
                  <h2>BEACH FARM</h2>
                  <p>
                    is an opportunity to safely put your $BEACH to work, <br />
                    staking or providing liquidity
                  </p>
                </div>
                <div className='col-lg-2'>
                  <h3>Coming  Soon</h3>
                </div>
              </div>
            </div>
                </div> */}
        </div>

        <div className='aboutWrapper' style={{ backgroundImage: 'url("images/sebastian-mittermeier.webp")' }}>
          <div className='container text-center mt-5'>
            <h2 className='text-uppercase mb-5'>About us</h2>

            <div className='row gy-3'>
              <div className='col-md-6 col-xl-3 d-flex justify-content-center'>
                <div className='aboutImgContainer'>
                  <img src='images/about-img1_new.webp' className='w-100 roundedImg' alt='img' height={250} />
                </div>
              </div>
              <div className='col-md-6 col-xl-3 d-flex justify-content-center'>
                <div className='aboutImgContainer'>
                  <img src='images/about-img2_new.webp' className='w-100 roundedImg' alt='img' height={250} />
                </div>
              </div>
              <div className='col-md-6 col-xl-3 d-flex justify-content-center'>
                <div className='aboutImgContainer'>
                  <img src='images/about-img3_new.webp' className='w-100 roundedImg' alt='img' height={250} />
                </div>
              </div>
              <div className='col-md-6 col-xl-3 d-flex justify-content-center'>
                <div className='aboutImgContainer'>
                  <img src='images/about-img4_new.webp' className='w-100 roundedImg' alt='img' height={250} />
                </div>
              </div>
            </div>

            <div className='my-5'>
              <h4 className='py-5 text-white'>
                Our work supports people, planet and addresses <br className='d-none d-md-inline' />
                half of the sustainable development goals.
              </h4>
            </div>
            <div className='row'>
            <div className='col-md-10 offset-md-1'>
              <div className='banner_grid'>

                <div>
                  <img src='images/about_banner1.webp' className='img-fluid' alt='img' />
                </div>
                <div>
                  <img src='images/about_banner2.webp' className='img-fluid' alt='img' />
                </div>
                <div>
                  <img src='images/about_banner3.webp' className='img-fluid' alt='img' />
                </div>
                <div>
                  <img src='images/about_banner4.webp' className='img-fluid' alt='img' />
                </div>
                <div>
                  <img src='images/about_banner5.webp' className='img-fluid' alt='img' />
                </div>

                <div>
                  <img src='images/about_banner6.webp' className='img-fluid' alt='img' />
                </div>
                <div>
                  <img src='images/about_banner7.webp' className='img-fluid' alt='img' />
                </div>
                <div>
                  <img src='images/about_banner8.webp' className='img-fluid' alt='img' />
                </div>
                <div>
                  <img src='images/about_banner9.webp' className='img-fluid' alt='img' />
                </div>
                <div>
                  <img src='images/about_banner10.webp' className='img-fluid' alt='img' />
                </div>
              </div>




            </div>
            </div>
          </div>
        </div>

        <div className='ourWrapper' style={{ backgroundImage: 'url("images/cristian-palmer.webp")' }}>
          <div className='container text-center mt-2'>
            <h2 className='text-uppercase mb-5'>Our Partners</h2>

            <div className='row flex-column flex-lg-column-reverse'>
              <div className='col-12 mt-lg-5' >
                <h5 className='pb-5  pt-md-5  text-center'>
                  To date we have engaged in ocean regeneration and direct climate action in  <br className='d-none d-md-inline' /> over 15 countries via our worldwide community partners
                </h5>
              </div>
              <div className='col-12' >
                <div className='row gy-lg-4 gx-lg-4 g-2'>
                  <div className='col-4 col-lg-3'>
                    <div className='imgCard'>
                      <img src='images/our_partner1.png' alt='img' />

                      <h5 className='imgName'>CAYMAN ISLANDS</h5>

                    </div>
                  </div>
                  <div className='col-4 col-lg-3'>
                    <div className='imgCard'>
                      <img src='images/our_partner2.png' alt='img' />

                      <h5 className='imgName'>BRAZIL</h5>

                    </div>
                  </div>
                  <div className='col-4 col-lg-3'>
                    <div className='imgCard'>
                      <img src='images/our_partner3.png' alt='img' />

                      <h5 className='imgName'>PAPUA NEW GUINEA</h5>

                    </div>
                  </div>
                  <div className='col-4 col-lg-3'>
                    <div className='imgCard'>
                      <img src='images/our_partner4.png' alt='img' />

                      <h5 className='imgName'>SOUTH AFRICA</h5>

                    </div>
                  </div>
                  <div className='col-4 col-lg-3'>
                    <div className='imgCard'>
                      <img src='images/our_partner5.png' alt='img' />

                      <h5 className='imgName'>GHANA</h5>

                    </div>
                  </div>
                  <div className='col-4 col-lg-3'>
                    <div className='imgCard'>
                      <img src='images/our_partner6.png' alt='img' />

                      <h5 className='imgName'>NIGERIA</h5>

                    </div>
                  </div>
                  <div className='col-4 col-lg-3'>
                    <div className='imgCard'>
                      <img src='images/our_partner7.png' alt='img' />

                      <h5 className='imgName'>KENYA</h5>

                    </div>
                  </div>
                  <div className='col-4 col-lg-3'>
                    <div className='imgCard'>
                      <img src='images/our_partner8.png' alt='img' />

                      <h5 className='imgName'>TANZANIA</h5>

                    </div>
                  </div>
                  <div className='col-4 col-lg-3'>
                    <div className='imgCard'>
                      <img src='images/our_partner9.png' alt='img' />

                      <h5 className='imgName'>CYPRUS</h5>

                    </div>
                  </div>
                  <div className='col-4 col-lg-3'>
                    <div className='imgCard'>
                      <img src='images/our_partner10.png' alt='img' />

                      <h5 className='imgName'>Philippines</h5>

                    </div>
                  </div>
                  <div className='col-4 col-lg-3'>
                    <div className='imgCard'>
                      <img src='images/our_partner11.png' alt='img' />

                      <h5 className='imgName'>INDONESIA</h5>

                    </div>
                  </div>
                  <div className='col-4 col-lg-3'>
                    <div className='imgCard'>
                      <img src='images/our_partner12.png' alt='img' />

                      <h5 className='imgName'>LEBANON</h5>

                    </div>
                  </div>

                </div>
              </div>

            </div>

            <div className='row'>
            <div className='col-md-10 offset-md-1'>
                    
            <h2 className='text-uppercase my-5'>Categories</h2>
            <div className='row gy-3 gy-md-0'>
                  <div className='col-4 col-lg-2 d-flex flex-column align-items-center'>
                    <img src='images/Cleaning.svg'  alt='img' />
                    <h5>Cleaning</h5>
                  </div>
                  <div className='col-4 col-lg-2 d-flex flex-column align-items-center'>
                  <img src='images/Conservation.svg' alt='img' />
                  <h5>Conservation</h5>
                  </div>
                  <div className='col-4 col-lg-2 d-flex flex-column align-items-center'>
                  <img src='images/Footprint.svg' alt='img' />
                  <h5>Footprint</h5>
                  </div>
                  <div className='col-4 col-lg-2 d-flex flex-column align-items-center'>
                  <img src='images/Planting.svg' alt='img' />
                  <h5>Planting</h5>
                  </div>
                  <div className='col-4 col-lg-2 d-flex flex-column align-items-center'>
                  <img src='images/Kindness.svg' alt='img' />
                  <h5>Kindness</h5>
                  </div>
                  <div className='col-4 col-lg-2 d-flex flex-column align-items-center'>
                  <img src='images/Wildlife.svg' alt='img' />
                  <h5>Nature</h5>
                  </div>
            </div>

            </div>
            </div>
          </div>
        </div>


       

        <div className='ourWrapperBottom' style={{ backgroundImage: 'url("images/big-bg.webp")' }} >
        <div className='row justify-content-center'>
            <div className='col-md-8 col-10'>
            <div className="followus-sec rounded bg-white box-shadow2">
            <h5>FOLLOW US</h5>
            <div className="followus-items">
              <a href="https://t.me/BeachToken" target="_blank"><img src="images/ic-telegram.png" alt="img" /></a>
              <a href="https://twitter.com/beach_token" target="_blank"><img src="images/ic-twitter2.png" alt="img" /></a>
              <a href="https://www.instagram.com/beach_token/" target="_blank"><img src="images/ico-instagram.svg" alt="img" /></a>
              <a href="https://m.facebook.com/beachtoken" target="_blank"><img src="images/ic-fb-f.png" alt="img" /></a>
              <a href="https://discord.gg/beachtoken" target="_blank"><img src="images/ic-discord.png" alt="img" /></a>
              <a href="https://www.youtube.com/channel/UCWe9w4b1asXIAxHatN_FFhw" target="_blank"><img src="images/ic-YouTube2.png" alt="img" /></a>
              <a href="https://www.linkedin.com/company/80447914" target="_blank"><img src="images/ic-linkedin2.png" alt="img" /></a>
              <a href="https://www.tiktok.com/@beach_token" target="_blank"><img src="images/ic-TikTok.png" alt="img" /></a>
            </div>
          </div>
    
            </div>
            </div>
            <div class="container">
              <div className='row'>
                    <div className='col-4 text-center'>
                        <img src='images/beachAction-mobile.webp' className='img-fluid' alt='img' />
                        <Link to={'/action'} className='imgButton-left btn blue-btn text-white text-uppercase' >BEACH Action</Link>
                    </div>
                    <div className='col-4 text-center'></div>
                    <div className='col-4 text-center'>
                      <img src='images/beachPay-mobile.webp' className='img-fluid'  alt='img' />
                        <Link to={'/pay'} className='imgButton-right btn blue-btn text-white text-uppercase' >Beach Pay</Link>
                    </div>
              </div>
              </div>
        </div>

        <div className='impactWrapper text-blue ' style={{ backgroundImage: 'url("images/muhammad.webp")' }}>
              <div className='container text-center'>
                    <h2 className='text-uppercase text-blue my-5'>IMPACT</h2>
                    <p>Together, we have provided <strong>3000 days</strong> </p>
                    <p>of employment,<strong> 1000 school meals,</strong>  </p>
                    <p>collected <strong> 100,000kg of waste </strong> </p>
                    <p>and planted <strong>2500 mangroves </strong> </p>

                  
              </div>
        </div>

        <div className='beachActionWrapper' style={{ backgroundImage: 'url("images/andreas-gucklhorn2x.webp")'}} >
              <div className='container text-center'>
                    <h2 className='text-center text-uppercase text-white mb-5'>Beach Action</h2>


                   <div className='row mt-5'>
                   <div className='col-lg-8 col-10 offset-1 offset-lg-2'>
                   <img className='img-fluid' src='images/mobileGroup.webp' alt='img' />
                  </div>
                   </div>

                    <div className='my-5'>
                    <h3 className='text-dark'>View our impact on</h3>
                    <h2 className='text-dark my-2'>Beach Action</h2>
                    <Link to={'/action'} className="btn btn-blue my-2 text-white btn-lg" >Visit Beach Action</Link>
                    </div>
              </div>
        </div>


        <footer>
        <div className="container-fluid">
          <div className="footer-logo mb-3 text-center"><img src="/images/footer-logo.png" width={350} alt='img'/></div>
          <div className="row flex-column-reverse flex-md-row">
            <div className="col-md-4 d-none d-md-block">
              <div className="footer-nav">
                <Link to="/action" >Action</Link>
                <Link to="/pay">Pay</Link>
                <a   data-bs-toggle="modal" href="#beach-shop">Shop</a>
                <a   data-bs-toggle="modal" href="#beach-nomad">Nomad</a>
                <a   data-bs-toggle="modal" href="#beach-farm">Farm</a>
                <a   data-bs-toggle="modal" href="#beach-nft">NFT</a> 
              
      </div>
            </div>
            <div className="col-md-8">
              <div className="row justify-content-end">
                <div className="col-md-4 col-lg-3">
                  <div className="widget widget_links text-center text-md-start clearfix my-4 my-md-0 my-xl-0">
                    <h4>CONTACT US</h4>
                    <a href="mailto:hello@beachtoken.io" target="_blank" className="emailus">hello@beachtoken.io</a>
                  </div>
                  <div className="foot-signup mt-3 d-md-none ">
                      <h3>Sign up for updates</h3>
                      <div className='signup_Input'>
                      <input type="text" id="" name="" placeholder="Type email address here..."/>
                      <button className="signup_Btn">SIGN UP</button>
                      </div>
                      
                    </div>
                </div>
                <div className="col-md-4 col-lg-3 mt-3 mt-md-0">

                  <div className="d-flex justify-content-between d-md-block align-items-center">
                  <div className="footer-nav d-md-none">
                  <Link to="/action" >Action</Link>
                  <Link to="/pay" >Pay</Link>
                  <a   data-bs-toggle="modal" href="#beach-shop">Shop</a>
                  <a   data-bs-toggle="modal" href="#beach-nomad">Nomad</a>
                  <a   data-bs-toggle="modal" href="#beach-farm">Farm</a>
                  <a   data-bs-toggle="modal" href="#beach-nft">NFT</a> 
                
        </div>
                  <div className="widget widget_links clearfix">
                    <ul>
                      <li><Link to="/">Home</Link></li>
                      <li><a href="https://beachtoken.io/static/images/%24BEACH_White_Paper_May_2022.pdf" rel="noreferrer"  target="_blank">White Paper</a></li>
                      <li><a href="https://pancakeswap.finance/swap?outputCurrency=0x1a57dc4e3bc63b06c2b263774859f227b99ab031" rel="noreferrer"  target="_blank">Buy on Pancake Swap</a></li>
                      <li><a href="https://app.uniswap.org/#/swap?outputCurrency=0xbd15c4c8cd28a08e43846e3155c01a1f648d8d42&use=V2" rel="noreferrer"  target="_blank">Buy on Uniswap</a></li>
                      <li><Link to="/">Blog</Link></li>
                      <li><a to="https://beachcollective.io/" rel="noreferrer"  target="_blank">Beach Collective</a></li>
                    </ul>
                  </div>

                  </div>

                </div>
              </div>

              <div className="row justify-content-end">
                <div className="col-md-7 col-xl-6 d-none d-md-block">
                    <div className="foot-signup py-4">
                      <h3>Sign up for updates</h3>
                      <input type="text" id="" name=""/>
                    </div>
                </div>
                <div className="col-md-5 col-lg-4 col-xl-3 mt-3 mt-md-0">
                    <div className="widget clearfix">
                      <div className="footer-social  mt-3">
                        <div className="row m-0">
                          <div className="col-3 mb-2 responsive-0 d-flex justify-content-center align-items-center"> 
                              <a class="responsive-icon" href="https://t.me/BeachToken" target="_blank" rel="noreferrer" >
                                <img className="img-fluid" src="/images/ic-telegram.png" alt="img"/></a>
                          </div>
                          <div className="col-3 mb-2 responsive-0 d-flex justify-content-center align-items-center">
                            <a className="responsive-icon" href="https://twitter.com/beach_token" target="_blank" rel="noreferrer" >
                              <img className="img-fluid" src="/images/ic-twitter2.png" alt="img"/></a>
                          </div>
                          <div className="col-3 responsive-0  mb-2 d-flex justify-content-center align-items-center"> 
                            <a className="responsive-icon " href="https://m.facebook.com/beachtoken" target="_blank" rel="noreferrer" ><img class="img-fluid" src="/images/ic-fb-f.png" alt="img"/></a>
                          </div>
                          <div className="col-3 responsive-0 mb-2  d-flex justify-content-center align-items-center"> 
                                <a className="responsive-icon" href="https://discord.gg/beachtoken" target="_blank" rel="noreferrer" ><img className="img-fluid" src="/images/ic-discord.png" alt="img"/></a>
                          </div>
                          <div className="col-3 mb-2 responsive-0  d-flex justify-content-center align-items-center">
                            <a className="responsive-icon" href="https://www.instagram.com/beach_token/" target="_blank" rel="noreferrer" >
                              <img className="img-fluid" src="/images/ico-instagram.svg" alt="img"/></a>
                          </div>
                          
                          
                          <div className="col-3 mb-2 responsive-0  d-flex justify-content-center align-items-center"> 
                              <a className="responsive-icon" href="https://www.youtube.com/channel/UCWe9w4b1asXIAxHatN_FFhw" target="_blank" rel="noreferrer" ><img className="img-fluid" src="/images/ic-YouTube2.png" alt="img"/></a>
                          </div>
                          <div className="col-3 mb-2 responsive-0  d-flex justify-content-center align-items-center"> 
                            <a className="responsive-icon" href="https://www.linkedin.com/company/80447914" target="_blank" rel="noreferrer" ><img className="img-fluid" src="/images/ic-linkedin2.png" alt="img"/></a>
                          </div>
                          <div className="col-3 mb-2 responsive-0  d-flex justify-content-center align-items-center"> 
                            <a className="responsive-icon" href="https://www.tiktok.com/@beach_token?lang=en" target="_blank" rel="noreferrer" ><img className="img-fluid" height="30" src="/images/ic-TikTok.png" alt="img"/></a>
                        </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </footer>
      </div>

      <Model show={openConnector} hide={setOpenConnector}  link={!walletAddress && loggedIn} />
      {/*<ProfileModel wallet_address={walletAddress} />*/}
      <SignupModalNew show={isOpen} onClose={setOpen} openLogin={setLoginPopup} />
      <LoginModal show={loginPopup} hide={setLoginPopup} openSignup={setOpen} />
      {loggedIn && <ReferencePopup show={sharingLink} hide={setSharingLink} />}
      <HomePopup show={homePop} openAction={setActionPopup} openPay={setPayPop} close={setHomePop} signup={setOpen} />
      <PayPopup show={payPop} close={setPayPop} back={true} />
      <ActionPopup show={actionPop} close={setActionPopup} back={true} />
      {loggedIn && <WelcomePopup />}
    </div>
  )
}

export default About